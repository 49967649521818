import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	filterHeaders: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		padding: "8px 0 30px 15px",
		alignItems: "center"
	},
	filterItem: {
		display: "flex",
		flexDirection: "column",
		marginRight: "40px"
	},
	applyButton: {
		alignItems: "center",
		height: "30px",
		marginTop: "15px"
	},
	applyButton2: {
		alignItems: "center",
		height: "30px",
		marginTop: "15px",
		boxShadow: "0 0 0 0.2rem #8dcdff"
	},
	exportButton: {
		flexGrow: "1",
		display: "flex",
		justifyContent: "flex-end",
		marginRight: "20px"
	},
	contentContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	empty: {
		display: "flex",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		marginTop: "30px"
	},
	titleheading: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	unittypeheading: {
		display: "flex",
		backgroundColor: "#c4c4c4",
		lineHeight: "1em"
	},
	unittypestyling: {
		marginLeft: "14px"
	},
	pageHeading: {
		flexWrap: "nowrap",
		flexDirection: "column",
		width: "100%"
	},
	reportHeading: {
		marginBottom: "100px"
	},
	"@media print": {
		matrixContainer: {
			border: "none"
		}
	}
}));
