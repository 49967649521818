import React from "react";
import { useSecondaryReviewFilterContext } from "../../../Context";
import { formatCurrencyNoFractions } from "utils/miscUtils";
import useStyle from "./useStyle";

export const RightContent = () => {
	const { selectedRow } = useSecondaryReviewFilterContext();
	const classes = useStyle();

	return (
		<span className={classes.recommendedPriceContainer}>
			Recommended Price: {formatCurrencyNoFractions(selectedRow?.recommendedPrice)}
		</span>
	);
};
