import { createThemedUseStyles } from "theme";
export default createThemedUseStyles((theme) => ({
	container: {
		display: "flex",
		height: "64px",
		background: theme.palette.blue,
		justifyContent: "space-between",
		width: "100%"
	},
	dropDown: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	title: {
		color: theme.palette.white,
		fontFamily: theme.fontFamilyEmphasis,
		fontSize: "18px",
		whiteSpace: "nowrap",
		textTransform: "uppercase",
		background: `${theme.palette.blue} !important`,
		border: "none",
		padding: "0px",
		"&:focus": {
			boxShadow: "none !important"
		},
		"@media (max-width: 768px)": {
			fontSize: "14px"
		}
	},
	items: {
		padding: "12px",
		maxHeight: "300px",
		width: "250px",
		borderRadius: "8px",
		marginTop: "-60px",
		marginLeft: "-112px",
		textTransform: "capitalize",
		position: "absolute",
		top: "7rem",
		left: "8rem",
		listStyle: "none",
		margin: "0",
		backgroundColor: theme.palette.white,
		boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.3)",
		fontFamily: `${theme.fontFamily}`,
		color: theme.palette.black,
		"& li + li": { borderTop: `1px solid ${theme.palette.lightGray}` },
		"@media (max-width: 768px)": {
			left: "8rem"
		}
	},
	item: {
		height: "40px",
		display: "flex",
		alignItems: "center",
		"&:focus": {
			boxShadow: "none !important"
		}
	},
	generalInfo: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	date: {
		color: theme.palette.white,
		textAlign: "right",
		fontSize: "14px",
		fontFamily: `${theme.fontFamily}`,
		fontWeight: "bold",
		lineHeight: "normal",
		textTransform: "capitalize",
		"@media (max-width: 768px)": {
			fontSize: "12px"
		}
	},
	totalProperties: {
		margin: "10px 0 0 0",
		color: theme.palette.white,
		textAlign: "right",
		fontSize: "12px",
		fontFamily: `${theme.fontFamily}`,
		"@media (max-width: 768px)": {
			fontSize: "11px"
		}
	},
	checkbox: {
		marginRight: "10px",
		transform: "scale(1.5)"
	}
}));
