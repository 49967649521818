import React, { useEffect, useState } from "react";
import useStyle from "./useStyle";
import { DataTable as PrDataTable, DataTableProps } from "primereact/datatable";
import constants from "utils/constants";
import { ColumnType } from "./types";
import { HeaderGroup } from "./HeaderGroup";
import { SearchState } from "components/forms/PricingAutomation/interfaces";
import { ContentColumns } from "./ContentColumns";
import ReactDOM from "react-dom";
import { Paginator } from "primereact/paginator";
import { ToggleColumns } from "./ToggleColumns";

interface Props extends DataTableProps {
	columns: ColumnType[];
	searchState?: SearchState;
	showingRef?: React.MutableRefObject<any>;
	hideColumnsRef?: React.MutableRefObject<any>;
	tableName?: string;
	emptyMessage?: string;
	loading?: boolean;
	totalCount?: number;
	data: DataTableProps["value"];
}

// Default values
const defaultProps: DataTableProps = {
	lazy: true,
	autoLayout: true,
	dataKey: "id",
	paginator: true,
	pageLinkSize: 5,
	sortMode: "single",
	alwaysShowPaginator: true,
	rowsPerPageOptions: constants.rowsPerPageOptions,
	paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
};

/**
 * Data table component that displays a table with the data passed in.
 * To use it, you need to pass in the columns and the data, following the
 * correct type.
 *
 * @returns JSX.Element
 */
export const DataTable: React.FC<Props> = ({
	columns: defaultColumns = [],
	searchState,
	tableName,
	showingRef,
	emptyMessage,
	hideColumnsRef,
	loading,
	data = [],
	...props
}) => {
	const classes = useStyle();
	const [columns, setColumns] = useState(defaultColumns);

	const onToggleColumn = (columnName: string) => (checked: boolean) => {
		const newState = columns.map((column) => (column.name === columnName ? { ...column, visible: checked } : column));
		setColumns(newState);
	};

	useEffect(() => {
		setColumns(defaultColumns);
	}, [defaultColumns]);

	const headerGroup = HeaderGroup({ columns });

	return (
		<>
			{showingRef &&
				showingRef.current &&
				ReactDOM.createPortal(
					<Paginator
						rows={searchState?.take}
						first={searchState?.skip}
						totalRecords={props.totalRecords}
						className={classes.currentPageReport}
						template="CurrentPageReport"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
					/>,
					showingRef.current
				)}
			{hideColumnsRef &&
				hideColumnsRef.current &&
				ReactDOM.createPortal(
					<ToggleColumns columns={columns} onToggle={onToggleColumn} />,
					hideColumnsRef.current
				)}
			<PrDataTable
				loading={loading}
				rows={searchState?.take}
				first={searchState?.skip}
				sortField={searchState?.sortField}
				sortOrder={searchState?.sortOrder}
				onPage={props.onPage}
				onSort={props.onSort}
				emptyMessage={emptyMessage}
				headerColumnGroup={headerGroup}
				className={[classes.table].join(" ")}
				rowsPerPageOptions={constants.rowsPerPageOptions}
				value={data}
				{...defaultProps}
				{...props}
			>
				{ContentColumns({ columns })}
			</PrDataTable>
		</>
	);
};
