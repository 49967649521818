import React from "react";
import useStyle from "./useStyle";
import MenuItem, { MenuItemProps } from "./MenuItem";
import cssUtils from "utils/cssUtils";

export * from "./MenuItem";

export interface MenuProps {
	title: string | JSX.Element;
	items?: MenuItemProps[];
	actionArea?: JSX.Element;
}

const Menu: React.FC<MenuProps> = function (props) {
	const classes = useStyle();

	return (
		<div className={classes.container}>
			<div className={classes.titleBar}>
				<span className={["actions-bar_title", classes.title].join(" ")}>{props.title}</span>
				<div className={["actions-bar_menu", classes.items].join(" ")}>
					<div className={cssUtils.join(classes.mainItems, "mainMenuItems")}>
						{props.items &&
							props.items.map((i: MenuItemProps, index: number) => {
								return (
									<div key={i.title}>
										{/* The first tile always has an arrow in front of it. Also, any create or update screens have an arrow between all navbar elements. */}
										{index === 0 && (
											<i className="pi pi-angle-right" key={i.title + "mainPageArrows" + index}></i>
										)}
										{index > 0 && props.items!.length < 5 && (
											<i className="pi pi-angle-right" key={i.title + "createScreenArrows" + index}></i>
										)}
										<MenuItem key={i.title} {...i} />
									</div>
								);
							})}
						{props.children}
					</div>
					<div className={["actions-bar_menu_actions-area", classes.actionItems].join(" ")}>
						{props.actionArea}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Menu;
