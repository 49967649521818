import { ColumnType } from "components/controls/DataTable/types";
import { siteDefaultTheme as theme } from "theme";
import {
	formatDate,
	formatDecimalPoints,
	formatNetConversionBPS,
	formatVariance,
	formatPercentage,
	formatPercentageRoundedWhole,
	formatPrice
} from "utils/formatTemplates";
import { getAvailableUnitColumnTemplate } from "../../../utils/columnBodyHelper";

export const columns: ColumnType[] = [
	{
		name: "Property",
		subColumns: [{ field: "property" }],
		color: theme.palette.primary,
		align: "left"
	},
	{ name: "Unit Group", subColumns: [{ field: "unitGroupMasterName" }], align: "left" },
	{ name: "Total Units", subColumns: [{ field: "unitCount" }] },
	{ name: "Unit Avl", minWidth: "100px", subColumns: [{ field: "availableUnitCount", body: getAvailableUnitColumnTemplate(), name: "" }] },	
	{
		name: "ATR",
		subColumns: [
			{ field: "atr", name: "%", sortable: true, format: formatPercentage("atr") },
			{ field: "atrVariance", name: "VARIANCE %", sortable: true, format: formatPercentage("atrVariance") }
		]
	},		
	{
		name: "ATR - Property",
		visible: false,
		subColumns: [
			{ field: "atrProperty", name: "%", format: formatPercentage("atrProperty") },
			{ field: "atrPropertyVariance", name: "VARIANCE %", format: formatPercentage("atrPropertyVariance") }
		]
	},		
	{ name: "Auto-Price", subColumns: [{ name: "$", field: "currentPrice", format: formatPrice("currentPrice") }] },
	{
		name: "Auto-Price Adj",
		minWidth: "200px",
		subColumns: [
			{ name: "$", field: "priceAdjustment", format: formatPrice("priceAdjustment") },
			{ name: "% CHANGE", field: "priceAdjustmentPercent", format: formatPercentage("priceAdjustmentPercent") }
		]
	},
	{ name: "Yesterday's Price", subColumns: [{ name: "$", field: "publishedPrice", format: formatPrice("publishedPrice") }] },	
	{ name: "Last Price", visible: false, subColumns: [{ name: "$", field: "lastPrice", format: formatPrice("lastPrice") }] },
	{
		name: "Last Price Adj",
		subColumns: [
			{ name: "$", field: "lastPriceAdjustment", format: formatPrice("lastPriceAdjustment") },
			{ name: "% CHANGE", field: "lastPriceAdjustmentPercent", format: formatPercentage("lastPriceAdjustmentPercent") },
			{ name: "DATE", field: "lastPriceDate", format: formatDate("lastPriceDate") }
		]
	},
	{
		name: "Leads",
		subColumns: [
			{ field: "leads", name: "#" },
			{ field: "leadsWoWPctVariance", name: "VARIANCE", format: formatPercentageRoundedWhole("leadsWoWPctVariance") }
		]
	},
	{
		name: "Net Apps",
		minWidth: "13ch",
		subColumns: [
			{ field: "netApps", name: "#" }
		]
	},
	{
		name: "Net Apps - Property",
		minWidth: "13ch",
		visible: false,
		subColumns: [
			{ field: "netAppsProperty", name: "#" }
		]
	},
	{
		name: "LTA/GTA",
		minWidth: "13ch",
		subColumns: [
			{ field: "ltaGtaRecommended", name: "NEW", format: formatPercentage("ltaGtaRecommended") },
			{ field: "ltaGta", name: "CURRENT", format: formatPercentage("ltaGta") }
		]
	},
	{
		name: "LTA/GTA - Property",
		minWidth: "13ch",
		visible: false,
		subColumns: [
			{ field: "ltaGtaProperty", name: "%", format: formatPercentage("ltaGtaProperty") }
		]
	},	
	{
		name: "Net Conversion - Property",
		minWidth: "16ch",
		subColumns: [
			{ field: "netConversion", name: "%", format: formatPercentage("netConversion") },
			{
				field: "netConversionVariance",
				name: "VARIANCE",
				format: formatVariance("netConversionVariance")
			}
		]
	},	
	{
		name: "Leasing Velocity",
		minWidth: "16ch",
		subColumns: [
			{ field: "leasingVelocityTarget", name: "TARGET", format: formatDecimalPoints("leasingVelocityTarget") },
			{
				field: "leasingVelocity",
				name: "DELTA",
				format: formatDecimalPoints("leasingVelocity")
			}
		]
	}	
];
