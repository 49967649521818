import { ACTIONS_DEFAULT, ActionsContext } from "./context";

import React, { FC, useState } from "react";

export const ActionsProvider: FC = ({ children }) => {
	const [data, setData] = useState(ACTIONS_DEFAULT.data);
	const [confirmation, setConfirmation] = useState(ACTIONS_DEFAULT.confirmation);

	const handleClose = () => {
		setConfirmation("");
	};

	const handleOpen = (type: string) => {
		setConfirmation(type);
	};

	return (
		<ActionsContext.Provider value={{ confirmation, handleClose, handleOpen, data, handleData: setData }}>
			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
