/* eslint-disable react-hooks/exhaustive-deps */
import { useUserOverridesService } from "components/forms/PricingAutomation/services/userOverrides.service";
import { useQuery } from "@tanstack/react-query";

const defaultOverrideFormOptions = {
	overrideReasons: [],
};

export const useLoadData = () => {
	const { getOverrideFormOptions } = useUserOverridesService();

	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		queryKey: ["override-options"],
		queryFn: async ({ signal }) => getOverrideFormOptions(signal)
	});

	return { overrideFormOptionsData: data || defaultOverrideFormOptions, isInitialLoading };
};
