import React from "react";
import { UserModifiedUnitGroups } from "./UserModifiedUnitGroups";
import useStyle from "./useStyle";
import { ExclusionsFilterProvider } from "./Context";
import { ExclusionsFilterSection } from "./Filter";
import { UnitGroupExcludedFromAutomation } from "./UnitGroupExcludedFromAutomation";

const ExclusionsPage: React.FC = () => {
	const classes = useStyle();
	return (
		<main className={classes.container} id="pgExclusions">
			<ExclusionsFilterProvider>
				<ExclusionsFilterSection />
				<UnitGroupExcludedFromAutomation />
				<UserModifiedUnitGroups />
			</ExclusionsFilterProvider>
		</main>
	);
};

export default ExclusionsPage;
