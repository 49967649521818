import React, { useEffect, useState } from "react";
import Page from "components/layouts/Page";
import RequirePermission from "../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants, { pageAction, MessageAreaObject } from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";

import "./_manage-unitgroupmaster.scss";
import PrimaryButton from "components/controls/PrimaryButton";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import Dialog from "components/controls/Dialog";
import { Button } from "primereact/button";
import Input from "components/controls/Input";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { UnitGroupMaster, UnitGroupMasterForm, UpdateUnitGroupMasterForm } from "api";
import { UnitGroupMasterParams, getPageActionType, unitGroupMasterInitState } from "../MiscUnitGroupFunctions";
import {UnitGroupMasterCreateMenu, UnitGroupMasterUpdateMenu} from "./ManageUnitGroupMasterMenu";
import { isNullOrUndefinedOrEmpty, isEmptyObject, isNullOrUndefinedOrEmptyOrWhiteSpace } from "utils/miscUtils";
import Spinner from "components/controls/Spinner";

const ManageUnitGroupMaster: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const history = useHistory();
	const location = useLocation();
	const params = useParams<UnitGroupMasterParams>();

	//Track form state
	const [unitGroups, setUnitGroups] = useState<UnitGroupMaster[] | undefined>(undefined);
	const [localState, setLocalState] = useState(unitGroupMasterInitState);
	const [createPageDirty, setCreatePageIsDirty] = useState(false);
	const [updatePageDirty, setUpdatePageIsDirty] = useState(false);
	const [saveError, setSaveError] = useState(false);
	const [updateLoading, setUpdateLoading]= useState(true);
	const [spinnerVisible, setSpinnerVisible] = useState(false);
	const [saveErrorObject, setSaveErrorObject] = useState<MessageAreaObject>({
		title: constants.MessageAreaMessages.ApiDownTitle,
		message: constants.MessageAreaMessages.ApiDownMessage,
		severity: MessageSeverityLevel.Warning as MessageSeverity,
		autoHide: false
	});

	function isDirty() {
		if (onCreatePage()) {
			return createPageDirty;
		}

		if (onUpdatePage()) {
			return updatePageDirty;
		}
	}

	function resetForm (){
		localState.unitGroupMasterId = undefined;
		localState.unitGroupMasterName = "";
		localState.description = undefined;
		localState.bedroomId = undefined;
		localState.selectedBedroom = undefined;
		localState.pageAction = constants.pageAction.create;

		localState.initialUpdateName = "";
		localState.initialUpdateDescription = "";
		localState.initialUpdateBedroom = undefined;

		setLocalState({
			...localState,
			unitGroupMasterName: "",
			description: undefined,
			bedroomId: undefined,
			selectedBedroom: undefined,
			initialUpdateName: "",
			initialUpdateDescription: "",
			initialUpdateBedroom: undefined,
			pageAction: constants.pageAction.create
		});
	};

	function isDuplicate(){

		if(unitGroups !== undefined && unitGroups.length === 0){
			return false;
		}else if(unitGroups !== undefined && unitGroups.length !== 0){
			var exists = unitGroups!.find((x: UnitGroupMaster)=>{
				return ((x.bedroomId === localState.selectedBedroom!.key) && (x.name!.toLocaleLowerCase() === localState.unitGroupMasterName.toLocaleLowerCase()) && (x.id !== undefined && x.id !== localState.unitGroupMasterId));
			});

			return exists !== undefined;
		}

	}

	useFirstLoadEffect(() => {
		try {
			actions.getUnitGroupMastersWithBedrooms();
			if (onCreatePage()) {
				setUpdateLoading(false);
				resetForm();
				actions.loadBedroomDropdowns();
				
			} else {
				getUnitGroupMasterUpdateForm(localState.unitGroupMasterId!);
			}
		} catch (error) {
			setSaveError(true);
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Error as MessageSeverity,
				false
			);
		}
	});

	useEffect(() => {
		setUnitGroups(pageState.unitGroupMastersWithBedrooms);
	}, [unitGroups, pageState.unitGroupMastersWithBedrooms]);

	function onSave() {

		setSpinnerVisible(true);
		if (onCreatePage() === true) {
			var unitGroupCreateForm = {
				name: localState.unitGroupMasterName,
				description: localState.description,
				bedroomId: localState.selectedBedroom!.key!,
				bedroomCount: parseInt(localState.selectedBedroom!.displayValue!)
			} as UnitGroupMasterForm;

			createUnitGroupMaster(unitGroupCreateForm);
		}

		if (onUpdatePage() === true) {
			var unitGroupUpdateForm = {
				id: localState.unitGroupMasterId,
				name: localState.unitGroupMasterName,
				description: localState.description,
				bedroomId: localState.selectedBedroom!.key!,
				bedroomCount: parseInt(localState.selectedBedroom!.displayValue!)
			} as UnitGroupMasterForm;

			updateUnitGroupMaster(unitGroupUpdateForm);
		}
	}

	function onNameChange(value: string) {
		setCreatePageIsDirty((value !== undefined && value.length !== 0) || localState.selectedBedroom !== undefined);
		setUpdatePageIsDirty(
			value !== localState.initialUpdateName ||
				localState.description !== localState.initialUpdateDescription ||
				(localState.selectedBedroom !== undefined && localState.selectedBedroom !== localState.initialUpdateBedroom)
		);

		setLocalState({
			...localState,
			unitGroupMasterName: value
		});
	}

	function onBedroomChange(value: DropdownOption | undefined) {
		setCreatePageIsDirty(
			value !== undefined ||
				(localState.unitGroupMasterName !== undefined && localState.unitGroupMasterName.length !== 0)
		);
		setUpdatePageIsDirty(
			localState.unitGroupMasterName.trim() !== localState.initialUpdateName ||
				localState.description !== localState.initialUpdateDescription ||
				(value !== undefined && value !== localState.initialUpdateBedroom)
		);

		setLocalState({
			...localState,
			selectedBedroom: value
		});
	}

	function onDescriptionChange(value: string) {
		setCreatePageIsDirty(
			(value !== undefined && value.length !== 0) &&
				(localState.selectedBedroom !== undefined ||
				(localState.unitGroupMasterName !== undefined && localState.unitGroupMasterName.length !== 0))
		);
		setUpdatePageIsDirty(
			value.trim() !== localState.initialUpdateDescription ||
				localState.unitGroupMasterName.trim() !== localState.initialUpdateName ||
				(localState.selectedBedroom !== undefined && localState.selectedBedroom !== localState.initialUpdateBedroom)
		);
		setLocalState({
			...localState,
			description: value
		});
	}

	function onCreatePage() {
		if (localState.pageAction === pageAction.create) {
			return true;
		}
	}

	function onUpdatePage() {
		if (localState.pageAction === pageAction.update) {
			return true;
		}
	}

	function redirectUnitGroupMasterHome() {
		resetForm();
		history.push(routes.unitGroupAdmin.unitGroupMasters);
	}

	/* Params
	 */
	if (location && !isNullOrUndefinedOrEmpty(location.pathname)) {
		if (!isEmptyObject(params)) {
			const action = getPageActionType(location.pathname);
			if (action && action === constants.pageAction.create) {
				localState.pageAction = constants.pageAction.create;
			}

			if (action && action === constants.pageAction.update) {
				localState.unitGroupMasterId = params.unitGroupMasterId;
				localState.pageAction = constants.pageAction.update;
			}
		} else {
			redirectUnitGroupMasterHome();
		}
	}

	const createUnitGroupMaster = (unitGroupMasterForm: UnitGroupMasterForm) => {

		if(!isDuplicate()){

			actions.createUnitGroupMaster(unitGroupMasterForm).then((response: string | null | undefined) => {
				if (response === undefined) {
					setSaveError(true);
					setMessageBasedOnCustomMessage(
						constants.MessageAreaMessages.ApiDownTitle,
						constants.MessageAreaMessages.ApiDownMessage,
						MessageSeverityLevel.Error as MessageSeverity,
						false
					);
					setSaveErrorObject({
						...saveErrorObject,
						title: constants.MessageAreaMessages.ApiDownTitle,
						message: constants.MessageAreaMessages.ApiDownMessage,
						severity: MessageSeverityLevel.Error as MessageSeverity,
						autoHide: false
					});
					return;
				}

				setSpinnerVisible(false);
				setCreatePageIsDirty(false);
				resetForm();
				redirectUnitGroupMasterHome();

			});
	

		}else{
			setSpinnerVisible(false);
			setSaveError(true);
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.DuplicateUnitGroupMasterTitle,
				constants.MessageAreaMessages.DuplicateUnitGroupMasterMessage,
				MessageSeverityLevel.Error as MessageSeverity,
				false
			);
			setSaveErrorObject({
				...saveErrorObject,
				title: constants.MessageAreaMessages.DuplicateUnitGroupMasterTitle,
				message: constants.MessageAreaMessages.DuplicateUnitGroupMasterMessage,
				severity: MessageSeverityLevel.Error as MessageSeverity,
				autoHide: false
			});
			return;

		}

	};

	const updateUnitGroupMaster = (unitGroupMasterForm: UnitGroupMasterForm) => {
		
		if(!isDuplicate()){
			actions.updateUnitGroupMaster(unitGroupMasterForm).then((response: string | null | undefined) => {
				if (response === undefined) {
					setSaveError(true);
					setMessageBasedOnCustomMessage(
						constants.MessageAreaMessages.ApiDownTitle,
						constants.MessageAreaMessages.ApiDownMessage,
						MessageSeverityLevel.Warning as MessageSeverity,
						false
					);
					setSaveErrorObject({
						...saveErrorObject,
						title: constants.MessageAreaMessages.ApiDownTitle,
						message: constants.MessageAreaMessages.ApiDownMessage,
						severity: MessageSeverityLevel.Error as MessageSeverity,
						autoHide: false
					});
					return;
				}
				setSpinnerVisible(false);
				setUpdatePageIsDirty(false);
				resetForm();
				redirectUnitGroupMasterHome();
			});


		}else{
			setSpinnerVisible(false);
			setSaveError(true);
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.DuplicateUnitGroupMasterTitle,
				constants.MessageAreaMessages.DuplicateUnitGroupMasterMessage,
				MessageSeverityLevel.Error as MessageSeverity,
				false
			);
			setSaveErrorObject({
				...saveErrorObject,
				title: constants.MessageAreaMessages.DuplicateUnitGroupMasterTitle,
				message: constants.MessageAreaMessages.DuplicateUnitGroupMasterMessage,
				severity: MessageSeverityLevel.Error as MessageSeverity,
				autoHide: false
			});
			return;
		}

	};

	const getUnitGroupMasterUpdateForm = (unitGroupMasterId: string) => {
		actions.getUpdateUnitGroupMasterForm(unitGroupMasterId).then((result: UpdateUnitGroupMasterForm | undefined) => {
			const unitGroupDetail = result as UpdateUnitGroupMasterForm;
			setUpdateLoading(false);

			setLocalState({
				...localState,
				initialUpdateName: unitGroupDetail.name !== undefined ? unitGroupDetail.name : "",
				initialUpdateDescription: unitGroupDetail.description !== undefined ? unitGroupDetail.description : "",
				initialUpdateBedroom: unitGroupDetail.bedrooms!.find((x:DropdownOption) => x!.key === unitGroupDetail.bedroomId),
				unitGroupMasterName: unitGroupDetail.name!,
				description: unitGroupDetail.description,
				bedrooms: unitGroupDetail.bedrooms,
				bedroomId: unitGroupDetail.bedroomId!,
				selectedBedroom: unitGroupDetail.bedrooms!.find((x: DropdownOption) => x!.key === unitGroupDetail.bedroomId),
			});
		});
	};

	function renderMenu() {
		if (onCreatePage() === true) {
			return <UnitGroupMasterCreateMenu actionArea={navbarActionArea} />;
		} else {
			return <UnitGroupMasterUpdateMenu actionArea={navbarActionArea} />;
		}
	}

	function renderFooter() {
		return (
			<div>
				<Button
					label={constants.buttonLabel.ok}
					icon="pi pi-check"
					onClick={() => {
						setSaveError(false);
					}}
				/>
			</div>
		);
	}



	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}>
					<PrimaryButton
						className="cancel-button"
						onClick={redirectUnitGroupMasterHome}
						title={constants.buttonLabel.cancel}
					/>
					<PrimaryButton
						className="save-button"
						disabled={!isDirty() || isNullOrUndefinedOrEmptyOrWhiteSpace(localState.unitGroupMasterName) || (localState.selectedBedroom === undefined)}
						onClick={onSave}
						title={constants.buttonLabel.save}
					/>
					<Dialog
						header={saveErrorObject.title}
						footer={renderFooter()}
						isVisible={saveError}
						onHide={() => setSaveError(false)}
					>
						{`${saveErrorObject.message}`}
					</Dialog>
				</VisibilityToggle>
			</div>
		</RequirePermission>
	);

	const createForm = () => {
		return (
			<Card>
				<Spinner isVisible={spinnerVisible}/>
				<div className="create-unit-group-master-form">
					{onCreatePage() ? <h1> Create Unit Group Master</h1> : <h1>{localState.unitGroupMasterName}</h1>}

					<label className="label">
						Name
						<span className="star">*</span>
					</label>
					<Input
						value={localState.unitGroupMasterName}
						onChange={onNameChange}
						className="unit-group-master-input"
					></Input>

					<label className="label">Description</label>
					<Input
						value={localState.description}
						onChange={onDescriptionChange}
						className="unit-group-master-input"
					></Input>

					<label className="label">
						Bedrooms
						<span className="star">*</span>
					</label>
					<Dropdown
						placeholder={"Select bedrooms"}
						selectedValue={localState.selectedBedroom}
						options={pageState.bedroomDropdowns}
						onChange={onBedroomChange}
						className="unit-group-master-dropdown"
					></Dropdown>
				</div>
			</Card>
		);
	};

	const updateForm = () => {
		return (
			<Card>
				<Spinner isVisible={spinnerVisible}/>
				<div className="update-unit-group-master-form">
					{<h1>{localState.unitGroupMasterName}</h1>}
					<label className="label">
						Name
						<span className="star">*</span>
					</label>
					<Input
						value={localState.unitGroupMasterName}
						onChange={onNameChange}
						className="unit-group-master-input"
					></Input>

					<label className="label">Description</label>
					<Input
						value={localState.description}
						onChange={onDescriptionChange}
						className="unit-group-master-input"
					></Input>

					<label className="label">
						Bedrooms
						<span className="star">*</span>
					</label>
					<Dropdown
						placeholder={"Select bedrooms"}
						selectedValue={localState.selectedBedroom}
						options={pageState.bedroomDropdowns}
						onChange={onBedroomChange}
						className="unit-group-master-dropdown"
					></Dropdown>
				</div>
			</Card>
		);
	};

	return (
		<Page menu={renderMenu()} className="manage-unit-group-master-page">
			<div id="content-container" className="unit-group-master-wrapper">
				{pageState.isLoading || updateLoading ? (
					<Spinner isVisible={true}/>
				) : (
					<div className="manage-unit-group-master-body">{onCreatePage() ? createForm() : updateForm()}</div>
				)}
			</div>
		</Page>
	);
};

export default ManageUnitGroupMaster;
