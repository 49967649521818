import { useQuery } from "@tanstack/react-query";
import { useSecondaryReviewService } from "components/forms/PricingAutomation/services/secondaryReview.service";
import { useSecondaryReviewFilterContext } from "../../../Context";

export const useLoadData = () => {
	const { getConcessionList } = useSecondaryReviewService();
	const { selectedRow } = useSecondaryReviewFilterContext();
	const { data, isInitialLoading, isFetching, isPreviousData } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["concessions", selectedRow],
		queryFn: ({ signal }) => getConcessionList({ unitGroupId: selectedRow?.group ?? "" }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { data: data?.items || [], isLoading };
};
