import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	submitButton: {
		background: theme.palette.text,
		border: "none"
	},
	cancelButton: {
		color: `${theme.palette.text} !important`
	},
	formButtons: {
		paddingTop: "32px",
		display: "flex",
		gap: "12px"
	},
	errorMessage: {
		color: theme.palette.error,
		paddingBottom: "8px",
		fontSize: "small"
	},
	centerText: {
		textAlign: "center"
	}
}));
