import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	pageWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%"
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		justifyContent: "center"
	}
}));
