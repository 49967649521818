import { createContext } from "react";
import { createTheming, createUseStyles } from "react-jss";
import { StyleSheetFactoryOptions } from "jss";
import { Theming } from "theming";

const palette = {
	// Essex approved palette
	primary: "#c75109",
	primaryAlternateState: "#ab4202",
	primaryBlue: "#0070C0",
	foregroundContrast: "#ffffff",
	inactive: "#666666",
	softRed: "#C10404",
	dark: "#2e2f31",
	alternatingRowGray: "#f9f9f9",
	purple: "#7030A0",
	black: "#000",
	emphasis: "#66cccc",
	success: "#008F1F",
	warning: "#F9C201",
	blue: "#00838F",
	transparentBlue: "#00838f29",
	darkBlue: "#016670",
	gray: "#3e3e3e",
	darkGray: "#555",
	lightGray: "#ccc",
	faintGray: "#eee",
	background: "#ffffff",
	grayBackground: "#F8F8F8",
	bodyBackground: "#dfdfdf",
	transparent: "transparent",
	error: "#E50404",
	white: "#fff",
	text: "#333333"
};

const typeScale = {
	xsmall: 10,
	small: 12,
	medium: 14,
	large: 18,
	xlarge: 24,
	xxlarge: 32
};

const typeWeight = {
	bold: "bold"
};

const siteDefaultTheme = {
	fontFamily: ["OpenSans-Regular", "Helvetica Neue", "sans-serif"], //["'raleway-regular'", "Arial", "Helvetica", "sans-serif"],
	fontFamilyEmphasis: ["raleway-bold", "Arial", "Helvetica", "sans-serif"],
	color: palette.gray,
	palette,
	text: {},
	marginScale: {
		none: 0,
		xsmall: 2,
		small: 4,
		medium: 8,
		large: 16,
		xlarge: 32,
		xxlarge: 64
	},
	paddingScale: {
		none: 0,
		xsmall: 3,
		small: 6
	},
	sizeScale: {
		xxsmall: 8,
		xsmall: 16,
		small: 32,
		medium: 64,
		large: 128,
		xlarge: 256,
		xxlarge: 512
	},
	typeScale: typeScale,
	columnSize: {
		cols1: "8.333%",
		cols2: "16.667%",
		cols3: "25.000%",
		cols4: "33.333%",
		cols5: "41.667%",
		cols6: "50.000%",
		cols7: "58.333%",
		cols8: "66.667%",
		cols9: "75.000%",
		cols10: "83.333%",
		cols11: "91.667%",
		cols12: "100.000%"
	},
	navHeight: "64px",
	maxScreenWidth: "1440px",
	headings: {
		h1: {
			fontSize: typeScale.xxlarge,
			fontWeight: typeWeight.bold,
			lineHeight: "1.2em"
		},
		h2: {
			fontSize: typeScale.xlarge,
			fontWeight: typeWeight.bold,
			lineHeight: "1.2em"
		},
		h3: {
			fontSize: typeScale.large,
			fontWeight: typeWeight.bold,
			lineHeight: "1.2em"
		}
	},
	buttons: {
		primary: {
			border: palette.primary,
			borderFocus: "unset", //palette.emphasis,
			background: palette.primary,
			backgroundFocus: palette.primaryAlternateState,
			foreground: palette.foregroundContrast,
			foregroundFocus: palette.foregroundContrast
		},
		secondary: {
			border: palette.white,
			borderFocus: palette.white,
			background: palette.transparent,
			backgroundFocus: palette.transparent,
			foreground: palette.white,
			foregroundFocus: palette.white
		},
		secondaryWarn: {
			border: palette.gray,
			borderFocus: palette.darkGray,
			background: palette.softRed,
			backgroundFocus: palette.darkGray,
			foreground: palette.foregroundContrast,
			foregroundFocus: palette.background
		},
		secondaryImportant: {
			border: palette.transparent,
			borderFocus: palette.transparent,
			background: palette.softRed,
			backgroundFocus: palette.softRed,
			foreground: palette.foregroundContrast,
			foregroundFocus: palette.foregroundContrast
		},
		secondaryNotice: {
			border: palette.primary,
			borderFocus: palette.primaryAlternateState,
			background: palette.transparent,
			backgroundFocus: palette.transparent,
			foreground: palette.primary,
			foregroundFocus: palette.primaryAlternateState
		},
		secondaryContrast: {
			border: palette.background,
			borderFocus: palette.background,
			background: palette.transparent,
			backgroundFocus: palette.darkGray,
			foreground: palette.background,
			foregroundFocus: palette.background
		},
		minimal: {
			border: palette.transparent,
			borderFocus: palette.emphasis,
			background: palette.background,
			backgroundFocus: palette.background,
			foreground: palette.gray,
			foregroundFocus: palette.gray
		}
	}
};

const themeContext = createContext(siteDefaultTheme);
const siteTheming = createTheming(themeContext);
const { ThemeProvider, useTheme } = siteTheming;

const createUseStylesFactory = function <T>(theming: Theming<T>) {
	return function <C extends string>(
		styles: (theme: T) => Record<C, object | T | string>,
		options?: {
			index?: number;
			name?: string;
		} & StyleSheetFactoryOptions
	) {
		return createUseStyles(styles, { theming } as any);
	};
};

var createThemedUseStyles = createUseStylesFactory(siteTheming);

export {
	ThemeProvider as SiteThemeProvider,
	useTheme as useSiteTheme,
	siteDefaultTheme,
	siteTheming,
	createThemedUseStyles
};
