/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import useStyle from "./useStyle";
import constants from "utils/constants";
import { formatDateMDY } from "../../../utils/miscUtils";
import Menu from "../../forms/Menu";
import { Button } from "primereact/button";
import { useOnClickOutside } from "utils/useOnClickOutside";
import { Checkbox } from "../PricingCheckbox";
import { useAtAGlanceContext } from "components/forms/PricingAutomation/Pages/Home/Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

const TopHeaderMenu: React.FC = () => {
	const classes = useStyle();
	const { getTotalProperties } = useHomeService();
	const { filters, handleChange } = useAtAGlanceContext();
	const { data } = useQuery({
		keepPreviousData: true,
		initialData: { totalProperties: 0 },
		queryKey: ["total-properties", filters],
		queryFn: ({ signal }) => getTotalProperties(filters, signal)
	});
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLUListElement>(null);

	const options = [
		{
			key: "",
			label: "Total Portfolio"
		},
		{
			key: "laven",
			label: "LA/Ventura"
		},
		{
			key: "norcal",
			label: "Northern California"
		},
		{
			key: "ocsd",
			label: "OC/San Diego"
		},
		{
			key: "pnw",
			label: "Washington"
		}
	];

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleDivisionSelect = (key: any) => {
		handleChange(key)("divisionId");
		setIsOpen(false);
	};

	useOnClickOutside(menuRef, toggleDropdown);

	return (
		<>
			<Menu
				title={
					<>
						<Button
							label={options.filter((item) => item.key === filters.divisionId)[0].label}
							icon={"pi pi-angle-down"}
							iconPos="right"
							className={classes.title}
							onClick={toggleDropdown}
							aria-controls="popup_total_portfolio"
							aria-haspopup
						/>
						{isOpen && (
							<ul id="overlay-checkbox" className={classes.items} ref={menuRef}>
								{options.map((item) => (
									<li className={classes.item} key={item.key}>
										<Checkbox
											label={item.label}
											checked={filters.divisionId === item.key}
											onChange={() => handleDivisionSelect(item.key)}
										/>
									</li>
								))}
							</ul>
						)}
					</>
				}
				actionArea={
					<div className={classes.generalInfo}>
						<span className={classes.date}>{formatDateMDY(new Date())}</span>
						<span className={classes.totalProperties}>
							{`${constants.extraContent.totalProperties}: ${data?.totalProperties}`}
						</span>
					</div>
				}
			/>
		</>
	);
};

export default TopHeaderMenu;
