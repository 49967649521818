import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	checkbox: {
		width: "18px",
		height: "18px",
		minHeight: "18px",
		border: "1px solid black",
		borderRadius: "2px",
		position: "relative",
		"&:checked:before": {
			content: "'✓'",
			fontSize: "18px",
			fontWeight: "bold",
			position: "absolute",
			right: "1px",
			top: "-4.5px"
		}
	},
	checkboxContainer: {
		display: "flex",
		alignItems: "center",
		fontWeight: "400",
		fontSize: "14px"
	},
	gap: {
		gap: "8px"
	}
}));
