import { Dialog } from "primereact/dialog";
import React from "react";
import useStyle from "./useStyle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useConfigureAutomationService } from "components/forms/PricingAutomation/services/configureAutomation.service";
import { useAlertContext } from "components/forms/PricingAutomation/context/AlertContext";
import constants from "utils/constants";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { PricingButton } from "components/controls/PricingButton";

interface Props {
	open: boolean;
	onClose: () => void;
	data: any;
}

export const DeleteColumnConfirmation = ({ open, onClose, data }: Props) => {
	const classes = useStyle();
	const { deleteParamRow } = useConfigureAutomationService();
	const { setAlert } = useAlertContext();
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation({
		mutationKey: ["delete"],
		mutationFn: deleteParamRow,
		onSuccess: () => {
			setAlert({
				message:
					`${data["name"]} - ${data["columnKey"]} ` +
					constants.MessageAreaMessages.DeleteColumnParamSuccessMessage,
				severity: MessageSeverityLevel.Success as MessageSeverity,
				title: constants.MessageAreaMessages.Success,
				autoHide: true
			});
			//Refresh grid
			queryClient.invalidateQueries({ queryKey: ["configured"] });
			onClose();
		},
		onError: () => {
			setAlert({
				message:
					`${data["name"]} - ${data["columnKey"]} ` + constants.MessageAreaMessages.DeleteColumnParamErrorMessage,
				severity: MessageSeverityLevel.Error as MessageSeverity,
				title: constants.MessageAreaMessages.Error,
				autoHide: true
			});
			onClose();
		}
	});

	return (
		<Dialog visible={open} header={"Delete confirmation"} focusOnShow={false} onHide={onClose}>
			<div className={classes.confirmationContainer}>
				<p className={classes.message}>
					Are you sure you want to <strong>Delete</strong> value for <strong>{data["name"] ?? ""}</strong>
					{" - "}
					<strong>{data["columnKey"]}</strong>?
				</p>
			</div>
			<div className={classes.buttonsContainer}>
				<PricingButton
					variant="primary"
					type="button"
					isLoading={isLoading}
					onClick={() => mutate({ ...data["rowInfo"], paramName: data["columnKey"] } ?? {})}
				>
					Delete
				</PricingButton>
				<PricingButton variant="secondary" type="button" disabled={isLoading} onClick={onClose}>
					Cancel
				</PricingButton>
			</div>
		</Dialog>
	);
};
