import {
	ConcessionsReportFilterResponse,
	ConcessionsReportClient,
	ConcessionsReportRequest,
	ConcessionsReportResponse
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";

export const ConcessionsReportPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {
		const getForm = function (currentDivision: ConcessionsReportRequest): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, ConcessionsReportClient, "getConcessionsReportFilters");

			makeRemoteRequest(filterOptionsEndpoint, [currentDivision]).then((response) => {
				var options = response.data as ConcessionsReportFilterResponse;
				dispatcher({
					type: PricingPlatformActionTypes.UpdateConcessionsReportPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const updateFilterOptions = function (currentSelections: ConcessionsReportRequest): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, ConcessionsReportClient, "getConcessionsReportFilters");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as ConcessionsReportFilterResponse;
				dispatcher({
					type: PricingPlatformActionTypes.UpdateConcessionsReportPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const searchConcessions = function (currentFilterSelected: ConcessionsReportRequest): void {
			let apiEndpoint = getApiEndpoint(identity, ConcessionsReportClient, "getConcessionsReport");

			let propertyIdList = currentFilterSelected;

			dispatcher({
				type: PricingPlatformActionTypes.UpdateConcessionsReportPageIsLoading,
				payload: true
			});

			makeRemoteRequest(apiEndpoint, [propertyIdList]).then((response) => {
				dispatcher({
					type: PricingPlatformActionTypes.UpdateConcessionsReportPageReports,
					payload: response.data as ConcessionsReportResponse[]
				});
				dispatcher({
					type: PricingPlatformActionTypes.UpdateConcessionsReportPageIsLoading,
					payload: false
				});
			});
		};

		return {
			getForm,
			searchConcessions,
			updateFilterOptions
		};
	}
};
