import { Config } from "./ConfigClient";
import * as Msal from "msal";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { AuthenticationParameters } from "msal";

export type IdentityRole = "admin" | "user";

class PricingPlatformMsalAuthProvider {
  provider?: MsalAuthProvider;

  createMsalAuthProvider(config: Config): MsalAuthProvider {
    if (!(config.azureInstance && config.azureTenantId)) {
      throw new Error(
        "The config settings for Instance and Tenant are missing. These are required."
      );
    }

    const authConfig: Msal.Configuration = {
      auth: {
        clientId: config.azureClientId as string,
        redirectUri: window.location.origin + "/implicit/callback",
        authority: config.azureInstance + config.azureTenantId,
        postLogoutRedirectUri: window.location.origin + "/login"
      },
      cache: {
        cacheLocation: "localStorage"
      }
    };

    const options = {
      loginType: LoginType.Redirect,
      tokenRefreshUri: window.location.origin + "/auth.html"
    };

    if (!config.azurePricingPlatformScope) {
      throw new Error(
        "The config setting for AzurePricingPlatformScope is missing. This is required."
      );
    }

    const authenticationParameters: AuthenticationParameters = {
      // note: do not add openid scopes here (e.g. openid or email).
      // These appear to be added by the framework and cause errors if added here.
      scopes: [config.azurePricingPlatformScope]
    };

    return new MsalAuthProvider(authConfig, authenticationParameters, options);
  }

  // this provider should only be created one time for the lifetime of the app.
  // note: if the config changes, this does not get a new instance of MsalAuthProvider
  public createOrGetCachedProviderFromConfig(config: Config): MsalAuthProvider {
    this.provider = this.provider || this.createMsalAuthProvider(config);
    return this.provider;
  }
}

export const PricingPlatformMsalAuthProviderSingleton =
  new PricingPlatformMsalAuthProvider();
