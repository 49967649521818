import { AtAGlanceContext, AT_A_GLANCE_DEFAULT } from "./context";

import React, { FC, useState } from "react";

export const AtAGlanceProvider: FC = ({ children }) => {
	const [localState, setLocalState] = useState(AT_A_GLANCE_DEFAULT.filters);

	const handleChange = (value?: string) => (key: keyof typeof localState) => {
		setLocalState((prevState) => ({
			...prevState,
			[key]: value
		}));
	};

	return (
		<AtAGlanceContext.Provider
			value={{
				filters: localState,
				handleChange
			}}
		>
			{children}
		</AtAGlanceContext.Provider>
	);
};

export default AtAGlanceProvider;
