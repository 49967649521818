import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.marginScale.large,
    marginTop: theme.marginScale.large
  },
  title: {
    display: "inline-block",
    marginRight: theme.marginScale.medium,
    color: theme.palette.dark,
    fontSize: "24px",
    lineHeight: "64px",
    marginLeft: "15px"
  },
  break: {
    display: "block",
    flexGrow: 1,
    height: 2,
    backgroundColor: theme.palette.lightGray
  }
}));
