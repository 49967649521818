import {
	ConfiguredParameterDeleteRow,
	ConfiguredParameterFilter,
	ConfiguredParameterHeaderGroup,
	ConfiguredParameterItem,
	ConfiguredParameterRow,
	ConfiguredParameterUpdate,
	HierarchyLevelsResult,
	PricingAutomationClient,
	SystemAutomatedReviewFilterOptions,
	SystemAutomatedReviewSearchQuery
} from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

export const useConfigureAutomationService = () => {
	const identity = useContext(IdentityContext);

	const getHierarchyLevels = async (signal?: AbortSignal): Promise<HierarchyLevelsResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getHierarchyLevels", signal);

		try {
			const { data } = await makeRemoteRequest(endpoint, []);
			if (data === undefined) {
				throw new Error();
			}
			return data as HierarchyLevelsResult;
		} catch (err) {
			throw err;
		}
	};

	const getConfiguredParameters = async (
		query: ConfiguredParameterFilter,
		signal?: AbortSignal
	): Promise<ConfiguredParameterRow[]> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getConfiguredParameters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as ConfiguredParameterRow[];
		} catch (err) {
			throw err;
		}
	};

	const updateConfigurableParameters = async (
		query: ConfiguredParameterItem[],
		signal?: AbortSignal
	): Promise<string> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "updateConfiguredParameters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as string;
		} catch (err) {
			throw err;
		}
	};

	const updateAndAddConfiguredParameters = async (
		query: ConfiguredParameterUpdate,
		signal?: AbortSignal
	): Promise<string> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "updateAndAddConfiguredParameters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as string;
		} catch (err) {
			throw err;
		}
	};

	const getConfiguredParameterList = async (signal?: AbortSignal): Promise<ConfiguredParameterHeaderGroup[]> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getConfiguredParameterList", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, []);
			if (data === undefined) {
				throw new Error();
			}
			return data as ConfiguredParameterHeaderGroup[];
		} catch (err) {
			throw err;
		}
	};

	const deleteParamRow = async (query: ConfiguredParameterDeleteRow, signal?: AbortSignal): Promise<string> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "deleteParamRow", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as string;
		} catch (err) {
			throw err;
		}
	};

	const getConfigPageFilters = async (
		query: SystemAutomatedReviewSearchQuery,
		signal?: AbortSignal
	): Promise<SystemAutomatedReviewFilterOptions> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getConfigPageFilters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as SystemAutomatedReviewFilterOptions;
		} catch (err) {
			throw err;
		}
	};

	return {
		getHierarchyLevels,
		getConfiguredParameters,
		updateConfigurableParameters,
		getConfiguredParameterList,
		updateAndAddConfiguredParameters,
		getConfigPageFilters,
		deleteParamRow
	};
};
