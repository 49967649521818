import { zodResolver } from "@hookform/resolvers/zod";
import { QueryObserver, useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { configureAutomationFormType, dropdownValidation } from "./validations";
import { useConfigureAutomationFilterContext } from "../../Context";
import { parametersMapper } from "../../Table/parametersMapper";

export const useOverrideForm = () => {
	const queryClient = useQueryClient();
	const { setNewRow, handleEditedRowLevel, filters } = useConfigureAutomationFilterContext();
	const { data } = new QueryObserver(queryClient, {
		keepPreviousData: true,
		queryKey: ["configured", filters],
		select: (data: any) => parametersMapper(data).parameters.map((item) => item?.name) ?? []
	}).getCurrentResult();

	const methods = useForm({ resolver: zodResolver(dropdownValidation) });

	const onSubmit: SubmitHandler<configureAutomationFormType> = async (formValues) => {
		const { exist, ...rest } = formValues;

		const name = Object.values(rest)
			.map((item) => item?.displayValue)
			.join("-");

		setNewRow({
			name: name,
			isPortfolio: false,
			countyId: rest.County?.key ?? null,
			cityId: null,
			divisionId: rest.Division?.key ?? null,
			unitGroupMasterId: rest["Unit Group"]?.key ?? null,
			hierarchyId: filters?.hierarchyLevels.key ?? null,
			propertyId: rest.Property?.key ?? null
		});
		handleEditedRowLevel(name);
	};

	return { methods, levels: data as Array<string>, onSubmit };
};
