import {
	AutomationOverideFormOptions,
	PricingAutomationClient,
	PricingAutomationOverrideClient,
	UnitGroupChangeLog
} from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

export const useUserOverridesService = () => {
	const identity = useContext(IdentityContext);

	const getOverrideFormOptions = async (signal?: AbortSignal): Promise<AutomationOverideFormOptions> => {
		const endpoint = getApiEndpoint(
			identity,
			PricingAutomationOverrideClient,
			"getAutomationOverrideFormOptions",
			signal
		);
		try {
			const { data } = await makeRemoteRequest(endpoint, []);
			if (data === undefined) {
				throw new Error();
			}
			return data as AutomationOverideFormOptions;
		} catch (err) {
			throw err;
		}
	};

	const getPriceResetUnitGroupChangeLogs = async (
		{
			unitGroupId,
			num
		}: {
			unitGroupId: string | null;
			num: number;
		},
		signal?: AbortSignal
	): Promise<UnitGroupChangeLog[]> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getPriceResetUnitGroupChangeLogs", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [unitGroupId, num]);
			if (data === undefined) {
				throw new Error();
			}
			return data as UnitGroupChangeLog[];
		} catch (err) {
			throw err;
		}
	};

	return {
		getOverrideFormOptions,
		getPriceResetUnitGroupChangeLogs
	};
};
