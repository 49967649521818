export type BatchDefaultsEditMode = "editBatch" | "createBatch";

//TODO check for permissions

//Note: add pages to src\components\layouts\Main\index.tsx so they are accessible
export default {
	auth: "/auth-token",
	tokenCallback: "/implicit/callback",
	signOut: "/sign-out",
	signin: "/login",
	home: "/",
	unitAvailabilityPage: "/reports/unitAvailabilityPage",
	concessionsReportPage: "/reports/concessionsReportPage",
	pricingAdjustmentSummaryReportPage: "/reports/pricingAdjustmentSummaryReportPage",
	automationValidationReportPage: "/reports/automationValidationReportPage",
	rentSummary: "/rent-summary",
	reports: "/reports",
	units: (divisionId?: string, propertyId?: string, unitGroupId?: string) => {
		return `/units/${divisionId || ":divisionId"}/${propertyId || ":propertyId"}/${unitGroupId || ":unitGroupId"}`;
	},
	admin: {
		default: "/admin",
		users: "/admin/users",
		editUser: (key?: string) => {
			return `/admin/users/${key || ":id"}`;
		},
		addUser: "/admin/add-user"
	},
	leaseTermAdmin: {
		leaseTerm:  `/admin/lease-term-admin`,
	},
	unitGroupAdmin: {
		unitgroup: (divisionId?: string, divisionName?: string, propertyId?: string, propertyName?: string) => {
			return `/admin/unitGroupAdmin/${divisionId || ":divisionId"}/${divisionName || ":divisionName"}/${
				propertyId || ":propertyId"
			}/${propertyName || ":propertyName"}`;
		},
		createUnitGroup: (divisionId?: string, divisionName?: string, propertyId?: string, propertyName?: string) => {
			return `/admin/unitGroupAdmin/create-unitgroup/${divisionId || ":divisionId"}/${
				divisionName || ":divisionName"
			}/${propertyId || ":propertyId"}/${propertyName || ":propertyName"}`;
		},
		updateUnitGroup: (
			divisionId?: string,
			divisionName?: string,
			propertyId?: string,
			propertyName?: string,
			unitGroupId?: string
		) => {
			return `/admin/unitGroupAdmin/update-unitgroup/${divisionId || ":divisionId"}/${
				divisionName || ":divisionName"
			}/${propertyId || ":propertyId"}/${propertyName || ":propertyName"}/${unitGroupId || ":unitGroupId"}`;
		},
		unassignedUnitTypes: "/admin/unitGroupAdmin/unassignedUnitTypes",
		bedrooms: "/admin/unitGroupAdmin/bedrooms",
		createBedroom: "/admin/unitGroupAdmin/create-Bedroom",
		updateBedroom: (bedroomId?: string) => {
			return `/admin/unitGroupAdmin/update-Bedroom/${bedroomId || ":bedroomId"}`;
		},
		unitGroupMasters: "/admin/unitGroupAdmin/unitGroupMasters",
		createUnitGroupMaster: (unitGroupMasterId?: string) => {
			return `/admin/unitGroupAdmin/create-unitgroupMaster/${unitGroupMasterId || ":unitGroupMasterId"}`;
		},
		updateUnitGroupMaster: (unitGroupMasterId?: string) => {
			return `/admin/unitGroupAdmin/update-unitgroupMaster/${unitGroupMasterId || ":unitGroupMasterId"}`;
		},
		classifications: "/admin/unitGroupAdmin/classifications",
		createClassification: "/admin/unitGroupAdmin/createClassification",
		updateClassification: (classificationId?: string) => {
			return `/admin/unitGroupAdmin/updateClassification/${classificationId || ":classificationId"}`;
		}
	},
	properties: (divisionId?: string, collectionId?: string, propertyId?: string, unitGroupId?: string) => {
		//TODO get search params to work in router - but I think we may have to upgrade react-router to v6 - @Dirk 8/16/22
		// let params = [];
		// let url = '/properties';

		// if (divisionId) params.push(`divisionId=${divisionId}`);

		// if (propertyId) params.push(`propertyId=${propertyId}`);

		// if (unitGroupId) params.push(`unitGroupId=${unitGroupId}`);

		// if (collectionId) params.push(`collectionId=${collectionId}`);

		// if (params.length > 0) {
		// 	url += `?${params.join('&')}`;
		// }

		// return url;

		return `/properties/${divisionId || ":divisionId"}/${collectionId || ":collectionId"}/${
			propertyId || ":propertyId"
		}/${unitGroupId || ":unitGroupId"}`;
	},
	pricingAutomation: {
		default: "/pricing-automation",
		secondaryReview: "/pricing-automation/secondary-review",
		exclusions: "/pricing-automation/exclusions",
		systemAutomated: "/pricing-automation/system-automated",
		configureAutomation: "/pricing-automation/configure-automation"
	},
	pricingMatrix: (divisionId?: string, propertyId?: string, unitGroupId?: string, unitId?: string) => {
		return `/reports/pricingMatrix/${divisionId || ":divisionId"}/${propertyId || ":propertyId"}/${
			unitGroupId || ":unitGroupId"
		}/${unitId || ":unitId"}`;
	},
	exceptions: (divisionId?: string, collectionId?: string, propertyId?: string, unitGroupId?: string) => {
		return `/exceptions/${divisionId || ":divisionId"}/${collectionId || ":collectionId"}/${
			propertyId || ":propertyId"
		}/${unitGroupId || ":unitGroupId"}`;
	},
	staleUnitPage: "/reports/staleUnitPage",
	//TODO clean up these routes @Dirk 7/25/22

	// bestRenewalOffers: (key?: string) => {
	// 	return `/batches/${key || ":id"}/best-renewal-offer`;
	// },
	// bestRenewalOffersActuals: (key?: string) => {
	// 	return `/batches/${key || ":id"}/actuals`;
	// },
	// reviewSummary: (key?: string) => {
	// 	return `/batches/${key || ":id"}/review-summary`;
	// },
	// renewalFollowUp: (key?: string) => {
	// 	return `/batches/${key || ":id"}/renewal-follow-up`;
	// },
	// actualResultsSummary: (key?: string) => {
	// 	return `/batches/${key || ":id"}/actual-results-summary`;
	// },

	// baselinedefaults: {
	// 	growthRates: "/baselinedefaults/growth-rates",
	// 	gtlAndLtl: "/baselinedefaults/ltl-and-gtl",
	// 	scaling: "/baselinedefaults/scaling",
	// 	incentives: "/baselinedefaults/incentives",
	// 	exceptions: "/baselinedefaults/exceptions",
	// 	exportTerms: "/baselinedefaults/export-terms",
	// 	renewalNotice: "/baselinedefaults/renewal-notice-period"
	// },
	// regulations: {
	// 	bmr: "/baselineregulations/bmr",
	// 	ordinances: "/baselineregulations/ordinances",
	// 	rentControl: "/baselineregulations/rent-control"
	// },
	// batchdefaults: {
	// 	growthRates: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/growth-rates/${mode || ":mode"}`;
	// 	},
	// 	gtlAndLtl: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/ltl-and-gtl/${mode || ":mode"}`;
	// 	},
	// 	scaling: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/scaling/${mode || ":mode"}`;
	// 	},
	// 	incentives: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/incentives/${mode || ":mode"}`;
	// 	},
	// 	exceptions: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/exceptions/${mode || ":mode"}`;
	// 	},
	// 	exportTerms: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/export-terms/${mode || ":mode"}`;
	// 	},
	// 	renewalNotice: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchdefaults/${key || ":id"}/renewal-notice-period/${mode || ":mode"}`;
	// 	}
	// },
	// batchregulations: {
	// 	bmr: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchregulations/${key || ":id"}/bmr/${mode || ":mode"}`;
	// 	},
	// 	rentControl: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchregulations/${key || ":id"}/rent-control/${mode || ":mode"}`;
	// 	},
	// 	ordinances: (key?: string, mode?: BatchDefaultsEditMode) => {
	// 		return `/batchregulations/${key || ":id"}/ordinances/${mode || ":mode"}`;
	// 	}
	// },
	// createBatch: "/create-batch"
};
