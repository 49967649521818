import { useQuery } from "components/forms/PricingAutomation/hooks/useQuery";

export const useMapFilterKeys = () => {
	const searchParams = useQuery();
	const ids = searchParams.get("id") ?? "";
	const division = searchParams.get("division") ?? "";
	const county = searchParams.get("county") ?? "";
	const property = searchParams.get("property") ?? "";
	const action = searchParams.get("action") ?? "";
	const manualExclusion = searchParams.get("manualExclusion") ?? "";

	return {
		recommendActionId: action,
		divisionId: division,
		countyId: county,
		propertyIds: property ? property?.split(",") : [],
		reviewReasonIds: ids ? ids?.split(",") : [],
		manualExclusionFilter: manualExclusion,
	};
};
