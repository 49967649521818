export interface Config {
  apiUrl?: string;
  azureInstance?: string;
  azureTenantId?: string;
  azureClientId?: string;
  azurePricingPlatformScope?: string;
  isLoaded?: boolean;
}

export const DefaultConfigConstant: Config = {
  apiUrl: undefined,
  azureInstance: undefined,
  azureTenantId: undefined,
  azureClientId: undefined,
  azurePricingPlatformScope: undefined,
  isLoaded: false
};

export class ConfigClient {
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;

  getConfig(): Config {
    const config: Config = {
      apiUrl: process.env.REACT_APP_API_URL,
      azureInstance: process.env.REACT_APP_API_AZURE_INSTANCE,
      azureTenantId: process.env.REACT_APP_API_AZURE_TENANT_ID,
      azureClientId: process.env.REACT_APP_API_AZURE_CLIENT_ID,
      azurePricingPlatformScope:
        process.env.REACT_APP_API_AZURE_PRICING_PLATFORM_SCOPE,
      isLoaded: true
    };

    return config;
  }
}

export default ConfigClient;
