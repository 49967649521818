import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	concessionsContainer: {
		overflowX: "auto",
		padding: "13px 0 13px",
		"& table": {
			minWidth: "100%"
		},
		"@media (max-width: 768px)": {
			maxWidth: "calc(100vw - 40px)"
		}
	},
	errorMessage: {
		color: theme.palette.error,
		paddingBottom: "8px"
	},
	notFound: {
		color: theme.palette.inactive
	}
}));
