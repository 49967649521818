import constants from "utils/constants";
import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { LeadsAndNetChart } from "components/controls/LeadsAndNetChart/Chart";
import { DashboardChartData } from "api";
import { leadAndNetMapper } from "./leadsAndNetMapper";

interface Props {
	isLoading: boolean;
	data: DashboardChartData;
}

export const LeadsAndNet = ({ isLoading, data }: Props) => {
	const classes = useStyle();
	const mappedData = leadAndNetMapper(data);

	if (isLoading) {
		return (
			<span
				role="presentation"
				className={[classes.cardContainerSkeleton, classes.cardContainer, "skeleton", "p-card"].join(" ")}
			/>
		);
	}

	return (
		<Card title={constants.cardTitle.leadsAndNetApps} className={classes.cardContainer}>
			<LeadsAndNetChart data={mappedData} />
		</Card>
	);
};
