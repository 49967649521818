import { useContext } from "react";
import { IdentityContext } from "components/IdentityProvider";
import {
  SiteClient,
  ResponsePayloadOfString,
  UserPermissions
} from "./pricing-platform-api";
import { PricingPlatformConfigContext } from "components/PricingPlatformConfigProvider";

type ClientConstructor<T> = (
  baseUrl?: string,
  http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
) => T;

const createUseClient = function <T>(clientFactory: ClientConstructor<T>) {
  return function () {
    const identity = useContext(IdentityContext);

    const config = useContext(PricingPlatformConfigContext);
    const baseUrl = (config && config.apiUrl) || "";

    return clientFactory(baseUrl, {
      fetch: async function (url: RequestInfo, init?: RequestInit) {
        const accessToken = await identity.getAccessToken();

        if (accessToken != null) {
          init = init || {};
          init.headers = {
            ...init.headers,
            Authorization: `bearer ${accessToken}`
          };
        }
        return window.fetch(url, init);
      }
    });
  };
};

export interface Option {
  key?: string;
  displayValue?: string;
}

//response payload of "string" is our "site" endpoint (root api url)
export type ResponsePayload = Required<
  Pick<ResponsePayloadOfString, "traceId" | "permissions">
>;

export type ResponsePayloadOf<T> = {
  traceId: string;
  permissions: UserPermissions;
  data?: T;
};

const useSiteClient = createUseClient((url, http) => new SiteClient(url, http));
export { useSiteClient };

export * from "./pricing-platform-api";
