import { AskingPriceTileResult } from "api";
import constants from "utils/constants";
import { nullOrUndefinedToZero } from "utils/miscUtils";

export enum compPremiumType {
	local = "local",
	submarket = "submarket"
}

export const compPremiumMapper = (
	data: AskingPriceTileResult,
	compPremiumType: compPremiumType
): Required<AskingPriceTileResult> => {
	return {
		tileName: data.tileName || constants.compPremium[compPremiumType],
		current: Math.floor(nullOrUndefinedToZero(data.current)),
		historicalAverage: Math.floor(nullOrUndefinedToZero(data.historicalAverage)),
		historicalRangeFromValue: Math.floor(nullOrUndefinedToZero(data.historicalRangeFromValue)),
		historicalRangeToValue: Math.floor(nullOrUndefinedToZero(data.historicalRangeToValue))
	};
};
