import {DashboardChartData, DashboardChartRecord, LeadAndNetAppsResultItem} from "api";
import { formatDate } from "utils/miscUtils";

export interface LeadsAndNetMapped extends Omit<LeadAndNetAppsResultItem, "date"> {
	date: string;
}

export const leadAndNetMapper = (data: DashboardChartData): LeadsAndNetMapped[] => {
	if (!data.series) return [];

	const mappedData = data.series.map((item: DashboardChartRecord) => ({
		date: formatDate(item.date),
		leads: item.leads,
		netApps: item.netApps
	}));

	return mappedData;
};
