import React from "react";
import logo from "assets/logo.png";
import ContentArea from "components/layouts/ContentArea";
import PrimaryButton from "components/controls/PrimaryButton";
import useStyle from "./useStyle";
import { useHistory } from "react-router-dom";
import routes from "components/routes";

export interface LoginPageProps {
  loginAction?: () => void;
}

const LoginPage: React.FC<LoginPageProps> = function(props: LoginPageProps) {
  const classes = useStyle();
  const history = useHistory();
  let loginAction = props.loginAction;
  if (!loginAction) {
    loginAction = () => {
      history.push(routes.home);
    };
  }

  return (
    <div className={classes.content}>
      <ContentArea columnSize="5">
        <div>
          <div className={classes.items}>
            <img className={classes.logo} src={logo} alt="Essex" />
          </div>
          <div className={classes.items}>
            Welcome to Essex Pricing. Please login to continue
          </div>
          <div className={classes.items}>
            <PrimaryButton title="Login" onClick={loginAction} />
          </div>
        </div>
      </ContentArea>
    </div>
  );
};

export default LoginPage;
