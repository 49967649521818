import React from "react";

import useStyle from "./useStyle";
import { useExclusionsContext } from "../../Context";

export const PriceResetHeader = () => {
	const classes = useStyle();

	const { selectedRow } = useExclusionsContext();

	return (
		<header>
			<h2 className={classes.title}>{`${selectedRow?.unitGroupMasterName} - ${selectedRow?.property}`}</h2>
			<div className={classes.flaggedLine}>
				<span>REASON: {selectedRow?.exclusionReason ?? "---"}</span>
				<span>{selectedRow?.reviewReasonDescription}</span>
			</div>
		</header>
	);
};
