import React from "react";
import useStyle from "./useStyle";

export const HoldConfirmation = () => {
	const classes = useStyle();
	return (
		<>
			<div className={classes.titleContainer}>
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 24" fill="none">
					<path
						d="M19.2475 7C19.2475 5.62 18.1275 4.5 16.7475 4.5C16.5775 4.5 16.4075 4.52 16.2475 4.55V4C16.2475 2.62 15.1275 1.5 13.7475 1.5C13.5175 1.5 13.2875 1.53 13.0775 1.59C12.7075 0.66 11.8075 0 10.7475 0C9.51753 0 8.49753 0.89 8.28753 2.06C8.11753 2.02 7.93753 2 7.74753 2C6.36753 2 5.24753 3.12 5.24753 4.5V10.39C4.90753 10.08 4.48753 9.85 4.02753 9.73L3.25753 9.52C2.42753 9.29 1.55753 9.61 1.06753 10.35C0.687532 10.92 0.667532 11.66 0.917532 12.3L3.47753 18.73C4.73753 21.91 7.81753 24 11.2475 24C15.6675 24 19.2475 20.42 19.2475 16V7ZM17.2475 16C17.2475 19.31 14.5575 22 11.2475 22C8.63753 22 6.29753 20.41 5.33753 17.99L2.73753 11.45L3.26753 11.59C3.72753 11.71 4.09753 12.05 4.26753 12.49L5.24753 15H7.24753V4.5C7.24753 4.22 7.46753 4 7.74753 4C8.02753 4 8.24753 4.22 8.24753 4.5V12H10.2475V2.5C10.2475 2.22 10.4675 2 10.7475 2C11.0275 2 11.2475 2.22 11.2475 2.5V12H13.2475V4C13.2475 3.72 13.4675 3.5 13.7475 3.5C14.0275 3.5 14.2475 3.72 14.2475 4V12H16.2475V7C16.2475 6.72 16.4675 6.5 16.7475 6.5C17.0275 6.5 17.2475 6.72 17.2475 7V16Z"
						fill="#494949"
					/>
				</svg>
				<h3 className={classes.title}>Hold Price</h3>
			</div>
			<p className={classes.message}>Clicking continue will hold the price on the selected properties.</p>
		</>
	);
};
