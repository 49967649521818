import {
	UnitFilterOptions,
	UnitAvailabilityClient,
	UnitAvailabilityPage,
	UnitAvailabilityDropdownSelections
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";

export const UnitAvailabilityPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {
		const getForm = function (currentDivision: UnitAvailabilityDropdownSelections): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, UnitAvailabilityClient, "getFilterOptions");

			makeRemoteRequest(filterOptionsEndpoint, [currentDivision]).then((response) => {
				var options = response.data as UnitFilterOptions;
				dispatcher({
					type: PricingPlatformActionTypes.UpdateUnitAvailabilityPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const updateFilterOptions = function (currentSelections: UnitAvailabilityDropdownSelections): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, UnitAvailabilityClient, "getFilterOptions");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as UnitFilterOptions;
				dispatcher({
					type: PricingPlatformActionTypes.UpdateUnitAvailabilityPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const searchProperty = function (currentFilterSelected: UnitAvailabilityDropdownSelections): void {
			let apiEndpoint = getApiEndpoint(identity, UnitAvailabilityClient, "getAvailabilityReports");

			let propertyIdList = currentFilterSelected;

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitAvailabilityPageIsLoading,
				payload: true
			});

			makeRemoteRequest(apiEndpoint, [propertyIdList]).then((response) => {
				dispatcher({
					type: PricingPlatformActionTypes.UpdateUnitAvailabilityPageReports,
					payload: response.data as UnitAvailabilityPage[]
				});
				dispatcher({
					type: PricingPlatformActionTypes.UpdateUnitAvailabilityPageIsLoading,
					payload: false
				});
			});
		};

		return {
			getForm,
			searchProperty,
			updateFilterOptions
		};
	}
};
