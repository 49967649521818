import { createContext, useContext } from "react";

export type ExclusionsContextType = {
	selectedRow: Record<string, string | number>;
	setSelectedRow: (row: Record<string, string | number>) => void;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
};

export const EXCLUSIONS_DEFAULT: ExclusionsContextType = {
	selectedRow: {},
	setSelectedRow: () => null,
	refresh: false,
	setRefresh: () => null
};

export const ExclusionsContext = createContext(EXCLUSIONS_DEFAULT);

export const useExclusionsContext = () => {
	const context = useContext(ExclusionsContext);

	if (!context) {
		throw new Error("useExclusionsContext used outside ExclusionsContext provider");
	}

	return context;
};
