import constants from "utils/constants";
import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { useLoadData } from "./useLoadData";
import { MarketPositionChart } from "components/controls/MarketPositionChart/Chart";
import { IsDateInRange } from "utils/miscUtils";


interface Props {
	startDate?: Date | undefined;
	endDate?: Date | undefined;
}

export const MarketPosition = ({ startDate, endDate }: Props) => {
	const classes = useStyle();
	const { isLoading, data } = useLoadData();

	const FilterDateRange = (value: any) => {
		const date = new Date(value.date);
		return IsDateInRange(date, startDate, endDate);
	}

	const chartData = data.filter(FilterDateRange);

	if (isLoading) {
		return <span role="presentation" className={[classes.cardContainer, "skeleton", "p-card"].join(" ")} />;
	}

	return (
		<Card title={constants.cardTitle.marketPositioning} className={classes.cardContainer}>
			<MarketPositionChart data={chartData} />
		</Card>
	);
};
