import { useConfigureAutomationFilterContext } from "../Context";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "components/controls/TextField";
import { RangeInput } from "components/controls/RangeInput";
import { parsePercentage } from "utils/formatTemplates";
import { formatDate, formatDefaultDate, isNullOrUndefined, onlyNumber } from "utils/miscUtils";
import useStyle from "./useStyle";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { DeleteColumn } from "./Actions/DeleteColumn";

interface ContentProps {
	data: any;
	dataKey?: string;
	name?: string;
	type?: number;
}

export const Content = ({ data, dataKey = "", type }: ContentProps) => {
	const classes = useStyle();
	const { editedRowLevel, setDirtyFields, dirtyFields } = useConfigureAutomationFilterContext();
	const { register, errors } = useFormContext();
	const editing = data["name"] === editedRowLevel;

	const row = data[dataKey] || {};
	let value = "";
	let startDate;
	let endDate;

	const handleDirtyFields = () => {
		if (dirtyFields.includes(dataKey)) return;
		setDirtyFields([...dirtyFields, dataKey]);
	};

	switch (type) {
		case 0:
			value = row.isPercentValue ? parsePercentage(row.minValue) : row.minValue;
			return editing ? (
				<TextField
					label="Value"
					ref={register({ valueAsNumber: true })}
					onKeyDown={onlyNumber}
					onChangeCapture={handleDirtyFields}
					errors={errors}
					name={`${dataKey}_minValue`}
					defaultValue={value}
				/>
			) : (
				<div className={classes.content}>
					{value ?? "Not set"}
					{!isNullOrUndefined(value) ? <DeleteColumn data={data} columnKey={dataKey} /> : null}
				</div>
			);

		case 1:
			value = row.isPercentValue ? parsePercentage(row.maxValue) : row.maxValue;
			return editing ? (
				<TextField
					label="Value"
					ref={register({ valueAsNumber: true })}
					onKeyDown={onlyNumber}
					onChangeCapture={handleDirtyFields}
					errors={errors}
					name={`${dataKey}_maxValue`}
					defaultValue={value}
				/>
			) : (
				<div className={classes.content}>
					{value ?? "Not set"}
					{!isNullOrUndefined(value) ? <DeleteColumn data={data} columnKey={dataKey} /> : null}
				</div>
			);
		case 2:
			const minValue = row.isPercentValue ? parsePercentage(row.minValue) : row.minValue;
			const maxValue = row.isPercentValue ? parsePercentage(row.maxValue) : row.maxValue;
			value = minValue !== undefined ? `${minValue} to ${maxValue}` : "Not set";
			return editing ? (
				<RangeInput
					ref={register({ valueAsNumber: true })}
					handleDirtyFields={handleDirtyFields}
					errors={errors}
					initialMin={minValue}
					initialMax={maxValue}
					divider="to"
					nameMin={`${dataKey}_minValue`}
					nameMax={`${dataKey}_maxValue`}
				/>
			) : (
				<div className={classes.content}>
					{value}
					{!isNullOrUndefined(minValue) || !isNullOrUndefined(maxValue) ? (
						<DeleteColumn data={data} columnKey={dataKey} />
					) : null}
				</div>
			);
		case 3:
			return editing ? (
				<Controller
					name={`${dataKey}_isExcluded`}
					defaultValue={row.isExcluded ?? null}
					render={(field) => (
						<div className={classes.checkbox}>
							<TriStateCheckbox
								disabled={!editing}
								{...field}
								value={field.value ?? null}
								onChange={(e) => {
									handleDirtyFields();
									field.onChange(e.value);
								}}
							/>
							{String(field.value) === "true" ? "True" : String(field.value) === "false" ? "False" : "Not Set"}
						</div>
					)}
				/>
			) : (
				<div className={classes.content}>
					{row.isExcluded === true ? "True" : row.isExcluded === false ? "False" : "Not Set"}
					{!isNullOrUndefined(row.isExcluded) ? <DeleteColumn data={data} columnKey={dataKey} /> : null}
				</div>
			);
		case 4:
			return editing ? (
				<Controller
					name={`${dataKey}_paramFlagValue`}
					defaultValue={row.paramFlagValue ?? null}
					render={(field) => (
						<div className={classes.checkbox}>
							<TriStateCheckbox
								disabled={!editing}
								{...field}
								value={field.value ?? null}
								onChange={(e) => {
									handleDirtyFields();
									field.onChange(e.value);
								}}
							/>
							{String(field.value) === "true" ? "True" : String(field.value) === "false" ? "False" : "Not Set"}
						</div>
					)}
				/>
			) : (
				<div className={classes.content}>
					{row.paramFlagValue === true ? "True" : row.paramFlagValue === false ? "False" : "Not Set"}
					{!isNullOrUndefined(row.isExcluded) ? <DeleteColumn data={data} columnKey={dataKey} /> : null}
				</div>
			);

		case 5:
			value = row.isPercentValue ? parsePercentage(row.maxValue) : row.maxValue;
			startDate = row.startDate ? formatDate(row.startDate) : "Not set";
			endDate = row.endDate ? formatDate(row.endDate) : "Not set";
			return editing ? (
				<div className={classes.displayFive}>
					<TextField
						label="Value"
						ref={register({ valueAsNumber: true })}
						onKeyDown={onlyNumber}
						onChangeCapture={handleDirtyFields}
						errors={errors}
						name={`${dataKey}_maxValue`}
						type="text"
						defaultValue={value}
					/>

					<TextField
						ref={register({ valueAsDate: true })}
						errors={errors}
						onChangeCapture={handleDirtyFields}
						label="Start Date"
						name={`${dataKey}_startDate`}
						type="date"
						defaultValue={formatDefaultDate(row.startDate)}
					/>
					<TextField
						ref={register({ valueAsDate: true })}
						errors={errors}
						onChangeCapture={handleDirtyFields}
						label="End Date"
						name={`${dataKey}_endDate`}
						type="date"
						defaultValue={formatDefaultDate(row.endDate)}
					/>
				</div>
			) : (
				<div className={classes.displayFive} style={{ gap: "16px" }}>
					<span className={classes.col}>
						<strong>Value</strong>
						{value ?? "Not Set"}
					</span>
					<span className={classes.col}>
						<strong>Start Date</strong>
						{startDate}
					</span>
					<span className={classes.col}>
						<strong>End Date</strong>
						{endDate}
					</span>
					<span className={classes.col}>
						{!isNullOrUndefined(value) || !isNullOrUndefined(row.startDate) || !isNullOrUndefined(row.endDate) ? (
							<DeleteColumn data={data} columnKey={dataKey} />
						) : null}
					</span>
				</div>
			);
		case 6:
			value = row.isPercentValue ? parsePercentage(row.maxValue) : row.maxValue;
			startDate = row.startDate ? formatDate(row.startDate) : "Not set";
			endDate = row.endDate ? formatDate(row.endDate) : "Not set";
			return editing ? (
				<div className={classes.displayFive}>
					<Controller
						name={`${dataKey}_isExcluded`}
						defaultValue={row.isExcluded ?? null}
						render={(field) => (
							<div className={classes.checkbox}>
								<TriStateCheckbox
									disabled={!editing}
									{...field}
									value={field.value ?? null}
									onChange={(e) => {
										handleDirtyFields();
										field.onChange(e.value);
									}}
								/>
								{String(field.value) === "true"
									? "True"
									: String(field.value) === "false"
									? "False"
									: "Not Set"}
							</div>
						)}
					/>
					<TextField
						ref={register({ valueAsDate: true })}
						errors={errors}
						onChangeCapture={handleDirtyFields}
						label="Start Date"
						name={`${dataKey}_startDate`}
						type="date"
						defaultValue={formatDefaultDate(row.startDate)}
					/>
					<TextField
						ref={register({ valueAsDate: true })}
						errors={errors}
						onChangeCapture={handleDirtyFields}
						label="End Date"
						name={`${dataKey}_endDate`}
						type="date"
						defaultValue={formatDefaultDate(row.endDate)}
					/>
				</div>
			) : (
				<div className={classes.displayFive} style={{ gap: "16px" }}>
					<span className={classes.col}>
						<strong>Value</strong>
						{row.isExcluded === true ? "True" : row.isExcluded === false ? "False" : "Not Set"}
					</span>
					<span className={classes.col}>
						<strong>Start Date</strong>
						{startDate}
					</span>
					<span className={classes.col}>
						<strong>End Date</strong>
						{endDate}
					</span>
					<span className={classes.col}>
						{!isNullOrUndefined(value) || !isNullOrUndefined(row.startDate) || !isNullOrUndefined(row.endDate) ? (
							<DeleteColumn data={data} columnKey={dataKey} />
						) : null}
					</span>
				</div>
			);
		case 99:
			return editing ? (
				<input ref={register()} type="text" defaultValue={data[dataKey]} name={dataKey} hidden />
			) : (
				<></>
			);
		default:
			return <></>;
	}
};
