import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	sticky: {
		position: "sticky",
		top: 0,
		zIndex: 10
	},
	container: {
		background: theme.palette.blue,
		display: "flex",
		width: "100%",
		height: "64px",
		justifyContent: "space-between"
	},
	titleBar: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		padding: [[theme.marginScale.large, theme.marginScale.large]],
		color: theme.palette.foregroundContrast
	},
	title: {
		fontFamily: theme.fontFamilyEmphasis,
		marginRight: theme.marginScale.xlarge,
		whiteSpace: "nowrap",
		textTransform: "uppercase"
	},
	items: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%"
	},
	mainItems: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	actionItems: {},
	item: {
		marginRight: theme.marginScale.large,
		marginLeft: theme.marginScale.large,
		fontSize: theme.typeScale.small,
		textDecoration: "none",
		color: theme.palette.lightGray,

		"&:visited": {
			color: theme.palette.lightGray
		},
		"&:hover": {
			color: theme.palette.foregroundContrast,
			textDecoration: "underline"
		},
		"&:active": {
			color: theme.palette.foregroundContrast
		}
	},
	active: {
		textDecoration: "underline",
		fontWeight: "bold"
	}
}));
