import React from "react";
import { Button } from "primereact/button";
import cssUtils from "utils/cssUtils";

export enum IconType {
  Download = 1,
  Add = 2,
  Remove = 3,
  Question = 4,
  MoveRight = 5,
  MoveLeft = 6,
  MoveRightAll = 7,
  MoveLeftAll = 8
}

export interface IconButtonProps {
  title?: string | undefined;
  disabled?: boolean ;
  icon: IconType;
  onClick?: () => void;
  onlyShowIcon?: boolean | undefined;
  className?: string | undefined;
}

const IconButton: React.FC<IconButtonProps> = function(props) {
  let iconClass = "pi-plus";

  switch (props.icon) {
    case IconType.Download:
      iconClass = "pi-download";
      break;
    case IconType.Add:
      iconClass = "pi-plus";
      break;
    case IconType.Remove:
      iconClass = "pi-minus";
      break;
    case IconType.Question:
      iconClass = "pi-question-circle";
      break;
    case IconType.MoveRight:
      iconClass = "pi-angle-right";
      break;
    case IconType.MoveLeft:
        iconClass = "pi-angle-left";
        break;
    case IconType.MoveRightAll:
      iconClass = "pi-angle-double-right";
      break;
    case IconType.MoveLeftAll:
        iconClass = "pi-angle-double-left";
        break;
      
  }

  const className = props.onlyShowIcon ? "essexPricingPlatformLinkButton" : undefined;
  const isDisabled = props.disabled === undefined ? false : props.disabled;    

  return (
    <Button
      className={cssUtils.join(className, props.className)}
      label={props.title}
      icon={`pi ${iconClass}`}
      onClick={(e) => {  
        e.preventDefault();      
        props.onClick && props.onClick();
      }}
      disabled = {isDisabled}
    />
  );
};

export default IconButton;
