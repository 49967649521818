import React from "react";
import { AtAGlanceProvider } from "./Context";
import Page from "components/layouts/Page";
import TopHeaderMenu from "components/controls/TopHeaderMenu";
import useStyle from "../../useStyle";
import { GlanceSection } from "./Glance";
import { SecondaryReviewSection } from "./SecondaryReview";
import { ExclusionSection } from "./Exclusions";
import { SuccessfulAutomationSection } from "./SuccessfulAutomation";

export const Home = () => {
	const classes = useStyle();

	return (
		<AtAGlanceProvider>
			<Page menu={<TopHeaderMenu />} withDrawer>
				<div className={classes.mainContent}>
					<GlanceSection />
					<SecondaryReviewSection />
					<ExclusionSection />
					<SuccessfulAutomationSection />
				</div>
			</Page>
		</AtAGlanceProvider>
	);
};
