import { ExclusionsContext } from "./context";
import React, { FC, useEffect, useState } from "react";

export const ExclusionsFilterProvider: FC = ({ children }) => {
	const [selectedRow, setSelectedRow] = useState<Record<string, string | number>>({});
	const [refresh, setRefresh] = useState(false);

	const handleSelectedRow = (row: Record<string, string | number>) => {
		setSelectedRow(row);
	};

	useEffect(() => {
		if (refresh) {
			const timer = setTimeout(() => {
				setRefresh(false);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [refresh]);

	return (
		<ExclusionsContext.Provider
			value={{
				selectedRow,
				setSelectedRow: handleSelectedRow,
				refresh,
				setRefresh
			}}
		>
			{children}
		</ExclusionsContext.Provider>
	);
};

export default ExclusionsFilterProvider;
