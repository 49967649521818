import React from "react";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import useStyle from "./useStyle";
import { Route, Switch, useLocation } from "react-router-dom";
import routes from "components/routes";
import InvalidPage from "../InvalidPage";
import SecondaryReviewPage from "./Pages/SecondaryReview";
import constants from "utils/constants";
import { useEffect } from "react";
import ExclusionsPage from "./Pages/Exclusions";
import SystemAutomatedPage from "./Pages/SystemAutomated";
import { ConfigureAutomationPage } from "./Pages/ConfigureAutomation";
import { AlertProvider } from "./context/AlertProvider";
import { Home } from "./Pages/Home";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { retry: 1, refetchOnReconnect: false, refetchOnWindowFocus: false }
	}
});

const PricingAutomationPage = () => {
	const classes = useStyle();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<QueryClientProvider client={queryClient}>
			<AlertProvider>
				<Switch>
					<Route exact path={routes.pricingAutomation.default}>
						<Home />
					</Route>
					<Route exact path={routes.pricingAutomation.secondaryReview}>
						<Page withDrawer menu={<Menu title={constants.menuTitle.secondaryReview} />}>
							<div className={classes.mainContent}>
								<SecondaryReviewPage />
							</div>
						</Page>
					</Route>
					<Route exact path={routes.pricingAutomation.exclusions}>
						<Page withDrawer menu={<Menu title={constants.menuTitle.exclusions} />}>
							<div className={classes.mainContent}>
								<ExclusionsPage />
							</div>
						</Page>
					</Route>
					<Route exact path={routes.pricingAutomation.systemAutomated}>
						<Page withDrawer menu={<Menu title={constants.menuTitle.systemAutomated} />}>
							<div className={classes.mainContent}>
								<SystemAutomatedPage />
							</div>
						</Page>
					</Route>
					<Route exact path={routes.pricingAutomation.configureAutomation}>
						<Page withDrawer menu={<Menu title={constants.menuTitle.configureAutomation} />}>
							<div className={classes.mainContent}>
								<ConfigureAutomationPage />
							</div>
						</Page>
					</Route>
					<Route path="*">
						<Route component={InvalidPage} />
					</Route>
				</Switch>
			</AlertProvider>
		</QueryClientProvider>
	);
};

export default PricingAutomationPage;
