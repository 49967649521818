import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import useStyle from "../useStyle";
import { DrawerItems } from "components/forms/Drawer/DrawerItems";
import { useLocation } from "react-router-dom";
import { IconButton } from "components/controls/PricingIconButton";

export const MobileDrawer = () => {
	const classes = useStyle();
	const [visible, setVisible] = useState<boolean>(false);
	const { pathname } = useLocation();
	const currentPath = pathname.split("/")[1];

	return (
		<div className={classes.mobileDrawer}>
			<Sidebar
				blockScroll
				modal={false}
				closeOnEscape
				visible={visible}
				onHide={() => setVisible(false)}
				className={classes.drawerContainer}
			>
				<DrawerItems currentPath={currentPath} />
			</Sidebar>
			<IconButton onClick={() => setVisible(true)}>
				<i className={`pi pi-bars ${classes.outlined}`} />
			</IconButton>
		</div>
	);
};
