import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";

const PricingAdjustmentSummaryReportPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageFilterOptions:
      newState.pricingAdjustmentSummaryReportPageState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageReports:
      newState.pricingAdjustmentSummaryReportPageState.pricingAdjustmentSummaryReports = action.payload;
      return newState;
    case PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageIsLoading:
      newState.pricingAdjustmentSummaryReportPageState.isLoading = action.payload;
      return newState;
  }
  return newState;
};

export default PricingAdjustmentSummaryReportPageReducer;