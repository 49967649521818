import { DropdownOption } from "api";
import { createContext, useContext } from "react";

export type FilterType = {
	hierarchyLevels: DropdownOption;
	divisionId: DropdownOption;
	countyId: DropdownOption;
	propertyIds: Array<DropdownOption>;
	unitGroupId: DropdownOption;
};
export type OverrideFilterOptionsType = {
	portfolio: Array<DropdownOption>;
	division: Array<DropdownOption>;
	county: Array<DropdownOption>;
	property: Array<DropdownOption>;
	unitGroup: Array<DropdownOption>;
};

export type ConfigureAutomationFilterContextType = {
	filters: FilterType;
	handleChange: (
		value?: DropdownOption | DropdownOption[]
	) => (key: keyof typeof CONFIGURE_AUTOMATION_DEFAULT.filters) => void;
	editedRowLevel: string;
	handleEditedRowLevel: (level: string) => void;
	newRow: Record<string, any>;
	setNewRow: (row: Record<string, any>) => void;
	dirtyFields: Array<string>;
	setDirtyFields: (field: Array<string>) => void;
};

const defaultDropdownOption = { key: "", displayValue: "" };

const defaultFilters = {
	hierarchyLevels: defaultDropdownOption,
	divisionId: defaultDropdownOption,
	countyId: defaultDropdownOption,
	propertyIds: [],
	unitGroupId: defaultDropdownOption
};
export const defaultOverrideFilterOptions = {
	portfolio: [defaultDropdownOption],
	division: [defaultDropdownOption],
	county: [defaultDropdownOption],
	property: [defaultDropdownOption],
	unitGroup: [defaultDropdownOption]
};

export const CONFIGURE_AUTOMATION_DEFAULT: ConfigureAutomationFilterContextType = {
	filters: defaultFilters,
	handleChange: () => () => null,
	editedRowLevel: "",
	handleEditedRowLevel: () => null,
	newRow: defaultOverrideFilterOptions,
	setNewRow: () => null,
	dirtyFields: [],
	setDirtyFields: () => null
};

export const ConfigureAutomationFilterContext = createContext(CONFIGURE_AUTOMATION_DEFAULT);

export const useConfigureAutomationFilterContext = () => {
	const context = useContext(ConfigureAutomationFilterContext);

	if (!context) {
		throw new Error("useConfigureAutomationFilterContext used outside ConfigureAutomationFilterContext provider");
	}

	return context;
};
