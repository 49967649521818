import React from "react";
import pageStyle from "./AdvancedFilters.module.css";
import { PriorityFilterSelections } from "components/store/PropertiesPage/propertyPageInterfaces";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { Calendar } from "primereact/calendar";
import PriorityFilterComponent, { PriorityFilterDropdown } from "./PriorityFilter";
import { LastAssesedDateFilter } from "../../store/PropertiesPage/propertyPageInterfaces";

export interface AdvancedFilterProps {
	priorityFilters: PriorityFilterSelections[];
	lastAssesedDateFilter: LastAssesedDateFilter;
	onDropdownChange: (type: string, operator: DropdownOption | undefined, filterId?: string) => void;
	onValueChange: (type: string, e: any, filterId?: string) => void;
}

export default function AdvancedFiltersComponent(props: AdvancedFilterProps) {
	const { priorityFilters, lastAssesedDateFilter, onDropdownChange, onValueChange } = props;

	const PropertyFilters = () => {
		return (
			<div className={pageStyle.propertyFilterSection}>
				<div className={pageStyle.filterSectionTitle}>Property</div>
				<div className={pageStyle.filterLevel}>
					<PriorityFilterComponent
						filters={priorityFilters.filter((filter) => filter.filterLevel === 0)}
						onDropdownChange={onDropdownChange}
						onValueChange={onValueChange}
					/>
				</div>
			</div>
		);
	};

	const UnitGroupFilters = () => {
		return (
			<div className={pageStyle.unitGroupFilterSection}>
				<div className={pageStyle.filterSectionTitle}>Unit Group</div>
				<div className={pageStyle.filterLevel}>
					<PriorityFilterComponent
						filters={priorityFilters.filter((filter) => filter.filterLevel === 1)}
						onDropdownChange={onDropdownChange}
						onValueChange={onValueChange}
					/>
					<div className={pageStyle.calendarFilter}>
						<label
							htmlFor="lastAdjustedDatePicker"
							title="Excl. means that the filter will not be applied. ≥ finds records greater than or equal to the provided value. < finds records less than the provided value."
						>
							Last assessed date
						</label>
						<div className={pageStyle.calendarOption}>
							<Dropdown
								selectedValue={lastAssesedDateFilter.operator}
								hideClear={true}
								options={PriorityFilterDropdown}
								onChange={(d) => onDropdownChange("lastAssesedDateFilter", d)}
								disabled={false}
								style={{ width: 80 }}
							/>
							<Calendar
								id="lastAdjustedDatePicker"
								style={{ maxHeight: "30px", alignSelf: "center", width: "120px" }}
								value={lastAssesedDateFilter.filterValue}
								onChange={(e) => onValueChange("lastAssesedDateFilter", e)}
								disabled={lastAssesedDateFilter.operator && lastAssesedDateFilter.operator.key === "Excluded"}
								maxDate={new Date()}
								dateFormat="mm-dd-y"
								placeholder="mm-dd-yy"
								showIcon
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className={pageStyle.advancedFilters}>
			{PropertyFilters()}
			{UnitGroupFilters()}
		</div>
	);
}
