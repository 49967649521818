import React, { useEffect, useState } from "react";

import { DataTable as PrDataTable, DataTableProps } from "primereact/datatable";
import constants from "utils/constants";
import useStyle from "./useStyle";

interface Props extends DataTableProps {
	emptyMessage?: string;
	data?: any[];
	paging?: boolean;
	pagingOptions?: number[];
	deps?: readonly any[];
	onAddRowAction?: (() => void) | undefined;
	onRemoveRowAction?: ((rowToRemove: any) => void) | undefined;
	hasRemoveRowBuffer?: boolean | undefined;
	ref?: React.Ref<any>;
}


/**
 * Data table component that displays a table with the data passed in.
 * To use it, you need to pass in the columns and the data, following the
 * correct type.
 *
 * @returns JSX.Element
 */
export const DataTableStandard: React.FC<Props> = ({
	emptyMessage,
	loading,
	...props
}) => {
	const classes = useStyle();
	
	return (
		<>
			<PrDataTable
				loading={loading}
				onPage={props.onPage}
				onSort={props.onSort}
				emptyMessage={emptyMessage}
				resizableColumns={true}
        		columnResizeMode="expand"
				tableClassName={[classes.table].join(" ")}
				rowsPerPageOptions={constants.rowsPerPageOptions}
				value={props.data}
				{...props}
			>
			</PrDataTable>
		</>
	);
};
