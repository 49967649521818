import React from "react";
import useStyle from "./useStyle";
import { Card } from "primereact/card";
import constants from "utils/constants";
import { useLoadData } from "./useLoadData";
import { ParametersList } from "./ParametersList";

export const AutomationCard = () => {
	const classes = useStyle();
	const { counts, isLoading } = useLoadData();

	if (isLoading) {
		return <span role="presentation" className={[classes.cardContainer, "skeleton", "p-card"].join(" ")} />;
	}

	return (
		<Card title={constants.cardTitle.automationCard} className={[classes.cardContainer].join(" ")}>
			<ParametersList counts={counts} />
		</Card>
	);
};
