import { useAtAGlanceContext } from "../../Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getAutomationCounts } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		queryKey: ["automation-counts", filters],
		queryFn: ({ signal }) => getAutomationCounts(filters, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { counts: data, isLoading };
};
