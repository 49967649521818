import { LeadAndNetAppsResult, LeadAndNetAppsResultItem } from "api";
import { formatDate } from "utils/miscUtils";

export interface LeadsAndNetMapped extends Omit<LeadAndNetAppsResultItem, "date"> {
	date: string;
}

export const leadAndNetMapper = (data: LeadAndNetAppsResult): LeadsAndNetMapped[] => {
	if (!data.items) return [];

	const mappedData = data.items.map((item: LeadAndNetAppsResultItem) => ({
		date: formatDate(item.date),
		leads: item.leads,
		netApps: item.netApps
	}));

	return mappedData;
};
