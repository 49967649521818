/* eslint-disable react-hooks/exhaustive-deps */
import { useSystemAutomatedFilterContext } from "../Context";
import { mapFilterKeys } from "./mapFilterKeys";
import { useSystemAutomatedService } from "components/forms/PricingAutomation/services/systemAutomated.service";
import { useQuery } from "@tanstack/react-query";

const defaultFilterOptions = {
	divisions: [],
	counties: [],
	properties: [],
	unitGroups: []
};

export const useLoadData = () => {
	const { getSystemAutomatedReviewsFilters } = useSystemAutomatedService();
	const { filters } = useSystemAutomatedFilterContext();
	const payload = {
		...mapFilterKeys(filters),
		unitGroupId: filters.unitGroupId.key
	};
	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		queryKey: ["system-automated-filters", payload],
		queryFn: async ({ signal }) => getSystemAutomatedReviewsFilters({ ...payload }, signal)
	});

	return { filterOptions: data || defaultFilterOptions, isInitialLoading };
};
