import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	cardContainer: {
		borderRadius: "12px",
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			margin: "auto",
			textAlign: "center",
		},
		"& [class$=p-card-body]": {
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		"& [class$=p-card-content]": {
			padding: theme.paddingScale.small,
			marginTop: "12px",
			textAlign: "center",
		},
	},
	cardContainerWithLink: {
		cursor: "pointer",
		"&:hover": {
			scale: "1.025",
			background: theme.palette.grayBackground,
			transition: "0.2s",
		},
	},
	cardRowContent: {
		display: "flex",
		justifyContent: "space-between",
		marginTop: "4px",
	},
	link: {
		textDecoration: "none",
	},
}));
