import { useSecondaryReviewFilterContext } from "../../Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";
import { marketPositionMapper } from "../../../Home/Glance/MarketPosition/marketPositionMapper";
import { MarketPositionLineChartData } from "api";

export const useLoadData = () => {
	const { getMarketPositionLineChartData } = useHomeService();
	const { selectedRow } = useSecondaryReviewFilterContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["market-position", selectedRow],
		queryFn: ({ signal }) => getMarketPositionLineChartData({ unitGroupId: String(selectedRow?.group) }, signal),
		select: (data: MarketPositionLineChartData) => marketPositionMapper(data?.series ?? [])
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { data: data ?? [], isLoading };
};
