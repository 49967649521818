import React, { useState } from "react";
import Menu from "../Menu";
import Page from "components/layouts/Page";
import useStyle from "./useStyle";
import useFirstLoadEffect from "utils/useMountEffect";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import { RouteComponentProps } from "react-router";
import { PricingMatrixDropdownSelections } from "api";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import Checkbox from "components/controls/Checkbox";
import { Button } from "primereact/components/button/Button";
import { MemoizedTable } from "./PricingMatrixTable";
import { ProgressSpinner } from "primereact/progressspinner";
import MultiSelect from "components/controls/MultiSelect";
import Heading from "components/controls/Heading";
import Label from "components/controls/Label";
import constants from "utils/constants";
import { format } from "date-fns";

export interface PricingMatrixPageParams {
	divisionId: string;
	propertyId: string;
	unitGroupId: string;
	unitId: string;
}

interface PageProps extends RouteComponentProps<PricingMatrixPageParams> {}

const PricingMatrixPage: React.FC<PageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.pricingMatrixState.filterOptions,
		currentMatrices: appState.pricingMatrixState.currentMatrices,
		pageLoading: appState.pricingMatrixState.pageLoading,
		...appState
	};

	const actions = {
		...appActions.pricingMatrixPage,
		...appActions
	};

	interface LocalState {
		currentFilters: PricingMatrixDropdownSelections;
		appliedFilters: PricingMatrixDropdownSelections;
		initialLoad: boolean;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: { selectedUnavailable: false },
		appliedFilters: {},
		initialLoad: true
	});

	const classes = useStyle();

	useFirstLoadEffect(() => {
		actions.getDropdownSelections(localState.currentFilters, props.match.params).then((selections: any) => {
			var newSelections = { ...selections, selectedUnavailable: localState.currentFilters.selectedUnavailable };
			setLocalState({
				...localState,
				currentFilters: newSelections,
				appliedFilters: newSelections
			});

			actions.updateDropdownSelections(newSelections);
		});
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedDivision = division;
		curSelections.selectedProperties = undefined;
		curSelections.selectedUnitGroup = undefined;
		curSelections.selectedUnit = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateDropdownSelections(curSelections);
	}

	function onSelectCollection(collection: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedCollection = collection;
		curSelections.selectedProperties = undefined;
		curSelections.selectedUnitGroup = undefined;
		curSelections.selectedUnit = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateDropdownSelections(curSelections);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedProperties = properties;
		curSelections.selectedUnitGroup = undefined;
		curSelections.selectedUnit = undefined;

		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateDropdownSelections(curSelections);
	}

	function onSelectUnitGroup(unitGroup: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedUnitGroup = unitGroup;
		curSelections.selectedUnit = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateDropdownSelections(curSelections);
	}

	function onSelectUnit(unit: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedUnit = unit;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function unitGroupDropdownDisabled() {
		var isPopulated =
			pageState.filterOptions && pageState.filterOptions.unitGroups && pageState.filterOptions.unitGroups.length > 0;

		return !isPopulated;
	}
	function unitDropdownDisabled() {
		var isPopulated =
			pageState.filterOptions && pageState.filterOptions.units && pageState.filterOptions.units.length > 0;

		return !isPopulated;
	}

	function applySearchDisabled() {
		if (
			localState.currentFilters.selectedUnavailable &&
			(localState.currentFilters.selectedUnit === null || localState.currentFilters.selectedUnit === undefined)
		) {
			return true;
		}

		var noPropSelected =
			localState.currentFilters.selectedProperties === null ||
			localState.currentFilters.selectedProperties === undefined ||
			localState.currentFilters.selectedProperties.length === 0;
		var noCollectionSelected =
			localState.currentFilters.selectedCollection === null ||
			localState.currentFilters.selectedCollection === undefined;
		return noPropSelected && noCollectionSelected;
	}

	function nullExport() {
		var exportBool =
			pageState.currentMatrices !== null &&
			pageState.currentMatrices !== undefined &&
			pageState.currentMatrices.length !== 0;
		return !exportBool;
	}

	function clickCheckBox() {
		var currentFilterState = { ...localState.currentFilters };
		currentFilterState.selectedUnavailable = !currentFilterState.selectedUnavailable;
		setLocalState({
			...localState,
			currentFilters: currentFilterState
		});
		actions.updateDropdownSelections(currentFilterState);
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		actions.getMatrices(curSelections);
		setLocalState({
			...localState,
			appliedFilters: curSelections,
			initialLoad: false
		});
	}

	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		//if (a.selectedDivision!.key === b.selectedDivision!.key && a.selectedProperty!.key === b.selectedProperty!.key && a.selectedUnitGroup!.key === b.selectedUnitGroup!.key) {
		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	window.addEventListener("afterprint", (e) => {document.title = "Essex Pricing Platform"});

	const printPage = () => {
		const selectedProperties = localState.appliedFilters.selectedProperties;
		const selectedUnit = localState.appliedFilters.selectedUnit;
		const fileDate = format(new Date(), "yyyyMMdd");

		document.title = `Pricing Matrix_${fileDate}`;

		if (selectedProperties?.length === 1 && selectedUnit)
		{
			const propertyName = selectedProperties[0].displayValue;
			const unitName = selectedUnit.displayValue;
			document.title = `${propertyName}_${unitName}_${fileDate}`;
		}

		window.print();
	};

	var date = new Date();

	return (
		<Page menu={<Menu title={constants.menuTitle.pricingMatrix} />}>
			<div id="matrixFilters" className={classes.filterHeaders1}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedDivision}
						options={pageState.filterOptions!.divisions}
						onChange={(d) => onSelectDivision(d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.divisions)}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Collection:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedCollection}
						options={pageState.filterOptions!.collections}
						onChange={(d) => onSelectCollection(d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.collections)}
						style={{ width: 180 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"Select properties"}
						selectedValues={localState.currentFilters.selectedProperties}
						options={pageState.filterOptions!.properties}
						onChange={(d) => onSelectProperties(d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.properties)}
						hideClear={false}
						filter
						style={{ width: "300px" }}
					></MultiSelect>
				</div>
				<div className={classes.filterItem}>
					{"Unit Group:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedUnitGroup}
						options={pageState.filterOptions!.unitGroups}
						onChange={(d) => onSelectUnitGroup(d)}
						disabled={unitGroupDropdownDisabled()}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Unit:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedUnit}
						options={pageState.filterOptions!.units}
						onChange={(d) => onSelectUnit(d)}
						disabled={unitDropdownDisabled()}
						style={{ width: 150 }}
					/>
				</div>
				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled()}
				></Button>
				<div className={classes.exportButton}>
					<Button
						label="Export All"
						className={classes.applyButton}
						onClick={() => printPage()}
						disabled={nullExport()}
					></Button>
				</div>
			</div>
			<div className={classes.filterHeaders2}>
				<div id="pricingMatrixUnavailableCheckbox" className={classes.checkbox}>
					<Checkbox
						label={"Show unavailable units"}
						labelPosition={"right"}
						checked={localState.currentFilters.selectedUnavailable}
						onChange={clickCheckBox}
					/>
				</div>
			</div>
			<div className={classes.contentContainer}>
				{pageState.pageLoading || !pageState.currentMatrices ? (
					<ProgressSpinner />
				) : (
					pageState.currentMatrices.map((propForm) => {
						return (
							<div
								key={JSON.stringify(propForm.propertyName)}
								className={classes.titleheading}
								style={{ pageBreakAfter: "always" }}
							>
								<Heading level="2">{"Pricing Matrix: " + propForm.propertyName}</Heading>
								<Label label={date.toLocaleString("en-US")}></Label>

								<MemoizedTable currentMatrices={propForm.unitForms} firstLoad={localState.initialLoad} />
							</div>
						);
					})
				)}
			</div>
		</Page>
	);
};

export default PricingMatrixPage;
