import {
	formatCurrencyNoFractions,
	formatDate as DateFormat,
	formatDateWithTime as DateTimeFormat,
	isUndefined,
	formatDecimalNumber,
	convertPercentage,
	formatNumberNotFraction
} from "./miscUtils";

export const parsePercentage = <T>(value?: T[keyof T]) => {
	if (!isNaN(Number(value))) {
		return convertPercentage(Number(value));
	}
	return "";
};

export const formatPrice =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return formatCurrencyNoFractions(data[key]);
	};

export const formantNormalizedRent = 
<T extends Record<string, unknown>>(normalizedRentKey: keyof T, baseRentKey: keyof T, averageAmenityKey: keyof T) =>
	(data: T) => {
		return `${formatCurrencyNoFractions(data[normalizedRentKey])} (${formatCurrencyNoFractions(data[baseRentKey])}B, ${formatCurrencyNoFractions(data[averageAmenityKey])}A)`;
	};

export const formantNormalizedRentCalculatedBaseRent = 
<T extends Record<string, unknown>>(normalizedRentKey: keyof T, averageAmenityKey: keyof T) =>
	(data: T) => {
		const baseRent = (data[normalizedRentKey] as any) - (data[averageAmenityKey] as any);
		return `${formatCurrencyNoFractions(data[normalizedRentKey])} (${formatCurrencyNoFractions(baseRent)}B, ${formatCurrencyNoFractions(data[averageAmenityKey])}A)`;
	};

export const formatPercentage =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) =>
		`${parsePercentage(data[key])}%`;

export const formatPercentageWithoutSymbol =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return `${parsePercentage(data[key])}`;
	};

export const formatPercentageRoundedWhole = 
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		if(parsePercentage(data[key]) !== '') {
			return `${Number(parsePercentage(data[key])).toFixed(0)}%`
		}
		return "";
	};

export const formatDate =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return DateFormat(data[key]);
	};

export const formatDateNullable =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		if (data[key])
		{
			return DateFormat(data[key]);
		}
		return "";
	};	

export const formatDateWithTime =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return DateTimeFormat(data[key]);
	};

export const formatLowerOrEqual =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return `<=${data[key]}`;
	};

export const formatUpperOrEqual =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return `>=${data[key]}`;
	};

export const formatLower =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return `<${data[key]}`;
	};

export const formatUpper =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return `>${data[key]}`;
	};

export const formatRange =
	<T extends Record<string, unknown>>({ key1, key2, divider }: { key1: keyof T; key2: keyof T; divider?: string }) =>
	(data: T) => {
		if (isUndefined(data[key1]) && isUndefined(data[key2])) return "";
		return `${data[key1] || ""} ${divider} ${data[key2] || ""}`;
	};

export const formatRangePercentage =
	<T extends Record<string, unknown>>({ key1, key2, divider }: { key1: keyof T; key2: keyof T; divider?: string }) =>
	(data: T) => {
		if (isUndefined(data[key1]) && isUndefined(data[key2])) return "";
		return `${parsePercentage(data[key1])} ${divider} ${parsePercentage(data[key2])}`;
	};

export const formatDecimalPoints =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return formatDecimalNumber(data[key]);
	};

export const formatNetConversionBPS =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return formatNumberNotFraction(Number(data[key]) * 100 * 100);
	};

export const formatVariance =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return Number((Number(data[key]) * 100).toFixed(1));
	}

	export const formatBoolAsYorN =
	<T extends Record<string, unknown>>(key: keyof T) =>
	(data: T) => {
		return (data[key] as boolean) === true ? "Y" : "N";
	}
	