import React from "react";
import useStyle from "./useStyle";
import constants from "utils/constants";
import DropdownSelect from "components/controls/DropdownSelect";
import DropdownMultiSelect from "components/controls/DropdownMultiSelect";
import { useLoadData } from "./useLoadData";
import { ExclusionReasonDropdown } from "./ExclusionReason";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownOption } from "api";
import { isEqualString, sortDropdown, updatedQueryParams } from "utils/miscUtils";

const filters = ["division", "county", "property", "group", "manualExclusion"];

export const ExclusionsFilterSection = () => {
	const classes = useStyle();
	const { filterOptions, isInitialLoading, selected } = useLoadData();
	const history = useHistory();
	const location = useLocation();

	const handleChange = (value?: DropdownOption | DropdownOption[]) => (key: string) => {
		const newValues = Array.isArray(value) ? value?.map((x) => x.key).join(",") : value?.key;
		history.push({
			pathname: location.pathname,
			search: updatedQueryParams({ key, value: newValues, arr: filters })
		});
	};

	return (
		<div className={classes.filterHeaders}>
			<DropdownSelect
				disabled={isInitialLoading}
				inputId={constants.filterLabels.division}
				inputLabel={constants.filterLabels.division}
				selectedValue={filterOptions.divisions?.find((i) => isEqualString(i.key, selected.divisionId))}
				options={filterOptions.divisions}
				onChange={(value) => handleChange(value)(filters[0])}
			/>
			<DropdownSelect
				disabled={isInitialLoading}
				inputId={constants.filterLabels.county}
				inputLabel={constants.filterLabels.county}
				selectedValue={filterOptions.counties?.find((i) => isEqualString(i.key, selected.countyId))}
				options={filterOptions?.counties?.sort(sortDropdown)}
				onChange={(value) => handleChange(value)(filters[1])}
			/>
			<DropdownMultiSelect
				filter
				disabled={isInitialLoading}
				inputId={constants.filterLabels.property}
				inputLabel={constants.filterLabels.property}
				selectedValues={filterOptions.properties?.filter((i) => selected.propertyIds.includes(i.key ?? ""))}
				options={filterOptions.properties}
				onChange={(value) => handleChange(value)(filters[2])}
			/>
			<DropdownSelect
				filter
				inputId={constants.filterLabels.unitGroup}
				disabled={selected.propertyIds?.length > 1 || isInitialLoading}
				inputLabel={constants.filterLabels.unitGroup}
				selectedValue={filterOptions.unitGroups?.find((i) =>
					isEqualString(i.displayValue, selected.unitGroupMasterName)
				)}
				options={filterOptions.unitGroups}
				onChange={(value) => handleChange({ key: value?.displayValue })(filters[3])}
			/>
			<ExclusionReasonDropdown handleChange={handleChange} />
			<DropdownSelect
				filter={false}
				inputId={constants.filterLabels.manualExclusion}
				disabled={isInitialLoading}
				inputLabel={constants.filterLabels.manualExclusion}
				selectedValue={filterOptions.manualExclusion?.find((i) =>
					isEqualString(i.key, selected.manualExclusionFilter)
				)}
				options={filterOptions.manualExclusion}
				onChange={(value) => handleChange({ key: value?.key })(filters[4])}
			/>
		</div>
	);
};
