import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	filterHeaders: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		padding: "8px 0 50px 15px",
		alignItems: "center"
	},
	filterItem: {
		display: "flex",
		flexDirection: "column",
		marginRight: "40px"
	},
	contentContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	tableHeader: {
		width: "100%"
	}
}));
