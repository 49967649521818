import React from "react";
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, BarChart, CartesianGrid, Bar } from "recharts";
import useStyle from "./useStyle";
import { siteDefaultTheme } from "theme";
import { formatDateAsMonth, formatDateString } from "utils/miscUtils";
import { LeadsAndNetMapped } from "components/forms/PricingAutomation/Pages/Home/Glance/LeadsAndNet/leadsAndNetMapper";

interface Props {
	data: LeadsAndNetMapped[];
}

export const LeadsAndNetChart = ({ data }: Props) => {
	const classes = useStyle();

	return (
		<ResponsiveContainer width="100%" height={225} className={classes.chart}>
			<BarChart data={data} barGap={0}>
				<CartesianGrid strokeDasharray="3 3" vertical={false} />
				<Legend
					wrapperStyle={{ top: "-16px", right: "0px" }}
					verticalAlign="top"
					align="right"
					iconType="circle"
					iconSize={10}
					width={400}
					height={30}
				/>
				<XAxis dataKey="date" tickMargin={5} tickLine={false} tickFormatter={formatDateAsMonth} />
				<YAxis tickLine={false} axisLine={false} width={40} />
				<Tooltip labelFormatter={formatDateString} contentStyle={{ fontWeight: "bold" }} />
				<Bar
					isAnimationActive={false}
					dataKey="netApps"
					name="NetApps"
					barSize={2}
					fill={siteDefaultTheme.palette.primary}
				/>
				<Bar
					isAnimationActive={false}
					dataKey="leads"
					name="Leads"
					barSize={2}
					fill={siteDefaultTheme.palette.blue}
				/>
			</BarChart>
		</ResponsiveContainer>
	);
};
