import React from "react";

function displayValue(
	value?: string | number,
	format?: "currency" | "percent" | "number" | string,
	decimalPlaces?: number
) {

	if (decimalPlaces === undefined || decimalPlaces < 0) {
		decimalPlaces = 0;
	}

	if (typeof value === "undefined") {
		return "N/A";
	}
	if (typeof value === "number") {
		switch (format) {
			case "currency":
				return "$" + Math.round(value).toLocaleString();
			case "percent":
				return (value * 100).toLocaleString("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + "%";
			case "number":
			default:
				return value.toLocaleString("en-US", { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces });
		}
	}
	if (typeof value === "string") {
		if (value.length > 0) {
			return value;
		} else {
			return "-";
		}
	}

	return "~";
}

function displayAsPercent(value: number | undefined): string {
	if (value === undefined) {
		return "N/A";
	}
	return `${(value * 100).toFixed(1)}%`;
}

function displayAsCurrency(value: number | undefined): string {
	if (value === undefined) {
		return "N/A";
	}
	return "$" + Math.round(value).toLocaleString();
}

function displayAsCurrencyChange(value: number | undefined): string {
	if (value === undefined || value === 0) {
		return "";
	}

	return  (value > 0 ? '+' : '-') + "$" + Math.round(Math.abs(value)).toLocaleString();
}

function displayAsPercentChange(value?: number | undefined) {
	let body = "";
	let className = "wow";
	let iconClassName = "pi-arrow-circle-right";
	let invisible = true;

	if (typeof value === "undefined") {
		className += " wow-none invisible";
	} else if (typeof value === "number") {
		invisible = false;
		if (value === 0) {
			className += " wow-no-change";
			iconClassName = "pi-arrow-circle-right";
			body = "0%";
		} else if (value > 0) {
			className += " wow-up";
			iconClassName = "pi-arrow-circle-up";
			body = `${(value * 100).toFixed(1)}%`;
		} else {
			className += " wow-down";
			iconClassName = "pi-arrow-circle-down";
			body = `${(Math.abs(value) * 100).toFixed(1)}%`;
		}
	}

	return (
		<span className={className} style={{ visibility: invisible ? "hidden" : "visible" }}>
			&nbsp;
			<i className={`pi ${iconClassName}`} />
			{body}
		</span>
	);
}

export default {
	displayValue,
	displayAsPercentChange,
	displayAsCurrency,
	displayAsPercent,
	displayAsCurrencyChange
};
