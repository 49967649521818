import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	iconButton: {
		background: theme.palette.background,
		padding: "4px",
		width: "fit",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "fit",
		cursor: "pointer",
		border: "none",
		transition: "background-color, transform .1s ease-in-out",
		borderRadius: "50%",
		"&:disabled": {
			cursor: "not-allowed"
		},
		"&:hover:enabled": {
			background: theme.palette.faintGray,
			backfaceVisibility: "hidden",
			color: theme.palette.primary,
			fill: theme.palette.primary
		},
		"&:active:enabled": {
			transform: "scale(0.95)"
		}
	}
}));
