import React from "react";
import useStyle from "./useStyle";
import { PricingMatrixUnitRowForm, PricingMatrixUnitForm } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";

export interface PageProps {
	currentMatrices: PricingMatrixUnitForm[] | null | undefined;
	firstLoad: boolean;
}

function currencyTemplate(amount: any) {
	var result = parseInt(amount);

	return result.toLocaleString("en-US", {
		style: "currency",
		currency: "USD"
	});
}

function numberTemplate(number: any) {
	var result = parseInt(number);
	return result.toLocaleString("en-US");
}

const PricingMatrixTable: React.FC<PageProps> = function (props) {
	const classes = useStyle();

	const displayMatrix = (matrix: PricingMatrixUnitForm) => {
		if (matrix) {
			// eslint-disable-next-line no-lone-blocks
			{
				matrix.amenitizedRent === undefined
					? (matrix.amenitizedRent = "N/A")
					: (matrix.amenitizedRent = Math.round(+matrix.amenitizedRent).toString());
			}
			return (
				<div id="matrixBreak" key={matrix.unitName}>
					<header className={classes.headerContainer}>
						<div className={[classes.propertyName, classes.unitData].join(" ")}>
							<i>{matrix.propertyName}</i>
						</div>
						<div className={classes.unitData}>
							<div className={classes.unitDataRow}>
								<div className={classes.unitDataItem}>
									<b>Unit: </b> {matrix.unitName}
								</div>
								<div className={classes.unitDataItem}>
									<b>Unit Group: </b> {matrix.unitGroup}
								</div>
								<div className={classes.unitDataItem}>
									<b>Bed: </b> {matrix.bedrooms}
								</div>
								<div className={classes.unitDataItem}>
									<b>Sq Ft: </b> {numberTemplate(matrix.squareFeet)}
								</div>
								<div className={classes.unitDataItem}>
									<b>Amenitized Rent: </b> {currencyTemplate(matrix.amenitizedRent)}
								</div>
							</div>
							<div className={classes.unitDataRow}>
								<div className={classes.unitDataItem}>
									<b>Amenity List: </b> {matrix.amenityList}
								</div>
							</div>
						</div>
						<div className={classes.unitData} style={{ marginBottom: "8px" }}>
							{!!matrix?.concessionNote ? (
								<div className={[classes.unitDataRow, classes.unitDataItem].join(" ")}>
									<b>Concession: </b>
									{matrix?.concessionNote}
								</div>
							) : null}
						</div>
					</header>
					{matrix.hasInvalidAvailableDate ? (
						<Fieldset legend="">
							<p>This unit does not have a Date Available, Please correct the date in Yardi.</p>
						</Fieldset>
					) : matrix.rows === null || matrix.dateHeaders === null ? (
						<div>Matrix Unavailable...</div>
					) : (
						<DataTable value={matrix.rows} scrollable={true} className={classes.dataTable}>
							<Column
								bodyClassName={classes.dataColumn}
								bodyStyle={{ width: "5%" }}
								headerClassName={classes.dataColumn}
								headerStyle={{ width: "5%", textAlign: "right" }}
								field="term"
								header="Lease Term"
							></Column>
							{[...Array(matrix.dateIntervals)].map((_, i) => {
								return (
									<Column
										key={i}
										bodyClassName={classes.dataColumn}
										body={(e: PricingMatrixUnitRowForm) => {
											return parseInt(e.prices![i]).toLocaleString();
										}}
										bodyStyle={{
											width: "unset"
										}}
										headerStyle={{ width: "unset", textAlign: "center" }}
										headerClassName={classes.dataColumn}
										header={matrix!.dateHeaders![i]}
									></Column>
								);
							})}
						</DataTable>
					)}
				</div>
			);
		} else {
			return <div>Matrix Unavailable...</div>;
		}
	};

	const displayAllMatrices = (input: PricingMatrixUnitForm[] | null | undefined, firstLoadVar: boolean) => {
		if (input && input.length > 0) {
			return (
				<div id="matrixContainer" className={classes.matrixContainer}>
					{input.map((x) => {
						return displayMatrix(x);
					})}
				</div>
			);
		} else {
			return firstLoadVar ? (
				<div className={classes.empty}>Please Select A Unit Using The Filters Above</div>
			) : (
				<div className={classes.empty}>
					<h3>No Units Are Currently Available</h3>
				</div>
			);
		}
	};

	return displayAllMatrices(props.currentMatrices, props.firstLoad);
};

export const MemoizedTable = React.memo(PricingMatrixTable, (prevProps, nextProps) => {
	return JSON.stringify(nextProps) === JSON.stringify(prevProps);
});
