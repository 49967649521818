/* eslint-disable react-hooks/exhaustive-deps */
import { useAtAGlanceContext } from "../../Context";
import { askingPriceMapper } from "./askingPriceMapper";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getAskingPrice } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: ["asking-price", filters],
		queryFn: ({ signal }) => getAskingPrice({ divisionId: filters.divisionId || " " }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { ...askingPriceMapper(data), isLoading };
};
