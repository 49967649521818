import { useAtAGlanceContext } from "../Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadExclusionCountData = () => {
	const { getExclusionCounts } = useHomeService();
	const { filters } = useAtAGlanceContext();

	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		queryKey: ["exclusion-counts", filters],
		queryFn: ({ signal }) => getExclusionCounts(filters, signal),
	});
	const exclusionCountsIsLoading = isInitialLoading || (isFetching && isPreviousData);

	return { exclusionCounts: data, exclusionCountsIsLoading };
};
