import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	termCell: {
		width: "100%",
		display: "flex",
		flexDirection: "column"		,
		alignItems: "center"
	},
	scalingInput: {
		width: "40px"
	},
	bedColumn: {
		width:"110px"
	},
	removeColumn: {
		width: "70px"
	},
	selectColumn: {
		width: "160px"
	},
	dateColumn: {
		width: "125px"
	},
	countyColumn: {
		width: "165px"
	},	
	cityColumn: {
		width: "165px"
	},
	propertyColumn: {
		width: "165px"
	},
	footerButton: {
		width: "100px"
	}
}));
