import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	textArea: {
		paddingTop: "22px"
	},
	errorMessage: {
		color: theme.palette.error
	},
	container: {
		height: "min-content"
	}
}));
