import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { MessageInfo } from "utils/messageUtils";

export const MessagesActionMethods = {
  get: function(dispatcher: React.Dispatch<PricingPlatformActions>) {
    const showErrorState = function(messages: MessageInfo[]) {
      dispatcher({
        type: PricingPlatformActionTypes.ShowErrorState,
        messages: messages
      });
    };
    const showValidationErrorState = function(messages: MessageInfo[]) {
      dispatcher({
        type: PricingPlatformActionTypes.ShowValidationErrorState,
        messages: messages
      });
    };
    return { showErrorState, showValidationErrorState };
  }
};
