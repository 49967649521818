import React from "react";
import Menu from "../Menu";
import Page from "components/layouts/Page";
import routes from "components/routes";
import { Link } from "react-router-dom";
import constants from "utils/constants";

const InvalidPage: React.FC = function () {

  return (
    <Page menu={<Menu title={constants.menuTitle.invalidPage404} />}>
        <div><h1>Invalid url. Click <Link to={routes.home}>here</Link> to return home.</h1></div>
    </Page>
  );
};

export default InvalidPage;
