import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	outlined: {
		borderRadius: "50%",
		margin: "0 2px"
	},
	table: {
		"& td": {
			whiteSpace: "wrap"
		}
	}
}));
