import React from "react";

import useStyle from "./useStyle";
import { useSecondaryReviewFilterContext } from "../../Context";

export const PriceResetHeader = () => {
	const classes = useStyle();

	const { selectedRow } = useSecondaryReviewFilterContext();

	return (
		<header>
			<h2 className={classes.title}>{`${selectedRow?.unitGroupMasterName} - ${selectedRow?.property}`}</h2>
			<div className={classes.flaggedLine}>
				<span>REVIEW REASON: {selectedRow?.reviewReasonName}</span>
				<span>{selectedRow?.reviewReasonDescription}</span>
			</div>
		</header>
	);
};
