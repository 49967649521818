/* eslint-disable react-hooks/exhaustive-deps */
import { SecondaryReviewTilesResponse } from "api";
import { useAtAGlanceContext } from "../Context";
import { useSecondaryReviewService } from "components/forms/PricingAutomation/services/secondaryReview.service";
import { useQuery } from "@tanstack/react-query";

const defaultValue: SecondaryReviewTilesResponse = {
	count: {
		completed: 0,
		toReview: 0
	},
	secondaryReviewTiles: [
		{
			title: "HIGH ATR 30 + DAYS",
			toReview: 0,
			completed: 0
		},
		{
			title: "LOW MARKET POSITION, LOW LEASING VELOCITY",
			toReview: 0,
			completed: 0
		},
		{
			title: "HITTING FLOOR/CEILING",
			toReview: 0,
			completed: 0
		},
		{
			title: "LOW ATR, HIGH LEASING VELOCITY",
			toReview: 0,
			completed: 0
		},
		{
			title: "0% ATR, LOW MARKET POSITION",
			toReview: 0,
			completed: 0
		},
		{
			title: "EXCLUSION PARAMETER EXPIRATION",
			toReview: 0,
			completed: 0
		}
	]
};

export const useLoadData = () => {
	const { getSecondaryReviewsTiles } = useSecondaryReviewService();
	const { filters } = useAtAGlanceContext();

	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		keepPreviousData: true,
		queryKey: ["secondary-tiles", filters],
		queryFn: ({ signal }) => getSecondaryReviewsTiles(filters, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return {
		data: data || defaultValue,
		isLoading
	};
};
