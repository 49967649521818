import { createContext, useContext } from "react";
import { MessageSeverity } from "utils/messageUtils";

export interface AlertMessage {
	title: string;
	message: string;
	severity: MessageSeverity;
	autoHide?: boolean;
}

export type AlertContextType = {
	setAlert: (info: AlertMessage) => void;
};

export const ALERT_DEFAULT: AlertContextType = {
	setAlert: () => null
};

export const AlertContext = createContext(ALERT_DEFAULT);

export const useAlertContext = () => {
	const context = useContext(AlertContext);

	if (!context) {
		throw new Error("useAlertContext used outside AlertContext provider");
	}

	return context;
};
