import React from "react";
import useStyle from "./useStyle";
import { ExclusionsUITileFooter } from "api";

interface Props {
	count?: ExclusionsUITileFooter;
	isLoading: boolean;
}

export const Counts = ({ count, isLoading }: Props) => {
	const classes = useStyle();

	if (isLoading) {
		return <span role="presentation" className={["skeleton", "p-card", classes.loadingHeaderContent].join(" ")} />;
	}

	return <span>{`Total ${count?.completed} Unit Groups Excluded`}</span>;
};
