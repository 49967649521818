import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";

const StaleUnitPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdateStaleUnitPageFilterOptions:
      newState.staleUnitPageState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdateStaleUnitPageReports:
      newState.staleUnitPageState.staleUnitReports = action.payload;
      return newState;
    case PricingPlatformActionTypes.UpdateStaleUnitPageIsLoading:
      newState.staleUnitPageState.isLoading = action.payload;
      return newState;
  }
  return newState;
};

export default StaleUnitPageReducer;