import React from "react";
import { AskingPriceTileResult } from "api/pricing-platform-api";
import { formatCurrencyNoFractions } from "utils/miscUtils";

interface Props {
	data: Required<AskingPriceTileResult>;
}

const SubmarketComp = ({ data }: Props) => {
	return (
		<>
			<span>
				Current: <span data-value={data.current < 0}>{formatCurrencyNoFractions(data.current)}</span>
			</span>
			<span>
				Historical Avg:{" "}
				<span data-value={data.historicalAverage < 0}>{formatCurrencyNoFractions(data.historicalAverage)}</span>
			</span>
			<span>
				Historical Range:{" "}
				<span data-value={data.historicalRangeFromValue < 0}>
					{formatCurrencyNoFractions(data.historicalRangeFromValue)}
				</span>{" "}
				To{" "}
				<span data-value={data.historicalRangeToValue < 0}>
					{formatCurrencyNoFractions(data.historicalRangeToValue)}
				</span>
			</span>
		</>
	);
};

export default SubmarketComp;
