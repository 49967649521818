import React, { useState } from "react";
import useStyle from "./useStyle";
import { TextField } from "components/controls/TextField";
import { useExclusionsContext } from "../../../Context";
import { useFormContext } from "react-hook-form";
import { calculateAmount, calculatePercentage, onBlurNumber, onlyNumber, parseToFloat } from "utils/miscUtils";
import DropdownSelect, { DropdownOption } from "components/controls/DropdownSelect";
import { useLoadData } from "../useLoadData";

export const ChangePriceHold = () => {
	const classes = useStyle();
	const { selectedRow } = useExclusionsContext();
	const [selectedOption, setSelectedOption] = useState("change");
	const { overrideFormOptionsData } = useLoadData();
	const [selectedOverrideReason, setSelectedOverrideReason] = useState(undefined as (DropdownOption | undefined));
	const { register, errors, setValue } = useFormContext();

	var originalCurrentPrice: any;
	originalCurrentPrice = selectedRow?.completed ? selectedRow.lastPrice : selectedRow.currentPrice;

	const handleChange = (key: "amount" | "percentageChange" | "dollarChange") => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (key === "percentageChange") {
			setValue("amount", calculateAmount({ current: originalCurrentPrice, percentage: e.target.value }));
			setValue("dollarChange", parseToFloat(Number(calculateAmount({ current: originalCurrentPrice, percentage: e.target.value })) - Number(originalCurrentPrice)));
		}
		if (key === "amount") {
			setValue("percentageChange", calculatePercentage({ current: e.target.value, last: originalCurrentPrice }));
			setValue("dollarChange", parseToFloat(Number(e.target.value) - Number(originalCurrentPrice)));
		}
		if (key === "dollarChange") {
			setValue("amount", parseToFloat(Number(originalCurrentPrice) + Number(e.target.value)));
			setValue("percentageChange", calculatePercentage({ current: parseToFloat(Number(originalCurrentPrice) + Number(e.target.value)), last: originalCurrentPrice }));
		}
	};

	const handleOverrideSelection = (e: DropdownOption | undefined) => {
		setValue("overrideReason", e?.key);
		setSelectedOverrideReason(e);
	}

	return (
		<div className={classes.outerContainer}>
		<div className={classes.changeResetContainer}>
			<div className={classes.radioButtonOption}>
				<input
					ref={register}
					type="radio"
					name="changeOrHold"
					value="change"
					onChange={(e) => setSelectedOption(e.target.value)}
					checked={selectedOption === "change"}
				/>
				<label htmlFor="changePrice">Change Price/Rate Reset</label>
			</div>
			<div className={classes.changeResetInputs}>
				<input ref={register} type="hidden" name="initialFormAmount" value={parseToFloat(selectedRow?.currentPrice)} />
				<TextField
					ref={register}
					name="amount"
					onKeyDown={onlyNumber}
					onBlur={onBlurNumber({ decimals: 0 })}
					onChangeCapture={handleChange("amount")}
					defaultValue={parseToFloat(selectedRow?.currentPrice)}
					disabled={selectedOption !== "change"}
					label="Amount"
					symbol={{ content: "$", direction: "left" }}
				/>
				<TextField
					ref={register}
					onKeyDown={onlyNumber}
					onBlur={onBlurNumber({ decimals: 1 })}
					name="percentageChange"
					onChangeCapture={handleChange("percentageChange")}
					disabled={selectedOption !== "change"}
					defaultValue={
						selectedRow?.completed
						? calculatePercentage({ current: selectedRow?.currentPrice, last: selectedRow?.lastPrice })
						: 0
					}
					label="% Change"
					symbol={{ content: "%", direction: "right" }}
				/>
				<TextField
					ref={register}
					name="dollarChange"
					onKeyDown={onlyNumber}
					onBlur={onBlurNumber({ decimals: 0 })}
					onChangeCapture={handleChange("dollarChange")}
					defaultValue={ 
						selectedRow?.completed
						? parseToFloat(Number(selectedRow?.currentPrice) - Number(selectedRow?.lastPrice)) 
						: 0
					}
					disabled={selectedOption !== "change"}
					label="$ Change"
					symbol={{ content: "$", direction: "left" }}
				/>						
			</div>
			{errors.amount && <span className={classes.errorMessage}>{errors.amount.message}</span>}
			{errors.percentageChange && <span className={classes.errorMessage}>{errors.percentageChange.message}</span>}
			{errors.changeOrHold && <span className={classes.errorMessage}>{errors.changeOrHold.message}</span>}
			<div className={classes.radioButtonOption}>
				<input
					ref={register}
					type="radio"
					name="changeOrHold"
					value="hold"
					onChange={(e) => setSelectedOption(e.target.value)}
					checked={selectedOption === "hold"}
				/>
				<label htmlFor="hold">Hold</label>
			</div>
		</div>
		<div className={classes.overrideReasonContainer}>
				Override Reason
				<DropdownSelect
				disabled={false}
				selectedValue={selectedOverrideReason}
				options={overrideFormOptionsData.overrideReasons}
				onChange={(value) => handleOverrideSelection(value)}
			/>
			<input ref={register} name="overrideReason" hidden/>
			</div>
		</div>
	);
};
