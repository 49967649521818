import React from "react";
import { FormProvider } from "react-hook-form";
import { RecommendationSection } from "./Recommendation";
import { NotesSection } from "./Notes";
import useStyle from "./useStyle";
import { Confirmation } from "./Alerts/Confirmation";
import { usePriceForm } from "./usePriceForm";
import { PricingButton } from "components/controls/PricingButton";
import RequirePermission from "components/forms/RequirePermission";
import constants from "utils/constants";

interface Props {
	handleClose: () => void;
}

export const PriceResetForm = ({ handleClose }: Props) => {
	const classes = useStyle();
	const { handleValidateForm, methods, onSubmit, confirmationPopup } = usePriceForm({ handleClose });

	return (
		<FormProvider {...methods}>
			<form id="priceResetForm" onSubmit={methods.handleSubmit(onSubmit)}>
				<RequirePermission permissions={[constants.permissions.canManagePricing]}>
					<RecommendationSection />
					<NotesSection />
					<div className={classes.formButtons}>
						<PricingButton variant="primary" type="button" onClick={handleValidateForm}>
							Save
						</PricingButton>
						<PricingButton variant="secondary" type="button" onClick={handleClose}>
							Cancel
						</PricingButton>
					</div>
				</RequirePermission>
			</form>
			<Confirmation onClose={confirmationPopup.handleClose} open={confirmationPopup.open} />
		</FormProvider>
	);
};
