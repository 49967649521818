import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	mainContent: {
		borderRadius: "12px",
		background: theme.palette.background,
		paddingBottom: "28px",
		display: "flex",
		flexDirection: "column",
		gap: "54px",
		paddingLeft: "14px",
		paddingRight: "14px",
		color: theme.palette.text
	},
	cardListLayout: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
		gap: "18px",
		paddingTop: "18px"
	},
	cardContainer: {
		borderRadius: "12px",
		cursor: "pointer",
		"&:hover": {
			scale: "1.025",
			background: theme.palette.grayBackground,
			transition: "0.2s"
		},
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			margin: "auto",
			textAlign: "center",
			maxWidth: "250px"
		},
		"& [class$=p-card-body]": {
			height: "100%",
			display: "flex",
			flexDirection: "column"
		},
		"& [class$=p-card-content]": {
			padding: theme.paddingScale.small
		}
	},
	link: {
		display: "inherit",
		textDecoration: "none",
		color: theme.palette.primary,
		flexGrow: 1
	},
	reviewAll: {
		textDecoration: "none",
		color: theme.palette.text,
		fontSize: "14px",
		"&:hover": {
			color: theme.palette.primary
		}
	},
	cardRowContent: {
		display: "flex",
		justifyContent: "space-between"
	},
	headerContent: {
		display: "flex",
		gap: "18px"
	}
}));
