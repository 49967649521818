import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";
import constants from "utils/constants";

  const createMenuItems = (): MenuItemProps[] => {
    return(
      [
        {
          title: constants.menuTitle.bedrooms.toUpperCase(),
          to: routes.unitGroupAdmin.bedrooms,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.bedrooms)
        },
        {
            title: constants.menuTitle.createBedroom.toUpperCase(),
            to: routes.unitGroupAdmin.createBedroom,
            isActive:
            locationUtils.matchPathWithParams(routes.unitGroupAdmin.createBedroom)
        }
      ]
    )
}

const editMenuItems = (): MenuItemProps[] => {
  return(
    [
      {
        title: constants.menuTitle.bedrooms.toUpperCase(),
        to: routes.unitGroupAdmin.bedrooms,
        isActive:
          locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.bedrooms)
      },
      {
          title: constants.menuTitle.updateBedroom.toUpperCase(),
          to: routes.unitGroupAdmin.updateBedroom(""),
          isActive:
          locationUtils.matchPathWithParams(routes.unitGroupAdmin.updateBedroom(""))
      },
    ]
  )
}

export interface MenuProps {
  action?: string;
  title?: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

const BedroomMenu: React.FC<MenuProps> = function(props: MenuProps) {  
  if(props.action === constants.pageAction.create) {
    return <Menu {...props} title="ADMIN" items={createMenuItems()} />;
  } else {
    return <Menu {...props} title="ADMIN" items={editMenuItems()} />;
  }  
};

export default BedroomMenu;
