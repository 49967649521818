import React from "react";
import useStyle from "./useStyle";
import { useLocation } from "react-router-dom";
import { DrawerItems } from "./DrawerItems";

export const Drawer = () => {
	const classes = useStyle();
	const { pathname } = useLocation();

	return (
		<aside id="drawer" className={classes.drawerContainer}>
			<DrawerItems currentPath={pathname} />
		</aside>
	);
};
