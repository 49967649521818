import { createContext, useContext } from "react";

export type ActionsContextType = {
	confirmation: string;
	handleOpen: (type: string) => void;
	handleClose: () => void;
	handleData: (data: any) => void;
	data: any;
};

export const ACTIONS_DEFAULT: ActionsContextType = {
	confirmation: "",
	data: {},
	handleClose: () => null,
	handleData: () => null,
	handleOpen: () => null
};

export const ActionsContext = createContext(ACTIONS_DEFAULT);

export const useActionsContext = () => {
	const context = useContext(ActionsContext);

	if (!context) {
		throw new Error("useActionsContext used outside ActionsContext provider");
	}

	return context;
};
