import React, { useState } from "react";
import { initialAdvancedFilters, initialPropertiesPageSelectedFilters } from "components/store/usePricingPlatformStore";
import { PropertySearchState } from "components/store/PropertiesPage/propertyPageInterfaces";

import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import constants from "utils/constants";
import { Property } from "api";
import { initSearchState, PropertyPageData } from "./ExceptionsSearchFilter";

import "../PropertiesPage/_properties-page.scss";

const PropertyExceptions: React.FC<PropertyPageData> = function (props: PropertyPageData) {
	const pageState = props.pagedProperties;
	const [messageAreaState] = useMessageArea();
	const [state] = useState({
		currentFilters: initialPropertiesPageSelectedFilters,
		advancedFilters: initialAdvancedFilters
	});

	//initial search filters state.
	const [searchState] = useState<PropertySearchState>({
		...initSearchState()
	});

	// #region Grid stuff
	const onPage = (e: any) => {
		//Call parent searchProperties.
		props.onChange(state.currentFilters, state.advancedFilters, 0, searchState.take, e.sortField, e.sortOrder);
	};

	const onSort = (e: any) => {
		//Call parent searchProperties.
		props.onChange(state.currentFilters, state.advancedFilters, 0, searchState.take, e.sortField, e.sortOrder);
	};

	const onRowClick = (e: any) => {
		const property = e.data as Property;
		// To Do: Capture the row data to update a field value.
		console.log(property);
	};

	const dataTable = (pageState: any) => {
		if (pageState === undefined) {
			return <Spinner isVisible={true} />;
		} else if (!pageState.pageLoading && pageState.items && pageState.items.length === 0) {
			return (
				<div className="error-message-styling">
					No properties found. You either need to adjust the page filters, or you do not have permission to view
					any properties.
				</div>
			);
		} else {
			return (
				<DataTable
					id="property-exceptions-list"
					className={`property-list p-datatable-striped`}
					tableClassName="property-list-table"
					rowClassName={() => {
						return { "property-list_row": true };
					}}
					value={pageState.items}
					paginator={true}
					alwaysShowPaginator={true}
					pageLinkSize={5}
					rowsPerPageOptions={constants.rowsPerPageOptions}
					lazy={true}
					totalRecords={pageState.totalCount}
					loading={pageState.pageLoading}
					onPage={onPage}
					onSort={onSort}
					onRowClick={onRowClick}
					first={searchState.skip}
					rows={searchState.take}
					sortField={searchState.sortBy}
					sortOrder={searchState.sortOrder}
					dataKey="id"
					emptyMessage="No properties found"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} properties"
				>
					<Column field="displayName" header="Name" sortable></Column>
					<Column field="address" header="Address" sortable></Column>
					<Column field="county" header="County" sortable></Column>
					<Column field="city" header="City" sortable></Column>
					<Column field="state" header="State" sortable></Column>
					<Column field="type" header="Type" sortable></Column>
					<Column field="stories" header="Stories" sortable></Column>
					<Column field="totalUnits" header="Units" sortable></Column>
					<Column field="propertyClass" header="Class" sortable></Column>
					<Column field="region" header="Region" sortable></Column>
				</DataTable>
			);
		}
	};

	return (
		<div>
			<div>{messageAreaState.messageArea}</div>
			<div>{dataTable(pageState)}</div>
		</div>
	);
};

export default PropertyExceptions;
