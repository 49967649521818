import React from "react";
import PageSection from "components/controls/PageSection";
import { TextArea } from "components/controls/TextArea";
import useStyle from "./useStyle";
import { useFormContext } from "react-hook-form";
import constants from "utils/constants";

export const NotesSection = () => {
	const classes = useStyle();
	const { register, errors } = useFormContext();

	return (
		<div className={classes.container}>
			<PageSection title={constants.titleSection.notes}>
				<div className={classes.textArea}>
					<TextArea ref={register} name="notes" rows={8} maxLenght={2000} withCounter />
					{errors.notes && <span className={classes.errorMessage}>{errors.notes.message}</span>}
				</div>
			</PageSection>
		</div>
	);
};
