import React from "react";
import { usePopUp } from "./usePopUp";
import { ConfigureAutomationPopUp } from "./PopUp";
import constants from "utils/constants";
import useStyle from "./useStyle";
import { useConfigureAutomationFilterContext } from "../Context";
import ReactDOM from "react-dom";

export const ConfigureAutomationNewOverride = ({
	overrideRef,
	onPage
}: {
	overrideRef: React.MutableRefObject<any>;
	onPage: (e: any) => void;
}) => {
	const classes = useStyle();
	const { open, handleOpen, handleClose } = usePopUp();
	const { filters, editedRowLevel } = useConfigureAutomationFilterContext();

	const handleClick = () => {
		onPage({ first: 0, rows: 10, page: 1 });
		handleOpen();
	};

	return (
		<>
			<button
				onClick={handleClick}
				type="button"
				className={classes.newOverride}
				disabled={
					!filters.hierarchyLevels?.key || !!editedRowLevel || filters.hierarchyLevels.displayValue === "Portfolio"
				}
			>
				{`+ ${constants.popUpContent.configureAutomation.title}`}
			</button>
			{overrideRef?.current &&
				ReactDOM.createPortal(<ConfigureAutomationPopUp open={open} onClose={handleClose} />, overrideRef.current)}
		</>
	);
};
