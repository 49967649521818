import { SecondaryReviewFilterContext } from "./context";

import React, { FC, useEffect, useState } from "react";

export const SecondaryReviewFilterProvider: FC = ({ children }) => {
	const [selectedRow, setSelectedRow] = useState<Record<string, string | number>>({});
	const [refresh, setRefresh] = useState(false);

	const handleSelectedRow = (row: Record<string, string | number>) => {
		setSelectedRow(row);
	};

	useEffect(() => {
		if (refresh) {
			const timer = setTimeout(() => {
				setRefresh(false);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [refresh]);

	return (
		<SecondaryReviewFilterContext.Provider
			value={{
				selectedRow,
				setSelectedRow: handleSelectedRow,
				refresh,
				setRefresh
			}}
		>
			{children}
		</SecondaryReviewFilterContext.Provider>
	);
};

export default SecondaryReviewFilterProvider;
