import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	cardContainer: {
		overflow: "hidden",
		borderRadius: "8px",
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			display: "flex",
			justifyContent: "center",
			textAlign: "center",
			overflowClipBox: "content-box",
			textOverflow: "ellipsis"
		},
		"& [class$=p-card-body]": {
			height: "100%",
			paddingInline: "8px",
			display: "flex",
			flexDirection: "column"
		},
		"& [class$=p-card-content]": {
			padding: "0px",
			display: "flex",
			wordBreak: "break-all",
			flexDirection: "column",
			justifyContent: "space-around",
			height: "100%"
		}
	},
	variance: {
		display: "flex",
		position: "relative",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		overflowX: "visible",
		"& span": {
			top: "20px",
			right: "0px",
			whiteSpace: "nowrap",
			position: "absolute",
			fontSize: "10px"
		}
	},
	footer: {
		display: "flex",
		alignItems: "center",
		justifySelf: "end",
		justifyContent: "center",
		gap: "8px",
		fontWeight: "500"
	},
	arrow: {
		fontSize: "8px",
		color: theme.palette.background,
		borderRadius: "50%",
		padding: "5px"
	},
	yellow: {
		background: theme.palette.warning
	},
	red: {
		backgroundColor: theme.palette.softRed
	},
	green: {
		backgroundColor: theme.palette.success
	}
}));
