import React from "react";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { ColumnType } from "./types";

interface Props {
	columns: ColumnType[];
}

/**
 * This component renders a header group for a table,
 * and a sub-header group for each sub-column.
 * @param columns: ColumType[] - The columns to render.
 * @returns A header group for a table - JSX.Element
 *
 * @example
 * <HeaderGroup columns={columns}/>
 */
export const HeaderGroup = ({ columns = [] }: Props) => {
	return (
		<ColumnGroup>
			<Row>
				{columns?.map(
					(
						{ field, name, visible = true, sortable = false, subColumns, minWidth, frozen = false, height },
						index
					) =>
						visible && (
							<Column
								frozen={frozen}
								field={field}
								sortable={sortable}
								key={`${name}-${index}`}
								header={name}
								colSpan={(subColumns && subColumns.length) || 1}
								style={{ minWidth, height }}
							/>
						)
				)}
			</Row>
			{columns?.some(({ subColumns }) => subColumns && subColumns.length > 0) && (
				<Row>
					{columns.map(
						({ visible = true, subColumns = [], frozen = false }) =>
							visible &&
							subColumns.map(({ sortable = true, ...subColumn }, index) => (
								<Column
									frozen={frozen}
									className="sub-header"
									sortable={sortable}
									key={`${subColumn.name}-${index}`}
									field={subColumn.field}
									header={subColumn.name}
									style={{ textAlign: "center" }}
								/>
							))
					)}
				</Row>
			)}
		</ColumnGroup>
	);
};
