import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	page: {
		margin: "0 auto",
		position: "relative"
	},
	menu: {
		position: "fixed",
		background: theme.palette.blue,
		display: "flex",
		maxWidth: "calc(100vw - 118px)",
		"@media (max-width: 768px)": {
			maxWidth: "100%"
		},
		width: "100%",
		top: "64px",
		zIndex: "950",
		height: "64px"
	},
	body: {
		margin: "128px auto 0",
		padding: "12px",
		maxWidth: theme.maxScreenWidth
	},
	"@media print": {
		menu: {
			display: "none"
		},
		body: {
			margin: "0"
		}
	}
}));
