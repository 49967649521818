import {
	PagedResultOfPricingAutomationBaseResult,
	PagedResultOfUserModifiedFloorPlansGridItem,
	PriceAdjustmentRequest,
	PriceAdjustmentResponse,
	PricingAutomationClient,
	SystemAutomatedReviewFilterOptions,
	SystemAutomatedReviewSearchQuery,
	SystemAutomatedSearchQuery
} from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

export const useSystemAutomatedService = () => {
	const identity = useContext(IdentityContext);

	const getSuccessfulAutomationResult = async (
		payload: SystemAutomatedSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfPricingAutomationBaseResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSuccessfulAutomationResult", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfPricingAutomationBaseResult;
		} catch (err) {
			throw err;
		}
	};

	const getUserModifiedFloorPlans = async (
		payload: SystemAutomatedSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfUserModifiedFloorPlansGridItem> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getUserModifiedFloorPlans", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfUserModifiedFloorPlansGridItem;
		} catch (err) {
			throw err;
		}
	};

	const getSuccessfullyAutomatedResult = async (
		payload: SystemAutomatedSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfPricingAutomationBaseResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSuccessfulAutomationResult", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfPricingAutomationBaseResult;
		} catch (err) {
			throw err;
		}
	};

	const getSystemAutomatedReviewsFilters = async (
		payload: SystemAutomatedReviewSearchQuery,
		signal?: AbortSignal
	): Promise<SystemAutomatedReviewFilterOptions> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSystemAutomatedReviewsFilters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as SystemAutomatedReviewFilterOptions;
		} catch (err) {
			throw err;
		}
	};

	const savePriceAdjustment = async (
		payload: PriceAdjustmentRequest,
		signal?: AbortSignal
	): Promise<PriceAdjustmentResponse> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "savePriceAdjustment", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PriceAdjustmentResponse;
		} catch (err) {
			throw err;
		}
	};

	return {
		getSuccessfulAutomationResult,
		getUserModifiedFloorPlans,
		getSuccessfullyAutomatedResult,
		getSystemAutomatedReviewsFilters,
		savePriceAdjustment
	};
};
