import React from "react";
import { Dialog } from "primereact/dialog";
import useStyle from "../useStyle";
import constant from "utils/constants";
import { NewOverrideForm } from "./Form";

interface Props {
	open: boolean;
	onClose: () => void;
}

export const ConfigureAutomationPopUp = ({ open, onClose }: Props) => {
	const classes = useStyle();
	return (
		<Dialog
			visible={open}
			className={classes.dialog}
			header={constant.popUpContent.configureAutomation.title}
			focusOnShow={false}
			blockScroll
			closeOnEscape
			onHide={onClose}
		>
			<NewOverrideForm onClose={onClose} />
		</Dialog>
	);
};
