import { Checkbox as PrimeCheckbox } from "primereact/checkbox";
import React from "react";
import useStyle from "./useStyle";
import { withLabel } from "../Label";

//might need to delete undefined again
export type Checked = boolean | "mixed" | undefined;

export interface CheckboxProps {
	checked: Checked;
	onChange: (checked: Checked) => void;
	mixedIcon?: string;
	disabled?: boolean | undefined;
	validationMessage?: string | undefined;
}

export const defaultMixedIcon = "\\e90f";

const Checkbox: React.FC<CheckboxProps> = function (props) {
	const classes = useStyle(props);
	return (
		<PrimeCheckbox
			className={classes.checkbox}
			checked={props.checked !== false}
			onChange={(e) => props.onChange(e.checked)}
			tooltip={props.validationMessage}
			tooltipOptions={{ event: "hover" }}
			disabled={props.disabled || false}
		/>
	);
};

Checkbox.defaultProps = {
	mixedIcon: defaultMixedIcon
};

export default withLabel(Checkbox);
