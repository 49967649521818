const join = function (...classNames: (string | null | undefined)[]): string {
	return classNames.filter((name) => name != null).join(" ");
};

const conditionClass = function (
	className: string | null | undefined,
	condition: boolean | undefined | null
): string | undefined {
	if (!condition || !className) {
		return;
	}

	return className;
};

export default {
	join: join,
	conditionClass
};
