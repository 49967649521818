import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	concessionsContent: {
		width: "100%",
		padding: "0",
		margin: "0",
		height: "100%",
		fontWeight: "500"
	},
	concessionsRow: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		borderBottom: `1px solid ${theme.palette.lightGray}`,
		justifyContent: "space-between",
		"& > p": {
			margin: "8px 0px"
		}
	},
	borderTop: {
		marginTop: "12px",
		borderTop: `1px solid ${theme.palette.lightGray}`
	},
	icon: {
		fontSize: "14px",

		color: theme.palette.blue
	}
}));
