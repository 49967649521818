import React from "react";
import useStyle from "../useStyle";
import { LeadsAndNet } from "../LeadsAndNet";
import { LeasingActivity } from "../LeasingActivity";
import { MarketPosition } from "../MarketPosition";
import { useLoadData } from "./useLoadData";

export const Charts = () => {
	const classes = useStyle();
	const { data, isLoading } = useLoadData();
	return (
		<>
			<MarketPosition data={data?.series ?? []} isLoading={isLoading} />
			<div className={[classes.chartsBottomGrid, classes.gridSection].join(" ")}>
				<LeasingActivity data={data ?? {}} isLoading={isLoading} />
				<LeadsAndNet data={data ?? {}} isLoading={isLoading} />
			</div>
		</>
	);
};
