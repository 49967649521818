/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { useConfigureAutomationFilterContext } from "../../../Context";
import { SystemAutomatedReviewFilterOptions } from "api";
import { mapFilterOptions } from "./mapFilterOptions";
import { useFormContext } from "react-hook-form";
import { useMemo } from "react";
import { useConfigureAutomationService } from "components/forms/PricingAutomation/services/configureAutomation.service";

export const useLoadData = () => {
	const { filters } = useConfigureAutomationFilterContext();
	const hierarchySelected = filters.hierarchyLevels.displayValue?.split(" ").join("").toLowerCase();
	const { watch } = useFormContext();
	const { getConfigPageFilters } = useConfigureAutomationService();
	const values = watch();

	const filterPayload = useMemo(
		() => ({
			countyId: values?.County?.key ?? "",
			divisionId: values?.Division?.key ?? "",
			propertyIds: values?.Property?.key ? [values?.Property?.key] : [],
			unitGroupId: values?.["Unit Group"]?.key ?? ""
		}),
		[values]
	);

	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		queryKey: ["configure-automation-filters", filterPayload],
		queryFn: async ({ signal }) => getConfigPageFilters(filterPayload, signal),
		select: (data: SystemAutomatedReviewFilterOptions) => mapFilterOptions(data)
	});

	const filterOptions = Object.entries(data || []).filter(({ "0": key }) =>
		hierarchySelected?.includes(key.toLowerCase())
	);
	return { data: filterOptions, isLoading: isInitialLoading };
};
