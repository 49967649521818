import { AtGlanceParameter } from "api";

export const percentageFields = {
	values: ["ATR", "LTA/GTA", "NET CONVERSION"],
	footers: ["LEADS", "LEASING VELOCITY"]
};

const footerFormats = [
	{ name: "ATR", footerFormat: "BPS" },
	{ name: "LEADS", footerFormat: "VARIANCE-PERCENTAGE" },
	{ name: "NET APPS", footerFormat: "VARIANCE" },
	{ name: "LTA/GTA", footerFormat: "VARIANCE-PERCENTAGE" },
	{ name: "NET CONVERSION", footerFormat: "BPS" },
	{ name: "LEASING VELOCITY", footerFormat: "VARIANCE-PERCENTAGE" }
];

//TODO: Active leasing velocity tag when is ready
export const parametersListMapper = (data: AtGlanceParameter[]) => {
	return (
		data
			//Hide leasing velocity
			.filter(({ title }) => title !== "LEASING VELOCITY")
			.map((parameter) => ({
				...parameter,
				footerFormat: footerFormats.find(({ name }) => name === parameter.title)?.footerFormat || ""
			}))
	);
};
