import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	submitButton: {
		background: theme.palette.text,
		border: "none"
	},
	cancelButton: {
		color: `${theme.palette.text} !important`
	},
	formButtons: {
		paddingBlock: "20px",
		display: "flex",
		gap: "12px",
		margin: "0px"
	}
}));
