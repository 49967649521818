import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";

const RentSummaryPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdateRentSummaryPageFilterOptions:
      newState.rentSummaryPageState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdateRentSummaryPageReport:
      newState.rentSummaryPageState.propertyReport = action.payload;
      return newState;
    case PricingPlatformActionTypes.UpdateRentSummaryPageIsLoading:
      newState.rentSummaryPageState.isLoading = action.payload;
      return newState;
  }
  return newState;
};

export default RentSummaryPageReducer;