import getYear from "date-fns/getYear";
import parseISO from "date-fns/parseISO";

export function matchesDivision(
  divisionFilter: string | undefined | null,
  division: string | undefined | null
) {
	if(division!==undefined && division!==null)
  		return divisionFilter == null || division.includes(divisionFilter);
	else
		return divisionFilter == null;
}

export function matchesYear(
  yearFilter: string | number | undefined | null,
  startDate: Date | string | undefined | null,
  endDate: Date | string | undefined | null
) {
  if (yearFilter == null) return true;
  if (endDate == null) return false;

  if (typeof startDate === "string") {
    startDate = parseISO(startDate);
  }
  if (typeof endDate === "string") {
    endDate = parseISO(endDate);
  }

  if (typeof yearFilter === "string") {
    yearFilter = parseInt(yearFilter);
  }

  if (startDate == null) {
    return getYear(endDate) === yearFilter;
  }
  else {
    return getYear(startDate) <= yearFilter && getYear(endDate) >= yearFilter;
  }
}

export function matchesVersion(
  versionFilter: string | undefined | null,
  version: string | undefined | null
) {
  return versionFilter == null || version === versionFilter;
}
