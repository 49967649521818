import React from "react";
import {
  UserForm,
  UserFormRole,
  UserFormDivision,
  UserFormProperty,
  UserFormConfiguration
} from "api";
import Validation from "components/controls/Validation";
import Spacer from "components/controls/Spacer";
import Dropdown from "components/controls/Dropdown";
import MultiSelect from "components/controls/MultiSelect";
import PropertyChooser from "../PropertyChooser";
import PrimaryButton from "components/controls/PrimaryButton";
import listUtils from "utils/listUtils";
import InputStateless from "components/controls/InputStateless";
import ContentArea from "components/layouts/ContentArea";
import LoadingPage from "components/forms/LoadingPage";
import VisibilityToggle from "components/controls/VisibilityToggle";
import Checkbox from "components/controls/Checkbox";

export interface EditUserProps {
  state: UserForm;
  setState: (newState: UserForm) => any;
  disableSubmit: boolean;
  onSubmit: () => void;
  hideSaveButton?: boolean | undefined;

}

const EditUser: React.FC<EditUserProps> = function(props: EditUserProps) {
  if (!(props && props.state)) {
    return <LoadingPage message="Error" />;
  }

  function toggleUserActive() {
    props.setState({
      ...props.state,
      isActive: !props.state.isActive
    })
    console.log(props.state.isActive)
  }

  function setRole(role: UserFormRole | undefined) {
    props.setState({
      ...props.state,
      selectedRole: role
    });
  }

  function setDivision(division: UserFormDivision | undefined) {
    props.setState({
      ...props.state,
      selectedRole: {
        ...(props.state && props.state.selectedRole),
        selectedDivisions: division ? [division] : []
      }
    });
  }

  function setDivisions(divisions: UserFormDivision[] | undefined) {
    

  if(divisions)
   divisions.forEach(combineArrays);

    props.setState({
      ...props.state,
      selectedRole: {
        ...(props.state && props.state.selectedRole),
        selectedDivisions: divisions || []
      }
    });

  }
  function combineArrays(item:any) {
    const selectedDivisions = props.state.selectedRole && props.state.selectedRole.selectedDivisions;

    if(selectedDivisions){
    var selectedDivision = selectedDivisions.find(obj => obj.key === item.key);
    if(selectedDivision)
    item.selectedProperties = selectedDivision.selectedProperties;
    
  }
  }
  function createNewSelectedDivisionsWithSelectedProperties(
    selectedDivisions: UserFormDivision[] | undefined,
    properties: UserFormProperty[]
  ): Array<UserFormDivision> {
    if (!selectedDivisions) {
      return [];
    }
    const newSelectedDivisions: Array<UserFormDivision> = listUtils.deepCopyOneLevel(
      selectedDivisions
    );

    newSelectedDivisions.forEach((division: UserFormDivision) => {
      division.selectedProperties = [];

      properties.forEach((property: UserFormProperty) => {
        if (!division.properties || !division.selectedProperties) {
          return;
        }

        const propertyInThisDivision = listUtils.firstOrDefaultKeyedItem(
          division.properties,
          property
        );

        if (propertyInThisDivision) {
          division.selectedProperties.push({ ...property });
        }
      });
    });

    return newSelectedDivisions;
  }

  function getDivisionDropdown(
    userFormConfiguration: UserFormConfiguration
  ): JSX.Element {
    return (
      <React.Fragment>
        <Spacer orientation="v" />
        <Dropdown
          label={userFormConfiguration.divisionLabelName || "Division"}
          validationMessage={
            props.state.fieldValidationErrors &&
            props.state.fieldValidationErrors["SelectedDivisions"]
          }
          selectedValue={
            (props.state.selectedRole &&
              props.state.selectedRole.selectedDivisions &&
              listUtils.firstOrDefault(
                props.state.selectedRole.selectedDivisions
              )) ||
            undefined
          }
          options={
            (props.state.selectedRole && props.state.selectedRole.divisions) ||
            ([] as UserFormDivision[])
          }
          onChange={d => setDivision(d)}
        />
      </React.Fragment>
    );
  }

  function getDivisionMultiSelect(
    userFormConfiguration: UserFormConfiguration
  ): JSX.Element {
    return (
      <React.Fragment>
        <Spacer orientation="v" />
        <MultiSelect
          label={userFormConfiguration.divisionLabelName || "Division"}
          validationMessage={
            props.state.fieldValidationErrors &&
            props.state.fieldValidationErrors["SelectedDivisions"]
          }
          selectedValues={
            (props.state.selectedRole &&
              props.state.selectedRole.selectedDivisions &&
              props.state.selectedRole.selectedDivisions) ||
            undefined
          }
          options={
            (props.state.selectedRole && props.state.selectedRole.divisions) ||
            ([] as UserFormDivision[])
          }
          onChange={d => setDivisions(d)}
        />
      </React.Fragment>
    );
  }

  function setProperties(properties: UserFormProperty[] | []) {
    const selectedDivisions =
      props.state.selectedRole && props.state.selectedRole.selectedDivisions;

    const newSelectedDivisions = createNewSelectedDivisionsWithSelectedProperties(
      selectedDivisions,
      properties
    );

    props.setState({
      ...props.state,
      selectedRole: {
        ...(props.state && props.state.selectedRole),
        selectedDivisions: newSelectedDivisions
      }
    });
  }

  let divisionDropdown = <React.Fragment />;
  if (
    props.state.selectedRole &&
    props.state.selectedRole.userFormConfiguration
  ) {
    if (!props.state.selectedRole.userFormConfiguration.hideDivision) {
      if (
        props.state.selectedRole.userFormConfiguration.enableDivisionMultiselect
      ) {
        divisionDropdown = getDivisionMultiSelect(
          props.state.selectedRole.userFormConfiguration
        );
      } else {
        divisionDropdown = getDivisionDropdown(
          props.state.selectedRole.userFormConfiguration
        );
      }
    }
  }
  const propertyChooser =
    !(
      props.state.selectedRole && props.state.selectedRole.userFormConfiguration
    ) || props.state.selectedRole.userFormConfiguration.hideProperties ? (
      <React.Fragment />
    ) : (
      <React.Fragment>
        
        <Spacer orientation="h" size="l" />

        <ContentArea columnSize={"3"}>
          <PropertyChooser
            validationMessage={
              props.state.fieldValidationErrors &&
              props.state.fieldValidationErrors["SelectedProperties"]
            }
            properties={
              (props.state.selectedRole &&
                props.state.selectedRole.selectedDivisions &&
                listUtils.flatten(
                  props.state.selectedRole.selectedDivisions.map(
                    (item: UserFormDivision) => item.properties || []
                  )
                )) ||
              []
            }
            selectedProperties={
              (props.state.selectedRole &&
                props.state.selectedRole.selectedDivisions &&
                listUtils.flatten(
                  props.state.selectedRole.selectedDivisions.map(
                    (item: UserFormDivision) => item.selectedProperties || []
                  )
                )) 
            }
            onChange={ps => setProperties(ps)}
          />
        </ContentArea>
      </React.Fragment>
    );

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ContentArea columnSize={"3"}>
        <div>
          {props.state.formValidationErrors &&
            props.state.formValidationErrors.map(e => (
              <Validation message={e} />
            ))}
          <InputStateless
            label="First Name"
            value={props.state.firstName}
            validationMessage={
              props.state.fieldValidationErrors &&
              props.state.fieldValidationErrors["FirstName"]
            }
            onChange={fn => {
              props.setState({
                ...props.state,
                firstName: fn
              });
            }}
          />
          <Spacer orientation="v" />
          <InputStateless
            label="Last Name"
            value={props.state.lastName}
            validationMessage={
              props.state.fieldValidationErrors &&
              props.state.fieldValidationErrors["LastName"]
            }
            onChange={ln => {
              props.setState({
                ...props.state,
                lastName: ln
              });
            }}
          />
          <Spacer orientation="v" />
          <InputStateless
            label="Email"
            value={props.state.email}
            validationMessage={
              props.state.fieldValidationErrors &&
              props.state.fieldValidationErrors["Email"]
            }
            onChange={e => {
              props.setState({
                ...props.state,
                email: e
              });
            }}
          />
          <Spacer orientation="v" />
          <Dropdown
            label="Role"
            validationMessage={
              props.state.fieldValidationErrors &&
              props.state.fieldValidationErrors["SelectedRole"]
            }
            selectedValue={props.state.selectedRole || undefined}
            options={props.state.roles || ([] as UserFormRole[])}
            onChange={r => setRole(r)}
          />
          {divisionDropdown}
          <Checkbox label="Active User" checked={props.state.isActive} onChange={toggleUserActive}></Checkbox>
          <VisibilityToggle isVisible={!props.hideSaveButton}>
            <Spacer orientation="v" size="l" />
            <PrimaryButton
              disabled={props.disableSubmit}
              onClick={props.onSubmit}
              title="SAVE"
            />
          </VisibilityToggle>
        </div>
      </ContentArea>
      <Spacer orientation="h" size="xxl" />
      {propertyChooser}
    </div>
  );
};

export default EditUser;
