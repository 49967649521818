import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	newOverride: {
		all: "unset",
		lineHeight: "initial",
		width: "max-content",
		color: theme.palette.primary,
		fontWeight: 500,
		cursor: "pointer",
		padding: "10px"
	}
}));
