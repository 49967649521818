import { DropdownOption } from "api";
import { createContext, useContext } from "react";

export type SystemAutomatedContextType = {
	filters: {
		divisionId: DropdownOption;
		countyId: DropdownOption;
		propertyIds: Array<DropdownOption>;
		unitGroupId: DropdownOption;
	};
	handleInitialFilters: (filters: any) => void;
	selectedRow: Record<string, string | number>;
	setSelectedRow: (row: Record<string, string | number>) => void;
	handleChange: (
		value?: DropdownOption | DropdownOption[]
	) => (key: keyof typeof SYSTEM_AUTOMATED_DEFAULT.filters) => void;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
};

const defaultDropdownOption = { key: "", displayValue: "" };

export const SYSTEM_AUTOMATED_DEFAULT: SystemAutomatedContextType = {
	filters: {
		divisionId: defaultDropdownOption,
		countyId: defaultDropdownOption,
		propertyIds: [],
		unitGroupId: defaultDropdownOption
	},
	selectedRow: {},
	setSelectedRow: () => null,
	handleInitialFilters: () => null,
	handleChange: () => () => null,
	refresh: false,
	setRefresh: () => null
};

export const SystemAutomatedFilterContext = createContext(SYSTEM_AUTOMATED_DEFAULT);

export const useSystemAutomatedFilterContext = () => {
	const context = useContext(SystemAutomatedFilterContext);

	if (!context) {
		throw new Error("useSystemAutomatedFilterContext used outside SystemAutomatedFilterContext provider");
	}

	return context;
};
