import React, { useState } from "react";
import AdminMenu from "../AdminMenu";
import Page from "components/layouts/Page";
import { UserForm, UserFormClient, ApiException } from "api";
import RequirePermission from "../RequirePermission";
import { useRemoteData, responseHandlers } from "components/useRemoteData";
import { useApiEndpoint } from "components/useApiEndpoint";
import Section from "components/controls/Section";
import EditUser from "components/parts/EditUser";
import { useHistory } from "react-router";
import routes from "components/routes";
import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import PrimaryButton from "components/controls/PrimaryButton";
import constants from "utils/constants";

interface PageState {
  form: UserForm;
}

const UserAddPage: React.FC = function () {
  const history = useHistory();

  const initialState = {
    key: "",
    firstName: "",
    lastName: "",
    email: ""
  };
  const [state, setState] = useState<UserForm>(initialState);

  const [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException
  ] = useMessageArea();

  const [dataState] = useRemoteData(
    useApiEndpoint(UserFormClient, "getAddUserForm"),
    [],
    {
      autoFetch: true,
      onChange: (d) => {
        var form: UserForm = d !== null ? d : ({} as UserForm);
        setState({ ...form });
      }
    }
  );

  const [submitState, submit] = useRemoteData(
    useApiEndpoint(UserFormClient, "addUserForm"),
    [state],
    {
      onChange: (d) => {
        setMessageBasedOnForm(d);
        if (
          d &&
          ((d.formValidationErrors && d.formValidationErrors.length > 0) ||
            (d.fieldValidationErrors &&
              Object.keys(d.fieldValidationErrors).length > 0))
        ) {
          setState({
            ...state,
            ...d
          });
        } else {
          //clear form in case user navigates back to this page?
          setState({} as UserForm);
          history.push(routes.admin.users);
        }
      },
      onError: (error: ApiException) => {
        responseHandlers.use({
          "400": (ex: ApiException) => {
            const form = ex.result;
            setMessageBasedOnForm(form);
            setState({ ...ex.result });
          },
          _: (ex) => {
            setMessageBasedOnApiException(ex);
          }
        })(error);
      }
    }
  );

  const spinner = (
    <Spinner isVisible={dataState.isFetching || submitState.isFetching} />
  );

  //See T-4258 for refactoring improvements
  if (
    (state == null || state === initialState || state.roles == null) &&
    (dataState.isFetching || submitState.isFetching)
  ) {
    return spinner;
  } else if (state == null) {
    return messageAreaState.messageArea;
  }

  const saveButton = (
    <PrimaryButton
      disabled={submitState.isFetching}
      onClick={() => submit([])}
      title="SAVE"
    />
  );

  return (
    <Page menu={<AdminMenu actionArea={saveButton} />}>
      <Section title="ADD USER" />
      <RequirePermission permissions={[constants.permissions.canManageUsers]} accessDenied="page">
        {spinner}
        {messageAreaState.messageArea}
        <EditUser
          state={state}
          setState={setState}
          disableSubmit={submitState.isFetching}
          onSubmit={() => submit([])}
          hideSaveButton={true}
        />
      </RequirePermission>
    </Page>
  );
};

export default UserAddPage;
