/* eslint-disable react-hooks/exhaustive-deps */
import { atrCountMapper } from "./atrCountMapper";
import { useAtAGlanceContext } from "../../Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getATRCounts } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: ["atr-card", filters],
		queryFn: ({ signal }) => getATRCounts(filters, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { atrCounts: atrCountMapper(data), isLoading };
};
