import { useQuery } from "@tanstack/react-query";
import { ExclusionsUITilesResult } from "api";
import { useExclusionsService } from "components/forms/PricingAutomation/services/exclusions.service";

export const useLoadData = () => {
	const { getExclusionsUITiles } = useExclusionsService();
	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["exclusions-tiles"],
		queryFn: ({ signal }) => getExclusionsUITiles({ divisionId: "" }, signal),
		select: (data: ExclusionsUITilesResult) =>
			data?.tiles?.map((tile) => ({ key: tile.tileHeader, displayValue: tile.tileHeader }))
	});

	return { options: data || [], isLoading: isInitialLoading };
};
