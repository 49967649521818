import { SystemAutomatedReviewFilterOptions } from "api";

export const mapFilterOptions = (data: SystemAutomatedReviewFilterOptions) => {
	return {
		hierarchyLevels: "",
		division: data.divisions || [],
		county: data.counties || [],
		property: data.properties || [],
		unitGroup: data.unitGroups || []
	};
};
