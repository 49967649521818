import { createThemedUseStyles } from "theme";


export default createThemedUseStyles(theme => ({
  filterHeaders: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "30px 0 50px 15px",
    alignItems: "center",
  },
  filterItem: {
    display: "flex",
    flexDirection: "column",
    marginRight: "40px"
  },
  applyButton: {
    alignItems: "center",
    height: "30px",
    marginTop: "15px",
  },
  applyButton2: {
    alignItems: "center",
    height: "30px",
    marginTop: "15px",
    boxShadow: "0 0 0 0.2rem #8dcdff"
  },
  exportButton: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px"
    
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  empty: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px"
  },
  titleheading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  unittypeheading: {
    display: "flex",
    backgroundColor: "#c4c4c4",
    lineHeight: "0em",
  },
  unittypestyling: {
    marginLeft: "5px"
  }
  }));
  