import { AverageRentSummary } from "api";
import { nullOrUndefinedToZero } from "utils/miscUtils";

export const askingPriceMapper = (data?: AverageRentSummary): Required<AverageRentSummary> => {
	if (!data) {
		return {
			averageAskingPrice: 0,
			averagePriceChange: 0,
			percent: 0
		};
	}

	return {
		averageAskingPrice: nullOrUndefinedToZero(data.averageAskingPrice),
		averagePriceChange: nullOrUndefinedToZero(data.averagePriceChange),
		percent: nullOrUndefinedToZero(data.percent)
	};
};
