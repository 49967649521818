import { zodResolver } from "@hookform/resolvers/zod";
import { configureAutomationSchema, configureAutomationFormType } from "./validations";
import { SubmitHandler, useForm } from "react-hook-form";
import { useConfigureAutomationFilterContext } from "../Context";
import { QueryObserver, useMutation, useQueryClient } from "@tanstack/react-query";
import { ConfiguredParameterHeaderGroup, ConfiguredParameterRow } from "api";
import { mapColumns, parametersMapper } from "../Table/parametersMapper";
import { NaNtoNull, convertToPercentage, isEmptyObject } from "utils/miscUtils";
import { useConfigureAutomationService } from "components/forms/PricingAutomation/services/configureAutomation.service";
import constants from "utils/constants";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { useAlertContext } from "components/forms/PricingAutomation/context/AlertContext";

export const useConfigureAutomationForm = () => {
	const { filters, editedRowLevel, handleEditedRowLevel, dirtyFields, setDirtyFields, newRow, setNewRow } =
		useConfigureAutomationFilterContext();
	const queryClient = useQueryClient();
	const { setAlert } = useAlertContext();
	const { updateAndAddConfiguredParameters } = useConfigureAutomationService();

	const { mutate } = useMutation({
		mutationKey: ["update-save-parameters"],
		mutationFn: updateAndAddConfiguredParameters,
		onSuccess: () => {
			setAlert({
				message: constants.MessageAreaMessages.ConfigurableParamsSuccessMessage,
				severity: MessageSeverityLevel.Success as MessageSeverity,
				title: constants.MessageAreaMessages.Success,
				autoHide: true
			});
			refetch();
			handleClearInputs();
		},
		onError: () => {
			setAlert({
				message: constants.MessageAreaMessages.ConfigurableParamsErrorMessage,
				severity: MessageSeverityLevel.Error as MessageSeverity,
				title: constants.MessageAreaMessages.Error,
				autoHide: true
			});
			handleClearInputs();
		}
	});

	const handleClearInputs = () => {
		setDirtyFields([]);
		handleEditedRowLevel("");
		setNewRow({});
	};

	//Get original data mapped
	const { data, refetch } = new QueryObserver(queryClient, {
		keepPreviousData: true,
		queryKey: ["configured", filters],
		select: (data: ConfiguredParameterRow[]) => parametersMapper(data).parameters
	}).getCurrentResult();
	//Get columns data mapped
	const { data: cols } = new QueryObserver(queryClient, {
		keepPreviousData: true,
		queryKey: ["cols"],
		select: (data: ConfiguredParameterHeaderGroup[]) => mapColumns(data)
	}).getCurrentResult();

	const methods = useForm<configureAutomationFormType>({
		resolver: zodResolver(configureAutomationSchema)
	});

	//Convert values to percentages before send them to the backend
	const formatValue = (field: string, value: unknown, isPercentage?: boolean) => {
		switch (field) {
			case "maxValue":
				return isPercentage ? convertToPercentage(value) : NaNtoNull(value);
			case "minValue":
				return isPercentage ? convertToPercentage(value) : NaNtoNull(value);
			default:
				return value;
		}
	};

	//Submit method with form data
	const onSubmit: SubmitHandler<configureAutomationFormType> = async (formData) => {
		const values = Object.entries(formData);
		const originalData = data?.find((item) => item.name === editedRowLevel) ?? {};
		const { rowInfo, name, groupName, ...parameters } = originalData;
		const updatedData: Record<string, any> = {};
		const newData: Record<string, any> = {};
		const params: Record<string, any> = { ...parameters };

		//Transform the data as the payload of 'updateAndAddConfiguredParameters' method
		values.forEach((val) => {
			const [name, field] = val[0].split("_");
			//Skip iteration if the field is not dirty
			if (!dirtyFields.includes(name)) return;

			if (params[name]?.paramId) {
				if (!updatedData[name]) {
					updatedData[name] = {
						...params[name],
						...Object.assign({}, rowInfo)
					};
				}
				updatedData[name] = {
					...updatedData[name],
					[field]: formatValue(field, val[1], params[name]?.["isPercentValue"])
				};
			} else {
				const colInfo = cols?.find((col) => col.name === name || col.subColumns.some((i) => i.name === name))
					?.subColumns[0];

				const data = {
					...newData[name],
					displayType: colInfo?.type,
					name: name,
					[field]: formatValue(field, val[1], colInfo?.isPercentage)
				};

				//Avoid name of the row
				const { name: _, ...newRowInfo } = newRow;

				if (!isEmptyObject(newRowInfo)) {
					newData[name] = {
						...newRowInfo,
						...data
					};
				} else {
					newData[name] = {
						...Object.assign({}, rowInfo),
						...data
					};
				}
			}
		});

		await mutate({ newItems: Object.values(newData ?? {}), updatedItems: Object.values(updatedData ?? {}) });
	};

	return { onSubmit, methods };
};
