import React, { useRef, MouseEventHandler } from "react";
import useStyle from "./useStyle";
import { OverlayPanel } from "primereact/overlaypanel";
import { ColumnType } from "./types";
import { Checkbox } from "../PricingCheckbox";
import { IconButton } from "../PricingIconButton";

interface Props {
	columns: ColumnType[];
	onToggle: (columnName: string) => (checked: boolean) => void;
}

export const ToggleColumns = ({ columns, onToggle }: Props) => {
	const classes = useStyle();
	const op = useRef<OverlayPanel>(null);

	const handleMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
		if (op.current) {
			op.current.toggle(e);
		}
	};

	return (
		<>
			<IconButton onClick={handleMenu} aria-controls="popup_menu" aria-haspopup>
				<i className={`pi pi-ellipsis-v ${classes.outlined}`} />
			</IconButton>
			<OverlayPanel
				id="overlay-checkbox"
				ref={op}
				appendTo={document.body}
				className={[classes.overlayPanel, "p-menu-list p-reset"].join(" ")}
			>
				<h4 id="list-title" className={classes.listTitle}>
					Additional Columns
				</h4>
				<ul aria-labelledby="list-title">
					{columns.map(({ name, visible = true }) => {
						return (
							<li key={name} className={[classes.listItem, "p-dropdown-item"].join(" ")}>
								<Checkbox label={name} checked={visible || false} onChange={onToggle(name)} />
							</li>
						);
					})}
				</ul>
			</OverlayPanel>
		</>
	);
};
