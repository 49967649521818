import React, { useRef } from "react";
import useStyle from "../useStyle";
import { Menu } from "primereact/menu";
import routes from "components/routes";
import { useHistory } from "react-router-dom";
import { IconButton } from "components/controls/PricingIconButton";

export const UserMenu = () => {
	const classes = useStyle();
	const history = useHistory();
	const menuLeft = useRef<Menu>(null);

	const navigateTo = (uri: string) => {
		history.push(uri);
	};

	const items = [
		{
			label: "Profile",
			items: [
				{
					label: "Sign out",
					icon: "pi pi-fw pi-sign-out",
					command: () => {
						navigateTo(routes.signOut);
					}
				}
			]
		}
	];

	const handleMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
		if (menuLeft.current) {
			return menuLeft.current.toggle(event);
		}
	};

	return (
		<>
			<IconButton onClick={handleMenu} aria-controls="popup_menu_left" aria-haspopup>
				<i className={`pi pi-user ${classes.outlined}`} />
			</IconButton>
			<Menu model={items} popup ref={menuLeft} id="popup_menu_left" className={classes.menu} />
		</>
	);
};
