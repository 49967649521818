import { PriceResetFormType } from "./validations";

export const mapFormData = (data: PriceResetFormType, selectedRow: any) => {
	return {
		isPriceChangeAccepted: data.amount === selectedRow.recommendedPrice,
		isPriceHold: data.changeOrHold === "hold",
		isPriceOverride: data.changeOrHold === "change" && data.amount !== selectedRow.recommendedPrice,
		newNormalizedRentValue: data.amount,
		notes: data.notes,
		overrideReason: data.overrideReason
	};
};
