import React from "react";
import useStyle from "./useStyle";
import Image from "../Image";
import { Link } from "react-router-dom";

export type Checked = boolean | "mixed";

export interface TileProps {
	assetName?: string | undefined;
	title?: string | undefined;
	href?: string | undefined;
	visible?: boolean | undefined;
}

const Tile: React.FC<TileProps> = function (props) {
	const classes = useStyle(props);
	const image = props.assetName ? <Image className={classes.image} assetName={props.assetName} /> : <div />;
	var cssClass = classes.container;
	
	if(!props.visible) {
		cssClass += ' ' + classes.hide;
	}

	return (
		<div className={cssClass}>
			<Link className={classes.link} to={props.href || "/"}>
				<div className={classes.imageContainer}>{image}</div>
				<div className={classes.contentContainer}>
					<div className={classes.heading}>{props.title}</div>
				</div>
			</Link>
		</div>
	);
};

export default Tile;
