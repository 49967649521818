import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useConfigureAutomationFilterContext } from "../Context";
import { useConfigureAutomationService } from "components/forms/PricingAutomation/services/configureAutomation.service";
import { mapColumns, parametersMapper } from "./parametersMapper";
import { ConfiguredParameterHeaderGroup, ConfiguredParameterRow } from "api";
import { mapFilterKeys } from "../Filter/mapFilterKeys";
import { Content } from "./Content";
import { ColumnType } from "components/controls/DataTable/types";
import { FormatActions } from "./Actions/FormatActions";
import useStyle from "./useStyle";
import { siteDefaultTheme } from "theme";

export const useColumns = () => {
	const classes = useStyle();
	const { filters } = useConfigureAutomationFilterContext();
	const { getConfiguredParameters, getConfiguredParameterList } = useConfigureAutomationService();

	const { data: cols, isInitialLoading: colsLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["cols"],
		queryFn: async ({ signal }) => getConfiguredParameterList(signal),
		select: (data: ConfiguredParameterHeaderGroup[]) => mapColumns(data)
	});

	const { data, isInitialLoading, isFetching, isPreviousData } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		enabled: !colsLoading,
		queryKey: ["configured", filters],
		queryFn: async ({ signal }) =>
			getConfiguredParameters(
				{
					hierarchyId: filters.hierarchyLevels.key,
					hierarchyName: filters.hierarchyLevels.displayValue,
					...mapFilterKeys(filters)
				},
				signal
			),
		select: (data: ConfiguredParameterRow[]) => {
			const { parameters } = parametersMapper(data);
			const renderColumns: ColumnType[] = [
				{
					name: "",
					subColumns: [
						{
							field: "name",
							sortable: false,
							format: (data) => (
								<div>
									<div className={classes.truncate} style={{ color: siteDefaultTheme.palette.primary }}>
										{data["title"]}
									</div>
									<div className={classes.truncate}>{data["name"]}</div>
									<Content data={data} dataKey={"name"} type={99} />
								</div>
							)
						}
					],
					minWidth: "250px",
					frozen: true,
					align: "left",
					height: "50.44px"
				},
				...(cols?.map(({ name, subColumns }) => ({
					name: name,
					minWidth: "200px",
					height: "50.44px",
					subColumns: subColumns.map(({ key, name, type }) => ({
						field: key,
						name: name,
						minWidth: type === 5 || type === 6 ? "400px" : "200px",
						sortable: false,
						format: (data) => <Content data={data} dataKey={key} type={type} />
					}))
				})) as ColumnType[]),
				{
					name: "Actions",
					minWidth: "20px",
					height: "50.44px",
					frozen: true,
					align: "center",
					subColumns: [{ field: "actions", sortable: false, format: (data) => <FormatActions data={data} /> }]
				}
			];
			return { data: parameters, columns: renderColumns };
		}
	});

	const isLoading = colsLoading || isInitialLoading || (isFetching && isPreviousData);

	return {
		columns: data?.columns,
		data: data?.data,
		isLoading
	};
};
