import { SecondaryReviewTile } from "api";
import useStyle from "./useStyle";
import React from "react";
import { InfoCard } from "./InfoCard";
import { isEqualString } from "utils/miscUtils";

interface Props {
	tiles?: SecondaryReviewTile[];
	isLoading?: boolean;
}

export const Tiles = ({ tiles, isLoading }: Props) => {
	const classes = useStyle();

	if (isLoading) {
		return (
			<>
				{Array.from({ length: 5 }).map((_, index) => (
					<span
						key={index}
						role="presentation"
						className={["skeleton", "p-card", classes.loadingTiles].join(" ")}
					/>
				))}
			</>
		);
	}

	return (
		<>
			{tiles?.map((item, index) => (
				<InfoCard
					key={index}
					id={item.id}
					title={item.title}
					disabled={isEqualString(item.title, "exclusion parameter expiration")}
					completed={item.completed}
					toReview={item.toReview}
				/>
			))}
		</>
	);
};
