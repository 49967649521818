import React from "react";
import pageStyle from "./PriorityFilter.module.css";
import { PriorityFilterSelections } from "components/store/PropertiesPage/propertyPageInterfaces";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { InputNumber } from "primereact/inputnumber";

export const PriorityFilterDropdown: DropdownOption[] = [
	{ key: "0", displayValue: "≥" },
	{ key: "1", displayValue: "<" },
	{ key: "Excluded", displayValue: "Excl." }
];

export interface PriorityFilterProps {
	filters: PriorityFilterSelections[];
	onDropdownChange: (type: string, operator: DropdownOption | undefined, filterId?: string) => void;
	onValueChange: (type: string, e: any, filterId?: string) => void;
}

export default function PriorityFilterComponent(props: PriorityFilterProps) {
	const { filters, onDropdownChange, onValueChange } = props;

	return (
		<>	
			{filters.map((filter) => {
				return (
					<div key={filter.filterId} className={pageStyle.filter}>
						<div
							className={pageStyle.priorityFilterLabel}
							title="Excl. means that the filter will not be applied. ≥ finds records greater than or equal to the provided value. < finds records less than the provided value."
						>
							{filter.filterLabel}
						</div>

						<div className={pageStyle.priorityFilter}>
							<Dropdown
								selectedValue={filter.operator}
								hideClear={true}
								options={PriorityFilterDropdown}
								onChange={(d) => onDropdownChange("priorityFilter", d, filter.filterId)}
								disabled={false}
								style={{ width: 80 }}
							/>
							<InputNumber
								id={`${filter.filterId}-value`}
								name={`${filter.filterId}-value`}
								value={filter.filterValue}
								onValueChange={(e) => onValueChange("priorityFilter", e, filter.filterId)}
								suffix={filter.isPercentage ? "%" : ""}
								disabled={filter.operator && filter.operator.key === "Excluded"}
								minFractionDigits={filter.isPercentage ? 1 : 0}
								maxFractionDigits={filter.isPercentage ? 1 : 0}
								step={0.1}
								min={filter.minimumValue || 0}
								size={1}
								style={{ width: 50 }}
							></InputNumber>
						</div>
					</div>
				);
			})}
		</>
	);
}
