import { ResponsePayload } from "api";
import { useMemo, useContext } from "react";
import { IdentityContext, Identity } from "./IdentityProvider";

function isResponsePayload(response: any): response is ResponsePayload {
	return response != null && "traceId" in response && "permissions" in response;
}

export type FunctionPropertyNames<T> = {
	[K in keyof T]: T[K] extends (...args: any) => Promise<any> ? K : never;
}[keyof T];

type ApiClient = new (
	baseUrl: string | undefined,
	http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> } | undefined
) => any;

export function getApiEndpoint<C extends ApiClient, K extends FunctionPropertyNames<InstanceType<C>>>(
	identity: Identity,
	construct: C,
	methodName: K,
	signal?: AbortSignal
): InstanceType<C>[K] {
	async function authenticatedFetch(input: RequestInfo, init?: RequestInit) {
		const token = await identity.getAccessToken();
		if (init == null) {
			init = {};
		}
		return await fetch(input, {
			...init,
			signal,
			headers: {
				...init.headers,
				authorization: `bearer ${token}`
			}
		});
	}

	let instance: InstanceType<C>;
	const endpoint = async (...args: any) => {
		if (instance == null) {
			const config = identity.getConfig();
			const apiUri = (config && config.apiUrl) || "";
			instance = new construct(apiUri, {
				fetch: authenticatedFetch
			});
		}
		const response = await instance[methodName](...args);
		if (isResponsePayload(response)) {
			identity.setPermissions(response.permissions);
		}
		return response;
	};

	return endpoint;
}

export function useApiEndpoint<C extends ApiClient, K extends FunctionPropertyNames<InstanceType<C>>>(
	construct: C,
	methodName: K
): InstanceType<C>[K] {
	const identityProvider = useContext(IdentityContext);

	return useMemo(() => {
		return getApiEndpoint(identityProvider, construct, methodName);
	}, [construct, methodName, identityProvider]);
}
