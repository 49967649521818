import React, { useState, useEffect, useContext } from "react";
import Page from "components/layouts/Page";
import UnitGroupsAdminMenu from "../UnitGroupsAdminMenu";
import RequirePermission from "../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";
import { DataTable } from "primereact/datatable";
import Spinner from "components/controls/Spinner";
import { Column } from "primereact/column";
import PrimaryButton from "components/controls/PrimaryButton";
import { format } from "date-fns";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import { useHistory } from "react-router-dom";
import { Card } from "primereact/card";
import { Bedroom, BedroomForm } from "api";

import "./_list-bedrooms.scss";
import { Identity, IdentityContext } from "components/IdentityProvider";

const BedroomsAdminPage: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const [localState] = useState({
		bedrooms: appState.unitGroupAdminPageState.unitGroupBedrooms
	});
	const history = useHistory();
	const identity: Identity = useContext(IdentityContext);

	interface SearchState {
		skip: number;
		take: number;
		rows: number;
		page: number;
		totalRecords: number;
	}

	const createdDateTemplate = (e: Bedroom) => {
		if (e.createdDate === undefined) {
			return <span>N/A</span>;
		} else {
			return <span>{format(new Date(e.createdDate), "M/d/yyyy")}</span>;
		}
	};

	const updatedDateTemplate = (e: Bedroom) => {
		if (e.lastUpdatedDate === undefined) {
			return <span>N/A</span>;
		} else {
			return <span>{format(new Date(e.lastUpdatedDate), "M/d/yyyy")}</span>;
		}
	};

	useFirstLoadEffect(() => {
		try {
			actions.loadBedrooms();
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	useEffect(() => {
		localState.bedrooms = pageState.unitGroupBedrooms;
	}, [localState.bedrooms, pageState.unitGroupBedrooms]);

	function onCreate() {
		history.push(routes.unitGroupAdmin.createBedroom);
	}

	const onEdit = (e: { originalEvent: Event; data: BedroomForm; index: number }) => {
		history.push(routes.unitGroupAdmin.updateBedroom(e.data.bedroomId));
	};

	const handleRowClick = (e: { originalEvent: Event; data: any; index: number }) => {
		if (identity.permissions.canManageUnitGroups) {
			onEdit(e);
		}
	};

	const dataTable = () => {
		if (pageState.unitGroupBedrooms === undefined) {
			return <Spinner isVisible={true} />;
		} else {
			return (
				<Card>
					<DataTable
						id="bedroom-list"
						value={localState.bedrooms}
						pageLinkSize={5}
						className="bedroom-list p-datatable-striped"
						tableClassName="bedroom-list-table"
						lazy={true}
						loading={pageState.tableLoading}
						dataKey="bedroomId"
						onRowClick={handleRowClick}
						emptyMessage="No bedrooms found. You either need to adjust the page filters, or you do not have permission to view
						any bedrooms."
					>
						<Column
							field="bedroomCount"
							header="Number of bedrooms"
							bodyStyle={{ width: "20%", textAlign: "left" }}
							headerStyle={{ width: "20%", textAlign: "left" }}
						></Column>
						<Column
							field="description"
							header="Description"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
						></Column>
						<Column
							field="createdDate"
							header="Created"
							body={createdDateTemplate}
							bodyStyle={{ width: "16%" }}
							headerStyle={{ width: "16%" }}
						></Column>
						<Column
							field="lastUpdatedDate"
							header="Last Updated"
							body={updatedDateTemplate}
							bodyStyle={{ width: "17%" }}
							headerStyle={{ width: "17%" }}
						></Column>
						<Column
							field="lastUpdatedBy"
							header="Last Updated By"
							bodyStyle={{ width: "20%" }}
							headerStyle={{ width: "20%" }}
						></Column>
					</DataTable>
				</Card>
			);
		}
	};

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}></VisibilityToggle>
			</div>
		</RequirePermission>
	);

	return (
		<Page menu={<UnitGroupsAdminMenu actionArea={navbarActionArea} />} className="unit-group-bedroom-page">
			<div id="unitGroupAdminFilters" className="filter-headers-1">
				<RequirePermission permissions={[constants.permissions.canManageUnitGroups]}>
					<PrimaryButton className="createButton" onClick={onCreate} title="CREATE BEDROOM" />
				</RequirePermission>
			</div>
			<div id="contentContainer" className="">
				<div className="indexBody">{dataTable()}</div>
			</div>
		</Page>
	);
};

export default BedroomsAdminPage;
