import { zodResolver } from "@hookform/resolvers/zod";
import { PriceResetFormType, changeOrHoldSchema } from "./validations";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePopup } from "../usePopup";
import { useExclusionsContext } from "../../Context";
import constants from "utils/constants";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { useAlertContext } from "components/forms/PricingAutomation/context/AlertContext";
import { mapFormData } from "./mapFormData";
import { useSystemAutomatedService } from "components/forms/PricingAutomation/services/systemAutomated.service";
import { useMutation } from "@tanstack/react-query";

interface Props {
	handleClose: () => void;
}

export const usePriceForm = ({ handleClose }: Props) => {
	const { savePriceAdjustment } = useSystemAutomatedService();
	const methods = useForm<PriceResetFormType>({ resolver: zodResolver(changeOrHoldSchema) });
	const { setAlert } = useAlertContext();
	const { selectedRow, setRefresh } = useExclusionsContext();
	const confirmationPopup = usePopup();
	const { mutate } = useMutation({
		mutationKey: ["save-price"],
		mutationFn: savePriceAdjustment,
		onSuccess: () => {
			setAlert({
				message: constants.MessageAreaMessages.PriceAdjustmentSuccessMessage,
				severity: MessageSeverityLevel.Success as MessageSeverity,
				title: constants.MessageAreaMessages.Success,
				autoHide: true
			});
			setRefresh(true);
			handleClose();
		},
		onError: () => {
			setAlert({
				message: constants.MessageAreaMessages.PriceAdjustmentErrorMessage,
				severity: MessageSeverityLevel.Error as MessageSeverity,
				title: constants.MessageAreaMessages.Error,
				autoHide: true
			});
			confirmationPopup.handleClose();
		}
	});

	const onSubmit: SubmitHandler<PriceResetFormType> = async (data) => {
		await mutate({
			...mapFormData(data, selectedRow),
			unitGroupId: String(selectedRow.group)
		});
	};

	const handleValidateForm = async () => {
		await methods.trigger().then((valid) => {
			if (valid) {
				confirmationPopup.handleOpen({});
			}
		});
	};

	return { onSubmit, handleValidateForm, methods, confirmationPopup };
};
