import React from "react";
import { withLabel } from "../Label";
import useStyle from "./useStyle";
import { InputText } from "primereact/inputtext";
import ValidationWrapper from "../ValidationWrapper";

export interface InputProps {
  value: string | undefined | null;
  onChange?: (value: string) => void;
  validationMessage?: string | null | undefined;
}

const Input: React.FC<InputProps> = function(props) {
  const classes = useStyle(props);

  return (
    <span className="pricingPlatformPrimeOverride-input-with-validation">
      <ValidationWrapper showErrorState={props.validationMessage != null}>
        <InputText
          value={props.value || ""}
          className={classes.input}
          tooltip={props.validationMessage}
          onChange={e => {
            const value = e.currentTarget.value;

            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </ValidationWrapper>
    </span>
  );
};
export default withLabel(Input);
