import React, { useRef } from "react";
import useStyle from "./useStyle";
import { Tooltip } from "primereact/tooltip";

export interface ValidationWrapperProps {
	showErrorState: boolean;
	children: any;
	validationMessage?: string;
  showValidationMessage?: boolean;
}

const ValidationWrapper: React.FC<ValidationWrapperProps> = function (props) {
	const classes = useStyle();
  const inputRef = useRef(null);
  
	if (!props.showErrorState) {
    return <div className={classes.validationContainerPlaceholder}>{props.children}</div>;
  } 

  if (!props.showValidationMessage) {
    return <div className={classes.validationContainer}>{props.children}</div>;
  }

	return (
		<div>
			<Tooltip content={props.validationMessage}  target={inputRef.current}/>
			<div className={classes.validationContainer} ref={inputRef}>{props.children}</div>
		</div>
	);
};

export default ValidationWrapper;
