import React, { InputHTMLAttributes } from "react";
import useStyle from "./useStyle";
import type { FieldErrors } from "react-hook-form";
import { Tooltip } from "primereact/tooltip";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	errors?: FieldErrors;
	symbol?: {
		content: string;
		direction: "left" | "right";
	};
}

export const TextField = React.forwardRef(({ label, symbol, ...props }: Props, ref: React.Ref<HTMLInputElement>) => {
	const classes = useStyle();

	const errorMessage = props.errors?.[props?.name ?? ""]?.message;

	return (
		<div className={classes.inputWrapper}>
			{label && <label htmlFor={props.id}>{label}</label>}
			<div className={classes.inputContainer}>
				<input
					ref={ref}
					id={props.id}
					data-error={!!errorMessage}
					aria-invalid={!!errorMessage}
					data-value={symbol?.direction}
					data-pr-tooltip={props.errors?.[props?.name ?? ""]?.message}
					className={[classes.root, "p-inputtext", !!errorMessage && "with-tooltip"].join(" ")}
					{...props}
				/>
				{symbol && (
					<span
						className={[
							classes.symbol,
							props.disabled ? classes.disabled : "",
							classes[symbol.direction as keyof typeof classes]
						].join(" ")}
					>
						{symbol.content}
					</span>
				)}
			</div>
			{!!errorMessage && <Tooltip target=".with-tooltip" position="top" />}
		</div>
	);
});
