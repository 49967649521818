import React from "react";
import useStyle from "./useStyle";
import { Link } from "react-router-dom";
import cssUtils from "utils/cssUtils";

export interface MenuItemProps {
  title: string;
  to: string;
  isActive: boolean;
}

const MenuItem: React.FC<MenuItemProps> = function(props) {
  const classes = useStyle();

  return (
    <Link
      id={props.title}
      to={props.to}
      className={cssUtils.join(
        classes.item,
        cssUtils.conditionClass(classes.active, props.isActive)
      )}
    >
      <span>{props.title}</span>
    </Link>
  );
};
export default MenuItem;
