import { AtAGlanceATRCounts } from "api";
import { AtrScore } from "components/forms/PricingAutomation/interfaces";
import { formatPercentage } from "utils/miscUtils";

export const atrCountMapper = (data?: AtAGlanceATRCounts) => {
	if (!data) {
		return [
			{
				name: "low",
				range: `${formatPercentage(0)} to < ${formatPercentage(0)}`,
				count: 0
			},
			{
				name: "middle",
				range: `${formatPercentage(0)} to ${formatPercentage(0)}`,
				count: 0
			},
			{ name: "high", range: `> ${formatPercentage(0)}`, count: 0 }
		] as AtrScore[];
	}
	return [
		{
			name: "low",
			range: `${formatPercentage(data.lowThresholdMin)} to < ${formatPercentage(data.lowThresholdMax)}`,
			count: data.lowCount
		},
		{
			name: "middle",
			range: `${formatPercentage(data.middleThresholdMin)} to ${formatPercentage(data.middleThresholdMax)}`,
			count: data.middleCount
		},
		{ name: "high", range: `> ${formatPercentage(data.highThreshold)}`, count: data.highCount }
	] as AtrScore[];
};
