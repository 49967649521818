import { ConfigureAutomationFilterContext, CONFIGURE_AUTOMATION_DEFAULT } from "./context";

import React, { FC, useState } from "react";
import { DropdownOption } from "api";

// Keys that depend on the previous filter, make sure to use the same dependency order
const dependentFilters = ["divisionId", "countyId", "propertyIds", "unitGroupId"];

export const ConfigureAutomationFilterProvider: FC = ({ children }) => {
	const [localState, setLocalState] = useState(CONFIGURE_AUTOMATION_DEFAULT.filters);
	const [newRow, setNewRow] = useState({});
	const [editedRowLevel, setEditedRowLevel] = useState("");
	const [dirtyFields, setDirtyFields] = useState<Array<string>>([]);

	const handleChange = (value?: DropdownOption | DropdownOption[]) => (key: keyof typeof localState) => {
		setLocalState((prevState) => ({ ...prevState, [key]: value, ...resetNextFilters(key) }));
	};

	const resetNextFilters = (key: keyof typeof localState): Partial<typeof localState> => {
		// Find the next key based on a given key
		const keyIndex = Object.keys(CONFIGURE_AUTOMATION_DEFAULT.filters).indexOf(key);
		const nextKey = Object.keys(CONFIGURE_AUTOMATION_DEFAULT.filters)?.[keyIndex + 1] as keyof typeof localState;
		// Change the next key to default
		if (nextKey && dependentFilters.includes(nextKey))
			return { [nextKey]: CONFIGURE_AUTOMATION_DEFAULT.filters[nextKey], ...resetNextFilters(nextKey) };
		// If no next key, return
		return {};
	};

	const handleEditedRowLevel = (level: string) => {
		setEditedRowLevel(level);
	};

	return (
		<ConfigureAutomationFilterContext.Provider
			value={{
				filters: localState,
				handleChange,
				editedRowLevel,
				handleEditedRowLevel,
				newRow,
				setNewRow,
				dirtyFields,
				setDirtyFields
			}}
		>
			{children}
		</ConfigureAutomationFilterContext.Provider>
	);
};

export default ConfigureAutomationFilterProvider;
