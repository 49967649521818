import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	container: {
		display: "flex",
		gap: "18px",
		fontWeight: "500"
	},
	lastPriceContainer: {
		display: "flex",
		gap: "12px"
	},
	table: {
		"& td": {
			background: theme.palette.bodyBackground,
			fontWeight: "500"
		}
	},
	sectionOptionsRow: {
		display: "flex",
		gap: "8px"
	}
}));
