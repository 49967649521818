import { ChangeEventHandler, useState, TextareaHTMLAttributes, Ref } from "react";
import React from "react";
import useStyle from "./useStyle";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	rows?: number;
	withCounter?: boolean;
	maxLenght?: number;
}

export const TextArea = React.forwardRef(
	({ rows = 5, withCounter, maxLenght = 0, ...props }: Props, ref: Ref<HTMLTextAreaElement>) => {
		const classes = useStyle();
		const [characterCount, setCharacterCount] = useState(0);

		const handleCharacterCount: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
			setCharacterCount(e.target.value.length);
		};

		return (
			<div>
				<textarea
					ref={ref}
					rows={rows}
					onInput={handleCharacterCount}
					maxLength={maxLenght}
					className={[classes.root, "p-inputtextarea p-inputtext", props.className].join(" ")}
					{...props}
				/>
				{withCounter && (
					<span>
						{characterCount}
						{" / "}
						{maxLenght} {"characters remaining"}
					</span>
				)}
			</div>
		);
	}
);
