import React from "react";
import useStyle from "./useStyle";
import { SecondaryReviewTilesCounters } from "api";

interface Props {
	count?: SecondaryReviewTilesCounters;
	isLoading: boolean;
}

export const Counts = ({ count, isLoading }: Props) => {
	const classes = useStyle();

	if (isLoading) {
		return <span role="presentation" className={["skeleton", "p-card", classes.loadingHeaderContent].join(" ")} />;
	}

	return (
		<>
			<span>{`${count?.toReview} Unit Groups To Review`}</span>&nbsp;<span>{`${count?.completed} Completed`}</span>
		</>
	);
};
