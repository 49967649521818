import { useContext } from "react";
import { IdentityContext } from "components/IdentityProvider";
import React from "react";
import { UserPermissions } from "api";
import utils from "utils";
import Spinner from "components/controls/Spinner";
type PermissionName = keyof UserPermissions;

export interface RequirePermissionProps {
	permissions: PermissionName[];
	accessDenied?: "page" | React.ReactElement | null | undefined;
	showSpinner?: boolean;
}

const RequirePermission: React.FC<RequirePermissionProps> = function ({ showSpinner = true, ...props }) {
	const identity = useContext(IdentityContext);
	const permissions = identity.getPermissions();

	if (utils.isObjectEmpty(permissions) && !identity.apiException) {
		return showSpinner ? <Spinner isVisible={true} /> : <></>;
	}

	for (const name of props.permissions) {
		const hasPermission = permissions[name] === true;
		if (!hasPermission) {
			if (props.accessDenied === "page") return <>You do not have permission to view this page.</>;
			if (props.accessDenied == null) return null;
			return props.accessDenied;
		}
	}

	return <>{props.children}</>;
};

export default RequirePermission;
