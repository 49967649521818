import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	actionsContainer: {
		display: "flex",
		gap: "8px",
		justifySelf: "center",
		width: "min-content",
		margin: "auto"
	},
	content: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "8px"
	},
	hideActions: {
		display: "none"
	},
	container: {
		display: "flex",
		overflowX: "auto"
	},
	fallbackContent: {
		height: "500px",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		gap: "8px",
		alignItems: "center"
	},
	label: {
		display: "flex",
		flexDirection: "column"
	},
	checkbox: {
		display: "flex",
		flexDirection: "row",
		gap: "6px",
		alignItems: "center"
	},
	displayFive: {
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		alignItems: "center"
	},
	col: {
		display: "flex",
		flexDirection: "column",
		"& strong": {
			fontSize: "12px"
		}
	},
	truncate: {
		width: "230px",
		position: "relative",
		whiteSpace: "nowrap",
		maxHeight: "36px",
		fontWeight: "500",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"&:hover": {
			whiteSpace: "wrap",
			textOverflow: "none",
			background: theme.palette.white,
			zIndex: 9999,
			width: "max-content",
			overflow: "visible"
		}
	},
	bold: {
		fontWeight: "500"
	},
	table: {
		"& .p-datatable-unfrozen-view": {
			width: "calc(100% - 350px) !important",
			borderRight: `1px solid ${theme.palette.faintGray}`,
			borderLeft: `1px solid ${theme.palette.faintGray}`,
			zIndex: "150px",
			left: "250px !important"
		},
		"& p-datatable-scrollable-wrapper": {
			minWidth: `calc(${theme.maxScreenWidth} - 100px)`,
			overflowX: "auto"
		},
		"& p-datatable-frozen-view": {
			minWidth: `calc(${theme.maxScreenWidth} - 100px)`
		},
		"& [class$=p-datatable]": {
			position: "relative"
		},
		"& .p-datatable-thead > tr > th": {
			background: theme.palette.background
		},
		"& [class=p-datatable-wrapper]": {
			overflowX: "auto"
		},
		"& table": {
			minWidth: `calc(${theme.maxScreenWidth} - 100px)`
		},
		"& [class$=p-sortable-column] > span": {
			display: "inline"
		},
		"& .p-datatable-thead > tr > .sub-header": {
			fontSize: "12px",
			height: "46px",
			background: "transparent !important",
			textTransform: "capitalize"
		},
		"& th": {
			textAlign: "center",
			verticalAlign: "bottom",
			fontSize: "14px"
		},
		"& th:not(:last-child)": {
			borderRight: `1.5px solid ${theme.palette.faintGray} !important`
		},
		"& td": {
			overflow: "hidden",
			height: "58px",
			maxHeight: "58px !important",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis"
		},
		"& th > span": {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			fontWeight: "500",
			width: "100%"
		},
		"& td > div": {
			justifyContent: "center"
		}
	}
}));
