import React from "react";
import useStyle from "./useStyle";
import { SuccessfullyAutomated } from "./SuccessfullyAutomated";
import { UserModifiedFloorPlans } from "./UserModifiedFloorPlans";
import { SystemAutomatedFilterProvider } from "./Context";
import { SystemAutomatedFilter } from "./Filter";

const SystemAutomatedPage: React.FC = () => {
	const classes = useStyle();
	return (
		<SystemAutomatedFilterProvider>
			<main className={classes.container} id="pgSystemAutomated">
				<SystemAutomatedFilter />
				<SuccessfullyAutomated />
				<UserModifiedFloorPlans />
			</main>
		</SystemAutomatedFilterProvider>
	);
};

export default SystemAutomatedPage;
