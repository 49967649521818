import { DataTable } from "components/controls/DataTable";
import PageSection from "components/controls/PageSection";
import React, { useRef } from "react";
import { ColumnType } from "components/controls/DataTable/types";
import { useSecondaryReviewFilterContext } from "../../Context";
import useStyle from "./useStyle";
import { useShowAudit } from "./AuditLog/useShowAudit";
import { AuditButton } from "./AuditLog/AuditButton";
import { AuditLogTable } from "./AuditLog";

interface Props {
	columns: ColumnType[];
}

export const TableSection = ({ columns }: Props) => {
	const hideColumnRef = useRef<HTMLDivElement>(null);
	const { selectedRow } = useSecondaryReviewFilterContext();
	const classes = useStyle();
	const { showAudit, toggleShowAudit } = useShowAudit();

	const displayedColumns = columns.filter(({ subColumns }) => subColumns?.some(({ field }) => field !== "property"));

	return (
		<PageSection
			title="Price Adjustment"
			rightContent={
				<div className={classes.sectionOptionsRow}>
					<AuditButton showAudit={showAudit} toggleShowAudit={toggleShowAudit} />
					<div ref={hideColumnRef} />
				</div>
			}
		>
			{showAudit ? (
				<AuditLogTable hideColumnRef={hideColumnRef} />
			) : (
				<DataTable
					columns={displayedColumns}
					data={[selectedRow]}
					tableName="Price reset table"
					hideColumnsRef={hideColumnRef}
					alwaysShowPaginator={false}
					tableClassName={classes.table}
				/>
			)}
		</PageSection>
	);
};
