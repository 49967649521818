import React from "react";

import useStyle from "./useStyle";
import { useSystemAutomatedFilterContext } from "../../Context";

export const PriceResetHeader = () => {
	const classes = useStyle();

	const { selectedRow } = useSystemAutomatedFilterContext();

	return (
		<header>
			<h2 className={classes.title}>{`${selectedRow?.unitGroupMasterName} - ${selectedRow?.property}`}</h2>
		</header>
	);
};
