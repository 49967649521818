import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	root: {
		width: "100%",
		height: "38px"
	},
	dropdownWrapper: {
		"& > label": {
			position: "absolute",
			top: "-10px",
			left: "6px",
			padding: "0 4px",
			backgroundColor: theme.palette.white,
			color: "#79747e",
			fontSize: "13px",
			zIndex: "10"
		},
		"& [class=p-dropdown-trigger]": {
			backgroundColor: `transparent !important`,
			color: `${theme.palette.gray} !important`
		}
	}
}));
