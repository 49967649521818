/* eslint-disable react-hooks/exhaustive-deps */
import { useAtAGlanceContext } from "../Context";
import { useExclusionsService } from "components/forms/PricingAutomation/services/exclusions.service";
import { useQuery } from "@tanstack/react-query";
import { ExclusionsUITilesResult } from "api";

const defaultValue: ExclusionsUITilesResult = {
	tiles: [
		{
			tileHeader: "ADU",
			toReview: 0,
			completed: 0
		},
		{
			tileHeader: "BMR",
			toReview: 0,
			completed: 0
		},
		{
			tileHeader: "MANUAL EXCLUSION",
			toReview: 0,
			completed: 0
		},
		{
			tileHeader: "SMALL UNIT GROUP",
			toReview: 0,
			completed: 0
		}
	],
	footer: {
		toReview: 0,
		completed: 0
	}
};

export const useLoadData = () => {
	const { getExclusionsUITiles } = useExclusionsService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		keepPreviousData: true,
		queryKey: ["exclusions-tiles", filters],
		queryFn: ({ signal }) => getExclusionsUITiles(filters, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { localState: data || defaultValue, isLoading };
};
