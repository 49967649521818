import React from "react";
import { ToReview } from "./ToReview";
import { Completed } from "./Completed";
import { SecondaryReviewFilterSection } from "./Filter";
import { SecondaryReviewFilterProvider } from "./Context";
import useStyle from "./useStyle";

const SecondaryReviewPage = () => {
	const classes = useStyle();
	return (
		<SecondaryReviewFilterProvider>
			<main className={classes.container} id="pgSecondaryReview">
				<SecondaryReviewFilterSection />
				<ToReview />
				<Completed />
			</main>
		</SecondaryReviewFilterProvider>
	);
};

export default SecondaryReviewPage;
