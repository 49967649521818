import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	pageWrapper: {
		display: "flex",
		width: "100%",
		padding: "1 rem"
	},
	filterHeaders1: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		padding: "8px 0 0 15px",
		alignItems: "center"
	},
	filterHeaders2: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
		padding: "15px 0 30px 15px",
		flexWrap: "wrap",
		alignItems: "center"
	},
	filterItem: {
		display: "flex",
		flexDirection: "column",
		marginRight: "20px"
	},
	applyButton: {
		alignItems: "center",
		height: "30px",
		marginTop: "15px"
	},
	applyButton2: {
		alignItems: "center",
		height: "30px",
		marginTop: "15px",
		boxShadow: "0 0 0 0.2rem #8dcdff"
	},
	titleheading: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	checkbox: {
		marginRight: "20px"
	},
	exportButton: {
		flexGrow: "1",
		display: "flex",
		justifyContent: "flex-end",
		marginRight: "20px"
	},
	contentContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column"
	},
	unitData: {
		paddingLeft: theme.marginScale.xlarge
	},
	unitDataRow: {
		display: "flex",
		paddingBottom: "10px"
	},
	propertyName: {
		paddingBottom: "8px",
		gridColumn: "1/3",
		paddingRight: "auto"
	},
	unitDataItem: {
		paddingRight: "15px"
	},
	matrixContainer: {
		backgroundColor: "white",
		border: "1px solid gray",
		height: "unset",
		marginLeft: theme.marginScale.large,
		marginRight: theme.marginScale.large,
		marginBottom: theme.marginScale.xlarge,
		maxHeight: "600px",
		overflowY: "scroll",
		paddingTop: "20px",
		flexWrap: "nowrap",
		width: "100%"
	},
	empty: {
		display: "flex",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		marginTop: "30px"
	},
	dataTable: {
		marginLeft: theme.marginScale.xlarge,
		marginRight: theme.marginScale.xlarge,
		paddingBottom: "50px",
		"& [class$=scrollable-header-box]": {
			marginRight: "0px !important"
		}
	},
	dataColumn: {
		textAlign: "center !important"
	},
	errorMessage: {
		marginBottom: "10px"
	},
	"@media print": {
		matrixContainer: {
			border: "none"
		},
		dataColumn: {
			fontSize: "30px"
		},
		propertyName: {
			fontSize: "30px",
			paddingBottom: "15px"
		},
		unitDataItem: {
			fontSize: "30px",
			paddingBottom: "15px"
		}
	},
	headerContainer: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		"@media (max-width: 768px)": {
			display: "block",
			gridTemplateColumns: "1fr"
		}
	}
}));
