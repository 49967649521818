import React from "react";
import { InfoCard } from "../InfoCard";
import useStyle from "./useStyle";
import constants from "utils/constants";
import { useLoadData } from "./useLoadData";
import { formatCurrencyNoFractions, formatDecimalNumber } from "utils/miscUtils";

export const ConcessionsTile = () => {
	const classes = useStyle();

	const { data, isLoading } = useLoadData();

	if (isLoading) {
		return <span role="presentation" className={["skeleton", "p-card"].join(" ")} />;
	}

	return (
		<InfoCard title={constants.cardTitle.concessions}>
			<ul className={classes.concessionsContent}>
				<li className={[classes.concessionsRow, classes.borderTop].join(" ")}>
					<span className={classes.icon}>WEEKS</span>
					<p>{formatDecimalNumber(data.weeksFree)}</p>
				</li>
				<li className={classes.concessionsRow}>
					<i className={`pi pi-dollar ${classes.icon}`} />
					<p>{formatCurrencyNoFractions(data.amount)}</p>
				</li>
			</ul>
		</InfoCard>
	);
};
