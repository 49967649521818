import React, { useRef } from "react";
import { DataTable } from "components/controls/DataTable";
import constants from "utils/constants";
import PageSection from "components/controls/PageSection";
import { columns } from "./columns";
import { useLoadData } from "./useLoadData";
import { useSearchState } from "./useSearchState";
import { PriceResetPopUp } from "../PriceReset/PopUp";
import { usePopup } from "../PriceReset/usePopup";

export const Completed = () => {
	const { open, handleClose, handleOpen } = usePopup();
	const { onPage, onSort, searchState } = useSearchState();
	const { localState, isLoading } = useLoadData(searchState);
	const showingRef = useRef<HTMLDivElement>(null);
	const hideColumnRef = useRef<HTMLDivElement>(null);

	return (
		<PageSection
			title={constants.titleSection.completed}
			leftContent={<div ref={showingRef} />}
			rightContent={<div ref={hideColumnRef} />}
		>
			<DataTable
				onRowDoubleClick={(e) => handleOpen({ e, completed: true })}
				loading={isLoading}
				onPage={onPage}
				onSort={onSort}
				columns={columns}
				data={localState?.items}
				showingRef={showingRef}
				searchState={searchState}
				totalRecords={localState?.totalCount}
				hideColumnsRef={hideColumnRef}
				tableName={constants.tableContent.completedSRTitle}
				emptyMessage={constants.tableContent.completedSREmpty}
			/>
			<PriceResetPopUp open={open} onClose={handleClose} columns={columns} />
		</PageSection>
	);
};
