/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSystemAutomatedFilterContext } from "../Context";
import { mapFilterKeys } from "../Filter/mapFilterKeys";
import { useSystemAutomatedService } from "components/forms/PricingAutomation/services/systemAutomated.service";
import { useQuery } from "@tanstack/react-query";
import { mapSearchState } from "utils/miscUtils";
import { SearchState } from "components/forms/PricingAutomation/interfaces";

export const useLoadData = (searchState: SearchState) => {
	const { getSuccessfullyAutomatedResult } = useSystemAutomatedService();
	const { filters, refresh } = useSystemAutomatedFilterContext();
	const payload = {
		...mapFilterKeys(filters),
		...mapSearchState(searchState),
		unitGroupMasterName: filters.unitGroupId.displayValue
	};
	const [isRefreshing, setIsRefreshing] = useState(false);
	const { data, isInitialLoading, isFetching, isPreviousData, refetch } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["successful-automation", payload],
		queryFn: async ({ signal }) => getSuccessfullyAutomatedResult({ ...payload }, signal)
	});
	//It is loaded when it is loaded for the first time, when new data is obtained or when it is manually reloaded.
	const isLoading = isInitialLoading || (isFetching && isPreviousData) || isRefreshing;

	useEffect(() => {
		if (refresh) {
			setIsRefreshing(true);
			refetch().finally(() => setIsRefreshing(false));
		}
	}, [refresh]);

	return { localState: data, isLoading };
};
