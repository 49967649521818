import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	mainGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(8, minmax(auto, 1fr))",
		gap: "28px",
		paddingTop: "18px"
	},
	gridSection: {
		display: "grid",
		gridTemplateColumns: "repeat(4, minmax(auto, 1fr))",
		gridAutoRows: "minmax(200px, 1fr)",
		gridColumn: "span 4",
		"@media (max-width: 1280px)": {
			gridTemplateColumns: "repeat(4, minmax(auto, 1fr))",
			gridColumn: "span 8"
		},
		"@media (max-width: 768px)": {
			gridTemplateColumns: "repeat(2, minmax(auto, 1fr))",
			gridColumn: "span 8"
		},
		gap: "28px"
	},
	cardInfoGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(4, minmax(auto, 1fr))",
		gridAutoRows: "minmax(200px, 1fr)",
		gridColumn: "span 4",
		"@media (max-width: 1280px)": {
			gridTemplateColumns: "repeat(3, minmax(auto, 1fr))",
			gridColumn: "span 8"
		},
		"@media (max-width: 768px)": {
			gridTemplateColumns: "repeat(2, minmax(auto, 1fr))",
			gridColumn: "span 8"
		},
		gap: "28px"
	},
	textContent: {
		fontSize: "32px",
		fontWeight: "bold",
		textAlign: "center"
	},
	concessionsContent: {
		display: "grid",
		gridTemplateColumns: "1fr",
		fontWeight: "bold",
		width: "100%"
	},
	concessionsRow: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		borderBottom: `1px solid ${theme.palette.lightGray}`,
		justifyContent: "space-between",
		"& > p": {
			margin: "8px 0px"
		}
	},
	borderTop: {
		marginTop: "12px",
		borderTop: `1px solid ${theme.palette.lightGray}`
	},
	icon: {
		fontSize: "22px",
		color: theme.palette.darkBlue
	},
	loadingCards: {
		display: "flex",
		gridColumn: "span 4",
		gridRow: "span 2",
		borderRadius: "12px",
		background: theme.palette.faintGray
	},
	chartsGrid: {
		gridTemplateColumns: "repeat(4, minmax(auto, 1fr))",
		gridTemplateRows: "repeat(8, minmax(auto, 1fr))",
		gridRow: "span 2",
		"@media (max-width: 768px)": {
			gridRow: "span 1"
		}
	},
	scoreGrid: {
		display: "flex"
	},
	expiringExclusionsCard: {
		overflow: "hidden",
		minHeight: "120px",
		gridColumn: "span 2",
		height: "max-content",
		"@media (max-width: 1280px)": {
			gridColumn: "span 2"
		},
		"@media (max-width: 768px)": {
			gridColumn: "span 4"
		},
		borderRadius: "8px",
		grid: {
			"@media (max-width: 1280px)": {
				gridTemplateColumns: "repeat(4, minmax(auto, 1fr))"
			},
			"@media (max-width: 768px)": {
				gridAutoRows: "minmax(200px, 450px)"
			}
		}
	},
	chartsBottomGrid: {
		gridTemplateRows: "repeat(3, minmax(auto, 1fr))",
		gridRow: "span 4",
		gridColumn: "span 4"
	},
	automationAndExpiringCountGroup: {
			width: "50%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			height: "100%"
	}
}));
