import React from "react";
import { ConfigureAutomationFilterProvider } from "./Context";
import { ConfigureAutomationFilter } from "./Filter";
import useStyle from "./useStyle";
import { ConfigureAutomationForm } from "./Form";

export const ConfigureAutomationPage = () => {
	const classes = useStyle();

	return (
		<ConfigureAutomationFilterProvider>
			<main className={classes.container}>
				<ConfigureAutomationFilter />
				<ConfigureAutomationForm />
			</main>
		</ConfigureAutomationFilterProvider>
	);
};
