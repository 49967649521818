const cloneObject = function<T>(item: T): T {
  return JSON.parse(JSON.stringify(item));
};

const isObjectEmpty = function(object: any): boolean {
  if (object == null) {
    return true;
  }

  for (const prop in object) {
    if (object.hasOwnProperty(prop)) {
      return false;
    }
  }

  // make sure the object is not a primitive (has no properties).
  return JSON.stringify(object) === JSON.stringify({});
};

const stringIsNullOrEmpty = function(str: string | null | undefined): boolean {
  const newString = str == null ? "" : str;

  return newString === "";
};

const stringContainsCaseInsensitive = function(
  haystack: string,
  needle: string
): boolean {
  return (
    haystack != null &&
    haystack.toUpperCase &&
    haystack.toUpperCase().indexOf(needle.toUpperCase()) >= 0
  );
};

const stringIsEqualCaseInsensitiveTrimmed = function(
  str1: string,
  str2: string
): boolean {
  return str1.trim().toUpperCase() === str2.trim().toUpperCase();
};

const getEmptyPromiseFunc = function(): () => Promise<any> {
  const emptyPromise = () => {
    return new Promise<void>(function(resolve) {
      resolve();
    });
  };

  return emptyPromise;
};

const getEmptyPromise = function(): Promise<any> {
  return new Promise<void>(function(resolve) {
    resolve();
  });
};

const getRejectedPromise = function(): Promise<any> {
  return new Promise(function(resolve, reject) {
    reject();
  });
};

export default {
  cloneObject,
  isObjectEmpty,
  stringIsNullOrEmpty,
  stringIsEqualCaseInsensitiveTrimmed,
  stringContainsCaseInsensitive,
  getEmptyPromiseFunc,
  getEmptyPromise,
  getRejectedPromise
};
