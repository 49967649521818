import { UnitsClient, UnitFilterOptions, UnitFilter, UnitDropdownSelections, UnitResultForm } from "api";
import { UnitPageParams } from "../../forms/UnitPage";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";

export const UnitPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {
		const getForm = function (
			currentFilterSelected: UnitDropdownSelections,
			appliedFilerSelected: UnitDropdownSelections,
			skip: number,
			take: number,
			unleasedOnly: boolean,
			params: UnitPageParams
		): void {
			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitTableLoading,
				payload: true
			});

			let filterOptionsEndpoint = getApiEndpoint(identity, UnitsClient, "getFilterOptions");
			let unitEndpoint = getApiEndpoint(identity, UnitsClient, "getUnitsByFilter");
			var unitFilter: UnitFilter = {
				dropdownSelections: getRawSelectionsFromParams(params, appliedFilerSelected),
				skip: skip,
				take: take,
				unleasedOnly: unleasedOnly
			};

			makeRemoteRequest(unitEndpoint, [unitFilter])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitPageUnits,
						updatedUnits: response.data as UnitResultForm
					});
				})
				.catch((err) => {});
			makeRemoteRequest(filterOptionsEndpoint, [currentFilterSelected])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					var options = response.data as UnitFilterOptions;
					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitPageFilterOptions,
						updatedOptions: options
					});

					var paramSelections = matchOptionsWithParams(options, params);
					if (paramSelections.selectedDivision !== undefined || paramSelections.selectedProperty !== undefined) {
						unitFilter.dropdownSelections = paramSelections;
						updateCurrentSelections(paramSelections);
						dispatcher({
							type: PricingPlatformActionTypes.UpdateUnitAppliedSelections,
							updatedSelections: paramSelections
						});
					}
				})
				.catch((err) => {});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitTableLoading,
				payload: false
			});
		};

		const updateCurrentSelections = function (newSelections: UnitDropdownSelections): void {
			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitCurrentSelections,
				updatedSelections: newSelections
			});

			let filterOptionsEndpoint = getApiEndpoint(identity, UnitsClient, "getFilterOptions");

			makeRemoteRequest(filterOptionsEndpoint, [newSelections])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitPageFilterOptions,
						updatedOptions: response.data as UnitFilterOptions
					});
				})
				.catch((err) => {});
		};

		const searchUnits = function (
			selections: UnitDropdownSelections,
			skip: number,
			take: number,
			unleasedOnly: boolean
		): void {
			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitAppliedSelections,
				updatedSelections: selections
			});
			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitTableLoading,
				payload: true
			});

			let unitEndpoint = getApiEndpoint(identity, UnitsClient, "getUnitsByFilter");

			var unitFilter: UnitFilter = {
				dropdownSelections: selections,
				skip: skip,
				take: take,
				unleasedOnly: unleasedOnly
			};

			makeRemoteRequest(unitEndpoint, [unitFilter])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitPageUnits,
						updatedUnits: response.data as UnitResultForm
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitTableLoading,
						payload: false
					});
				})
				.catch((err) => {});
		};

		const pageUnits = function (
			selections: UnitDropdownSelections,
			skip: number,
			take: number,
			unleasedOnly: boolean,
			sortField: string | undefined,
			sortOrder: number | undefined
		): void {
			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitTableLoading,
				payload: true
			});

			let unitEndpoint = getApiEndpoint(identity, UnitsClient, "getUnitsByFilter");

			var unitFilter: UnitFilter = {
				dropdownSelections: selections,
				skip: skip,
				take: take,
				unleasedOnly: unleasedOnly,
				sortField: sortField,
				sortOrder: sortOrder
			};

			makeRemoteRequest(unitEndpoint, [unitFilter])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitPageUnits,
						updatedUnits: response.data as UnitResultForm
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitTableLoading,
						payload: false
					});
				})
				.catch((err) => {});
		};

		const sortUnits = function (
			selections: UnitDropdownSelections,
			skip: number,
			take: number,
			sortField: string,
			sortOrder: number,
			unleasedOnly: boolean
		): void {
			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitTableLoading,
				payload: true
			});

			let unitEndpoint = getApiEndpoint(identity, UnitsClient, "getUnitsByFilter");

			var unitFilter: UnitFilter = {
				dropdownSelections: selections,
				skip: skip,
				take: take,
				unleasedOnly: unleasedOnly,
				sortField: sortField,
				sortOrder: sortOrder
			};

			makeRemoteRequest(unitEndpoint, [unitFilter])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitPageUnits,
						updatedUnits: response.data as UnitResultForm
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitTableLoading,
						payload: false
					});
				})
				.catch((err) => {});
		};

		const getRawSelectionsFromParams = function (
			params: UnitPageParams,
			appliedOptions: UnitDropdownSelections
		): UnitDropdownSelections {
			var selections: UnitDropdownSelections = appliedOptions;
			if (params.divisionId !== ":divisionId") {
				selections.selectedDivision = { key: params.divisionId };
			}
			if (params.propertyId !== ":propertyId") {
				selections.selectedProperty = { key: params.propertyId };
			}
			if (params.unitGroupId !== ":unitGroupId" && params.propertyId !== ":propertyId") {
				selections.selectedUnitGroup = { key: params.unitGroupId };
			}

			return selections;
		};

		const matchOptionsWithParams = function (
			options: UnitFilterOptions,
			params: UnitPageParams
		): UnitDropdownSelections {
			var selectedDivision = undefined;
			var selectedProperty = undefined;
			var selectedUnitGroup = undefined;

			if (options.divisions !== null && options.divisions !== undefined) {
				selectedDivision = options.divisions.find((x) => x.key === params.divisionId);
			}
			if (options.properties !== null && options.properties !== undefined) {
				selectedProperty = options.properties.find((x) => x.key === params.propertyId);
			}
			if (options.unitGroups !== null && options.unitGroups !== undefined && selectedProperty !== undefined) {
				selectedUnitGroup = options.unitGroups.find((x) => x.key === params.unitGroupId);
			}

			var dropdownSelections: UnitDropdownSelections = {
				selectedDivision: selectedDivision,
				selectedProperty: selectedProperty,
				selectedUnitGroup: selectedUnitGroup
			};
			return dropdownSelections;
		};

		return {
			getForm,
			updateCurrentSelections,
			searchUnits,
			pageUnits,
			sortUnits
		};
	}
};
