import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	confirmationContainer: {
		display: "flex",
		flexDirection: "column",
		paddingTop: "16px",
		paddingBottom: "32px"
	},
	deleteButton: {
		background: theme.palette.text,
		border: "none"
	},
	title: {
		textAlign: "center"
	},
	message: {
		maxWidth: "330px",
		whiteSpace: "wrap",
		textAlign: "center",
		"& strong": {
			fontWeight: "500"
		}
	},
	textButton: {
		color: `${theme.palette.text} !important`
	},
	buttonsContainer: {
		display: "flex",
		width: "100%",
		gap: "10px",
		justifyContent: "start"
	},
	titleContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "10px"
	}
}));
