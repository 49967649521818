import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	validationContainerPlaceholder: {
		position: "relative",
		border: "3px solid transparent"
	},
	validationContainer: {
		border: "3px solid red",
		borderColor: theme.palette.error,
		borderRadius: "4px"
	}
}));
