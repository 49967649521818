import { ConfiguredParameterHeaderGroup, ConfiguredParameterRow } from "api";

interface Column {
	name: string;
	subColumns: Array<{ name?: string; key?: string; type?: number; isPercentage?: boolean }>;
}

export const parametersMapper = (data?: ConfiguredParameterRow[]) => {
	const parameters: Array<Record<string, unknown>> = [];

	data?.forEach((row) => {
		const rowInfo = {
			cityId: row.cityId,
			countyId: row.countyId,
			divisionId: row.divisionId,
			isPortfolio: row.isPortfolio,
			propertyId: row.propertyId,
			unitGroupMasterId: row.unitGroupMasterId,
			hierarchyId: row.hierarchyId
		};
		const params: Record<string, any> = { name: row.name, title: row.title, rowInfo };
		row.params?.forEach((param) => {
			params["groupName"] = param.groupName;
			param.params?.forEach((key) => {
				params[key.paramName ?? ""] = key;
			});
		});
		parameters.push(Object.assign(params ?? {}));
	});

	return { parameters };
};

export const mapColumns = (columns: Array<ConfiguredParameterHeaderGroup>): Column[] => {
	return columns.map(({ groupName, parameters }) => ({
		name: groupName ?? "",
		subColumns:
			parameters?.map((param) => ({
				key: param.parameterName ?? "",
				isPercentage: param.isPercentage ?? false,
				name: param.parameterName !== groupName ? param.parameterName ?? "" : "",
				type: param.displayType ?? 0
			})) ?? []
	}));
};
