import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { PricingPlatformState, getMutableCopyOfPricingPlatformState } from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";
import MessageUtils from "utils/messageUtils";
const MessagesReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);
  switch (action.type) {
    case PricingPlatformActionTypes.ShowErrorState:
      newState.messageState.messages.push(
        MessageUtils.getGenericErrorMessage()
      );
      newState.loadingState.isLoading = false;
      return newState;
    case PricingPlatformActionTypes.ShowValidationErrorState:
      newState.messageState.messages.push(
        MessageUtils.getGenericValidationMessage()
      );
      newState.loadingState.isLoading = false;
      return newState;
  }
  return newState;
};

export default MessagesReducer;
