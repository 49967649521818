import { MarketPositionLineChartDataItem } from "api";
import { formatDate } from "utils/miscUtils";

export interface MarketPositionMapped extends Omit<MarketPositionLineChartDataItem, "date"> {
	date: string;
}

export const marketPositionMapper = (data: MarketPositionLineChartDataItem[]): MarketPositionMapped[] => {
	if (!data) return [];

	const mappedData = data?.map((item) => ({ ...item, date: formatDate(item.date) }));

	return mappedData;
};
