import React from "react";
import useStyle from "../../../useStyle";
import PageSection from "components/controls/PageSection";
import constants from "utils/constants";
import routes from "components/routes";
import { Link } from "react-router-dom";
import { useLoadData } from "./useLoadData";
import { Counts } from "./Counts";
import { Tiles } from "./Tiles";

export const ExclusionSection = () => {
	const classes = useStyle();
	const { localState, isLoading } = useLoadData();

	return (
		<PageSection
			title={constants.titleSection.exclusion}
			leftContent={<Counts count={localState?.footer} isLoading={isLoading} />}
			rightContent={
				<Link to={routes.pricingAutomation.exclusions} className={classes.reviewAll}>
					{constants.buttonLabel.reviewAll}
				</Link>
			}
		>
			<div className={classes.cardListLayout}>
				<Tiles isLoading={isLoading} tiles={localState?.tiles} />
			</div>
		</PageSection>
	);
};
