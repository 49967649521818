import React, { useState } from "react";
import useStyle from "./useStyle";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption, UnitDropdownSelections } from "api";
import { Button } from "primereact/button";
import Label from "components/controls/Label";
import Heading from "components/controls/Heading";
import { ProgressSpinner } from "primereact/progressspinner";
import constants from "utils/constants";

const RentSummaryPage: React.FC = function (props) {
	const classes = useStyle();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.rentSummaryPageState.filterOptions,
		rentSummaryPageReport: appState.rentSummaryPageState.propertyReport,
		isLoading: appState.rentSummaryPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.rentSummaryPage,
		...appActions
	};

	interface LocalState {
		currentFilters: UnitDropdownSelections;
		appliedFilters: UnitDropdownSelections;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getForm(localState.currentFilters);
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedDivision = division;
		curSelections.selectedProperty = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectProperty(property: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedProperty = property;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function applySearchDisabled() {
		return (
			localState.currentFilters.selectedProperty === null || localState.currentFilters.selectedProperty === undefined
		);
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		setLocalState({
			...localState,
			appliedFilters: curSelections
		});

		actions.searchProperty(curSelections);
	}
	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		//if (a.selectedDivision!.key === b.selectedDivision!.key && a.selectedProperty!.key === b.selectedProperty!.key && a.selectedUnitGroup!.key === b.selectedUnitGroup!.key) {
		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	const displayReport = () => {
		if (pageState.rentSummaryPageReport !== null && pageState.rentSummaryPageReport !== undefined) {
			return (
				<div>
					<div className={classes.titleheading}>
						<Heading level="2">"Summary of Current Rent - Available Floor Plans"</Heading>
						<Label label={pageState.rentSummaryPageReport.name}></Label>
					</div>
				</div>
			);
		} else {
			console.log(pageState.rentSummaryPageReport);
			return <div className={classes.empty}>Please Select A Property Using The Filters Above</div>;
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.rentSummary} />}>
			<div className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedDivision}
						options={pageState.filterOptions!.divisions}
						onChange={(d) => onSelectDivision(d)}
						disabled={pageState.filterOptions!.divisions === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedProperty}
						options={pageState.filterOptions!.properties}
						onChange={(d) => onSelectProperty(d)}
						disabled={pageState.filterOptions!.properties === undefined}
						style={{ width: 350 }}
					/>
				</div>
				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled()}
				></Button>
			</div>
			<div className={classes.contentContainer}>{pageState.isLoading ? <ProgressSpinner /> : displayReport()}</div>
		</Page>
	);
};

export default RentSummaryPage;
