import { useAtAGlanceContext } from "../../Context";
import { AtGlanceParameter } from "api";
import { parametersListMapper } from "./fields";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

const defaultValues: AtGlanceParameter[] = [
	{
		title: "ATR",
		value: 0,
		footer: {
			variant: "Equal",
			value: 0
		}
	},
	{
		title: "LEADS",
		value: 0,
		footer: {
			variant: "Equal",
			value: 0
		}
	},
	{
		title: "NET APPS",
		value: 0,
		footer: {
			variant: "Equal",
			value: 0
		}
	},
	{
		title: "LTA/GTA",
		value: 0,
		footer: {
			variant: "Equal",
			value: 0
		}
	},
	{
		title: "NET CONVERSION",
		value: 0,
		footer: {
			variant: "Equal",
			value: 0
		}
	},
	{
		title: "LEASING VELOCITY",
		value: 0,
		footer: {
			variant: "Equal",
			value: 0
		}
	}
];

export const useLoadData = () => {
	const { getAtGlanceParametersReview } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const {
		data = { atGlanceParametersList: defaultValues },
		isFetching,
		isPreviousData,
		isInitialLoading
	} = useQuery({
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: ["parameters-list", filters],
		queryFn: ({ signal }) => getAtGlanceParametersReview(filters, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { atGlanceParametersList: parametersListMapper(data?.atGlanceParametersList || []), isLoading };
};
