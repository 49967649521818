import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	title: {
		marginBottom: "12px"
	},
	flaggedLine: {
		display: "flex",
		gap: "18px"
	}
}));
