import React, { useContext } from "react";
import { PricingPlatformMsalAuthProviderSingleton } from "components/PricingPlatformMsalAuthProvider";
import { PricingPlatformConfigContext } from "components/PricingPlatformConfigProvider";
import AzureAD, {
  IAzureADFunctionProps,
  AuthenticationState
} from "react-aad-msal";
import IdentityProvider from "components/IdentityProvider";
import Spinner from "components/controls/Spinner";
import LoginPage from "components/forms/LoginPage";

interface AzureADIdentityProviderProps {
  readonly children: any | any[];
}

const AzureADIdentityProvider: React.FC<AzureADIdentityProviderProps> = function (
  props: AzureADIdentityProviderProps
) {
  const config = useContext(PricingPlatformConfigContext);
  const pricingPlatformMsalAuthProvider = PricingPlatformMsalAuthProviderSingleton.createOrGetCachedProviderFromConfig(
    config
  );

  const getAccessTokenAction = async function (): Promise<string> {
    const token = await pricingPlatformMsalAuthProvider.getAccessToken();
    return token && token.accessToken && token.accessToken;
  };

  const getLoginPage = function (azureAdProps: IAzureADFunctionProps) {
    return <LoginPage loginAction={azureAdProps.login} />;
  };

  const azureADChildComponentFunction = function (
    azureAdProps: IAzureADFunctionProps
  ) {
    switch (azureAdProps.authenticationState) {
      case AuthenticationState.Authenticated:
        if (!azureAdProps.accountInfo || !azureAdProps.accountInfo.jwtIdToken) {
          return getLoginPage(azureAdProps);
        }

        return (
          <IdentityProvider
            getAccessTokenAction={getAccessTokenAction}
            logoutAction={azureAdProps.logout}
          >
            {props.children}
          </IdentityProvider>
        );

      case AuthenticationState.Unauthenticated:
        return getLoginPage(azureAdProps);

      case AuthenticationState.InProgress:
        return <Spinner isVisible={true} />;
    }
  };

  return (
    <AzureAD provider={pricingPlatformMsalAuthProvider} forceLogin={true}>
      {azureADChildComponentFunction}
    </AzureAD>
  );
};

export default AzureADIdentityProvider;
