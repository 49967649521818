import React from "react";
import { PropertySummary } from "api";
import formats from "./formats";
import routes from "components/routes";
import pageStyle from "./TableTemplates.module.css";

export function metricsTemplate(x: PropertySummary) {
	function getRow(
		name: string,

		firstLabel: string = '7-Day',
		firstTooltip: string | undefined,
		firstValue: number | undefined,
		firstFormat: string = 'number',

		secondLabel: string = '28-Day',
		secondTooltip: string | undefined,
		secondValue: number | undefined,
		secondFormat: string = 'number',
		secondFormatDecimals: number = 1,
	) {

		if (!firstTooltip){
			firstTooltip = firstLabel;
		}
		if (!secondTooltip){
			secondTooltip = secondLabel;
		}

		return (
			<>
			<tr className={pageStyle.metricsTableFieldHeading}>
				<th colSpan={2}>{name}</th>
			</tr>
			<tr className={pageStyle.metricsTableFieldValue}>
				<td title={firstTooltip}><b>{firstLabel}:</b>&nbsp;<span>{firstValue !== undefined && firstValue != null ? formats.displayValue(firstValue, firstFormat) : "N/A"}</span></td>
				<td title={secondTooltip}><b>{secondLabel}:</b>&nbsp;<span>{secondValue !== undefined && secondValue != null ? formats.displayValue(secondValue, secondFormat, secondFormatDecimals) : "N/A"}</span></td>
			</tr>
			</>
		);
	}

	return (
		<div>
			<table className={pageStyle.metricsTable}>
				<tbody>
					{getRow('Net Apps','7-Day','Total applications for residency received in the last 7 days', x.netApplications7Day,'number',
					'28-Day Avg','Average applications for residency received in the last 28 days',	x.averageApplications28Day, 'number', 1)}

					{getRow('Leads','7-Day','Total leads over the last 7 days', x.netLeads7Day,'number',
					'28-Day Avg','Average leads over the last 28 days. (Total leads over the last 28 days / 4)',x.averageLeads28Day, 'number', 1)}

					{getRow('Net Conversion (%)', '7-Day','Total conversions over the last 7 days', x.netConversions7Day, 'percent',
					'28-Day Avg','Average conversions over the last 28 days',x.averageConversions28Day, 'percent', 1)}

					{getRow('Shows','7-Day','Total shows over the last 7 days', x.netShows7Day,'number',
					'28-Day Avg','Average shows over the last 28 days. (Total shows over the last 28 days / 4)',x.averageShows28Day, 'number', 1)}

					{getRow('Net Close (%)','7-Day','Total closed over the last 7 days', x.netClose7Day,'percent',
					'28-Day Avg','Average closed over the last 28 days. (Total closed over the last 28 days / 4)',x.averageClose28Day, 'percent', 1)}
				</tbody>
			</table>
		</div>
	);
}

export function propertyLocationTemplate(x: PropertySummary) {
	//break on the first comma / before city
	let addressDisplay = x.address ? x.address.replace(/,/, "<br/>") : "";
	var unitsHref = routes.units(undefined, x["id"]);

	return (
		<div>
			<div>
				<a target="_blank" rel="noopener noreferrer" href={unitsHref}>
					<b>{x.name}</b>
				</a>
				{addressDisplay && <div dangerouslySetInnerHTML={{ __html: addressDisplay }} />}
			</div>
			<div>
				<b>County:</b> {x.county}
			</div>
		</div>
	);
}

export function propertyStructureTemplate(x: PropertySummary) {
	return (
		<div>
			<div>
				<b>Units:</b> {formats.displayValue(x.totalUnits, "number")}&nbsp; (
				<span title="Vacant units">{x.vacantUnits} V</span>,{" "}
				<span title="Notice to vacate">{x.onNoticeUnits} NTV</span>)
			</div>

			<div>
				<b>Stories:</b> {x.stories}
			</div>
			<div>
				<b>Class:</b> {x.class}
			</div>
			<div title="Regulations">
				<b>Regs:</b> {x.regulations || "N/A"}
			</div>
		</div>
	);
}

export function occupancyTemplate(x: PropertySummary) {
	return (
		<div>
			<div title="Percentage of property's units that are available to rent.">
				<b>ATR:</b> {formats.displayAsPercent(x.atr)} {formats.displayAsPercentChange(x.atrWoW)}
			</div>
			<div title="Occupancy percentage">
				<b>Occ:</b> {formats.displayAsPercent(x.occupancyPercent)}
			</div>
			<div>
				<b>Leases Needed:</b> <span title="waiting on data">N/A</span>
				{/* {formats.displayValue(x.leasesNeeded, "number")} ({formats.displayAsPercent(x.leasesNeededPercent)}) */}
			</div>
		</div>
	);
}
