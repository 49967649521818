/* eslint-disable react-hooks/exhaustive-deps */
import { SearchState } from "components/forms/PricingAutomation/interfaces";
import { useAtAGlanceContext } from "../Context";
import { useSystemAutomatedService } from "components/forms/PricingAutomation/services/systemAutomated.service";
import { useQuery } from "@tanstack/react-query";
import { mapSearchState } from "utils/miscUtils";

export const useLoadData = (searchState: SearchState) => {
	const { getSuccessfulAutomationResult } = useSystemAutomatedService();
	const { filters } = useAtAGlanceContext();
	const payload = {
		divisionId: filters.divisionId,
		countyId: "",
		propertyIds: [],
		unitGroupId: "",
		...mapSearchState(searchState)
	};
	const { data, isInitialLoading, isFetching, isPreviousData } = useQuery({
		keepPreviousData: true,
		queryKey: ["successful-automation", payload],
		queryFn: async ({ signal }) => getSuccessfulAutomationResult({ ...payload }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { localState: data, isLoading };
};
