import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";
import constants from "utils/constants";

  const items = (): MenuItemProps[] => {
    return(
      [
        {
          title: constants.menuTitle.unitGroupMapping.toUpperCase(),
          to: routes.unitGroupAdmin.unitgroup(),
          isActive: 
            locationUtils.matchPathWithParams(routes.unitGroupAdmin.unitgroup())
        },
        {
          title: constants.menuTitle.unassignedUnitTypes.toUpperCase(),
          to: routes.unitGroupAdmin.unassignedUnitTypes,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.unassignedUnitTypes)
        },
        {
          title: constants.menuTitle.unitGroupMaster.toUpperCase(),
          to: routes.unitGroupAdmin.unitGroupMasters,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.unitGroupMasters)
        },
        {
          title: constants.menuTitle.classifications.toUpperCase(),
          to: routes.unitGroupAdmin.classifications,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.classifications)
        },
        {
          title: constants.menuTitle.bedrooms.toUpperCase(),
          to: routes.unitGroupAdmin.bedrooms,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.bedrooms)
        }

      ]
    )
}
  
export interface MenuProps {
  title?: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

const UnitGroupsAdminMenu: React.FC<MenuProps> = function(props: MenuProps) {
  return <Menu {...props} title="ADMIN" items={items()} />;
};

export default UnitGroupsAdminMenu;
