import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
		
	}
}));
