/* eslint-disable react-hooks/exhaustive-deps */
import { useSecondaryReviewService } from "components/forms/PricingAutomation/services/secondaryReview.service";
import { useQuery } from "@tanstack/react-query";
import { useMapFilterKeys } from "./useMapFilterKeys";

const defaultFilterOptions = {
	recommendActions: [],
	divisions: [],
	counties: [],
	properties: [],
	reviewReasons: [],
	manualExclusion: [],
};

export const useLoadData = () => {
	const { getSecondaryReviewFilters } = useSecondaryReviewService();
	const mappedFilters = useMapFilterKeys();
	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		queryKey: ["secondary-filters", mappedFilters],
		queryFn: async ({ signal }) => getSecondaryReviewFilters(mappedFilters, signal)
	});

	return { filterOptions: data || defaultFilterOptions, selected: mappedFilters, isInitialLoading };
};
