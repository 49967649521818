import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	dialog: {
		width: "100%",
		maxWidth: theme.maxScreenWidth,
		height: "100vh",
		"& [class=p-dialog-content]": {
			height: "100%",
			paddingTop: "0",
			overflowX: "hidden"
		},
		"& [class$=p-dialog-header]": {
			borderBottom: "none",
			color: theme.palette.darkGray
		},
		"& [class$=p-dialog-header-icons]": {
			marginBottom: "auto"
		}
	},
	recommendationChartsGrid: {
		display: "grid",
		gap: "28px",
		gridTemplateColumns: "repeat(2, 1fr)",
		"@media (max-width: 1280px)": {
			gridTemplateColumns: "1fr"
		}
	},
	recommendationChartsGridItem: {
		display: "grid",
		gridAutoRows: "140px",
		gap: "28px",
		padding: "3px",
		"@media (max-width: 1280px)": {
			gridRow: "1"
		}
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "28px"
	},
	calendarInput: {
		width: "50%"
	},
	dateRangeFilter: {
		display: "flex"
	},
	dateRangeInput: {
		height: "42px",
		alignSelf: "end"
	}
}));
