/* eslint-disable react-hooks/exhaustive-deps */
import { useAtAGlanceContext } from "../../../Context";
import { compPremiumMapper, compPremiumType } from "../compPremiumMapper";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getLocalCompPremium } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: ["local-comp", filters],
		queryFn: ({ signal }) => getLocalCompPremium({ divisionId: filters.divisionId }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return {
		data: compPremiumMapper(data || {}, compPremiumType.local),
		isLoading
	};
};
