import { Dialog } from "primereact/dialog";
import React from "react";
import { useFormContext } from "react-hook-form";
import { HoldConfirmation } from "./HoldConfirmation";
import { ChangeConfirmation } from "./ChangeConfirmation";
import useStyle from "./useStyle";
import { useIsMutating } from "@tanstack/react-query";
import { PricingButton } from "components/controls/PricingButton";

interface Props {
	open?: boolean;
	onClose: () => void;
}

export const Confirmation = ({ open, onClose }: Props) => {
	const classes = useStyle();
	const { watch } = useFormContext();
	const mutating = useIsMutating({ mutationKey: ["save-price"] });

	return (
		<Dialog visible={open} showHeader={false} onHide={onClose} focusOnShow={false} blockScroll>
			<div className={classes.confirmationContainer}>
				{watch("changeOrHold") === "change" ? <ChangeConfirmation /> : <HoldConfirmation />}
			</div>
			<div className={classes.buttonsContainer}>
				<PricingButton variant="primary" isLoading={mutating > 0} form="priceResetForm" type="submit">
					Continue
				</PricingButton>
				<PricingButton variant="secondary" disabled={mutating > 0} type="button" onClick={onClose}>
					Cancel
				</PricingButton>
			</div>
		</Dialog>
	);
};
