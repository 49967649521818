import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	filterHeaders: {
		display: "grid",
		gridTemplateColumns: "repeat(5, 1fr)",
		width: "100%",
		gap: "16px",
		marginTop: "28px",
		"@media (max-width: 1280px)": {
			gridTemplateColumns: "repeat(3, 1fr)"
		},
		"@media (max-width: 768px)": {
			gridTemplateColumns: "repeat(2, 1fr)"
		}
	},
	filterItem: {
		width: "100%",
		gridColumn: "span 1"
	}
}));
