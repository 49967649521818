import { DetailedHTMLProps, ReactNode } from "react";
import useStyle from "./useStyle";
import React from "react";

interface IconButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	children?: ReactNode;
	tooltip?: string;
}

export const IconButton = ({ children, tooltip, ...props }: IconButtonProps) => {
	const classes = useStyle();

	return (
		<button
			data-pr-tooltip={tooltip}
			className={[classes.iconButton, !!tooltip && "with-tooltip", props.className].join(" ")}
			{...props}
		>
			{children}
		</button>
	);
};
