import { Card } from "primereact/card";
import React, { FC } from "react";
import useStyle from "./useStyle";
import { AtGlanceParameter } from "api";
import { convertPercentage } from "utils/miscUtils";

interface Props extends AtGlanceParameter {
	footerFormat?: string;
}

export const InfoCard: FC<Props> = ({ title = "", footer, footerFormat = "VARIANCE", children }) => {
	const classes = useStyle();
	const variant = footer?.variant?.toLowerCase();

	const renderFormatedValue = () => {
		if (footerFormat === "BPS") return `${footer?.value} BPS`;
		if (footerFormat === "VARIANCE-PERCENTAGE")
			return (
				<div className={classes.variance}>
					{`${convertPercentage(footer?.value)}%`} <span>VARIANCE</span>
				</div>
			);
		if (footerFormat === "VARIANCE")
			return (
				<div className={classes.variance}>
					{footer?.value} <span>VARIANCE</span>
				</div>
			);

		return footer?.value;
	};

	return (
		<Card title={title} className={[classes.cardContainer].join(" ")}>
			{children}
			{!!footer && (
				<div className={classes.footer}>
					{variant === "higher" && <i className={`pi pi-arrow-up ${[classes.arrow, classes.green].join(" ")}`} />}
					{variant === "lower" && <i className={`pi pi-arrow-down ${[classes.arrow, classes.red].join(" ")}`} />}
					{variant === "higherinverse" && <i className={`pi pi-arrow-up ${[classes.arrow, classes.red].join(" ")}`} />}
					{variant === "lowerinverse" && <i className={`pi pi-arrow-down ${[classes.arrow, classes.green].join(" ")}`} />}					
					{variant === "equal" && (
						<i className={`pi pi-arrow-right ${[classes.arrow, classes.yellow].join(" ")}`} />
					)}
					{renderFormatedValue()}
				</div>
			)}
		</Card>
	);
};
