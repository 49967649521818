import {
	AskingPriceLeasingActivityDataResult,
	AskingPriceTileResult,
	AtAGlanceATRCounts,
	AtAGlanceAutomationCount,
	AtGlanceParametersReview,
	AtGlanceParametersReviewSearch,
	AverageRentSummary,
	PricingAutomationClient,
	AskingPriceSearchQuery,
	MarketPositionLineChartData,
	LeadAndNetAppsResult,
	AtGlanceConcessionsTileResponse,
	DivisionsClient,
	PricingAutomationFilter,
	PricingAutomationTopHeader, DashboardChartData, DashboardClient,
	PricingAutomationConfigurationClient,
	CountResponse
} from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

export const useHomeService = () => {
	const identity = useContext(IdentityContext);

	const getAutomationCounts = async (
		query: AtGlanceParametersReviewSearch,
		signal?: AbortSignal
	): Promise<AtAGlanceAutomationCount> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getAutomationCounts", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AtAGlanceAutomationCount;
		} catch (err) {
			throw err;
		}
	};

	
	const getExclusionCounts = async (
		query: AtGlanceParametersReviewSearch,
		signal?: AbortSignal
	): Promise<CountResponse> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationConfigurationClient, "getExpiringExclusionsCount", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as CountResponse;
		} catch (err) {
			throw err;
		}
	};


	const getATRCounts = async (
		query: AtGlanceParametersReviewSearch,
		signal?: AbortSignal
	): Promise<AtAGlanceATRCounts> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getATRCounts", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AtAGlanceATRCounts;
		} catch (err) {
			throw err;
		}
	};

	const getAtGlanceParametersReview = async (
		query: AtGlanceParametersReviewSearch,
		signal?: AbortSignal
	): Promise<AtGlanceParametersReview> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getAtGlanceParametersReview", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AtGlanceParametersReview;
		} catch (err) {
			throw err;
		}
	};

	const getSubmarketCompPremium = async (
		query: AskingPriceSearchQuery,
		signal?: AbortSignal
	): Promise<AskingPriceTileResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSubmarketCompPremiumDiscount", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query.divisionId, query.unitGroupId || ""]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AskingPriceTileResult;
		} catch (err) {
			throw err;
		}
	};

	const getLocalCompPremium = async (
		query: AskingPriceSearchQuery,
		signal?: AbortSignal
	): Promise<AskingPriceTileResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getLocalCompPremiumDiscount", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query.divisionId, query.unitGroupId || ""]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AskingPriceTileResult;
		} catch (err) {
			throw err;
		}
	};

	const getAskingPrice = async (
		{ divisionId }: { divisionId: string | null },
		signal?: AbortSignal
	): Promise<AverageRentSummary> => {
		const endpoint = getApiEndpoint(identity, DivisionsClient, "getAverageRentSummary", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [divisionId]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AverageRentSummary;
		} catch (err) {
			throw err;
		}
	};

	const getLeasingActivity = async (
		query: AskingPriceSearchQuery,
		signal?: AbortSignal
	): Promise<AskingPriceLeasingActivityDataResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getLeasingActivityData", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AskingPriceLeasingActivityDataResult;
		} catch (err) {
			throw err;
		}
	};
	const getLeadAndNetApps = async (
		query: AskingPriceSearchQuery,
		signal?: AbortSignal
	): Promise<LeadAndNetAppsResult> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getLeadAndNetAppsByProperty", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query.divisionId, query.unitGroupId || ""]);
			if (data === undefined) {
				throw new Error();
			}
			return data as LeadAndNetAppsResult;
		} catch (err) {
			throw err;
		}
	};

	const getDashboardChart = async (
		divisionId: string | null,
		signal?: AbortSignal
	): Promise<DashboardChartData> => {
		const endpoint = getApiEndpoint(identity, DashboardClient, "getDashboardChartData", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [divisionId]);
			if (data === undefined) {
				throw new Error();
			}
			return data as DashboardChartData;
		} catch (err) {
			throw err;
		}
	};

	const getMarketPositionLineChartData = async (
		query: AtGlanceParametersReviewSearch,
		signal?: AbortSignal
	): Promise<MarketPositionLineChartData> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getMarketPositionLineChartData", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as MarketPositionLineChartData;
		} catch (err) {
			throw err;
		}
	};

	const getAtGlanceConcessionsTile = async (
		query: AtGlanceParametersReviewSearch,
		signal?: AbortSignal
	): Promise<AtGlanceConcessionsTileResponse> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getAtGlanceConcessionsTile", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as AtGlanceConcessionsTileResponse;
		} catch (err) {
			throw err;
		}
	};

	const getTotalProperties = async (
		filter: PricingAutomationFilter,
		signal?: AbortSignal
	): Promise<PricingAutomationTopHeader> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getTotalProperties", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [filter]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PricingAutomationTopHeader;
		} catch (err) {
			throw err;
		}
	};

	return {
		getAutomationCounts,
		getATRCounts,
		getAtGlanceParametersReview,
		getSubmarketCompPremium,
		getLocalCompPremium,
		getAskingPrice,
		getLeasingActivity,
		getMarketPositionLineChartData,
		getLeadAndNetApps,
		getAtGlanceConcessionsTile,
		getTotalProperties,
		getDashboardChart,
		getExclusionCounts,
	};
};
