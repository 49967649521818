import React, { useState, useEffect, useContext } from "react";
import Page from "components/layouts/Page";
import UnitGroupsAdminMenu from "../UnitGroupsAdminMenu";
import RequirePermission from "../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";

import "./_list-unitgroup-master.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import Spinner from "components/controls/Spinner";
import { Column } from "primereact/column";
import PrimaryButton from "components/controls/PrimaryButton";
import { format } from "date-fns";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import { useHistory } from "react-router-dom";
import { Card } from "primereact/card";
import { UnitGroupMasterForm } from "api";
import { Identity, IdentityContext } from "components/IdentityProvider";

const UnitGroupMasterAdminPage: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const history = useHistory();
	const identity: Identity = useContext(IdentityContext);

	const [localState] = useState({
		unitGroups: appState.unitGroupAdminPageState.unitGroupMasterPage.unitGroupMasterForms,
		totalCount: appState.unitGroupAdminPageState.unitGroupMasterPage.totalCount
	});

	const rowsPerPageOptions: number[] = [10, 20, 50];

	interface SearchState {
		skip: number;
		take: number;
		rows: number;
		page: number;
		totalRecords: number;
	}

	const [searchState, setSearchState] = useState<SearchState>({
		skip: 0,
		take: 10,
		rows: rowsPerPageOptions[0],
		page: 0,
		totalRecords: 0
	});

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}></VisibilityToggle>
			</div>
		</RequirePermission>
	);

	useFirstLoadEffect(() => {
		try {
			actions.getUnitGroupMasterPage(searchState.skip, searchState.take);
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	useEffect(() => {
		localState.unitGroups = pageState.unitGroupMasterPage.unitGroupMasterForms;
		localState.totalCount = pageState.unitGroupMasterPage.totalCount;
	}, [
		localState.unitGroups,
		localState.totalCount,
		pageState.unitGroupMasterPage.totalCount,
		pageState.unitGroupMasterPage.unitGroupMasterForms
	]);

	function onCreate() {
		history.push(routes.unitGroupAdmin.createUnitGroupMaster());
	}

	function onEdit(e: { originalEvent: Event; data: UnitGroupMasterForm; index: number }) {
		var ugId = e.data.id;
		history.push(routes.unitGroupAdmin.updateUnitGroupMaster(ugId));
	}

	function onPage(e: any) {
		actions.getUnitGroupMasterPage(e.first, e.rows);

		setSearchState({
			...searchState,
			skip: e.first,
			take: e.rows,
			page: e.page
		});
	}

	function bedroomTemplate(e: UnitGroupMasterForm) {
		if (e.bedroomCount === undefined) {
			return <span>N/A</span>;
		} else if (e.bedroomCount === 1) {
			return <span>{`${e.bedroomCount} Bedroom`}</span>;
		} else {
			return <span>{`${e.bedroomCount} Bedrooms`}</span>;
		}
	}

	function createdDateTemplate(e: UnitGroupMasterForm) {
		if (e.createdDate === undefined) {
			return <span>N/A</span>;
		} else {
			return <span>{format(new Date(e.createdDate), "M/d/yyyy")}</span>;
		}
	}

	function updatedDateTemplate(e: UnitGroupMasterForm) {
		if (e.lastUpdatedDate === undefined) {
			return <span>N/A</span>;
		} else {
			return <span>{format(new Date(e.lastUpdatedDate), "M/d/yyyy")}</span>;
		}
	}

	const handleRowClick = (e: { originalEvent: Event; data: any; index: number }) => {
		if (identity.permissions.canManageUnitGroups) {
			onEdit(e);
		}
	};

	const dataTable = () => {
		if (pageState.unitGroupMasterPage.unitGroupMasterForms === undefined) {
			return <Spinner isVisible={true} />;
		} else if (
			!pageState.isLoading &&
			pageState.unitGroupMasterPage.unitGroupMasterForms &&
			pageState.unitGroupMasterPage.totalCount === 0
		) {
			return (
				<div className="error-message-styling">
					No unit groups found. You either need to adjust the page filters, or you do not have permission to view
					any unitgroups.
				</div>
			);
		} else {
			return (
				<Card>
					<DataTable
						id="unitGroupMaster-list"
						value={localState.unitGroups}
						paginator={true}
						alwaysShowPaginator={true}
						pageLinkSize={5}
						className="unit-group-master-list p-datatable-striped"
						tableClassName="unit-group-master-list-table"
						lazy={true}
						onPage={onPage}
						onRowClick={handleRowClick}
						loading={pageState.tableLoading}
						totalRecords={localState.totalCount}
						first={searchState.skip}
						rows={searchState.take}
						rowsPerPageOptions={rowsPerPageOptions}
						dataKey="id"
						emptyMessage="No unit groups found"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} master unit groups"
					>
						<Column
							field="name"
							header="Unit Group"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
						></Column>
						<Column
							field="description"
							header="Description"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
						></Column>
						<Column
							field="bedroomCount"
							header="Bedrooms"
							body={bedroomTemplate}
							bodyStyle={{ width: "20%", textAlign: "left" }}
							headerStyle={{ width: "20%", textAlign: "left" }}
						></Column>
						<Column
							field="createdDate"
							header="Created"
							body={createdDateTemplate}
							bodyStyle={{ width: "16%" }}
							headerStyle={{ width: "16%" }}
						></Column>
						<Column
							field="lastUpdatedDate"
							header="Last Updated"
							body={updatedDateTemplate}
							bodyStyle={{ width: "17%" }}
							headerStyle={{ width: "17%" }}
						></Column>
						<Column
							field="lastUpdatedBy"
							header="Last Updated By"
							bodyStyle={{ width: "20%" }}
							headerStyle={{ width: "20%" }}
						></Column>
					</DataTable>
				</Card>
			);
		}
	};

	return (
		<Page menu={<UnitGroupsAdminMenu actionArea={navbarActionArea} />} className="unit-group-master-page">
			<div id="unitGroupAdminFilters" className="filter-headers-1">
				<RequirePermission permissions={[constants.permissions.canManageUnitGroups]}>
					<PrimaryButton className="createButton" onClick={onCreate} title="CREATE UNIT GROUP" />
				</RequirePermission>
			</div>
			<div id="contentContainer" className="">
				{pageState.isLoading ? <ProgressSpinner /> : <div className="unitGroupDetailIndexBody">{dataTable()}</div>}
			</div>
		</Page>
	);
};

export default UnitGroupMasterAdminPage;
