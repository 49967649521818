import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	headerContainer: {
		display: "flex",
		gap: "30px",
		fontWeight: 600,
		fontSize: "13px"
	},
	percentage: {
		paddingLeft: "8px"
	},
	loadingContainer: {
		width: "428px",
		height: "15px",
		"@media (max-width: 768px)": {
			width: "80vw"
		}
	}
}));
