import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	},
	footer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "20px"
	},
	dialog: {
		width: "fit-content",
		minWidth: "400px",
		"& [class=p-dialog-content]": {
			display: "grid",
			width: "100%",
			overflowY: "initial"
		},
		"& [class$=p-dialog-header]": {
			color: theme.palette.darkGray
		}
	}
}));
