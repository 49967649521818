import React from "react";
import useStyle from "../../../useStyle";
import PageSection from "components/controls/PageSection";
import routes from "components/routes";
import { Link } from "react-router-dom";
import constants from "utils/constants";
import { useLoadData } from "./useLoadData";
import { Tiles } from "./Tiles";
import { Counts } from "./Counts";

export const SecondaryReviewSection = () => {
	const classes = useStyle();
	const {
		data: { count, secondaryReviewTiles },
		isLoading
	} = useLoadData();

	return (
		<PageSection
			title={constants.titleSection.secondaryReview}
			leftContent={<Counts count={count} isLoading={isLoading} />}
			rightContent={
				<Link to={routes.pricingAutomation.secondaryReview} className={classes.reviewAll}>
					{constants.buttonLabel.reviewAll}
				</Link>
			}
		>
			<div className={classes.cardListLayout}>
				<Tiles tiles={secondaryReviewTiles} isLoading={isLoading} />
			</div>
		</PageSection>
	);
};
