import React from "react";
import useStyle from "./useStyle";
import { formatPercentage } from "utils/miscUtils";
import { AtAGlanceAutomationCount } from "api";

interface Props {
	counts?: AtAGlanceAutomationCount;
}

const defaultCounts = {
	successfulCount: 0,
	successfulPercent: 0,
	secondaryCount: 0,
	secondaryPercent: 0,
	excludedCount: 0,
	excludedPercent: 0,
	userModifiedSuccessfulCount: 0,
	userModifiedSuccessfulPercent: 0,
	userModifiedSecondaryCount: 0,
	userModifiedSecondaryPercent: 0
};

export const ParametersList = ({ counts = defaultCounts }: Props) => {
	const classes = useStyle();

	const parameters = [
		{
			name: "Successful",
			icon: <i className={["pi pi-check-circle", classes.success].join(" ")} />,
			values: [counts.successfulCount, counts.successfulPercent]
		},
		{
			name: "Secondary",
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					className={classes.warning}
				>
					<path d="M14.4 0H1.6C0.72 0 0.00799999 0.72 0.00799999 1.6L0 16L3.2 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6C16 0.72 15.28 0 14.4 0ZM14.4 11.2H2.536L1.6 12.136V1.6H14.4V11.2ZM6.8 9.6H12.8V8H8.4L6.8 9.6ZM9.888 4.904C10.048 4.744 10.048 4.496 9.888 4.336L8.472 2.92C8.312 2.76 8.064 2.76 7.904 2.92L3.2 7.624V9.6H5.176L9.888 4.904Z" />
				</svg>
			),
			values: [counts.secondaryCount, counts.secondaryPercent]
		},
		{
			name: "Excluded",
			icon: <i className={["pi pi-exclamation-circle", classes.error].join(" ")} />,
			values: [counts.excludedCount, counts.excludedPercent]
		}
	];

	return (
		<ul className={classes.list}>
			<li key={"Successful"} className={classes.listItemMultipleRows}>
				<div className={classes.listItem}>
					<div className={classes.listItemLabel}>
						<i className={["pi pi-check-circle", classes.success].join(" ")} />
						<span>{"Successful"}</span>
					</div>
					<div className={[classes.listItemLabel, classes.bold].join(" ")}>
						<span>{counts.successfulCount}</span>
						<span className={classes.percentageLabel}>{formatPercentage(counts.successfulPercent)}</span>
					</div>
				</div>
				<div className={classes.listItem}>
					<div className={classes.listItemLabel}>
						<span className={[classes.iconSpacing].join(" ")}>{"Modified"}</span>
					</div>
					<div className={[classes.listItemLabel, classes.subListItem].join(" ")}>
						<span>{counts.userModifiedSuccessfulCount}</span>
						<span className={classes.percentageLabel}>
							{formatPercentage(counts.userModifiedSuccessfulPercent)}
						</span>
					</div>
				</div>
			</li>
			<li key={"Secondary"} className={classes.listItemMultipleRows}>
				<div className={classes.listItem}>
					<div className={classes.listItemLabel}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							className={classes.warning}
						>
							<path d="M14.4 0H1.6C0.72 0 0.00799999 0.72 0.00799999 1.6L0 16L3.2 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6C16 0.72 15.28 0 14.4 0ZM14.4 11.2H2.536L1.6 12.136V1.6H14.4V11.2ZM6.8 9.6H12.8V8H8.4L6.8 9.6ZM9.888 4.904C10.048 4.744 10.048 4.496 9.888 4.336L8.472 2.92C8.312 2.76 8.064 2.76 7.904 2.92L3.2 7.624V9.6H5.176L9.888 4.904Z" />
						</svg>
						<span>{"Secondary"}</span>
					</div>
					<div className={[classes.listItemLabel, classes.bold].join(" ")}>
						<span>{counts.secondaryCount}</span>
						<span className={classes.percentageLabel}>{formatPercentage(counts.secondaryPercent)}</span>
					</div>
				</div>
				<div className={classes.listItem}>
					<div className={classes.listItemLabel}>
						<span className={[classes.iconSpacing].join(" ")}>{"Modified"}</span>
					</div>
					<div className={[classes.listItemLabel, classes.subListItem].join(" ")}>
						<span>{counts.userModifiedSecondaryCount}</span>
						<span className={classes.percentageLabel}>
							{formatPercentage(counts.userModifiedSecondaryPercent)}
						</span>
					</div>
				</div>
			</li>
			<li key={"Excluded"} className={classes.listItemMultipleRows}>
				<div className={classes.listItem}>
					<div className={classes.listItemLabel}>
						<i className={["pi pi-exclamation-circle", classes.error].join(" ")} />
						<span>{"Excluded"}</span>
					</div>
					<div className={[classes.listItemLabel, classes.bold].join(" ")}>
						<span>{counts.excludedCount}</span>
						<span className={classes.percentageLabel}>{formatPercentage(counts.excludedPercent)}</span>
					</div>
				</div>
				<div className={classes.listItem}>
					<div className={classes.listItemLabel}>
						<span className={[classes.iconSpacing].join(" ")}>{"Modified"}</span>
					</div>
					<div className={[classes.listItemLabel, classes.subListItem].join(" ")}>
						<span>{counts.userModifiedExcludedCount}</span>
						<span className={classes.percentageLabel}>
							{formatPercentage(counts.userModifiedExcludedPercent)}
						</span>
					</div>
				</div>
			</li>
		</ul>
	);
};
