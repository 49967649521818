import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	root: {
		display: "flex",
		height: "38px",
		width: "100%",
		maxWidth: "100%"
	},
	dropdownWrapper: {
		"& > label": {
			position: "absolute",
			top: "-10px",
			left: "6px",
			padding: "0 5px",
			backgroundColor: theme.palette.white,
			color: "#79747e",
			fontSize: "13px",
			zIndex: "10"
		},
		"& [class=p-multiselect-trigger]": {
			backgroundColor: `transparent !important`,
			color: `${theme.palette.gray} !important`
		}
	}
}));
