import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { Link } from "react-router-dom";
import cssUtils from "utils/cssUtils";

type Props = {
	title?: string;
	count?: number | undefined;
	link?: string | undefined;
	className?: string;
	isLoading?: boolean;
};

export const CountCard = ({ title = "", count, link, className, isLoading }: Props) => {
	const classes = useStyle();

	if (isLoading) {
		return (
			<span role="presentation" className={[className, classes.cardContainer, "skeleton", "p-card"].join(" ")} />
		);
	}

	const cardBody = (
		<Card
			title={title}
			className={[
				className,
				classes.cardContainer,
				cssUtils.conditionClass(classes.cardContainerWithLink, link !== undefined),
			].join(" ")}
		>
			<span>{count}</span>
		</Card>
	);

	return link ? (
		<Link to={`${link}`} className={classes.link}>
			{cardBody}
		</Link>
	) : (
		cardBody
	);
};
