import React from "react";
import useStyle from "./useStyle";

export interface PageProps {
	menu: React.ReactElement;
	className?: string | undefined;
	withDrawer?: boolean;
}

const Page: React.FC<PageProps> = function (props) {
	const classes = useStyle(props);
	let mainPageCssClasses = classes.page;

	if (props.className) {
		mainPageCssClasses += " " + props.className;
	}

	return (
		<div className={mainPageCssClasses}>
			<div className={[classes.menu, "top-menu"].join(" ")}>{props.menu}</div>
			<main className={classes.body}>{props.children}</main>
		</div>
	);
};

export default Page;
