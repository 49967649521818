import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";
import constants from "utils/constants";

const sampleMenuItems: MenuItemProps[] = [
  {
    title: constants.menuTitle.users.toUpperCase(),
    to: routes.admin.users,
    isActive:
      locationUtils.isRouteCurrentlyActive(routes.admin.users) || 
      locationUtils.isRouteCurrentlyActive(routes.admin.addUser) ||
      locationUtils.isRouteCurrentlyActive(routes.admin.editUser("*")) ||
      locationUtils.isRouteCurrentlyActive("/admin/")
  }
];

export interface MenuProps {
  title?: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

const AdminMenu: React.FC<MenuProps> = function(props: MenuProps) {
  return <Menu {...props} title="ADMIN" items={sampleMenuItems} />;
};

export default AdminMenu;
