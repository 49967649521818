import React, { useState } from "react";
import useStyle from "./useStyle";
import { TextField } from "components/controls/TextField";
import { useSystemAutomatedFilterContext } from "../../../Context";
import { useFormContext } from "react-hook-form";
import { calculateAmount, calculatePercentage, onBlurNumber, onlyNumber, parseToFloat } from "utils/miscUtils";
import DropdownSelect from "components/controls/DropdownSelect";
import { DropdownOption } from "api";
import { useLoadData } from "../useLoadData";

export const ChangePriceHold = () => {
	const classes = useStyle();
	const { selectedRow } = useSystemAutomatedFilterContext();
	const [selectedOption, setSelectedOption] = useState("change");
	const [selectedOverrideReason, setSelectedOverrideReason] = useState(undefined as (DropdownOption | undefined));
	const { register, errors, setValue } = useFormContext();
	const { overrideFormOptionsData } = useLoadData();
	
	// var originalPublishedPrice: any;
	// originalPublishedPrice = selectedRow.publishedPrice;

	// const handleChange = (key: "amount" | "percentageChange" | "dollarChange") => (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (key === "percentageChange") {
	// 		setValue("amount", calculateAmount({ current: originalPublishedPrice, percentage: e.target.value }));
	// 		setValue("dollarChange", parseToFloat(Number(calculateAmount({ current: originalPublishedPrice, percentage: e.target.value })) - Number(originalPublishedPrice)));
	// 	}
	// 	if (key === "amount") {
	// 		setValue("percentageChange", calculatePercentage({ current: e.target.value, last: originalPublishedPrice }));
	// 		setValue("dollarChange", parseToFloat(Number(e.target.value) - Number(originalPublishedPrice)));
	// 	}
	// 	if (key === "dollarChange") {
	// 		setValue("amount", parseToFloat(Number(originalPublishedPrice) + Number(e.target.value)));
	// 		setValue("percentageChange", calculatePercentage({ current: parseToFloat(Number(originalPublishedPrice) + Number(e.target.value)), last: originalPublishedPrice }));
	// 	}
	// };

	var comparedPrice = selectedRow?.completed ? selectedRow.lastPrice : selectedRow.publishedPrice;

	const handleChange =
		(key: "amount" | "percentageChange" | "dollarChange") => (e: React.ChangeEvent<HTMLInputElement>) => {
			if (key === "percentageChange") {
				setValue("amount", calculateAmount({ current: comparedPrice, percentage: e.target.value }));
				setValue(
					"dollarChange",
					parseToFloat(
						Number(calculateAmount({ current: comparedPrice, percentage: e.target.value })) -
							Number(comparedPrice)
					)
				);
			}
			if (key === "amount") {
				setValue("percentageChange", calculatePercentage({ current: e.target.value, last: comparedPrice }));
				setValue("dollarChange", parseToFloat(Number(e.target.value) - Number(comparedPrice)));
			}
			if (key === "dollarChange") {
				setValue("amount", parseToFloat(Number(comparedPrice) + Number(e.target.value)));
				setValue(
					"percentageChange",
					calculatePercentage({
						current: parseToFloat(Number(comparedPrice) + Number(e.target.value)),
						last: comparedPrice
					})
				);
			}
		};

	const handleHoldAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedOption(e.target.value);
		setValue("amount", comparedPrice);
		setValue("percentageChange", calculatePercentage({ current: comparedPrice, last: comparedPrice }));
		setValue("dollarChange", parseToFloat(Number(comparedPrice) - Number(comparedPrice)));
	};

	const handleOverrideSelection = (e: DropdownOption | undefined) => {
		setValue("overrideReason", e?.key);
		setSelectedOverrideReason(e);
	}

	return (
		<div className={classes.outerContainer}>
			<div className={classes.changeResetContainer}>
				<div className={classes.radioButtonOption}>
					<input
						ref={register}
						type="radio"
						name="changeOrHold"
						value="change"
						onChange={(e) => setSelectedOption(e.target.value)}
						checked={selectedOption === "change"}
					/>
					<label htmlFor="changePrice">Change Price/Rate Reset</label>
				</div>
				<div className={classes.changeResetInputs}>
					<input
						ref={register}
						type="hidden"
						name="initialFormAmount"
						value={parseToFloat(selectedRow?.currentPrice)}
					/>
					<TextField
						ref={register}
						name="amount"
						onKeyDown={onlyNumber}
						onBlur={onBlurNumber({ decimals: 0 })}
						defaultValue={parseToFloat(selectedRow?.currentPrice)}
						//defaultValue={parseToFloat(selectedRow?.publishedPrice)}
						onChangeCapture={handleChange("amount")}
						disabled={selectedOption !== "change"}
						label="Amount"
						symbol={{ content: "$", direction: "left" }}
					/>
					<TextField
						ref={register}
						onKeyDown={onlyNumber}
						onBlur={onBlurNumber({ decimals: 1 })}
						name="percentageChange"
						onChangeCapture={handleChange("percentageChange")}
						disabled={selectedOption !== "change"}
						defaultValue={
							selectedRow?.completed
								? calculatePercentage({ current: selectedRow?.currentPrice, last: selectedRow?.lastPrice })
								: calculatePercentage({ current: selectedRow?.currentPrice, last: selectedRow?.publishedPrice })
						}
						//defaultValue={calculatePercentage({ current: selectedRow?.publishedPrice, last: originalPublishedPrice })}
						label="% Change"
						symbol={{ content: "%", direction: "right" }}
					/>
					<TextField
						ref={register}
						name="dollarChange"
						onKeyDown={onlyNumber}
						onBlur={onBlurNumber({ decimals: 0 })}
						onChangeCapture={handleChange("dollarChange")}
						defaultValue={
							selectedRow?.completed
								? parseToFloat(Number(selectedRow?.currentPrice) - Number(selectedRow?.lastPrice))
								: parseToFloat(Number(selectedRow?.currentPrice) - Number(selectedRow?.publishedPrice))
						}
						//defaultValue={ parseToFloat(Number(selectedRow?.publishedPrice) - Number(originalPublishedPrice)) }
						disabled={selectedOption !== "change"}
						label="$ Change"
						symbol={{ content: "$", direction: "left" }}
					/>
				</div>
				{errors.amount && <span className={classes.errorMessage}>{errors.amount.message}</span>}
				{errors.percentageChange && <span className={classes.errorMessage}>{errors.percentageChange.message}</span>}
				{errors.changeOrHold && <span className={classes.errorMessage}>{errors.changeOrHold.message}</span>}
				<input ref={register} type="hidden" name="holdAmount" value={comparedPrice} />
				<div className={classes.radioButtonOption}>
					<input
						ref={register}
						type="radio"
						name="changeOrHold"
						value="overrideautohold"
						onChange={(e) => handleHoldAction(e)}
						checked={selectedOption === "overrideautohold"}
					/>
					<label htmlFor="hold">Hold</label>
				</div>
			</div>
			<div className={classes.overrideReasonContainer}>
				Override Reason
				<DropdownSelect
				disabled={false}
				selectedValue={selectedOverrideReason}
				options={overrideFormOptionsData.overrideReasons}
				onChange={(value) => handleOverrideSelection(value)}
			/>
			<input ref={register} name="overrideReason" hidden/>
			</div>
		</div>
	);
};
