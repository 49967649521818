import constants from "utils/constants";
import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { LeasingActivityChart } from "components/controls/LeasingActivityChart/Chart";
import { DashboardChartData } from "api";
import { leasingActivityMapper } from "./leasingActivityMapper";

interface Props {
	isLoading: boolean;
	data: DashboardChartData;
}

export const LeasingActivity = ({ isLoading, data }: Props) => {
	const classes = useStyle();
	const mappedData = leasingActivityMapper(data || {});

	if (isLoading) {
		return <span role="presentation" className={[classes.cardContainer, "skeleton", "p-card"].join(" ")} />;
	}

	return (
		<Card title={constants.cardTitle.leasingActivity} className={classes.cardContainer}>
			<LeasingActivityChart data={mappedData} />
		</Card>
	);
};
