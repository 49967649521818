import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	recommendedPriceContainer: {
		fontWeight: "500"
	},
	textArea: {
		paddingTop: "22px"
	},
	changeResetInputs: {
		display: "flex",
		paddingBlock: "16px",
		flexDirection: "row",
		marginLeft: "22px",
		maxWidth: "230px",
		gap: "12px"
	},
	changeResetContainer: {
		display: "flex",
		flexDirection: "column",
		marginBlock: "16px",
		width: "50%"
	},
	overrideReasonContainer: {
		display: "flex",
		flexDirection: "column",
		marginBlock: "16px",
		width: "50%"
	},
	radioButtonOption: {
		display: "flex",
		alignItems: "baseline",
		gap: "6px",
		margin: "10px 0"
	},
	errorMessage: {
		color: theme.palette.error,
		paddingBottom: "8px"
	},
	container: {
		height: "min-content"
	},
	outerContainer: {
		display: "flex",
		flexDirection: "row"
	}
}));
