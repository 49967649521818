import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import useApprovalPopupStyle from "./useApprovalPopupStyle";
import { useSecondaryReviewFilterContext } from "../Context";
import { SecondaryReviewBulkApprovalRequest } from "api";
import PrimaryButton from "components/controls/PrimaryButton";
import SecondaryButton from "components/controls/SecondaryButton";
import { useSecondaryReviewService } from "components/forms/PricingAutomation/services/secondaryReview.service";
import Input from "components/controls/Input";
import Spinner from "components/controls/Spinner";
import Spacer from "components/controls/Spacer";

interface Props {
	open: boolean;
	ids: string[];
	onClose: () => void;
	onApproval: () => void;
}

export const ApprovalPopup = ({ open, onClose, ids, onApproval }: Props) => {
	const classes = useApprovalPopupStyle();
	const { bulkApproveSecondaryReviews } = useSecondaryReviewService();
	const { setRefresh } = useSecondaryReviewFilterContext();
	const [pageState, setPageState] = useState({
		isLoading: false
	});

	const handleSubmit = async (dataToSubmit: string[]) => {
		setPageState({ ...pageState, isLoading: true });

		const payload = {
			automatedPricingActionIds: dataToSubmit
		} as SecondaryReviewBulkApprovalRequest;

		const result = await bulkApproveSecondaryReviews(payload);

		setPageState({ ...pageState, isLoading: false });
		if (!result.isError) {
			onApproval();
			setRefresh(true);
			onClose();
		}
	};

	return (
		<Dialog
			visible={open}
			focusOnShow={false}
			onHide={onClose}
			className={classes.dialog}
			header={`Accept engine recommended price for ${ids.length} selected row(s)?`}
		>
			<Spinner isVisible={pageState.isLoading}></Spinner>
			<div className={classes.contentContainer}>
				<div className={[classes.row, classes.justifyFlexEnd].join(" ")}>
					<PrimaryButton onClick={() => handleSubmit(ids)} title={"Confirm"} />
					<Spacer></Spacer>
					<SecondaryButton onClick={() => onClose()} title={"Cancel"} type={"notice"} />
				</div>
			</div>
		</Dialog>
	);
};
