import { ConfigureAutomationFilterContextType } from "../Context";

export const mapFilterKeys = (filters: ConfigureAutomationFilterContextType["filters"]) => {
	return {
		countyId: filters.countyId?.key,
		divisionId: filters.divisionId?.key,
		propertyIds: filters.propertyIds?.map((option) => option?.key || ""),
		unitGroupId: filters.unitGroupId?.key
	};
};
