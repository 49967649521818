/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SearchState } from "components/forms/PricingAutomation/interfaces";
import { useExclusionsContext } from "../Context";
import { useMapFilterKeys } from "../Filter/useMapFilterKeys";
import { useExclusionsService } from "components/forms/PricingAutomation/services/exclusions.service";
import { useQuery } from "@tanstack/react-query";
import { mapSearchState } from "utils/miscUtils";

export const useLoadData = (searchState: SearchState) => {
	const { getExcludedUnitGroups } = useExclusionsService();
	const { refresh } = useExclusionsContext();
	const mappedFilters = useMapFilterKeys();
	const payload = {
		...mappedFilters,
		...mapSearchState(searchState)
	};
	const [isRefreshing, setIsRefreshing] = useState(false);
	const { data, isInitialLoading, isFetching, isPreviousData, refetch } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["exclusions-excluded", payload],
		queryFn: async ({ signal }) => getExcludedUnitGroups({ ...payload }, signal)
	});
	//It is loaded when it is loaded for the first time, when new data is obtained or when it is manually reloaded.
	const isLoading = isInitialLoading || (isFetching && isPreviousData) || isRefreshing;

	useEffect(() => {
		if (refresh) {
			setIsRefreshing(true);
			refetch().finally(() => setIsRefreshing(false));
		}
	}, [refresh]);

	return { localState: data, isLoading };
};
