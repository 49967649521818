import { DropdownOption, HierarchyLevel } from "api";
import { getCapitalizeAndSplitBySpace } from "utils/miscUtils";

export const hierarchyMapper = (hierarchyLevels?: HierarchyLevel[]): DropdownOption[] => {
	if (!hierarchyLevels) return [];

	return hierarchyLevels.map((level) => ({
		key: level.id,
		displayValue: getCapitalizeAndSplitBySpace(level.description ?? "")
	}));
};
