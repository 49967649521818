import React from "react";
import logo from "assets/Essex-logo.svg";
import useStyle from "./useStyle";
import { Link } from "react-router-dom";
import routes from "components/routes";
import { UserMenu } from "./UserMenu";
import { MobileDrawer } from "./MobileDrawer";

export interface LoadingState {
	isLoading: boolean;
}

const Header: React.FC = () => {
	const classes = useStyle();

	return (
		<div className={classes.container}>
			<MobileDrawer />
			<div className={classes.logoWrapper}>
				<Link to={routes.home}>
					<img className={classes.logo} src={logo} alt="Essex" />
				</Link>
			</div>
			<div id="headerButtons" className={classes.buttons}>
				{/*TODO: Update when Notification API get ready */}
				{/* <NotificationsMenu /> */}
				<UserMenu />
			</div>
		</div>
	);
};
export default Header;
