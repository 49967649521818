import React from "react";
import { AtAGlanceParameters } from "./AtAGlanceParameters";
import { ConcessionsTile } from "./Concessions/ConcessionsTile";

export const ParametersList = () => {
	return (
		<>
			<AtAGlanceParameters />
			<ConcessionsTile />
		</>
	);
};
