import useMessageArea from "components/useMessageArea";
import { AlertContext, AlertMessage } from "./AlertContext";
import React, { FC } from "react";

export const AlertProvider: FC = ({ children }) => {
	const [messageArea, , , setMessageArea] = useMessageArea();

	const setAlert = ({ message, severity, title, autoHide }: AlertMessage) => {
		setMessageArea(title, message, severity, autoHide);
	};

	return (
		<AlertContext.Provider value={{ setAlert }}>
			{messageArea.messageArea}
			{children}
		</AlertContext.Provider>
	);
};

export default AlertProvider;
