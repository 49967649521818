import React, { useState } from "react";
import AdminMenu from "../AdminMenu";
import Page from "components/layouts/Page";
import useStyle from "./useStyle";
import {
  UserAdminForm,
  Option,
  UserAdminFormClient,
  UserAdminFormProperty,
  UserAdminFormDivision,
  UserAdminFormUser
} from "api";
import createDataTable, {
  IPagedTableState,
  DataTableProps
} from "components/controls/createTable";
import RequirePermission from "../RequirePermission";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { useRemoteData } from "components/useRemoteData";
import { useApiEndpoint } from "components/useApiEndpoint";
import { matchesDivision } from "../filters";
import { matchesRole } from "./filters";
import { useHistory } from "react-router";
import routes from "components/routes";
import ContentArea from "components/layouts/ContentArea";
import Spinner from "components/controls/Spinner";
import { InputText } from "primereact/inputtext";
import 'primeicons/primeicons.css';
import constants from "utils/constants";


interface PageState {
  form: UserAdminForm | null | undefined;
  globalFilter: string | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  propertiesFilter?: Option;
  rolesFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const UserAdminPage: React.FC = function() {
  const classes = useStyle();
  const history = useHistory();


  const [page, setState] = useState<PageState>({
    form: undefined,
    globalFilter: undefined
  });

  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortField: "email",
    sortOrder: 1,
    divisionFilter: undefined,
    propertiesFilter: undefined,
    rolesFilter: undefined
  });

  const [dataState] = useRemoteData(
    useApiEndpoint(UserAdminFormClient, "getUserAdmin"),
    [],
    {
      autoFetch: true,
      onChange: d => {
        setState({ ...page, form: d });
        setTable({
          ...table,
          divisionFilter: (d && d.selectedDivision) || undefined,
          propertiesFilter:
            (d && d.selectedDivision && d.selectedDivision.selectedProperty) ||
            undefined,
          rolesFilter: (d && d.selectedRole) || undefined
        });
      }
    }
  );

  const spinner = <Spinner isVisible={dataState.isFetching} />;

  //See T-4258 for refactoring improvements
  if (page.form == null && dataState.isFetching) {
    return spinner;
  } else if (page.form == null) {
    return <div>Error</div>;
  }

  function onPropertyChange(o: DropdownOption | undefined) {
    if (!page || !page.form) {
      return;
    }

    const selectedDivision: UserAdminFormDivision | undefined =
      page.form.selectedDivision;

    if (selectedDivision) {
      selectedDivision.selectedProperty = o;
    }

    setState({
      ...page,
      form: {
        ...page.form,
        selectedDivision: selectedDivision
      }
    });

    setTable({
      ...table,
      propertiesFilter: o
    });
  }

  let properties: UserAdminFormProperty[] = [];
  let selectedProperty: UserAdminFormProperty | undefined;

  if (page.form.selectedDivision && page.form.selectedDivision.properties) {
    properties = page.form.selectedDivision.properties;
    selectedProperty = page.form.selectedDivision.selectedProperty;
  }

  const navigateToAddUser = () => {
    history.push(routes.admin.addUser);
  };

  const header = (
    <div className="tableHeader">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e: any) => setState({...page, globalFilter: e.target.value })} placeholder="Global Search" />
      </span>
    </div>
  );

  const tableProps: DataTableProps<UserAdminFormUser, TableState> = {
    value: page.form.users,
    state: table,
    updateState: setTable,
    deps: [page.form],
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    selectionMode: "single",
    onAddRowAction: navigateToAddUser,
    onSelectionChange: (e: any) => {
      const row: UserAdminFormUser = e.value;

      if (row && row.key) {
        history.push(routes.admin.editUser(row.key));
      }
    },
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    header: header,
    globalFilter: page.globalFilter

  };



  const dataTable = createDataTable<UserAdminFormUser, TableState>(
    [
      {
        field: "email",
        header: "Email",
        sortable: true,
        style: { width: "25%" }
      },
      { field: "firstName", header: "First Name", style: { width: "13%" } },
      {
        field: "lastName",
        header: "Last Name",
        sortable: true,
        style: { width: "15%" }
      },
      {
        field: "role",
        header: "Role",
        sortable: true,
        style: { width: "17%" }
      },
      {
        field: "division",
        header: "Division",
        sortable: true,
        style: { width: "13%" }
      },
      { field: "propertyCount", header: "Properties", style: { width: "10%" } },
		  { field: 'isActive', header: 'Active', sortable: true, style: { width: '10%' }, 
        body: (rowData: UserAdminFormUser) => <i>{rowData.isActive ? 
          <i className="pi pi-check"></i> : ''}</i> },
    ],
    tableProps,
    {
      divisionFilter: (v, f) =>
        matchesDivision(v && v.displayValue, f.division),
      rolesFilter: (v, f) => matchesRole(v && v.displayValue, f.role),
      propertiesFilter: (v, f) => {
        if (!v || !v.displayValue) {
          return true;
        }

        return !f.properties || f.properties.some(p => p.key === v.key);
      }
    },

  );

  return (
    <Page menu={<AdminMenu />}>
      <RequirePermission
        permissions={[constants.permissions.canManageUsers]}
        accessDenied="page"
      >
        {spinner}
        <div className={classes.filterHeaders}>
          <div className={classes.filterItem}>
            <Dropdown
              label="Roles"
              selectedValue={page.form.selectedRole}
              options={page.form.roles}
              onChange={o => {
                setState({
                  ...page,
                  form: { ...page.form, selectedRole: o }
                });
                setTable({
                  ...table,
                  rolesFilter: o
                });
              }}
              style={{width: 250}}
            />
          </div>
          <div className={classes.filterItem}>
            <Dropdown
              label="Division"
              selectedValue={page.form.selectedDivision}
              options={page.form.divisions}
              onChange={o => {
                setState({
                  ...page,
                  form: {
                    ...page.form,
                    selectedDivision: o
                      ? { ...o, selectedProperty: undefined }
                      : undefined
                  }
                });
                setTable({
                  ...table,
                  divisionFilter: o,
                  propertiesFilter: undefined
                });
              }}
              style={{width: 250}}
            />
          </div>
          <div className={classes.filterItem}>
            <Dropdown
              label="Properties"
              selectedValue={selectedProperty}
              options={properties}
              onChange={o => onPropertyChange(o)}
              disabled={!page.form.selectedDivision}
              style={{width: 400}}
            />
          </div>
        </div>
        <ContentArea columnSize={"12"}>
          <div className={classes.contentContainer}>
            {dataTable}
          </div>
        </ContentArea>
      </RequirePermission>
    </Page>
  );
};

export default UserAdminPage;
