import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import useStyle from "./useStyle";
import { PriceResetHeader } from "./Header";
import { TableSection } from "./Table";
import { ColumnType } from "components/controls/DataTable/types";
import { PriceResetForm } from "./Form";
import { MarketPosition } from "./MarketPosition";
import { LeasingActivity } from "./LeasingActivity";
import { LeadsAndNet } from "./LeadsAndNet";
import Calendar, { CalendarType } from "components/controls/Calendar";
import { addDays } from "date-fns";
import SecondaryButton from "components/controls/SecondaryButton";
import Spacer from "components/controls/Spacer";

interface Props {
	open: boolean;
	columns: ColumnType[];
	onClose: () => void;
	sectionTitle: string;
}

export const PriceResetPopUp = ({ open, onClose, columns, sectionTitle }: Props) => {
	const [pageState, setPageState] = useState({
		startDate: undefined as Date | undefined,
		endDate: undefined as Date | undefined
	});

	const handleDateChange = (firstDate: Date | undefined, secondDate: Date | undefined) => {
		setPageState({ startDate: firstDate, endDate: secondDate });
	};

	const handleClose = () => {
		setPageState({ startDate: undefined, endDate: undefined });
		onClose();
	};

	const handleClearDateRange = () => {
		setPageState({ startDate: undefined, endDate: undefined });
	};

	const today = new Date();
	const startDate = addDays(today, -366);

	const classes = useStyle();
	return (
		<Dialog
			visible={open}
			header={<PriceResetHeader />}
			focusOnShow={false}
			onHide={handleClose}
			blockScroll
			closeOnEscape
			className={classes.dialog}
		>
			<div className={classes.contentContainer}>
				<TableSection columns={columns} sectionTitle={sectionTitle} />
				<div className={classes.recommendationChartsGrid}>
					<PriceResetForm handleClose={handleClose} />
					<div>
						<div className={classes.dateRangeFilter}>
							<div className={classes.calendarInput}>
								<Calendar
									type={CalendarType.RangeSelection}
									onChange={handleDateChange}
									maxDate={today}
									minDate={startDate}
									appendTo={document.body}
									label="Chart Date Range"
									labelPosition="top"
									monthNavigator
									yearNavigator={{pastYearsToShow: 2}}
									value={
										pageState.startDate && pageState.endDate
											? [pageState.startDate, pageState.endDate]
											: pageState.startDate
											? [pageState.startDate]
											: undefined
									}
								></Calendar>
							</div>
							<Spacer></Spacer>
							<SecondaryButton
								type={"notice"}
								title="Clear"
								onClick={() => handleClearDateRange()}
								className={classes.dateRangeInput}
							/>
						</div>
						<div className={classes.recommendationChartsGridItem}>
							<MarketPosition startDate={pageState.startDate} endDate={pageState.endDate} />
							<LeasingActivity startDate={pageState.startDate} endDate={pageState.endDate} />
							<LeadsAndNet startDate={pageState.startDate} endDate={pageState.endDate} />
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};
