import { ColumnType } from "components/controls/DataTable/types";
import { formatPrice, formatDate } from "utils/formatTemplates";

export const columns: ColumnType[] = [
	{
		name: "Concession Type",
		subColumns: [{ field: "concessionTypeName", sortable: false }],
		align: "left"
	},
	{
		name: "Start Date",
		subColumns: [{ field: "startDate", format: formatDate("startDate"), sortable: false }]
	},
	{
		name: "End Date",
		subColumns: [{ field: "endDate", format: formatDate("endDate"), sortable: false }]
	},
	{ name: "Amount", subColumns: [{ field: "ammount", format: formatPrice("amount"), sortable: false }] },
	{ name: "Period (# Weeks)", subColumns: [{ field: "period", sortable: false }] }
];
