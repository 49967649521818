import { AtrScore } from "components/forms/PricingAutomation/interfaces";
import React from "react";
import useStyle from "./useStyle";

interface Props {
	data: AtrScore[];
}

export const RangeList = ({ data }: Props) => {
	const classes = useStyle();

	return (
		<ul className={classes.rangeList}>
			{data.map((d) => (
				<li key={d.name}>
					<span className={classes.range}>
						<span data-testid={`${d.name}-dot`} className={[classes.dot, classes[d.name]].join(" ")} />
						{d.range}
					</span>
					<span className={classes.count}>{d.count}</span>
				</li>
			))}
		</ul>
	);
};
