import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";
import constants from "utils/constants";

export interface MenuProps {
  title?: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

  const createItems = (): MenuItemProps[] => {
    return(
      [

        {
          title: constants.menuTitle.classifications.toUpperCase(),
          to: routes.unitGroupAdmin.classifications,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.classifications)
        },
        {
            title: constants.menuTitle.createClassification.toUpperCase(),
            to: routes.unitGroupAdmin.createClassification,
            isActive:
              locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.createClassification)
        },
      ]
    )
}

const editItems = (): MenuItemProps[] => {
  return(
    [

      {
        title: constants.menuTitle.classifications.toUpperCase(),
        to: routes.unitGroupAdmin.classifications,
        isActive:
          locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.classifications)
      },
      {
        title: constants.menuTitle.editClassification.toUpperCase(),
        to: routes.unitGroupAdmin.updateClassification(),
        isActive:
          locationUtils.matchPathWithParams(routes.unitGroupAdmin.updateClassification())
    },
    ]
  )
}
  


const CreateClassificationMenu: React.FC<MenuProps> = function(props: MenuProps) {
  return <Menu {...props} title="ADMIN" items={createItems()} />;
};

const EditClassificationMenu: React.FC<MenuProps> = function(props: MenuProps) {
  return <Menu {...props} title="ADMIN" items={editItems()} />;
};

export {
  CreateClassificationMenu,
  EditClassificationMenu
}
