import React from "react";
import useStyle from "./useStyle";
import constants from "utils/constants";
import DropdownSelect from "components/controls/DropdownSelect";
import DropdownMultiSelect from "components/controls/DropdownMultiSelect";
import { useLoadData } from "./useLoadData";
import { useConfigureAutomationFilterContext } from "../Context";
import { sortDropdown } from "utils/miscUtils";

export const ConfigureAutomationFilter = () => {
	const classes = useStyle();
	const { filters, handleChange, editedRowLevel } = useConfigureAutomationFilterContext();
	const { filterOptions, isLoading } = useLoadData();

	const isDisabled = editedRowLevel !== "" || isLoading;

	return (
		<div className={classes.filterHeaders}>
			<DropdownSelect
				disabled={isDisabled}
				inputId={constants.filterLabels.hierarchyLevels}
				inputLabel={constants.filterLabels.hierarchyLevels}
				selectedValue={filters.hierarchyLevels}
				options={filterOptions.hierarchyLevels}
				onChange={(value) => handleChange(value)("hierarchyLevels")}
			/>
			<DropdownSelect
				disabled={isDisabled}
				inputId={constants.filterLabels.division}
				inputLabel={constants.filterLabels.division}
				selectedValue={filters.divisionId}
				options={filterOptions.divisions}
				onChange={(value) => handleChange(value)("divisionId")}
			/>
			<DropdownSelect
				disabled={isDisabled}
				inputId={constants.filterLabels.county}
				inputLabel={constants.filterLabels.county}
				selectedValue={filters.countyId}
				options={filterOptions?.counties?.sort(sortDropdown)}
				onChange={(value) => handleChange(value)("countyId")}
			/>
			<DropdownMultiSelect
				filter
				disabled={isDisabled}
				inputId={constants.filterLabels.property}
				inputLabel={constants.filterLabels.property}
				selectedValues={filters.propertyIds}
				options={filterOptions.properties}
				onChange={(value) => handleChange(value)("propertyIds")}
			/>
			<DropdownSelect
				filter
				disabled={isDisabled || filters.propertyIds?.length > 1}
				inputId={constants.filterLabels.unitGroup}
				inputLabel={constants.filterLabels.unitGroup}
				selectedValue={filters.unitGroupId}
				options={filterOptions.unitGroups}
				onChange={(value) => handleChange(value)("unitGroupId")}
			/>
		</div>
	);
};
