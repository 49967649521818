import { z } from "zod";

const changeOrHoldSchema = z
	.object({
		changeOrHold: z.enum(["change", "hold"]),
		amount: z.coerce.number({ invalid_type_error: "Amount should be a number" }).optional(),
		percentageChange: z.coerce.number({ invalid_type_error: "% Change should be a number" }).optional(),
		notes: z.string().max(2000, "Note cannot exceed 2000 characters").optional(),
		overrideReason: z.string().optional()
	})
	.refine(
		(data) =>
			!(
				data.changeOrHold === "change" &&
				(typeof data?.amount === "undefined" || typeof data?.percentageChange === "undefined")
			),
		{
			message: "If Change Price is selected, Amount and Percentage Change should be filled in.",
			path: ["changeOrHold"]
		}
	)
	.refine(
		(data) =>
			!(
				data.overrideReason === "" && data.notes === ""				
			),
		{
			message: "Must provide a note or override reason.",
			path: ["notes"]
		}
	);

export const priceResetFormSchema = changeOrHoldSchema;

export type PriceResetFormType = z.infer<typeof priceResetFormSchema>;
