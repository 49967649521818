import React from "react";
import { FormProvider } from "react-hook-form";
import { ConfigureAutomationTable } from "../Table";
import { useConfigureAutomationForm } from "./useForm";

export const ConfigureAutomationForm = () => {
	const { methods, onSubmit } = useConfigureAutomationForm();
	const overrideRef = React.useRef<HTMLDivElement>(null);
	return (
		<FormProvider {...methods}>
			<form id="configureAutomationForm" onSubmit={methods.handleSubmit(onSubmit)}>
				<ConfigureAutomationTable overrideRef={overrideRef} />
			</form>
			<div ref={overrideRef} />
		</FormProvider>
	);
};
