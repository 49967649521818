import { z } from "zod";

export const changeOrHoldSchema = z
	.object({
		changeOrHold: z.enum(["change", "hold", "overrideautohold"]),
		initialFormAmount: z.coerce.number({ invalid_type_error: "Amount should be a number" }).optional(),
		amount: z.coerce.number({ invalid_type_error: "Amount should be a number" }).optional(),
		percentageChange: z.coerce.number({ invalid_type_error: "% Change should be a number" }).optional(),
		notes: z.string().max(2000, "Note cannot exceed 2000 characters").optional(),
		holdAmount: z.coerce.number().optional(),
		overrideReason: z.string().optional()
	})
	.refine(
		(data) =>
			!(
				data.changeOrHold === "change" &&
				(typeof data?.amount === "undefined" || typeof data?.percentageChange === "undefined" || data?.amount === data?.initialFormAmount)
			),
		{
			message: "If Change Price is selected, Amount must change and Percentage Change or Dollar Change should be filled in.",
			path: ["changeOrHold"]
		}
	)
	.refine(
		(data) =>
			!(
				data.overrideReason === "" && data.notes === ""				
			),
		{
			message: "Must provide a note or override reason.",
			path: ["notes"]
		}
	);

export type PriceResetFormType = z.infer<typeof changeOrHoldSchema>;
