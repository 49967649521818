import React, { useState, useEffect, useRef } from "react";
import pageStyle from "./UnitGroupCard.module.css";
import { UnitGroupSummary } from "api";
import "primeicons/primeicons.css";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import RequirePermission from "../RequirePermission";
import formats from "./formats";
import FormattedDate from "components/controls/FormattedDate";
import ChangeLog from "./ChangeLog";
import { UpdatedUnitGroupDelta } from "components/store/PropertiesPage/propertyPageInterfaces";
import routes from "components/routes";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Link } from "react-router-dom";
import constants from "utils/constants";

export interface UnitGroupCardInputProps {
	propertyId: string | undefined;
	unitGroup: UnitGroupSummary;
	savedUnitGroups: UnitGroupSummary[];
	setSavedUnitGroups: React.Dispatch<React.SetStateAction<UnitGroupSummary[]>>;
	updateUnitGroupState: (unitGroup: UpdatedUnitGroupDelta) => void;

	isHoldAllActive: boolean;
	setIsHoldAllActive: React.Dispatch<React.SetStateAction<any>>;
}

interface UnitGroupCardState {
	unitGroupId: string;
	percentChange: number;
	dollarChange: number;
	baseRent: number;
	adjustedNormalizedRent: number;
	comment: string;
	isChanged: boolean;
	hold: boolean;
	unitGroup: UnitGroupSummary;
	propertyId: string;
}

export default function UnitGroupCard(props: UnitGroupCardInputProps) {
	const { unitGroup } = props;
	const unitGroupId: string = unitGroup.id || "";
	const propertyId: string | undefined = props.propertyId || "";

	const initialState: UnitGroupCardState = {
		unitGroupId: unitGroupId,
		percentChange: 0,
		dollarChange: 0,
		baseRent: unitGroup.baseRent || 0,
		adjustedNormalizedRent: Math.round((unitGroup && unitGroup.normalizedRent) || 0),
		comment: "",
		isChanged: false,
		hold: unitGroup.isHoldActive || false,
		unitGroup: Object.assign({}, unitGroup), //clone
		propertyId: propertyId
	};

	//the useRef Hook allows you to persist data between renders, Object.assign clones the prop so it doesn't get mutated

	const originalState = useRef<UnitGroupCardState>(Object.assign({}, initialState));

	const [state, setState] = useState<UnitGroupCardState>(initialState);
	const { percentChange, dollarChange, adjustedNormalizedRent, comment, isChanged, baseRent, hold } = state;
	const lagVarianceCalculation = (lag: number | undefined, adjNormalizedRent: number): any => {
		if (lag === undefined || lag === 0) return "N/A";

		if (adjNormalizedRent === undefined || adjNormalizedRent === 0) return "N/A";

		return (adjNormalizedRent - lag) / lag;
	};
	useEffect(() => {
		//watch for saved unit groups and rebind
		if (!props.savedUnitGroups || props.savedUnitGroups.length === 0) {
			return;
		}

		//this useEffect gets called everytime the savedUnitGroups array changes
		//this means that this little function runs even when an item was removed from the arrray
		//so you may notice that it's called more than once after the UG is saved.
		//no biggie. it only calls the API once.

		//this gets called a bunch
		let updatedSelf = props.savedUnitGroups.find((ug) => ug.id === unitGroup.id);

		if (!updatedSelf) {
			return;
		}

		//update original state after saving unit group
		originalState.current = {
			...originalState.current,
			hold: updatedSelf.isHoldActive || false,
			unitGroup: updatedSelf
		};

		//reload data
		Object.assign(unitGroup, updatedSelf);

		//reset state
		setState({
			...state,
			percentChange: 0,
			dollarChange: 0,
			baseRent: unitGroup.baseRent || 0,
			adjustedNormalizedRent: Math.round(unitGroup.normalizedRent || 0),
			comment: "",
			isChanged: false
		});

		//remove from the page-level savedUnitGroups list using the setter callback
		let newSavedUnitGroups = props.savedUnitGroups.filter((ug) => ug.id !== unitGroup.id);
		props.setSavedUnitGroups(newSavedUnitGroups);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.savedUnitGroups]); //ONLY check the savedUnitGroups here!

	useEffect(() => {
		//watch for hold changes at a higher level (e.g. hold all button on list component)
		if (props.unitGroup.isHoldActive !== state.hold) {
			handleStateChange("hold", props.unitGroup.isHoldActive);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.unitGroup.isHoldActive]);

	function handleInputChange(e: any) {
		const { name, value } = e.target || e.currentTarget;
		if (typeof name === "undefined") {
			return;
		}
		handleStateChange(name, value);
	}

	// const calculateNewBaseRent = (newAdjustedNormalizedRent:number):number =>{
	// 	let newBaseRent = newAdjustedNormalizedRent - (unitGroup.averageTotalUnitAmenities || 0);

	// 	if(newBaseRent<0)
	// 		newBaseRent =0;

	// 	return newBaseRent;
	// }
	function handleStateChange(name: string, value: any) {
		let newAdjustedNormalizedRent = state.adjustedNormalizedRent;
		let newDollarChange = state.dollarChange;
		let newPercentChange = state.percentChange;
		let newComment = state.comment;
		let hold = state.hold;

		let originalNormalizedRent = Math.round(originalState.current.unitGroup.normalizedRent || 0);

		switch (name) {
			case "comment":
				newComment = value;
				break;

			case "hold":
				hold = value;
				if (value === true && state.adjustedNormalizedRent !== originalNormalizedRent) {
					//User held price. Resetting price to original price.
					newAdjustedNormalizedRent = originalNormalizedRent;
					newDollarChange = 0;
					newPercentChange = 0;
				}
				break;

			case "percentChange":
				//note percent in the input is a whole number (5%), so we need to convert it to a decimal (.05)
				newPercentChange = value;
				newAdjustedNormalizedRent = Math.round(originalNormalizedRent * (1 + value / 100));
				if (newAdjustedNormalizedRent < 0) {
					newAdjustedNormalizedRent = 0;
					newPercentChange = -100;
				}
				newDollarChange = newAdjustedNormalizedRent - originalNormalizedRent;
				if (newPercentChange !== 0) {
					hold = false;
				}
				break;

			case "dollarChange":
				newDollarChange = value;
				newAdjustedNormalizedRent = Math.round(originalNormalizedRent + value);
				if (newAdjustedNormalizedRent < 0) {
					newAdjustedNormalizedRent = 0;
					newDollarChange = -originalNormalizedRent;
				}
				if (originalNormalizedRent !== 0) {
					newPercentChange = +((newDollarChange / originalNormalizedRent) * 100).toFixed(1);
				} else {
					newPercentChange = 0;
				}
				if (newDollarChange !== 0) {
					hold = false;
				}
				break;

			case "adjustedNormalizedRent":
				newAdjustedNormalizedRent = value;

				if (newAdjustedNormalizedRent <= 0) {
					newAdjustedNormalizedRent = 0;
				}

				if (originalNormalizedRent === value) {
					newDollarChange = 0.0;
					newPercentChange = 0.0;
				} else {
					newDollarChange = newAdjustedNormalizedRent - originalNormalizedRent;

					if (originalNormalizedRent !== 0) {
						newPercentChange = +((newDollarChange / originalNormalizedRent) * 100).toFixed(1);
					} else {
						newPercentChange = 0;
					}
				}
				if (newDollarChange !== 0) {
					hold = false;
				}
				break;
		}

		let isChanged =
			newComment.length > 0 ||
			originalNormalizedRent !== newAdjustedNormalizedRent ||
			hold !== originalState.current.unitGroup.isHoldActive;

		let newBaseRent = newAdjustedNormalizedRent - (unitGroup.averageTotalUnitAmenities || 0);

		let delta: UpdatedUnitGroupDelta = {
			unitGroupId,
			propertyId,
			isChanged,
			baseRent: newBaseRent,
			comment: newComment,
			hold,
			averageTotalUnitAmenities: unitGroup.averageTotalUnitAmenities,
			normalizedRent: newAdjustedNormalizedRent,
			previousNormalizedRent: originalNormalizedRent
		};

		props.updateUnitGroupState(delta);

		setState({
			...state,
			adjustedNormalizedRent: newAdjustedNormalizedRent,
			percentChange: newPercentChange,
			dollarChange: newDollarChange,
			comment: newComment,
			isChanged,
			baseRent: newBaseRent,
			hold
		});
	}

	if (!unitGroup) {
		return <div>No unit group supplied</div>;
	}

	function resetState() {
		setState(originalState.current);
		//remove from savedUnitGroups
		let newSavedUnitGroups = props.savedUnitGroups.filter((ug) => ug.id !== unitGroup.id);
		props.setSavedUnitGroups(newSavedUnitGroups);
		//tell daddy component
		props.updateUnitGroupState(originalState.current);
	}

	//small child component for formatting
	function DataPoint(props: any) {
		if (!props) {
			return <span>-</span>;
		}

		return (
			<span className={pageStyle.dataPoint} title={props.title || props.name || ""}>
				{props.name && <b>{props.name}:</b>}&nbsp;
				<span>
					<span>{formats.displayValue(props.value, props.format)}</span>
					{typeof props.wow === "number" && <span>{formats.displayAsPercentChange(props.wow)}</span>}
				</span>
			</span>
		);
	}

	function renderHoldTooltip(ug: UnitGroupSummary) {
		if (originalState.current.unitGroup.isHoldActive !== true) {
			return "";
		}
		let currentHoldActivatedDate = new Date();
		ug.holdActivatedDate = currentHoldActivatedDate;
		var timestamp = new Date(currentHoldActivatedDate);
		var title = "Price was held on " + timestamp.toLocaleDateString();
		return <i className="pi pi-info-circle" style={{ fontSize: ".7em" }} title={title} />;
	}

	//Check if we need to highlight it, ex. if it's the same day and not dirty
	function unitGroupHighlight(unitGroup: UnitGroupSummary) {
		var today = new Date();

		//Have to parse from C# date to javascript to compare
		if (unitGroup.lastAdjustedDate !== undefined) {
			//TODO put unit group's date in a format I can use to compare to JavaScript's date time object
			var lastUnitGroupUpdate = new Date(unitGroup.lastAdjustedDate);
			var checkLastUpdates = lastUnitGroupUpdate.getDate().valueOf() === today.getDate().valueOf();

			if (!unitGroup.lastHeldDate) {
				return checkLastUpdates;
			} else {
				var lastUnitGroupHold = new Date(unitGroup.lastHeldDate);

				var checkLastHold = lastUnitGroupHold.getDate().valueOf() === today.getDate().valueOf();
				return checkLastUpdates || checkLastHold;
			}
		} else if (unitGroup.lastHeldDate !== undefined) {
			//check hold state even if last updated isn't defined
			lastUnitGroupHold = new Date(unitGroup.lastHeldDate);

			return lastUnitGroupHold.getDate().valueOf() === today.getDate().valueOf();
		} else {
			// If last hold date and last updated are both empty/undefined, there are no changes
			return false;
		}
	}

	const ug = unitGroup;
	return (
		<div
			className={[
				pageStyle.card,
				!state.isChanged && unitGroupHighlight(ug) ? pageStyle.unitGroupHighlight : "",
				state.isChanged ? pageStyle.dirty : ""
			].join(" ")}
		>
			<div className={pageStyle.title}>
				<div className={pageStyle.titleName}>
					<Link className={pageStyle.titleLink} to={routes.units(undefined, props.propertyId, ug.id)}>
						{ug.name}
					</Link>
				</div>
				<div className={pageStyle.titleActionIcons}>
					<RequirePermission
						permissions={[constants.permissions.canManagePricing]}
						accessDenied={
							<i
								className="pi pi-lock"
								style={{ fontSize: ".7em" }}
								title="You do not have permission to edit pricing."
							/>
						}
					>
						{state.isChanged && (
							<Button
								title="Reset changes to this Unit Group"
								icon={`pi pi-undo`}
								label="Undo"
								onClick={resetState}
								className={["p-button-sm p-button-secondary p-button-outlined", pageStyle.undoButton].join(" ")}
							/>
						)}
					</RequirePermission>
				</div>
			</div>
			<div className={pageStyle.dataRow}>
				<div className={pageStyle.column}>
					<DataPoint
						name="Units"
						value={`${ug.totalUnits} (${ug.vacantUnits} V, ${ug.onNoticeUnits} NTV)`}
						title={`Total number of units in this group.  Vacant (V) includes units with lease statuses of ${ug.vacantStatuses}. Notice to Vacate (NTV) includes units with lease statuses of ${ug.nearToVacantStatuses}.`}
					/>
				</div>
				<div className={pageStyle.column}>
					<DataPoint name="UG Base Rent" value={baseRent} format="currency" title="Unit group's base rent" />
					<DataPoint
						name="UG Avg Amenities"
						value={ug.averageTotalUnitAmenities}
						format="currency"
						title="Average of unit amenity values"
					/>

					<DataPoint
						name="Normalized Rent"
						value={adjustedNormalizedRent}
						format="currency"
						wow={!isChanged ? ug.normalizedRentWoW : undefined}
						title="Base rent + average of units amenity values"
					/>
				</div>
				<div className={pageStyle.column}>
					<DataPoint
						name="Starting Rent"
						value={ug.startingRent || "N/A"}
						format="currency"
						title="Lowest normalized rent of available units.  If there are no available units, this will display N/A."
					/>
					<DataPoint
						name="LAG"
						value={ug.latestAverageGross || undefined}
						format="currency"
						title="Latest Average Gross (LAG)"
					/>
					<DataPoint
						name="LAG Variance"
						value={lagVarianceCalculation(ug.latestAverageGross, adjustedNormalizedRent)}
						format="percent"
						title="Variance is the Normalized Rent - LAG) / LAG"
					/>
				</div>
				<div className={pageStyle.column}>
					<DataPoint
						name="ATR"
						value={ug.atr}
						format="percent"
						wow={ug.atrWoW}
						title={`Percent of this group's units that are available to rent.  Available to rent includes units with lease statuses of ${ug.vacantStatuses}.`}
					/>
					<DataPoint
						name="LTA/GTA"
						value={ug.ltagtaPercent || undefined}
						format="percent"
						title="Loss to Market % (loss to asking or gain to asking)"
					/>
				</div>
				<div className={pageStyle.column}>
					<DataPoint name="Pricing Barometer" value="N/A" title="waiting on data" />
					{/* <DataPoint name="Pricing Barometer" value={ug.pricingBarometer || "N/A"} wow={ug.pricingBarometerWoW} /> */}

					<DataPoint
						name="Comp"
						value={`${formats.displayAsCurrency(ug.compMin || undefined)} - ${formats.displayAsCurrency(
							ug.compMax || undefined
						)}`}
						title="Range of comparable unit rents from external market"
					/>
					<DataPoint
						name="Comp Avg"
						value={ug.compAverage || undefined}
						format="currency"
						wow={ug.compAverageWoW}
						title="Average of comparable unit rents from external market"
					/>
				</div>
				<div className={pageStyle.column}>
					<span className={pageStyle.dataPointCard}>
						<b>Net Applications:</b>
						<span>
							<DataPoint name="7-day" value={ug.netApplications7Day} />
							&nbsp;|&nbsp;
							<DataPoint name="28-day" value={ug.netApplications28Day} />
						</span>
					</span>
					<span className={pageStyle.dataPoint}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={routes.pricingMatrix(undefined, props.propertyId, ug.id, undefined)}
						>
							View Pricing Matrix
						</a>
					</span>
				</div>
			</div>
			<div className={pageStyle.reviewRow}>
				{ug.lastAdjustedBy ? (
					<small>
						Last adjusted by {ug.lastAdjustedBy} on {<FormattedDate value={ug.lastAdjustedDate} useUtcDate />}
					</small>
				) : (
					<small>Last adjusted: N/A</small>
				)}
				{ug.lastHeldBy ? (
					<small>
						Last held by {ug.lastHeldBy} on {<FormattedDate value={ug.lastHeldDate} useUtcDate />}
					</small>
				) : (
					<small>Last held: N/A</small>
				)}
			</div>

			<RequirePermission permissions={[constants.permissions.canManagePricing]}>
				<div className={[pageStyle.priceAdjustmentForm, state.isChanged && pageStyle.dirty].join(" ")}>
					<div className={pageStyle.priceAdjustmentFormTitle}>Rent Adjustment:</div>
					<div>
						<span className="p-float-label">
							<InputNumber
								id="percentChange"
								name="percentChange"
								value={percentChange}
								className={pageStyle.input}
								suffix="%"
								onValueChange={handleInputChange}
								minFractionDigits={1}
								maxFractionDigits={2}
								step={0.5}
								min={-100}
								size={10}
								disabled={typeof unitGroup === "undefined" || unitGroup.baseRent === 0}
							/>
							<label htmlFor="percentChange">Percent Change</label>
						</span>
					</div>
					<div>
						<span className="p-float-label">
							<InputNumber
								id="dollarChange"
								name="dollarChange"
								className={pageStyle.input}
								value={dollarChange}
								onValueChange={handleInputChange}
								mode="currency"
								currency="USD"
								locale="en-US"
								maxFractionDigits={0}
								size={10}
							></InputNumber>
							<label htmlFor="dollarChange">Dollar Change</label>
						</span>
					</div>
					<div>
						<span className="p-float-label">
							<InputNumber
								id="adjustedNormalizedRent"
								name="adjustedNormalizedRent"
								className={pageStyle.input}
								value={adjustedNormalizedRent}
								onValueChange={handleInputChange}
								mode="currency"
								currency="USD"
								locale="en-US"
								maxFractionDigits={0}
								min={0}
								size={20}
							></InputNumber>
							<label htmlFor="adjustedNormalizedRent">Adjusted Normalized Rent</label>
						</span>
					</div>
					<div className={pageStyle.priceAdjustmentFormComments}>
						<span className="p-float-label">
							<InputTextarea
								id="comment"
								name="comment"
								className={pageStyle.comments}
								value={comment}
								rows={1}
								autoResize
								onChange={(e) => {
									handleStateChange(e.currentTarget.name, e.currentTarget.value);
								}}
							/>
							<label htmlFor="comment">Comments</label>
						</span>
					</div>

					<div className={pageStyle.priceAdjustmentFormActionsArea}>
						<label id="isHoldActiveLabel" htmlFor="holdSwitch">
							{renderHoldTooltip(ug)} Hold:
						</label>
						<InputSwitch
							id="holdSwitch"
							checked={hold}
							onChange={(e) => {
								handleStateChange("hold", e.value);
							}}
							ariaLabelledBy="isHoldActiveLabel"
						/>
					</div>
				</div>
			</RequirePermission>

			<ChangeLog log={unitGroup.changeLog} />
		</div>
	);
}
