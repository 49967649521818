import React from "react";
import {
	ClassificationForm,
	DropdownOption,
	UnitGroupAdminResultForm,
	UnitGroupMasterForm,
	UnitType,
	UnitTypeForm,
	UnitTypeStatus,
	UnitTypeStatusList
} from "api/pricing-platform-api";
import constants, { pageAction } from "utils/constants";
import { isNullOrUndefinedOrEmpty } from "utils/miscUtils";

export interface BedroomParams {
	bedroomId: string;
}

export interface IParams {
	divisionId: string;
	divisionName: string;
	propertyId: string;
	propertyName: string;
	unitGroupId: string;
}

export interface UnitGroupMasterParams {
	unitGroupMasterId: string;
}
export interface IUnitTypeList {
	name: string;
	code: string;
	status: UnitTypeStatus | null;
}

export interface UnitGroupAdminState {
	divisionName: string;
	divisionId: string;
	propertyName: string;
	propertyId: string;
	unitGroupId: string;
	unitGroupMasterId: string | undefined;
	classificationId: string | undefined;
	unitGroupDetail: UnitGroupAdminResultForm;
	selectedUnitGroupMaster: DropdownOption | undefined;
	unitGroupMaster: DropdownOption[] | undefined;
	selectedClassification: DropdownOption | undefined;
	classification: DropdownOption[] | undefined;
	unitTypes: UnitType[];
	normalizedRent: number | undefined;
	pageAction: string;
	assignedTotal: number;
	unassignedTotal: number;
	assignedUnitTypes: IUnitTypeList[];
	unassignedUnitTypes: IUnitTypeList[];
}

export interface UnitGroupMasterState {
	unitGroupMasterName: string;
	unitGroupMasterId: string | undefined;
	description?: string;
	selectedBedroom: DropdownOption | undefined;
	bedrooms: DropdownOption[] | undefined;
	bedroomId: string | undefined;
	initialUpdateName: string;
	initialUpdateDescription: string;
	initialUpdateBedroom: DropdownOption | undefined;
	pageAction: string;
}

export interface BedroomState {
	pageAction: string;
	bedroomId: string;
	numberOfBedrooms: number | undefined;
	description: string;
	isLoading: true;
	initialUpdateDescription: string;
	initialUpdateBedroom: number | undefined;
}

export const bedroomInitState: BedroomState = {
	pageAction: constants.pageAction.create,
	bedroomId: "",
	numberOfBedrooms: undefined,
	description: "",
	isLoading: true,
	initialUpdateDescription: "",
	initialUpdateBedroom: undefined
};

export const unitGroupAdminInitState: UnitGroupAdminState = {
	divisionName: "",
	divisionId: "",
	propertyName: "",
	propertyId: "",
	unitGroupId: "",
	unitGroupMasterId: undefined,
	normalizedRent: undefined,
	classificationId: undefined,
	unitGroupDetail: undefined as unknown as UnitGroupAdminResultForm,
	selectedUnitGroupMaster: undefined,
	unitGroupMaster: [],
	selectedClassification: undefined,
	classification: [],
	unitTypes: [],
	pageAction: pageAction.create,
	assignedTotal: 0,
	unassignedTotal: 0,
	assignedUnitTypes: [] as IUnitTypeList[],
	unassignedUnitTypes: [] as IUnitTypeList[]
};

export const unitGroupMasterInitState: UnitGroupMasterState = {
	unitGroupMasterId: "",
	unitGroupMasterName: "",
	description: "",
	bedrooms: undefined,
	selectedBedroom: undefined,
	bedroomId: undefined,
	initialUpdateName: "",
	initialUpdateDescription: "",
	initialUpdateBedroom: undefined,
	pageAction: pageAction.create
};

export const listItemTemplate = (option: any) => {
	return (
		<div className="list-item">
			<span>{option.code}</span>
			<span className="list-value">({option.name})</span>
		</div>
	);
};

export const mapToUnitGroupMaster = (unitGroupMaster: UnitGroupMasterForm[]): DropdownOption[] => {
	const mapped = unitGroupMaster.map((item: UnitGroupMasterForm) => {
		var description = item.description !== null ? " - " + item.description : "";

		return {
			displayValue: `${item.name}${description}`,
			key: item.id
		} as DropdownOption;
	});
	return mapped;
};

export const mapToClassification = (classification: ClassificationForm[]): DropdownOption[] => {
	return classification.map((item: ClassificationForm) => {
		return {
			displayValue: `${item.name}`,
			key: item.id
		} as DropdownOption;
	});
};

export const isCreateDirty = (localState: UnitGroupAdminState): boolean | undefined => {
	if (localState.pageAction === pageAction.create) {
		return (
			localState.unitGroupMasterId === undefined &&
			localState.normalizedRent === undefined &&
			localState.classificationId === undefined
		);
	} else if (localState.pageAction === pageAction.update) {
		return localState.unitGroupMasterId === undefined || localState.classificationId === undefined;
	}
};

export const getAssignedIdList = (unitTypes: IUnitTypeList[]): UnitTypeStatusList[] => {
	return unitTypes.length > 0
		? unitTypes
				.filter((item) => item.status === UnitTypeStatus.Assign)
				.map((item: IUnitTypeList) => {
					return {
						unitTypeId: item.code,
						status: item.status
					} as UnitTypeStatusList;
				})
		: [];
};

export const getChangedUnitTypes = (assigned: IUnitTypeList[], unassigned: IUnitTypeList[]): UnitTypeStatusList[] => {
	var unitTypes = assigned.concat(unassigned);

	var unitTypeIdList = unitTypes
		.filter((item: IUnitTypeList) => item.status !== null)
		.map((item: IUnitTypeList) => {
			return {
				unitTypeId: item.code,
				status: item.status
			} as UnitTypeStatusList;
		});

	return unitTypeIdList;
};

export const getAssigned = (unitTypes: UnitTypeForm[]): IUnitTypeList[] => {
	const assigned = unitTypes.filter((u) => u.unitGroupId !== null);
	const mapped = assigned.map((item) => {
		return {
			name: item.name,
			code: item.id,
			status: null
		} as IUnitTypeList;
	});
	return mapped;
};

export const getUnassigned = (unitTypes: UnitTypeForm[]): IUnitTypeList[] => {
	const unAssigned = unitTypes.filter((u) => u.unitGroupId === null);
	const mapped = unAssigned.map((item) => {
		return {
			name: item.name,
			code: item.id,
			status: null
		} as IUnitTypeList;
	});
	return mapped;
};

export const updateAssignedUnitTypes = (
	assignedUnitTypes: IUnitTypeList[],
	selectedUnassignedUnitTypes: IUnitTypeList[]
): IUnitTypeList[] => {
	selectedUnassignedUnitTypes.forEach((item) => {
		item.status = UnitTypeStatus.Assign;
	});
	const updatedAssigned = [...assignedUnitTypes, ...selectedUnassignedUnitTypes];
	return updatedAssigned.sort((a, b) => (a.code < b.code ? -1 : 1));
};

export const updateUnassignedUnitTypes = (
	unAssignedUnitTypes: IUnitTypeList[],
	selectedAssignedUnitTypes: IUnitTypeList[]
): IUnitTypeList[] => {
	selectedAssignedUnitTypes.forEach((item) => {
		item.status = UnitTypeStatus.Unassign;
	});
	const updatedUnassigned = [...unAssignedUnitTypes, ...selectedAssignedUnitTypes];
	return updatedUnassigned.sort((a, b) => (a.code < b.code ? -1 : 1));
};

export const removeItems = (sourceUnitTypes: IUnitTypeList[], itemsToRemove: IUnitTypeList[]): IUnitTypeList[] => {
	itemsToRemove.forEach((item: IUnitTypeList, i) => {
		var index = sourceUnitTypes.findIndex((i) => i.code === item.code);
		sourceUnitTypes.splice(index, 1);
	});
	return sourceUnitTypes;
};

export const getPageActionType = (path: string): string | undefined => {
	const pathInfo = path.split("/");
	if (pathInfo.length > 4 && !isNullOrUndefinedOrEmpty(pathInfo[1])) {
		// const screenName = pathInfo[1];
		let actionName = isNullOrUndefinedOrEmpty(pathInfo[2]) ? undefined : pathInfo[2];
		if (actionName!.toLowerCase() === constants.pageName.unitGroupAdmin.toLowerCase()) {
			return pathInfo[3]!.split("-")[0];
		}

		if (actionName!.toLowerCase() === constants.pageName.bedroom.toLowerCase()) {
			return pathInfo[3]!.split("-")[0];
		}
	}
	return;
};
