import DropdownSelect from "components/controls/DropdownSelect";
import React from "react";
import { useLoadData } from "./useLoadData";
import { useQuery } from "components/forms/PricingAutomation/hooks/useQuery";
import { DropdownOption } from "api";

interface Props {
	handleChange: (value?: DropdownOption) => (key: string) => void;
}

export const ExclusionReasonDropdown = ({ handleChange }: Props) => {
	const { isLoading, options } = useLoadData();
	const searchParams = useQuery();
	const selectedValue = searchParams.get("reason") ?? "";

	return (
		<DropdownSelect
			disabled={isLoading}
			inputId={"Exclusion Reason"}
			inputLabel={"Exclusion Reason"}
			selectedValue={{ key: selectedValue, displayValue: selectedValue }}
			options={options}
			onChange={(value) => handleChange({ key: value?.displayValue })("reason")}
		/>
	);
};
