import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";
import constants from "utils/constants";

export interface MenuProps {
  title?: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

  const createItems = (): MenuItemProps[] => {
    return(
        [

            {
              title: constants.menuTitle.unitGroupMapping.toUpperCase(),
              to: routes.unitGroupAdmin.unitgroup(),
              isActive:
                locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.unitgroup())
            },
            {
                title: constants.menuTitle.manageUnitGroupsAdminCreate.toUpperCase(),
                to: routes.unitGroupAdmin.createUnitGroup(),
                isActive:
                locationUtils.matchPathWithParams(routes.unitGroupAdmin.createUnitGroup())
            }
    
          ]
    )
}

const editItems = (): MenuItemProps[] => {
    return(
        [

            {
              title: constants.menuTitle.unitGroupMapping.toUpperCase(),
              to: routes.unitGroupAdmin.unitgroup(),
              isActive:
                locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.unitgroup())
            },
            {
                title: constants.menuTitle.manageUnitGroupsAdminUpdate.toUpperCase(),
                to: routes.unitGroupAdmin.updateUnitGroup(),
                isActive:
                locationUtils.matchPathWithParams(routes.unitGroupAdmin.updateUnitGroup())
            }
    
          ]
    )
}
  


const UnitGroupMappingCreateMenu: React.FC<MenuProps> = function(props: MenuProps) {
    return <Menu {...props} title="ADMIN" items={createItems()} />;
  };

const UnitGroupMappingUpdateMenu: React.FC<MenuProps> = function(props: MenuProps) {
    return <Menu {...props} title="ADMIN" items={editItems()} />;
  };
  

export {
  UnitGroupMappingCreateMenu,
  UnitGroupMappingUpdateMenu
}