/* eslint-disable react-hooks/exhaustive-deps */
import { useAtAGlanceContext } from "../../../Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

const defaultData = {
	weeksFree: 0,
	amount: 0
};

export const useLoadData = () => {
	const { getAtGlanceConcessionsTile } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["concessions-tile", filters],
		staleTime: Infinity,
		queryFn: ({ signal }) => getAtGlanceConcessionsTile(filters, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { data: data || defaultData, isLoading };
};
