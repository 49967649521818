import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";

const AutomationValidationReportPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdateAutomationValidationReportPageFilterOptions:
      newState.automationValidationReportPageState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdateAutomationValidationReportPageReports:
      newState.automationValidationReportPageState.automationValidationReports = action.payload;
      return newState;
    case PricingPlatformActionTypes.UpdateAutomationValidationReportPageIsLoading:
      newState.automationValidationReportPageState.isLoading = action.payload;
      return newState;
  }
  return newState;
};

export default AutomationValidationReportPageReducer;