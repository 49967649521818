import { DataTable } from "components/controls/DataTable";
import PageSection from "components/controls/PageSection";
import React, { useRef } from "react";
import { ColumnType } from "components/controls/DataTable/types";
import { useExclusionsContext } from "../../Context";
import useStyle from "./useStyle";

interface Props {
	columns: ColumnType[];
	sectionTitle: string;
}

export const TableSection = ({ columns, sectionTitle }: Props) => {
	const hideColumnRef = useRef<HTMLDivElement>(null);
	const { selectedRow } = useExclusionsContext();
	const classes = useStyle();

	const displayedColumns = columns.filter(({ subColumns }) => subColumns?.some(({ field }) => field !== "property"));

	return (
		<PageSection title={sectionTitle} rightContent={<div ref={hideColumnRef} />}>
			<DataTable
				columns={displayedColumns}
				data={[selectedRow]}
				tableName="Price reset table"
				hideColumnsRef={hideColumnRef}
				alwaysShowPaginator={false}
				tableClassName={classes.table}
			/>
		</PageSection>
	);
};
