export enum PricingPlatformActionTypes {
	UpdateUnitPageFilterOptions,
	UpdateUnitPageUnits,
	UpdateUnitCurrentSelections,
	UpdateUnitAppliedSelections,

	PropertiesPage_LoadedFilterOptions,
	PropertiesPage_LoadedProperties,
	PropertiesPage_FetchingProperties,
	PropertiesPage_SavedUnitGroups,

	UpdateUnitAvailabilityPageFilterOptions,
	UpdateUnitAvailabilityPageReports,
	UpdateUnitAvailabilityPageIsLoading,

	UpdateUnitGroupAdminPageIsLoading,

	UnitGroupAdminPageFetchingUnitTypes,
	UnitGroupAdminPageLoadedUnitTypes,
	UnitGroupAdminPageCreateUnitGroups,
	UnitGroupAdminPageUpdateUnitGroups,
	UnitGroupAdminPageDeleteUnitGroups,
	UnitGroupAdminPageFetchingBedroom,
	UnitGroupAdminPageLoadedBedroom,

	UnitGroupAdminPageFetchingUnitGroupMasters,
	UnitGroupAdminPageLoadedUnitGroupMasters,
	UnitGroupAdminLoadedUnitGroupMastersWithBedrooms,
	UnitGroupAdminPageFetchingClassifications,
	UnitGroupAdminPageLoadedClassifications,
	UnitGroupAdminPageLoadClassificationUpdate,
	UnitGroupAdminPageFetchingUnitGroupDetails,
	UnitGroupAdminPageLoadedUnitGroupDetails,

	UnitGroupAdminListBedroomsTableAction,
	UnitGroupAdminListBedroomsDropdownAction,
	UnitGroupAdminListUnitGroupMasterPage,
	UnitGroupAdminPageLoadUnassignedUnitTypes,
	UnitGroupAdminPageSetUnassignedMappings,
	UnitGroupAdminPageUpdateUnitGroupFilterOptionsAction,
	UnitGroupAdminPageUpdateUnassignedUnitTypesFilterOptionsAction,
	UnitGroupAdminPageLoadTableAction,
	UnitGroupAdminPageLoadUnitGroupTableAction,

	UpdateRentSummaryPageFilterOptions,
	UpdateRentSummaryPageReport,
	UpdateRentSummaryPageIsLoading,

	UpdateUnitTableLoading,
	UpdateMatrixPageFilterOptions,
	UpdateCurrentMatrices,
	UpdatePricingMatrixPageLoading,
	UpdateTablePagingState,
	UpdateLoadingState,
	ShowErrorState,
	ShowValidationErrorState,

	PricingAutomationPageFetchingSecondaryReviews,
	PricingAutomationPageLoadedSecondaryReviews,
	PricingAutomationPageFetchingAtAGlance,
	PricingAutomationPageLoadedAtAGlance,
	PricingAutomationPageFetchingExclusionsAction,
	PricingAutomationPageLoadedExclusionsAction,
	PricingAutomationPageFetchingExclusionUITile,
	PricingAutomationPageLoadedExclusionUITile,
	PricingAutomationPageFetchingTopHeaderAction,
	PricingAutomationPageLoadedTopHeaderAction,
	PricingAutomationFilterAction,
	PricingAutomationFetchingSecondaryReviewFilterOptionsAction,
	PricingAutomationLoadedSecondaryReviewFilterOptionsAction,

	UpdateStaleUnitPageFilterOptions,
	UpdateStaleUnitPageReports,
	UpdateStaleUnitPageIsLoading,

	UpdateConcessionsReportPageFilterOptions,
	UpdateConcessionsReportPageReports,
	UpdateConcessionsReportPageIsLoading,

	UpdatePricingAdjustmentSummaryReportPageFilterOptions,
	UpdatePricingAdjustmentSummaryReportPageReports,
	UpdatePricingAdjustmentSummaryReportPageIsLoading,

	UpdateAutomationValidationReportPageFilterOptions,
	UpdateAutomationValidationReportPageReports,
	UpdateAutomationValidationReportPageIsLoading,
}
