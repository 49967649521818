import constants from "utils/constants";
import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { useLoadData } from "./useLoadData";
import { LeadsAndNetChart } from "components/controls/LeadsAndNetChart/Chart";
import { IsDateInRange } from "utils/miscUtils";

interface Props {
	startDate?: Date | undefined;
	endDate?: Date | undefined;
}

export const LeadsAndNet = ({ startDate, endDate }: Props) => {
	const classes = useStyle();
	const { isLoading, data } = useLoadData();

	const FilterDateRange = (value: any) => {
		const date = new Date(value.date);
		return IsDateInRange(date, startDate, endDate);
	}

	const chartData = data.filter(FilterDateRange);

	if (isLoading) {
		return (
			<span
				role="presentation"
				className={[classes.cardContainerSkeleton, classes.cardContainer, "skeleton", "p-card"].join(" ")}
			/>
		);
	}

	return (
		<Card title={constants.cardTitle.leadsAndNetApps} className={classes.cardContainer}>
			<LeadsAndNetChart data={chartData} />
		</Card>
	);
};
