import { createContext, useContext } from "react";

export type SecondaryReviewFilterContextType = {
	selectedRow: Record<string, string | number | any>;
	setSelectedRow: (row: Record<string, string | number>) => void;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
};

export const SECONDARY_REVIEW_DEFAULT: SecondaryReviewFilterContextType = {
	selectedRow: {},
	setSelectedRow: () => null,
	refresh: false,
	setRefresh: () => null
};

export const SecondaryReviewFilterContext = createContext(SECONDARY_REVIEW_DEFAULT);

export const useSecondaryReviewFilterContext = () => {
	const context = useContext(SecondaryReviewFilterContext);

	if (!context) {
		throw new Error("useSecondaryReviewFilterContext used outside SecondaryReviewFilterContext provider");
	}

	return context;
};
