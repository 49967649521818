import React from "react";
import useStyle from "./useStyle";
import { DataTable } from "components/controls/DataTable";
import { columns } from "./columns";
import PageSection from "components/controls/PageSection";

import { useLoadData } from "./useLoadData";

export const ConcessionsGrid = () => {
	const classes = useStyle();
	const { data, isLoading } = useLoadData();

	return (
		<PageSection title={"Concessions"}>
			<div className={classes.concessionsContainer}>
				<DataTable
					data={data}
					columns={columns}
					loading={isLoading}
					tableName="Concessions"
					alwaysShowPaginator={false}
					emptyMessage="No Concessions Found"
				/>
			</div>
		</PageSection>
	);
};
