import { Dialog } from "primereact/dialog";
import React, { useEffect } from "react";
import useStyle from "./useStyle";
import { useIsMutating } from "@tanstack/react-query";
import { PricingButton } from "components/controls/PricingButton";

interface Props {
	open: boolean;
	onClose: () => void;
	level: string;
}

export const SaveConfirmation = ({ open, onClose, level }: Props) => {
	const classes = useStyle();
	const mutating = useIsMutating({ mutationKey: ["update-save-parameters"] });

	useEffect(() => {
		if (mutating === 0) onClose();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mutating]);

	return (
		<Dialog visible={open} header={"Save confirmation"} focusOnShow={false} onHide={onClose}>
			<div className={classes.confirmationContainer}>
				<p className={classes.message}>
					Are you sure you want to <strong>Save</strong> all changes for <strong>{level}</strong>?
				</p>
			</div>
			<div className={classes.buttonsContainer}>
				<PricingButton variant="primary" isLoading={mutating > 0} form="configureAutomationForm" type="submit">
					Save
				</PricingButton>
				<PricingButton variant="secondary" disabled={mutating > 0} type="button" onClick={onClose}>
					Cancel
				</PricingButton>
			</div>
		</Dialog>
	);
};
