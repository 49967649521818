import React, { useEffect } from "react";
import useStyle from "./useStyle";
import { Toast } from "primereact/toast";
import MessageUtils, { MessageInfo, MessageSeverity } from "utils/messageUtils";

export interface MessageAreaInfo {
	severity: MessageSeverity;
	summary: string;
	detail: string;
	sticky?: boolean | undefined;
}

interface MessageAreaProps {
	message: MessageAreaInfo;
	nonce?: number;
	children?: React.ReactNode;
	theme?: any;
}

const useMessageAreaShowMessageEffect = function (callback: () => void, dependencies: any[]) {
	useEffect(callback, dependencies);
};

const MessageArea: React.FC<MessageAreaProps> = function (props) {
	const classes = useStyle(props);
	let messagesRef: Toast | null = null;
	const messages = <Toast ref={(el) => (messagesRef = el)} />;

	useMessageAreaShowMessageEffect(() => {
		if (!messagesRef) {
			return;
		}

		messagesRef.clear();
		messagesRef.show([{ ...props.message }]);
	}, [props.nonce]);

	return <div className={classes.root}>{messages}</div>;
};

const MessageAreaUtils = {
	currentNonce: 0,
	getNextNonce: function () {
		return this.currentNonce++;
	},
	getMessage: function (message: MessageInfo) {
		return (
			<MessageArea
				message={{
					sticky: true,
					...message
				}}
				nonce={this.getNextNonce()}
			/>
		);
	},
	getGenericSaveSuccessMessageArea: function () {
		return this.getMessage(MessageUtils.getGenericSaveSuccessMessage());
	},
	getGenericErrorMessage: function () {
		return this.getMessage(MessageUtils.getGenericErrorMessage());
	},
	getGenericValidationMessage: function (customMessage?: string | undefined) {
		return this.getMessage(MessageUtils.getGenericValidationMessage(customMessage));
	},
	getCustomMessage: function (
		customTitle: string,
		customMessage: string,
		severityLevel: MessageSeverity,
		autoHideMessage: boolean
	) {
		return this.getMessage(MessageUtils.getCustomMessage(customTitle, customMessage, severityLevel, autoHideMessage));
	},
	getCustomMessageBasedOnError: function (
		title: string,
		message: string
	) {
		return this.getMessage(MessageUtils.getCustomMessage(title, message, "error", false));
	}
	
};

export { MessageArea as MessageAreaBasic };

export default MessageAreaUtils;
