export type MessageSeverity = "success" | "info" | "warn" | "error";
export const MessageSeverityLevel = {
	Success: "success",
	Info: "info",
	Warning: "warn",
	Error: "error"
};
export interface MessageInfo {
	severity: MessageSeverity;
	summary: string;
	detail: string;
	sticky?: boolean | undefined;
	life?: number | undefined;
}

const MessageUtils = {
	getGenericErrorMessage: function (): MessageInfo {
		return {
			severity: MessageSeverityLevel.Error as MessageSeverity,
			summary: "Sorry - We Experienced an Error",
			detail: "Please try again or reload the page to continue.",
			sticky: true
		};
	},

	getGenericSaveSuccessMessage: function (): MessageInfo {
		return {
			severity: MessageSeverityLevel.Success as MessageSeverity,
			summary: "Success",
			detail: "Your changes have been saved successfully.",
			sticky: false,
			life: 5000
		};
	},

	getGenericValidationMessage: function (customMessage?: string | undefined): MessageInfo {
		return {
			severity: MessageSeverityLevel.Error as MessageSeverity,
			summary: "Save Failed Due to Validation Errors",
			detail: `Please fix the errors below and try again${customMessage ? " - " + customMessage : ""}`,
			sticky: false,
			life: 5000
		};
	},

	getCustomMessage: function (
		customTitle: string,
		customMessage: string,
		severityLevel: MessageSeverity,
		autoHideMessage: boolean
	): MessageInfo {
		return {
			severity: severityLevel,
			summary: customTitle,
			detail: customMessage,
			sticky: !autoHideMessage,
			life: 5000
		};
	}
};

export default MessageUtils;
