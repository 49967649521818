import { StaleUnitClient, StaleUnitReportResponse, StaleUnitReportRequest, StaleUnitReportFilterResponse } from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";

export const StaleUnitPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {
		const getForm = function (currentDivision: StaleUnitReportRequest): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, StaleUnitClient, "getStaleUnitReportFilters");

			makeRemoteRequest(filterOptionsEndpoint, [currentDivision]).then((response) => {
				var options = response.data as StaleUnitReportFilterResponse;
				dispatcher({
					type: PricingPlatformActionTypes.UpdateStaleUnitPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const updateFilterOptions = function (currentSelections: StaleUnitReportRequest): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, StaleUnitClient, "getStaleUnitReportFilters");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as StaleUnitReportFilterResponse;

				dispatcher({
					type: PricingPlatformActionTypes.UpdateStaleUnitPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const searchProperty = function (currentFilterSelected: StaleUnitReportRequest): void {
			let apiEndpoint = getApiEndpoint(identity, StaleUnitClient, "getStaleUnitReport");

			let reportRequest = currentFilterSelected;

			dispatcher({
				type: PricingPlatformActionTypes.UpdateStaleUnitPageIsLoading,
				payload: true
			});

			makeRemoteRequest(apiEndpoint, [reportRequest]).then((response) => {
				dispatcher({
					type: PricingPlatformActionTypes.UpdateStaleUnitPageReports,
					payload: response.data as StaleUnitReportResponse[]
				});
				dispatcher({
					type: PricingPlatformActionTypes.UpdateStaleUnitPageIsLoading,
					payload: false
				});
			});
		};

		return {
			getForm,
			searchProperty,
			updateFilterOptions
		};
	}
};
