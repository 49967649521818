import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";

const ConcessionsReportPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdateConcessionsReportPageFilterOptions:
      newState.concessionsReportPageState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdateConcessionsReportPageReports:
      newState.concessionsReportPageState.concessionsReports = action.payload;
      return newState;
    case PricingPlatformActionTypes.UpdateConcessionsReportPageIsLoading:
      newState.concessionsReportPageState.isLoading = action.payload;
      return newState;
  }
  return newState;
};

export default ConcessionsReportPageReducer;