import {
	PricingAdjustmentSummaryReportFilterResponse,
	PricingAdjustmentReportClient,
	PricingAdjustmentSummaryReportRequest,
	PricingAdjustmentSummaryReportResponse
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";

export const PricingAdjustmentSummaryReportPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {

		const getForm = function (currentDivision: PricingAdjustmentSummaryReportRequest): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, PricingAdjustmentReportClient, "getReportFilters");

			makeRemoteRequest(filterOptionsEndpoint, [currentDivision]).then((response) => {
				var options = response.data as PricingAdjustmentSummaryReportFilterResponse;
				dispatcher({
					type: PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const updateFilterOptions = function (currentSelections: PricingAdjustmentSummaryReportRequest): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, PricingAdjustmentReportClient, "getReportFilters");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as PricingAdjustmentSummaryReportFilterResponse;
				dispatcher({
					type: PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageFilterOptions,
					updatedOptions: options
				});
			});
		};

		const searchReportData = function (currentFilterSelected: PricingAdjustmentSummaryReportRequest): void {
			let apiEndpoint = getApiEndpoint(identity, PricingAdjustmentReportClient, "getData");

			let propertyIdList = currentFilterSelected;

			dispatcher({
				type: PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageIsLoading,
				payload: true
			});

			makeRemoteRequest(apiEndpoint, [propertyIdList]).then((response) => {
				dispatcher({
					type: PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageReports,
					payload: response.data as PricingAdjustmentSummaryReportResponse[]
				});
				dispatcher({
					type: PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageIsLoading,
					payload: false
				});
			});
		};

		return {
			getForm,
			searchReportData,
			updateFilterOptions
		};
	}
};
