import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	container: {
		display: "flex",
		paddingRight: theme.marginScale.large,
		"@media (max-width: 768px)": {
			paddingLeft: theme.marginScale.medium,
			paddingRight: theme.marginScale.small
		},
		flexDirection: "row",
		backgroundColor: theme.palette.white,
		height: theme.navHeight,
		minHeight: theme.navHeight,
		maxHeight: theme.navHeight,
		width: "100%",
		position: "sticky",
		top: 0,
		zIndex: 10
	},
	buttons: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		gap: "1px",
		flexGrow: 1,
		marginLeft: theme.marginScale.xlarge
	},
	logoWrapper: {
		margin: theme.marginScale.large
	},
	logo: {
		height: "27px"
	},
	outlined: {
		fontSize: "18px",
		border: `2px solid ${theme.palette.transparent}`,
		padding: "8px",
		borderRadius: "50%"
	},
	menu: {
		marginTop: "8px",
		top: "54px !important"
	},
	mobileDrawer: {
		marginTop: "8px",

		"@media (min-width: 768px)": {
			display: "none"
		}
	},
	drawerContainer: {
		backgroundColor: theme.palette.darkBlue,
		"& [class=p-sidebar-icons]": {
			marginBottom: theme.marginScale.large
		}
	}
}));
