import React from "react";
import useStyle from "./useStyle";
import { ProgressSpinner } from "primereact/progressspinner";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	variant: "primary" | "secondary";
	isLoading?: boolean;
}

export const PricingButton = ({ isLoading, variant = "primary", children, ...props }: Props) => {
	const classes = useStyle();
	const buttonClass =
		variant === "primary"
			? ["p-button p-button-rounded", classes.submitButton].join(" ")
			: ["p-button p-button-rounded p-button-text", classes.textButton].join(" ");
	return (
		<button disabled={isLoading} className={[buttonClass, props.className].join(" ")} {...props}>
			{isLoading && <ProgressSpinner className={classes.loadingIcon} />}
			{children}
		</button>
	);
};
