/* eslint-disable react-hooks/exhaustive-deps */
import { useSecondaryReviewFilterContext } from "../../Context";
import {
	leasingActivityMapperUnitGroup
} from "../../../Home/Glance/LeasingActivity/leasingActivityMapper";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getLeasingActivity } = useHomeService();
	const { selectedRow } = useSecondaryReviewFilterContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["leasing-activity", selectedRow],
		queryFn: ({ signal }) => getLeasingActivity({ divisionId: "", unitGroupId: String(selectedRow?.group) }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { data: leasingActivityMapperUnitGroup(data || {}), isLoading };
};
