import React from "react";
import { useQuery } from "components/forms/PricingAutomation/hooks/useQuery";
import { DropdownOption } from "api";
import constants from "utils/constants";
import DropdownMultiSelect from "components/controls/DropdownMultiSelect";

interface Props {
	options?: DropdownOption[];
	isLoading?: boolean;
	handleChange: (value?: DropdownOption[]) => (key: string) => void;
}

export const ReviewReasonDropdown = ({ options = [], isLoading, handleChange }: Props) => {
	const searchParams = useQuery();
	const reasonId = searchParams.get("id") ?? "";
	const selectedValues = options?.filter((x) => reasonId.toLowerCase().includes(x.key?.toLowerCase() || ""));

	return (
		<DropdownMultiSelect
			disabled={isLoading}
			inputId={constants.filterLabels.reviewReasonDropdown}
			inputLabel={constants.filterLabels.reviewReason}
			selectedValues={selectedValues}
			options={options}
			onChange={(value) => handleChange(value)("id")}
			filter
		/>
	);
};
