import React from "react";
import useStyle from "./useStyle";
import { TextField } from "../TextField";
import type { FieldErrors } from "react-hook-form";
import { onlyNumber } from "utils/miscUtils";

interface Props {
	initialMin: number | string;
	initialMax: number | string;
	nameMin?: string;
	nameMax?: string;
	divider?: string;
	errors?: FieldErrors;
	handleDirtyFields: () => void;
}

export const RangeInput = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
	const classes = useStyle();

	return (
		<div className={classes.container}>
			<label htmlFor={props.nameMin} hidden>
				Min
			</label>
			<TextField
				ref={ref}
				label="Min"
				onKeyDown={onlyNumber}
				onChangeCapture={props.handleDirtyFields}
				id={props.nameMin}
				errors={props.errors}
				name={props.nameMin}
				defaultValue={props.initialMin}
			/>
			{props.divider}
			<label htmlFor={props.nameMax} hidden>
				Max
			</label>
			<TextField
				ref={ref}
				label="Max"
				onKeyDown={onlyNumber}
				onChangeCapture={props.handleDirtyFields}
				id={props.nameMax}
				errors={props.errors}
				name={props.nameMax}
				defaultValue={props.initialMax}
			/>
		</div>
	);
});
