import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	container: {
		maxWidth: theme.maxScreenWidth,
		width: "100%",
		height: "100%",
		minHeight: "100%",
		margin: "0 auto",
		boxSizing: "unset"
	},
	title: {
		textTransform: "uppercase",
		borderLeft: `8px solid ${theme.palette.primary}`,
		paddingLeft: "8px",
		fontSize: "20px",
		margin: "0",
		color: theme.palette.darkGray,
		whiteSpace: "nowrap"
	},
	header: {
		display: "flex",
		"@media screen and (max-width: 830px)": {
			flexDirection: "column",
			alignItems: "flex-start"
		},
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: `2px solid ${theme.palette.lightGray}`,
		padding: "12px 0",
		gap: "20px"
	},
	actionsContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		height: "100%",
		fontSize: "14px"
	},
	actionGroup: {
		display: "flex",
		gap: "8px",
		alignItems: "center",
		"@media (max-width: 768px)": {
			flexDirection: "column",
			alignItems: "start"
		}
	}
}));
