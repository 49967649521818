import React, { useState } from "react";
import useStyle from "./useStyle";
import format from "date-fns/format";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption, ConcessionsReportRequest } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import MultiSelect from "components/controls/MultiSelect";
import constants from "utils/constants";
import { formatDecimalNumber } from "utils/miscUtils";

const ConcessionsReportPage: React.FC = function () {
	const classes = useStyle();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.concessionsReportPageState.filterOptions,
		concessionsReportPageReports: appState.concessionsReportPageState.concessionsReports,
		isLoading: appState.concessionsReportPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.concessionsReportPage,
		...appActions
	};

	//does this not need to use the store because we defined directly here?
	interface LocalState {
		currentFilters: ConcessionsReportRequest;
		appliedFilters: ConcessionsReportRequest;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getForm(localState.currentFilters);
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.division = division;
		curSelections.property = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.property = properties;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function applySearchDisabled() {
		return !localState.currentFilters.division && !localState.currentFilters.property;
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		setLocalState({
			...localState,
			appliedFilters: curSelections
		});

		actions.searchConcessions(curSelections);
	}

	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		}
		if (applySearchDisabled() === true) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	function applyExport() {
		window.print();
	}

	const displayReport = () => {
		if (pageState.concessionsReportPageReports !== null && pageState.concessionsReportPageReports !== undefined) {
			if (pageState.concessionsReportPageReports.length === 0) {
				return (
					<div className={classes.empty}>
						<h3>No Concessions</h3>
					</div>
				);
			}

			return (
				<div className={classes.reportHeading} style={{ pageBreakAfter: "always" }}>
					<div id="unitTypeHeadingMargin2">
						<DataTable value={pageState.concessionsReportPageReports}>
							<Column field="division" header="Division"></Column>
							<Column field="propertyName" header="Property Name"></Column>
							<Column field="unitGroup" header="Unit Group"></Column>
							<Column field="concession" header="Concession"></Column>
							<Column
								field="startDate"
								headerStyle={{ textAlign: "center" }}
								bodyStyle={{ textAlign: "center" }}
								body={(e: any) => {
									if (e.startDate && e.startDate !== "") {
										return format(new Date(e.startDate), "M/d/yyyy");
									} else {
										return "N/A";
									}
								}}
								header="Start Date"
							></Column>
							<Column
								field="endDate"
								headerStyle={{ textAlign: "center" }}
								bodyStyle={{ textAlign: "center" }}
								body={(e: any) => {
									if (e.endDate && e.endDate !== "") {
										return format(new Date(e.endDate), "M/d/yyyy");
									} else {
										return "N/A";
									}
								}}
								header="End Date"
							></Column>
							<Column field="termOfUse" header="Term Of Use"></Column>
							<Column
								field="leasesNeeded"
								style={{ width: "5%" }}
								bodyStyle={{ textAlign: "center" }}
								headerStyle={{ textAlign: "center" }}
								body={(e: any) => formatDecimalNumber(e.leasesNeeded) || "N/A"}
								header="Leases Needed"
							></Column>
						</DataTable>
					</div>
				</div>
			);
		} else {
			if (localState.currentFilters.property === null || localState.currentFilters.property === undefined) {
				return <div className={classes.empty}>Please Select A Property Using The Filters Above</div>;
			}
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.concessionsReport} />}>
			<div id="concessionsReportFilters" className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={localState.currentFilters.division}
						options={pageState.filterOptions!.divisions}
						onChange={(d) => onSelectDivision(d)}
						disabled={pageState.filterOptions!.divisions === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"Select properties"}
						selectedValues={localState.currentFilters.property}
						options={pageState.filterOptions!.properties}
						onChange={(d) => onSelectProperties(d)}
						disabled={pageState.filterOptions!.properties === undefined}
						hideClear={false}
						filter
						style={{ width: 350 }}
					></MultiSelect>
				</div>
				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled()}
				></Button>
				<div className={classes.exportButton}>
					<Button
						label="Export"
						className={classes.applyButton}
						onClick={() => applyExport()}
						disabled={applySearchDisabled()}
					></Button>
				</div>
			</div>
			<div id="contentContainer" className={classes.contentContainer}>
				{pageState.isLoading ? <ProgressSpinner /> : <div className={classes.pageHeading}> {displayReport()}</div>}
			</div>
		</Page>
	);
};

export default ConcessionsReportPage;
