import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";
// import { initialPricingPlatformState } from "../usePricingPlatformStore";
// import utils from "utils";
// import {
//   UnitFilterOptions
// } from "api";

const UnitPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdateUnitPageFilterOptions:
      newState.unitPageState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdateUnitPageUnits:
      newState.unitPageState.units = action.updatedUnits;
      return newState;
    case PricingPlatformActionTypes.UpdateUnitCurrentSelections:
      newState.unitPageState.currentFilterSelected = action.updatedSelections;
      return newState;
    case PricingPlatformActionTypes.UpdateUnitAppliedSelections:
      newState.unitPageState.appliedFilterSelected = action.updatedSelections;
      return newState;
    case PricingPlatformActionTypes.UpdateUnitTableLoading:
      newState.unitPageState.tableLoading = action.payload;
      return newState;
  }
  return newState;
};

export default UnitPageReducer;