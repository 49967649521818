import {
	UnitDropdownSelections,
	UnitResultForm,
	UnitFilterOptions,
	PricingMatrixFilterOptions,
	PagedResultOfPropertySummary,
	PricingMatrixPropertyForm,
	UnitAvailabilityPage,
	UnitGroupSummary,
	PropertySummary,
	PropertySearchFilterOptions,
	PagedResultOfProperty,
	UnitType,
	UnitGroupAdminResultForm,
	UnitGroupAdminDropdownSelections,
	Bedroom,
	UnitGroupMaster,
	ClassificationForm,
	UnassignedUnitTypePageForm,
	UnitGroupAdminDetailForm,
	UnitGroupMasterPageForm,
	UnitGroupAdminDeleteResultForm,
	BedroomForm,
	UnitGroupAdminSummaryPageForm,
	SecondaryReviewListForm,
	StaleUnitReportFilterResponse,
	StaleUnitReportResponse,
	ConcessionsReportFilterResponse,
	ConcessionsReportResponse,
	PricingAdjustmentSummaryReportFilterResponse,
	PricingAdjustmentSummaryReportResponse,
	PriceAdjustmentResponse,
	AutomatedPricingRun
} from "api";
import { MessageInfo } from "utils/messageUtils";
import { IPagedTableState } from "components/controls/createTable";
import utils from "utils";
import { PropertySearchState, UpdatedUnitGroupDelta } from "./PropertiesPage/propertyPageInterfaces";
import { DropdownOption } from "components/controls/MultiSelect";
import { ExceptionsSearchState } from "./ExceptionsPage/exceptionsPageInterfaces";

export interface PricingPlatformState {
	unitPageState: UnitPageState;
	propertiesPageState: PropertiesPageState;
	exceptionsPageState: ExceptionsPageState;
	pricingMatrixState: PricingMatrixState;
	unitAvailabilityPageState: UnitAvailabilityPageState;
	unitGroupAdminPageState: UnitGroupAdminPageState;
	rentSummaryPageState: RentSummaryPageState;
	messageState: MessageState;
	loadingState: LoadingState;
	tableState: TableState;
	staleUnitPageState: StaleUnitPageState;
	concessionsReportPageState: ConcessionsReportPageState;
	pricingAdjustmentSummaryReportPageState: PricingAdjustmentSummaryReportPageState;
	automationValidationReportPageState: AutomationValidationReportPageState;
}

export interface PropertyDropdownSelections {
	selectedDivision?: DropdownOption | undefined;
	selectedCollection?: DropdownOption | undefined;
	selectedProperties?: DropdownOption[] | undefined;
	selectedUnitGroup?: DropdownOption | undefined;
	selectedUnit?: DropdownOption | undefined;
	selectedCounty?: DropdownOption | undefined;
}
export interface UnitPageState {
	filterOptions: UnitFilterOptions | null | undefined;
	units: UnitResultForm | null | undefined;
	currentFilterSelected: UnitDropdownSelections;
	appliedFilterSelected: UnitDropdownSelections;
	tableLoading: boolean;
}

export interface UnitGroupAdminPageState {
	unitGroupFilterOptions: UnitFilterOptions | null | undefined;
	unassignedUnitTypeFilterOptions: UnitFilterOptions | null | undefined;
	unitTypes: UnitType[];
	unassignedUnitTypes: UnassignedUnitTypePageForm;
	unassignedUnitTypeMapping: UnassignedUnitTypeMapping[];
	unitGroup: UnitGroupAdminResultForm;
	deleteUnitGroup: UnitGroupAdminDeleteResultForm;
	unitGroupMasters: UnitGroupMaster[];
	unitGroupMastersWithBedrooms: UnitGroupMaster[];
	unitGroupDetails: UnitGroupAdminDetailForm;
	currentFilterSelected: UnitGroupAdminDropdownSelections;
	currentUnassignedFilterSelected: UnitGroupAdminDropdownSelections;
	currentUnitGroupFilterSelected: UnitGroupAdminDropdownSelections;
	pagedUnitGroups: UnitGroupAdminSummaryPageForm;
	unitGroupBedroom: Bedroom;
	unitGroupBedrooms: BedroomForm[];
	bedroomDropdowns: DropdownOption[];
	unitGroupMasterPage: UnitGroupMasterPageForm;
	isLoading: boolean;
	tableLoading: boolean;
	classifications: ClassificationForm[];
	updateClassification: ClassificationForm;
}

export interface PropertiesPageState {
	filterOptions: PropertySearchFilterOptions | null | undefined;
	currentFilters: UnitDropdownSelections;
	pagedProperties: PagedResultOfPropertySummary;
	pageLoading: boolean;
	//unused - for now...
	savedUnitGroups: UnitGroupSummary[];
	propertySearchState: PropertySearchState | null | undefined;
	pageIsDirty: boolean;
	pageIsSaving: boolean;
	dirtyUnitGroups: UpdatedUnitGroupDelta[];
	expandedRows: {} | undefined;
}
export interface PricingAutomationPageState {
	pagedSecondaryReviews: SecondaryReviewListForm;
	pageLoading: boolean;
	tableLoading: boolean;
}

export interface ExceptionsPageState {
	filterOptions: PagedResultOfProperty | null | undefined;
	currentFilters: UnitDropdownSelections;
	pagedProperties: PagedResultOfProperty;
	pageLoading: boolean;
	exceptionsSearchState: ExceptionsSearchState | null | undefined;
	//unused - for now...
	pageIsDirty: boolean;
	pageIsSaving: boolean;
}

export interface PricingMatrixState {
	filterOptions: PricingMatrixFilterOptions | null | undefined;
	currentMatrices: PricingMatrixPropertyForm[] | null | undefined;
	pageLoading: boolean;
}

export interface UnitAvailabilityPageState {
	filterOptions: UnitFilterOptions | null | undefined;
	unitAvailabilityReports: UnitAvailabilityPage[] | null | undefined;
	isLoading: boolean;
}

export interface RentSummaryPageState {
	filterOptions: UnitFilterOptions | null | undefined;
	propertyReport: PropertySummary | null | undefined;
	isLoading: boolean;
}

export interface MessageState {
	messages: MessageInfo[];
}

export interface LoadingState {
	isLoading: boolean;
}

export interface UnassignedUnitTypeMapping {
	unitTypeId: string | undefined;
	selectedUnitGroup: DropdownOption | undefined;
}

export interface UnitTypeUnitGroupMapping {
	unitTypeId: string;
	unitGroupId: string;
}

interface TableState {
	pagingState: IPagedTableState;
}

export interface ExpandableTableState extends TableState {
	expandedRows: any[];
}

export enum ExceptionFilterEnum {
	Property = 1,
	Unit = 2,
	All = 3
}

export interface ExceptionFilterOption {
	name: string;
	type: ExceptionFilterEnum;
}

export interface ConcessionsReportPageState {
	filterOptions: ConcessionsReportFilterResponse | null | undefined;
	concessionsReports: ConcessionsReportResponse[] | null | undefined;
	isLoading: boolean;
}

// export interface BestRenewalOfferFieldValues {
//   field: keyof BestRenewalOffersFormOffer;
//   values: string[];
// }

// /* BEST RENEWAL OFFERS STATE */

// export interface SidebarBatchNotesDisplayState {
//   isVisible: boolean;
//   batchComments?: BestRenewalOffersFormBatchComments;
// }

// export interface SidebarColumnSelectorDisplayState {
//   isVisible: boolean;
//   columnConfigurations: BestRenewalOfferColumnConfiguration[];
// }

export interface PricingPlatformHistory {
	push: (url: string) => void;
}

export const getMutableCopyOfPricingPlatformState = function (state: PricingPlatformState): PricingPlatformState {
	return utils.cloneObject(state);
};

export interface StaleUnitPageState {
	filterOptions: StaleUnitReportFilterResponse | null | undefined;
	staleUnitReports: StaleUnitReportResponse[] | null | undefined;
	isLoading: boolean;
}

export interface PricingAdjustmentSummaryReportPageState {
	filterOptions: PricingAdjustmentSummaryReportFilterResponse | null | undefined;
	pricingAdjustmentSummaryReports: PricingAdjustmentSummaryReportResponse[] | null | undefined;
	isLoading: boolean;
}

export interface AutomationValidationReportPageState {
	filterOptions: PriceAdjustmentResponse | null | undefined;
	automationValidationReports:  AutomatedPricingRun[] | undefined;
	isLoading: boolean;
}