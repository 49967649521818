import { useState } from "react";
import constants from "utils/constants";

export interface SearchState {
	skip: number;
	take: number;
	rows: number;
	page: number;
	totalRecords: number;
	sortOrder: number;
	sortField: string;
}

export const defaultSearchState: SearchState = {
	skip: 0,
	take: 10,
    sortField: "id",
	sortOrder: -1,
	rows: constants.rowsPerPageOptions[0],
	page: 0,
	totalRecords: 0
};

export const useSearchState = () => {
	const [searchState, setSearchState] = useState<SearchState>({ ...defaultSearchState });

	const onPage = (e: any) => {
		setSearchState((prevState) => ({
			...prevState,
			skip: e.first,
			take: e.rows,
			page: e.page
		}));
	};

	const onSort = (e: any) => {
		setSearchState({
			...searchState,
			sortField: e.sortField,
			sortOrder: e.sortOrder
		});
	};

	return { searchState, onPage, onSort };
};
