import React from "react";
import { DeleteConfirmation } from "../Confirmations/DeleteConfirmation";
import { SaveConfirmation } from "../Confirmations/SaveConfirmation";
import { CancelConfirmation } from "../Confirmations/CancelConfirmation";
import { useActionsContext } from "./Context/context";
import { DeleteColumnConfirmation } from "../Confirmations/DeleteColumnConfirmation";

export const Actions = () => {
	const { confirmation, data, handleClose } = useActionsContext();
	return (
		<>
			<DeleteConfirmation onClose={handleClose} open={confirmation === "delete"} data={data} />
			<DeleteColumnConfirmation onClose={handleClose} open={confirmation === "delete-column"} data={data} />
			<SaveConfirmation onClose={handleClose} open={confirmation === "save"} level={data["name"] ?? ""} />
			<CancelConfirmation onClose={handleClose} open={confirmation === "cancel"} level={data["name"] ?? ""} />
		</>
	);
};
