import React, { useState } from "react";
import Spinner from "./controls/Spinner";
import ConfigClient, {
  Config,
  DefaultConfigConstant
} from "./ConfigClient";
import useMountEffect from "utils/useMountEffect";

export const PricingPlatformConfigContext = React.createContext<Config>(
  DefaultConfigConstant
);
interface PricingPlatformConfigProviderProps {
  children: any | any[];
}

const PricingPlatformConfigProvider: React.FC<PricingPlatformConfigProviderProps> = function(
  props: PricingPlatformConfigProviderProps
) {
  const [config, setConfig] = useState<Config>({} as Config);

  useMountEffect(() => {
    async function retrieveConfig() {
      const configClient = new ConfigClient();
      const config = await configClient.getConfig();

      setConfig({ ...config, isLoaded: true });
    }

    if (!config.isLoaded) {
      retrieveConfig();
    }
  });

  if (!config.isLoaded) {
    return <Spinner isVisible={true} />;
  }

  return (
    <PricingPlatformConfigContext.Provider value={config}>
      {props.children}
    </PricingPlatformConfigContext.Provider>
  );
};

export default PricingPlatformConfigProvider;
