import { useState } from "react";
import { useExclusionsContext } from "../Context";

export const usePopup = () => {
	const [open, setOpen] = useState(false);
	const { setSelectedRow } = useExclusionsContext();

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = ({ e, completed }: { e?: any; completed?: boolean }) => {
		if (typeof e !== "undefined") {
			setSelectedRow({ ...e.data, completed });
		}
		setOpen(true);
	};	

	return { open, handleClose, handleOpen };
};
