import React from "react";
import useStyle from "./useStyle";
import { Fields } from "./FormFields";
import { FormProvider } from "react-hook-form";
import { useOverrideForm } from "./useOverrideForm";
import { PricingButton } from "components/controls/PricingButton";

interface Props {
	onClose: () => void;
}

export const NewOverrideForm = ({ onClose }: Props) => {
	const classes = useStyle();
	const { methods, levels, onSubmit } = useOverrideForm();

	return (
		<FormProvider {...methods}>
			<form id="newOverrideForm">
				<div className={classes.centerText}>
					<input ref={methods.register} name="exist" defaultValue={levels} hidden={true} />
					{methods.errors?.["exist"] && (
						<small className={classes.errorMessage}>{methods.errors["exist"].message}</small>
					)}
				</div>
				<Fields />
				<div className={classes.formButtons}>
					<PricingButton
						variant="primary"
						form="newOverrideForm"
						onClick={methods.handleSubmit((data) => {
							onClose();
							onSubmit(data);
						})}
						type="button"
					>
						Add
					</PricingButton>
					<PricingButton variant="secondary" type="button" form="newOverrideForm" onClick={onClose}>
						Cancel
					</PricingButton>
				</div>
			</form>
		</FormProvider>
	);
};
