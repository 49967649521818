/* eslint-disable react-hooks/exhaustive-deps */
import { useMapFilterKeys } from "./useMapFilterKeys";
import { useQuery } from "@tanstack/react-query";
import { useExclusionsService } from "components/forms/PricingAutomation/services/exclusions.service";

const defaultFilterOptions = {
	divisions: [],
	counties: [],
	properties: [],
	unitGroups: [],
	manualExclusion: [],
};

export const useLoadData = () => {
	const { classificationType, isSmallUnitGroup, isManualExcluded, ...mappedFilters } = useMapFilterKeys();
	const { getExclusionsFilters } = useExclusionsService();
	const payload = { ...mappedFilters };
	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		queryKey: ["exclusion-filters", payload],
		queryFn: async ({ signal }) => getExclusionsFilters({ ...payload }, signal)
	});

	return { filterOptions: data || defaultFilterOptions, selected: mappedFilters, isInitialLoading };
};
