import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	cardContainer: {
		overflow: "hidden",
		minHeight: 310,
		gridColumn: "span 4",
		gridRow: "span 2",
		borderRadius: "8px",
		height: "max-content",
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			margin: "0px"
		},
		"& [class$=p-card-body]": {
			paddingBottom: "4px 0px"
		},
		"& [class$=p-card-content]": {
			padding: "0px"
		},
		"@media (max-width: 768px)": {
			overflowX: "scroll"
		}
	}
}));
