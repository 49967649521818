import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { 
	getMutableCopyOfPricingPlatformState, 
	PricingPlatformState } 
from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";
const PropertiesPageReducer = (
	oldState: PricingPlatformState,
	action: PricingPlatformActions
): PricingPlatformState => {
	const newState = getMutableCopyOfPricingPlatformState(oldState);

	//console.log("updated state in properties page reducer: ", action.type, oldState, newState);

	switch (action.type) {
		case PricingPlatformActionTypes.PropertiesPage_LoadedFilterOptions:
			newState.propertiesPageState.filterOptions = action.updatedOptions;
			return newState;

		case PricingPlatformActionTypes.PropertiesPage_LoadedProperties:
			newState.propertiesPageState.pagedProperties = action.pagedResult;
			return newState;

		case PricingPlatformActionTypes.PropertiesPage_FetchingProperties:
			newState.propertiesPageState.pageLoading = action.payload;
			return newState;

		case PricingPlatformActionTypes.PropertiesPage_SavedUnitGroups:
			newState.propertiesPageState.savedUnitGroups = action.savedUnitGroups;
			return newState;
	}
	return newState;
};

export default PropertiesPageReducer;
