import React from "react";
import { InfoCard } from "./InfoCard";
import useStyle from "./useStyle";
import { useLoadData } from "./useLoadData";
import { percentageFields } from "./fields";
import { convertPercentage } from "utils/miscUtils";

export const AtAGlanceParameters = () => {
	const classes = useStyle();
	const { atGlanceParametersList, isLoading } = useLoadData();

	//TODO: Update length to 6 when leasing velocity is ready
	if (isLoading) {
		return (
			<>
				{Array.from({ length: 5 }).map((_, index) => (
					<span key={index} className={["skeleton", "p-card"].join(" ")} />
				))}
			</>
		);
	}

	return (
		<>
			{atGlanceParametersList?.map(({ footer, title = "", footerFormat, value }) => (
				<InfoCard key={title} title={title} footer={footer} footerFormat={footerFormat}>
					<p className={classes.textContent}>
						{percentageFields.values.includes(title)
							? `${convertPercentage(value)}%`
							: value?.toLocaleString("en-US")}
					</p>
				</InfoCard>
			))}
		</>
	);
};
