import React, { useEffect, useState } from "react";

import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/components/button/Button";

import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import VisibilityToggle from "components/controls/VisibilityToggle";
import Page from "components/layouts/Page";
import Spacer from "components/controls/Spacer";
import useMessageArea from "components/useMessageArea";
import MultiSelect from "components/controls/MultiSelect";
import usePricingPlatformStore, {
	initialAdvancedFilters,
	initialPropertiesPageSelectedFilters
} from "components/store/usePricingPlatformStore";
import { AdvancedFilters, UpdatedUnitGroupDelta } from "components/store/PropertiesPage/propertyPageInterfaces";
import { ExceptionsPageProps } from "components/store/ExceptionsPage/exceptionsPageInterfaces";
import { PropertyDropdownSelections } from "components/store/PricingPlatformStateTypes";
import { getInitParams, getSearchQuery, updateFilters } from "./ExceptionsSearchFilter";
import PropertyExceptions from "./PropertyExceptions";
import RequirePermission from "../RequirePermission";
import Menu from "../Menu";
import { ChangeActionTypes } from "utils/enums";
import constants from "utils/constants";

import "./_exceptions-page.scss";
import { ExceptionsSearchQuery } from "api/pricing-platform-api";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";

interface ChangeAction {
	type?: ChangeActionTypes;
	delta?: UpdatedUnitGroupDelta;
	propertyId?: string;
}

const ExceptionsPage: React.FC<ExceptionsPageProps> = function (props: any) {
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.propertiesPageState };
	const actions = { ...appActions.propertiesPage };
	const [messageAreaState, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const [localState, setLocalState] = useState({
		currentFilters: initialPropertiesPageSelectedFilters,
		advancedFilters: initialAdvancedFilters
	});

	const searchProperties = (
		filters: PropertyDropdownSelections | undefined,
		advancedFilters: AdvancedFilters | undefined,
		skip: number | undefined = 0,
		take: number | undefined = 10,
		sortField: string | undefined = undefined,
		sortOrder: number | undefined = undefined
	) => {
		console.log(filters, advancedFilters, skip, take, sortField, sortOrder);
		let query = getSearchQuery(filters, advancedFilters, skip, take, sortField, sortOrder);

		actions.searchPropertyExceptions(query as ExceptionsSearchQuery).then((pagedResult: any) => {
			if (pagedResult === undefined) {
				setMessageBasedOnCustomMessage(
					constants.MessageAreaMessages.ApiDownTitle,
					constants.MessageAreaMessages.ApiDownMessage,
					MessageSeverityLevel.Warning as MessageSeverity,
					false
				);
				return;
			}
		});
		//setExceptionSearchState({ ...query });
		setLocalState({ ...localState });
	};

	useEffect(() => {
		//initialize search filter state from URL params
		var urlFilters = getInitParams(props);
		setLocalState({
			...localState,
			currentFilters: urlFilters
		});

		actions.loadFilters(urlFilters);
		searchProperties(urlFilters, localState.advancedFilters);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const loadFiltersData = (updatedFilters: PropertyDropdownSelections) => {
		setLocalState({
			...localState,
			currentFilters: updatedFilters
		});
		actions.loadFilters(updatedFilters);
	};

	const onSelectDivision = (type: string, division: DropdownOption | undefined) => {
		const updatedFilters = updateFilters(type, division, localState.currentFilters);
		loadFiltersData(updatedFilters);
	};

	const onSelectCounty = (type: string, county: DropdownOption | undefined) => {
		const updatedFilters = updateFilters(type, county, localState.currentFilters);
		loadFiltersData(updatedFilters);
	};

	const onSelectCollection = (type: string, collection: DropdownOption | undefined) => {
		const updatedFilters = updateFilters(type, collection, localState.currentFilters);
		loadFiltersData(updatedFilters);
	};

	const onSelectProperties = (type: string, properties: DropdownOption[] | undefined) => {
		const updatedFilters = updateFilters(type, properties, localState.currentFilters);
		loadFiltersData(updatedFilters);
	};

	const onSelectUnitGroup = (type: string, unitGroup: DropdownOption | undefined) => {
		const updatedFilters = updateFilters(type, unitGroup, localState.currentFilters);
		loadFiltersData(updatedFilters);
	};

	const unitGroupDropdownDisabled = () => {
		var isPopulated =
			pageState.filterOptions && pageState.filterOptions.unitGroups && pageState.filterOptions.unitGroups.length > 0;

		var selectedLength =
			localState.currentFilters.selectedProperties !== undefined &&
			localState.currentFilters.selectedProperties != null
				? localState.currentFilters.selectedProperties.length
				: 0;
		var multipleProperties = selectedLength > 1 ? true : false;

		return !isPopulated && multipleProperties;
	};

	const onSearchFilters = () => {
		searchProperties(localState.currentFilters, localState.advancedFilters);
		// To Do: logic before search click.
		console.log(localState.currentFilters);
	};

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllExceptions]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.pageLoading}></VisibilityToggle>
			</div>
		</RequirePermission>
	);

	return (
		<Page
			menu={<Menu title={constants.menuTitle.exceptions.toUpperCase()} actionArea={navbarActionArea} />}
			className="exceptions-page"
		>
			{messageAreaState.messageArea}
			<div className="filter-headers-1">
				<div className="filter-item">
					<span className="filter-item__title">Division:</span>
					<Dropdown
						selectedValue={localState.currentFilters.selectedDivision}
						options={pageState.filterOptions!.divisions}
						onChange={(d) => onSelectDivision(constants.filters.division, d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.divisions)}
						style={{ width: 200 }}
					/>
				</div>
				<div className="filter-item">
					<span className="filter-item__title">County:</span>
					<Dropdown
						selectedValue={localState.currentFilters.selectedCounty}
						options={pageState.filterOptions!.counties}
						onChange={(d) => onSelectCounty(constants.filters.county, d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.counties)}
						style={{ width: 180 }}
					/>
				</div>
				<div className="filter-item">
					<span className="filter-item__title">Collection:</span>
					<Dropdown
						selectedValue={localState.currentFilters.selectedCollection}
						options={pageState.filterOptions!.collections}
						onChange={(d) => onSelectCollection(constants.filters.collection, d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.collections)}
						style={{ width: 180 }}
					/>
				</div>
				<div className="filter-item">
					<span className="filter-item__title">Property:</span>
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"All"}
						selectedValues={localState.currentFilters.selectedProperties}
						options={pageState.filterOptions!.properties}
						onChange={(d) => onSelectProperties(constants.filters.property, d)}
						disabled={!(pageState.filterOptions && pageState.filterOptions.properties)}
						hideClear={false}
						filter
						style={{ width: "400px" }}
					></MultiSelect>
				</div>
				<div className="filter-item">
					<span className="filter-item__title">Unit Group:</span>
					<Dropdown
						selectedValue={localState.currentFilters.selectedUnitGroup}
						options={pageState.filterOptions!.unitGroups}
						onChange={(d) => onSelectUnitGroup(constants.filters.unitGroup, d)}
						disabled={unitGroupDropdownDisabled()}
						style={{ width: 250 }}
					/>
				</div>
			</div>
			<div className="filter-headers-2">
				<div className="priority-apply-button">
					<Button label="Apply" className={"applyButton"} onClick={() => onSearchFilters()}></Button>
				</div>
			</div>
			<div className="card">
				<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
					<TabPanel header={constants.menuTitle.properties}>
						<PropertyExceptions
							pagedProperties={pageState.pagedProperties}
							onChange={(filters, advancedFilters, skip, take, sortField, sortOrder) =>
								searchProperties(filters, advancedFilters, skip, take, sortField, sortOrder)
							}
						/>
					</TabPanel>
					<TabPanel header={constants.menuTitle.unitGroups}>
						<p className="m-0">
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
							totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
							sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur,
							adipisci velit, sed quia non numquam eius modi.
						</p>
					</TabPanel>
					<TabPanel header={constants.menuTitle.units}>
						<p className="m-0">
							At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
							deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
							provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
							fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta
							nobis est eligendi optio cumque nihil impedit quo minus.
						</p>
					</TabPanel>
				</TabView>
			</div>
		</Page>
	);
};

export default ExceptionsPage;
