import { DropdownOption } from "api";
import SecondaryButton from "components/controls/SecondaryButton";
import { format, parseISO } from "date-fns";
import { Dialog } from "primereact/dialog";
import React from "react";
import utils from "utils";

interface Props {
	selectedRow?: any;
    isVisible?: boolean;
    beds?: DropdownOption[];
	properties?: DropdownOption[];
	counties?: DropdownOption[];
	cities?: DropdownOption[];
    onConfirm: (row: any) => void;
    onHide: () => void;
}

export const RemoveRowPopup: React.FC<Props> = ({ ...props }) => {
	const getSelectedRowName = (selectedRow: any) => {
		let output = "";
		if (selectedRow["propertyId"] && props.properties) {
			output =
				props.properties.find(
					(x) => x.key && utils.stringIsEqualCaseInsensitiveTrimmed(x.key, selectedRow["propertyId"])
				)?.displayValue ?? "All";
		} else if (selectedRow["cityId"] && props.cities) {
			output =
				props.cities.find((x) => x.key && utils.stringIsEqualCaseInsensitiveTrimmed(x.key, selectedRow["cityId"]))
					?.displayValue ?? "All";
		} else if (selectedRow["countyId"] && props.counties) {
			output =
				props.counties.find(
					(x) => x.key && utils.stringIsEqualCaseInsensitiveTrimmed(x.key, selectedRow["countyId"])
				)?.displayValue ?? "All";
		}
		if (output == "") {
			return "";
		}
		return output + ", ";
	};

	const getSelectedRowBeds = (selectedRow: any) => {
		const bedroomId = selectedRow["bedroomId"];

		if (!bedroomId) {
			return "All";
		}
		if (!props.beds) {
			return "";
		}

		const bedroomCount = props.beds.find(
			(x) => x.key && utils.stringIsEqualCaseInsensitiveTrimmed(x.key, bedroomId)
		)?.displayValue;

		return bedroomCount;
	};



	const removePopup = () => {
		const hideAction = () => {
            props.onHide();
		};
		if (!props.selectedRow) {
			return;
		}
		
		return (
			<Dialog
				header={"Confirm"}
				visible={props.isVisible}
				onHide={() => hideAction()}
				footer={
					<>
						<SecondaryButton
							title="Delete"
							type={"important"}
							onClick={() => {
								props.onConfirm(props.selectedRow);
								hideAction();
							}}
						/>
						<SecondaryButton title={"Cancel"} type={"notice"} onClick={() => hideAction()} />
					</>
				}
			>
				<span>
					{"Delete configuration for "}
					<b>{`${getSelectedRowName(props.selectedRow)} ${getSelectedRowBeds(
						props.selectedRow
					)} beds `}</b>
					{props.selectedRow["startDate"]
						? "with start date of " + format(parseISO(props.selectedRow["startDate"]), "MM/dd/yyyy")
						: ""}
					{"?"}
				</span>
			</Dialog>
		);
	};

    return (<>{removePopup()}</>);
};
