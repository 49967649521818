import React from "react";
import useStyle from "./useStyle";
import constants from "utils/constants";
import DropdownSelect from "components/controls/DropdownSelect";
import DropdownMultiSelect from "components/controls/DropdownMultiSelect";
import { useLoadData } from "./useLoadData";
import { ReviewReasonDropdown } from "./ReviewReason";
import { isEqualString, sortDropdown, updatedQueryParams } from "utils/miscUtils";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownOption } from "api";

const filters = ["division", "county", "property", "manualExclusion"];

export const SecondaryReviewFilterSection = () => {
	const classes = useStyle();
	const { filterOptions, isInitialLoading, selected } = useLoadData();
	const history = useHistory();
	const location = useLocation();

	const handleChange = (value?: DropdownOption | DropdownOption[]) => (key: string) => {
		const newValues = Array.isArray(value) ? value?.map((x) => x.key).join(",") : value?.key;
		history.push({
			pathname: location.pathname,
			search: updatedQueryParams({ key, value: newValues, arr: filters })
		});
	};

	return (
		<div className={classes.filterHeaders}>
			<DropdownSelect
				disabled={isInitialLoading}
				inputId={constants.filterLabels.recommendActions}
				inputLabel={constants.filterLabels.recommendActions}
				selectedValue={filterOptions.recommendActions?.find((i) =>
					isEqualString(i.key, selected.recommendActionId)
				)}
				options={filterOptions?.recommendActions}
				onChange={(value) => handleChange(value)("action")}
			/>
			<DropdownSelect
				disabled={isInitialLoading}
				inputId={constants.filterLabels.division}
				inputLabel={constants.filterLabels.division}
				selectedValue={filterOptions.divisions?.find((i) => isEqualString(i.key, selected.divisionId))}
				options={filterOptions?.divisions}
				onChange={(value) => handleChange(value)(filters[0])}
			/>
			<DropdownSelect
				disabled={isInitialLoading}
				inputId={constants.filterLabels.county}
				inputLabel={constants.filterLabels.county}
				selectedValue={filterOptions.counties?.find((i) => isEqualString(i.key, selected.countyId))}
				options={filterOptions?.counties?.sort(sortDropdown)}
				onChange={(value) => handleChange(value)(filters[1])}
			/>
			<DropdownMultiSelect
				disabled={isInitialLoading}
				inputId={constants.filterLabels.property}
				inputLabel={constants.filterLabels.property}
				selectedValues={filterOptions.properties?.filter((i) => selected.propertyIds.includes(i.key ?? ""))}
				options={filterOptions?.properties}
				onChange={(value) => handleChange(value)(filters[2])}
				filter
			/>
			<ReviewReasonDropdown
				options={filterOptions?.reviewReasons}
				isLoading={isInitialLoading}
				handleChange={handleChange}
			/>
				<DropdownSelect
				filter={false}
				inputId={constants.filterLabels.manualExclusion}
				disabled={isInitialLoading}
				inputLabel={constants.filterLabels.manualExclusion}
				selectedValue={filterOptions.manualExclusion?.find((i) =>
					isEqualString(i.key, selected.manualExclusionFilter)
				)}
				options={filterOptions.manualExclusion}
				onChange={(value) => handleChange({ key: value?.key })(filters[3])}
			/>
		</div>
	);
};
