import { SystemAutomatedFilterContext, SYSTEM_AUTOMATED_DEFAULT } from "./context";

import React, { FC, useEffect, useState } from "react";
import { DropdownOption } from "api";

// Keys that depend on the previous filter, make sure to use the same dependency order
const dependentFilters = ["divisionId", "countyId", "propertyIds", "unitGroupId"];

export const SystemAutomatedFilterProvider: FC = ({ children }) => {
	const [localState, setLocalState] = useState(SYSTEM_AUTOMATED_DEFAULT.filters);
	const [selectedRow, setSelectedRow] = useState<Record<string, string | number>>({});
	const [refresh, setRefresh] = useState(false);

	const handleChange = (value?: DropdownOption | DropdownOption[]) => (key: keyof typeof localState) => {
		setLocalState((prevState) => ({ ...prevState, [key]: value, ...resetNextFilters(key) }));
	};

	const resetNextFilters = (key: keyof typeof localState): Partial<typeof localState> => {
		// Find the next key based on a given key
		const keyIndex = Object.keys(SYSTEM_AUTOMATED_DEFAULT.filters).indexOf(key);
		const nextKey = Object.keys(SYSTEM_AUTOMATED_DEFAULT.filters)?.[keyIndex + 1] as keyof typeof localState;
		// Change the next key to default
		if (nextKey && dependentFilters.includes(nextKey))
			return { [nextKey]: SYSTEM_AUTOMATED_DEFAULT.filters[nextKey], ...resetNextFilters(nextKey) };
		// If no next key, return
		return {};
	};

	const handleInitialFilters = (data: typeof SYSTEM_AUTOMATED_DEFAULT.filters) => {
		setLocalState(data);
	};

	const handleSelectedRow = (row: Record<string, string | number>) => {
		setSelectedRow(row);
	};

	useEffect(() => {
		if (refresh) {
			const timer = setTimeout(() => {
				setRefresh(false);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [refresh]);

	return (
		<SystemAutomatedFilterContext.Provider
			value={{
				filters: localState,
				handleChange,
				handleInitialFilters,
				selectedRow,
				setSelectedRow: handleSelectedRow,
				refresh,
				setRefresh
			}}
		>
			{children}
		</SystemAutomatedFilterContext.Provider>
	);
};

export default SystemAutomatedFilterProvider;
