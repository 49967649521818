import constants from "utils/constants";
import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { MarketPositionChart } from "components/controls/MarketPositionChart/Chart";
import { DashboardChartRecord } from "api";
import { marketPositionMapper } from "./marketPositionMapper";

interface Props {
	isLoading: boolean;
	data: DashboardChartRecord[];
}

export const MarketPosition = ({ data, isLoading }: Props) => {
	const classes = useStyle();
	const mappedData = marketPositionMapper(data ?? []);

	if (isLoading) {
		return (
			<span
				style={{ height: 310 }}
				role="presentation"
				className={[classes.cardContainer, "skeleton", "p-card"].join(" ")}
			/>
		);
	}

	return (
		<Card title={constants.cardTitle.marketPositioning} className={classes.cardContainer}>
			<MarketPositionChart data={mappedData} />
		</Card>
	);
};
