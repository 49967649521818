/* eslint-disable react-hooks/exhaustive-deps */
import { useSecondaryReviewFilterContext } from "../../../Context";
import { useSecondaryReviewService } from "components/forms/PricingAutomation/services/secondaryReview.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getPriceResetUnitGroupChangeLogs } = useSecondaryReviewService();
	const { selectedRow } = useSecondaryReviewFilterContext();
	const { data, isInitialLoading, isFetching, isPreviousData } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["unit-group-logs", selectedRow],
		queryFn: ({ signal }) =>
			getPriceResetUnitGroupChangeLogs({ unitGroupId: String(selectedRow?.group) ?? null, num: 3 }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { localState: data || [], isLoading };
};
