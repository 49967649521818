import { PricingAutomationFilter } from "api";
import { createContext, useContext } from "react";

export type AtAGlanceContextType = {
	filters: PricingAutomationFilter;
	handleChange: (value?: string) => (key: keyof typeof AT_A_GLANCE_DEFAULT.filters) => void;
};

export const AT_A_GLANCE_DEFAULT: AtAGlanceContextType = {
	filters: {
		divisionId: "",
		countyId: undefined,
		collectionId: undefined
	},
	handleChange: () => () => null
};

export const AtAGlanceContext = createContext(AT_A_GLANCE_DEFAULT);

export const useAtAGlanceContext = () => {
	const context = useContext(AtAGlanceContext);

	if (!context) {
		throw new Error("useAtAGlanceContext used outside AtAGlanceContext provider");
	}

	return context;
};
