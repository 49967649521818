import { DropdownOption } from "api";
import { SearchState } from "components/forms/PricingAutomation/interfaces";
import format from "date-fns/format";
import { KeyboardEventHandler } from "react";
import { DateTime } from "luxon";

const isKeyExist = (obj: { [key: string]: string }, keyToFind: string) => {
	const result = (Object.keys(obj) as (keyof typeof obj)[]).find((key) => {
		return obj[key] === keyToFind;
	});
	return result;
};

export default {
	isKeyExist
};

export const isEmpty = (str: string): boolean => {
	return str === "";
};

export const isEmptyObject = (str: object): boolean => {
	return Object.keys(str)?.length === 0;
};

export const isNullOrUndefined = (str: string): boolean => {
	return str === null || str === undefined;
};

export const isNullOrUndefinedOrEmpty = (str: string): boolean => {
	return isNullOrUndefined(str) || isEmpty(str);
};

export const isNullOrUndefinedOrEmptyOrWhiteSpace = (str: string): boolean => {
	return isNullOrUndefined(str) || isEmpty(str) || (!isNullOrUndefinedOrEmpty(str) && str.trim() === "");
};

export const getDropDownItemIndex = (items: Array<DropdownOption>, item: string) => {
	return items.findIndex((i: DropdownOption) => i.key!.toString() === item);
};

export const formatCurrency = (value: any) => {
	return value?.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const formatCurrencyNoFractions = (value: any) => {
	return value?.toLocaleString("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 });
};

export const formatDecimalNumber = (value: any) => {
	return value?.toLocaleString("en-US", { notation: "standard", maximumFractionDigits: 1, minimumFractionDigits: 1 });
};

export const formatNumberNotFraction = (value: any) => {
	return value?.toLocaleString("en-US", { notation: "standard", maximumFractionDigits: 0, minimumFractionDigits: 0 });
};

export const formatPSTDate = (value: any) => {
	var timestamp = new Date(value);

	const utcDateTime = DateTime.fromJSDate(timestamp, { zone: 'utc' });
	const pstDateTime = utcDateTime.setZone('America/Los_Angeles');
	
	return format(new Date(pstDateTime.toString()), "MM/dd/yy h:mm a");
};

export const formatDate = (value: any) => {
	return format(new Date(value), "MM/dd/yy");
};

export const formatDateTime = (value: any) => {
	return format(new Date(value), "MM/dd/yy hh:mm a");
};

export const formatDefaultDate = (date?: string | null) => {
	return date ? new Date(date).toISOString().split("T")[0] : "";
};

/**
 *
 * @param inputDateStr in the format MM/DD/YYYY
 * @returns date in format [Day], [Month] [Day], [Year] sample: Sunday, July 23, 2023
 */
export const formatDateString = (inputDateStr: string | number): string => {
	return new Date(inputDateStr).toLocaleDateString("en-US", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric"
	});
};
export const formatDateWithTime = (value: any) => {
	return formatDateTime(value);
};

export const formatDateAsMonth = (value: any) => {
	const date = new Date(value);
	return format(date, "MMM yyyy");
};

export const formatPercentage = (value?: string | number) => {
	if (!isNaN(Number(value))) return `${parseFloat((Number(value) * 100).toFixed(1))}%`;
	return value;
};

/**
 * Sort an array of objects by a key and order
 * @param data Array<any>
 * @param key string
 * @param order number
 * @returns Array<any>
 */
export const sortedArrayObject = (data: Array<any>, key: string = "", order: number = 0) => {
	return data.sort((a, b) => {
		if (order === 1) {
			return a[key] > b[key] ? 1 : -1;
		} else if (order === -1) {
			return a[key] > b[key] ? -1 : 1;
		} else {
			return 0;
		}
	});
};

export const formatDateMDY = (value: any) => {
	return new Date(value).toLocaleDateString("en-US", {
		year: "numeric",
		month: "long",
		day: "numeric"
	});
};

export const onlyNumber: KeyboardEventHandler<HTMLInputElement> = (event) => {
	const allowedSymbols = [".", "-", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
	// Only allow if the e.key value is a number or if it's 'Backspace'
	if (isNaN(Number(event.key)) && !allowedSymbols.includes(event.key)) {
		event.preventDefault();
	}
};

export const onBlurNumber =
	({ decimals = 0 }: { decimals?: number }) =>
	(event: React.FocusEvent<HTMLInputElement>) => {
		if (event.currentTarget.value.trim() === "") {
			event.currentTarget.value = parseToFloat("0").toFixed(decimals);
		} else {
			event.currentTarget.value = parseToFloat(event.currentTarget.value).toFixed(decimals);
		}
	};

export const nullOrUndefinedToZero = (num: number | undefined) => {
	return num ?? 0;
};

export const isUndefined = (value?: any) => {
	return value === undefined;
};

export const isEqualString = (a?: string, b?: string) => {
	const regExp = /[^a-zA-Z0-9]/g;
	return a?.replace(regExp, "").toLowerCase().trim() === b?.replace(regExp, "").toLowerCase().trim();
};

export const calculateAmount = ({ current, percentage }: { current: string | number; percentage: string | number }) => {
	return Math.floor(parseToFloat((Number(current) * Number(percentage)) / 100 + Number(current))).toFixed(0);
};

export const calculatePercentage = ({ current, last }: { current: string | number; last: string | number }) => {
	return convertPercentage((Number(current) - Number(last)) / Number(last));
};

export const convertPercentage = (value?: string | number) => {
	const percentage = Number(value)
		.toLocaleString("en", {
			style: "percent",
			minimumFractionDigits: 1,
			maximumFractionDigits: 1
		})
		.replace("%", "");

	if (percentage.includes("-0.0")) {
		return percentage.replace("-0.0", "0.0");
	}
	return percentage;
};

export const parseToFloat = (value?: string | number) => {
	return parseFloat(value?.toString() ?? "0");
};

export const getCapitalizeAndSplitBySpace = (key: string) =>
	`${key.charAt(0).toUpperCase()}${key
		.slice(1)
		.split(/(?=[A-Z])/)
		.join(" ")}`;

export const mapSearchState = (searchState: SearchState) => {
	return {
		skip: searchState.skip,
		take: searchState.take,
		sortBy: searchState.sortField,
		sortDirection: searchState.sortOrder < 0 ? "DESC" : "ASC"
	};
};

export const resetNextFilters = (query: { key: string; arr: Array<string>; url: URL }) => {
	// Find the next key based on a given key
	const keyIndex = query.arr.indexOf(query.key);
	const nextKey = query.arr?.[keyIndex + 1];
	// Change the next key to default
	if (nextKey) {
		query.url.searchParams.set(nextKey, "");
		resetNextFilters({ ...query, key: nextKey });
	}

	return;
};

export const updatedQueryParams = (query: { key: string; value?: string; arr: Array<string> }) => {
	const url = new URL(window.location.href);
	url.searchParams.set(query.key, query.value ?? "");

	if (query.arr.includes(query.key)) {
		resetNextFilters({ arr: query.arr, key: query.key, url: url });
	}

	return url.searchParams.toString();
};

export const sortDropdown = (a: DropdownOption, b: DropdownOption) =>
	a.displayValue! > b.displayValue! ? 1 : a.displayValue! < b.displayValue! ? -1 : 0;

export const convertToPercentage = (value: unknown) => {
	if (!isNaN(Number(value))) {
		return Number(value) / 100;
	}
	return null;
};

export const NaNtoNull = (value: unknown) => {
	if (isNaN(Number(value))) {
		return null;
	}
	return value;
};

export const IsDateInRange = (value: Date, rangeStart: Date | undefined, rangeEnd: Date | undefined) => {
	
	const isOnOrAfterStart = rangeStart == undefined || (value >= rangeStart);
	const isOnOrBeforeEnd = rangeEnd == undefined || (value <= rangeEnd);

	return isOnOrAfterStart && isOnOrBeforeEnd;
};