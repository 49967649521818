import {
    UnitsClient,
    UnitFilterOptions,
    UnitDropdownSelections,
    UnitAvailabilityClient,
    PropertySummary,
  } from "api";
  import { PricingPlatformActions } from "../PricingPlatformActions";
  import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
  import { Identity } from "components/IdentityProvider";
  import {
    getApiEndpoint,
  } from "components/useApiEndpoint";
  import { makeRemoteRequest } from "components/useRemoteData";
  
  export const RentSummaryPageActionMethods = {
    get: function (
      dispatcher: React.Dispatch<PricingPlatformActions>,
      identity: Identity,
    ) {
      const getForm = function (
        currentFilterSelected: UnitDropdownSelections,
      ): void {
      
        let filterOptionsEndpoint = getApiEndpoint(
          identity,
          UnitsClient,
          "getFilterOptions"
        );
  
        makeRemoteRequest(filterOptionsEndpoint, [currentFilterSelected])
          .then((response) => {
            var options = response.data as UnitFilterOptions;
            dispatcher({
              type: PricingPlatformActionTypes.UpdateRentSummaryPageFilterOptions,
              updatedOptions: options,
            });
          });
      };

      const updateFilterOptions = function(
        currentSelections: UnitDropdownSelections
      ): void {
        let filterOptionsEndpoint = getApiEndpoint(
            identity,
            UnitsClient,
            "getFilterOptions"
          );

          makeRemoteRequest(filterOptionsEndpoint, [currentSelections])
          .then((response) => {
            var options = response.data as UnitFilterOptions;
            dispatcher({
              type: PricingPlatformActionTypes.UpdateRentSummaryPageFilterOptions,
              updatedOptions: options,
            });
          });
      }

      const searchProperty = function(
        currentFilterSelected: UnitDropdownSelections
      ): void {
        let apiEndpoint = getApiEndpoint(
          identity,
          UnitAvailabilityClient,
          "getAvailabilityReport"
        );

        let propertyId = currentFilterSelected.selectedProperty!.key!.toString();

        dispatcher({
          type: PricingPlatformActionTypes.UpdateRentSummaryPageIsLoading,
          payload: true,
        });

        makeRemoteRequest(apiEndpoint, [propertyId])
          .then((response) => {
            dispatcher({
              type: PricingPlatformActionTypes.UpdateRentSummaryPageReport,
              payload: response.data as PropertySummary,
            });
            dispatcher({
              type: PricingPlatformActionTypes.UpdateRentSummaryPageIsLoading,
              payload: false,
            });
          });
        };
  
      return {
        getForm,
        searchProperty,
        updateFilterOptions
      };
    }
  };