import React, { useRef } from "react";
import useStyle from "../../../useStyle";
import { DataTable } from "components/controls/DataTable";
import constants from "utils/constants";
import PageSection from "components/controls/PageSection";
import { columns } from "./columns";
import { useLoadData } from "./useLoadData";
import { useSearchState } from "./useSearchState";
import { Link } from "react-router-dom";
import routes from "components/routes";

export const SuccessfulAutomationSection = () => {
	const classes = useStyle();
	const { onPage, onSort, searchState } = useSearchState();
	const { localState, isLoading } = useLoadData(searchState);
	const showingRef = useRef<HTMLDivElement>(null);
	const hideColumnRef = useRef<HTMLDivElement>(null);

	return (
		<PageSection
			title={constants.titleSection.successfulAutomation}
			leftContent={<div ref={showingRef} />}
			rightContent={
				<Link to={routes.pricingAutomation.systemAutomated} className={classes.reviewAll}>
					{constants.buttonLabel.reviewAll}
				</Link>
			}
		>
			<DataTable
				loading={isLoading}
				onPage={onPage}
				onSort={onSort}
				columns={columns}
				data={localState?.items}
				showingRef={showingRef}
				searchState={searchState}
				totalRecords={localState?.totalCount}
				hideColumnsRef={hideColumnRef}
				tableName={constants.tableContent.successfulAutomationTitle}
				emptyMessage={constants.tableContent.successfulAutomationEmpty}
				tableClassName="tblSuccessfulAutomation"
			/>
		</PageSection>
	);
};
