import { useAtAGlanceContext } from "../../Context";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getDashboardChart } = useHomeService();
	const { filters } = useAtAGlanceContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: ["charts", filters],
		queryFn: ({ signal }) => getDashboardChart(filters?.divisionId ?? null, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { data, isLoading };
};
