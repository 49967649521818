import React, { useState } from "react";
import useStyle from "./useStyle";
import format from "date-fns/format";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption, UnitAvailabilityDropdownSelections } from "api";
import Label from "components/controls/Label";
import Heading from "components/controls/Heading";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import MultiSelect from "components/controls/MultiSelect";
import constants from "utils/constants";
import { formatCurrencyNoFractions } from "utils/miscUtils";

const UnitAvailabilityPage: React.FC = function () {
	const classes = useStyle();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.unitAvailabilityPageState.filterOptions,
		unitAvailabilityPageReports: appState.unitAvailabilityPageState.unitAvailabilityReports,
		isLoading: appState.unitAvailabilityPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.unitAvailabilityPage,
		...appActions
	};

	//does this not need to use the store because we defined directly here?
	interface LocalState {
		currentFilters: UnitAvailabilityDropdownSelections;
		appliedFilters: UnitAvailabilityDropdownSelections;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getForm(localState.currentFilters);
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedDivision = division;
		curSelections.selectedProperties = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectCollection(collection: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedCollection = collection;
		curSelections.selectedProperties = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedProperties = properties;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function applySearchDisabled() {
		var noPropSelected =
			localState.currentFilters.selectedProperties === null ||
			localState.currentFilters.selectedProperties === undefined ||
			localState.currentFilters.selectedProperties.length === 0;
		var noCollectionSelected =
			localState.currentFilters.selectedCollection === null ||
			localState.currentFilters.selectedCollection === undefined;
		return noPropSelected && noCollectionSelected;
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		setLocalState({
			...localState,
			appliedFilters: curSelections
		});

		actions.searchProperty(curSelections);
	}
	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		}
		if (applySearchDisabled() === true) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	function numberTemplate(rowData: any, column: string) {
		return rowData[column].toLocaleString("en-US");
	}

	function applyExport() {
		window.print();
	}

	var date = new Date();
	const displayReport = () => {
		if (pageState.unitAvailabilityPageReports !== null && pageState.unitAvailabilityPageReports !== undefined) {
			return pageState.unitAvailabilityPageReports?.map((page) => (
				<div className={classes.reportHeading} key={page.propertyName} style={{ pageBreakAfter: "always" }}>
					<div className={classes.titleheading}>
						<Heading level="2">{"Unit Availability Report: " + page.propertyName}</Heading>
						<Label label={date.toLocaleString("en-US")}></Label>
					</div>
					{page.unitGroups && page?.unitGroups?.length === 0 ? (
						<div className={classes.empty}>
							<h3>No Units Are Currently Available</h3>
						</div>
					) : (
						<div></div>
					)}
					{page.unitGroups?.map(
						(unitType) =>
							unitType && (
								<div id="unitTypeHeadingMargin" key={unitType.unitGroupName}>
									<div className={classes.unittypeheading}>
										<h4 className={classes.unittypestyling}>{"Unit Group: " + unitType.unitGroupName}</h4>
										{(() => {
											const unitsWithConcession = unitType.units?.filter(
												(unit) =>
													unit.pricingParamValues != null &&
													unit.pricingParamValues.filter((x) => x.paramValueMin).length > 0
											);
											if (unitsWithConcession !== undefined && unitsWithConcession.length > 0) {
												const sumaParamValueMin = unitsWithConcession[0].pricingParamValues?.reduce(
													(acumulador, paramValue) => {
														return (
															acumulador +
															(typeof paramValue.paramValueMin === "number"
																? paramValue.paramValueMin
																: 0)
														);
													},
													0
												);
												return (
													<h4 className={classes.unittypestyling}>
														{"Concession: " + formatCurrencyNoFractions(sumaParamValueMin)}
													</h4>
												);
											} else {
												return null;
											}
										})()}
									</div>
									<div id="unitTypeHeadingMargin2">
										<DataTable value={unitType.units}>
											<Column field="unitTypeId" header="Unit Type"></Column>
											<Column field="unitName" header="Unit Number"></Column>
											<Column
												field="squareFeet"
												style={{ width: "5%" }}
												bodyStyle={{ textAlign: "right" }}
												body={(e: any) => numberTemplate(e, "squareFeet")}
												header="Sq Ft"
											></Column>
											<Column field="amenityList" style={{ width: "40%" }} header="Features"></Column>
											<Column field="status" style={{ width: "15%" }} header="Condition"></Column>
											<Column
												field="vacated"
												headerStyle={{ textAlign: "center" }}
												bodyStyle={{ textAlign: "center" }}
												body={(e: any) => {
													if (e.vacated && e.vacated !== "") {
														return format(new Date(e.vacated), "M/d/yyyy");
													} else {
														return "N/A";
													}
												}}
												header="Vacated Date"
											></Column>
											<Column
												field="dateAvailable"
												headerStyle={{ textAlign: "center" }}
												bodyStyle={{ textAlign: "center" }}
												header="Date Available"
											></Column>
											<Column
												field="optimalTerm"
												style={{ width: "5%" }}
												bodyStyle={{ textAlign: "center" }}
												headerStyle={{ textAlign: "center" }}
												body={(e: any) => e.optimalTerm || "N/A"}
												header="Term"
											></Column>
											<Column
												field="amenitizedRent"
												bodyStyle={{ textAlign: "right" }}
												body={(e: any) => numberTemplate(e, "amenitizedRent")}
												header="Monthly Effective Rent ($)"
											></Column>
										</DataTable>
									</div>
								</div>
							)
					)}
				</div>
			));
		} else {
			return <div className={classes.empty}>Please Select A Property Using The Filters Above</div>;
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.unitAvailability} />}>
			<div id="unitAvailabilityFilters" className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedDivision}
						options={pageState.filterOptions?.divisions}
						onChange={(d) => onSelectDivision(d)}
						disabled={pageState.filterOptions?.divisions === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Collection:"}
					<Dropdown
						selectedValue={localState.currentFilters.selectedCollection}
						options={pageState.filterOptions?.collections}
						onChange={(d) => onSelectCollection(d)}
						disabled={pageState.filterOptions?.collections === undefined}
						style={{ width: 180 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"Select properties"}
						selectedValues={localState.currentFilters.selectedProperties}
						options={pageState.filterOptions?.properties}
						onChange={(d) => onSelectProperties(d)}
						disabled={pageState.filterOptions?.properties === undefined}
						hideClear={false}
						filter
						style={{ width: 350 }}
					></MultiSelect>
				</div>
				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled() || pageState.isLoading}
				></Button>
				<div className={classes.exportButton}>
					<Button
						label="Export"
						className={classes.applyButton}
						onClick={() => applyExport()}
						disabled={applySearchDisabled() || pageState.isLoading}
					></Button>
				</div>
			</div>
			<div id="contentContainer" className={classes.contentContainer}>
				{pageState.isLoading ? <ProgressSpinner /> : <div className={classes.pageHeading}> {displayReport()}</div>}
			</div>
		</Page>
	);
};

export default UnitAvailabilityPage;
