import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	root: {
		width: "100%",
		height: "38px",
		"&:disabled": {
			borderColor: theme.palette.lightGray,
			color: `${theme.palette.lightGray} !important`
		},
		"&[data-value$='right']": {
			padding: "0 18px 0 4px"
		},
		"&[data-value$='left']": {
			padding: "0 4px 0 16px"
		},
		"&[data-error=true]": {
			borderColor: `${theme.palette.error} !important`
		}
	},
	disabled: {
		borderColor: theme.palette.lightGray,
		color: `${theme.palette.lightGray} !important`
	},
	symbol: {
		position: "absolute",
		top: "50%",
		transform: "translate(0, -50%)",
		marginInline: "4px"
	},
	left: {
		left: "0"
	},
	right: {
		right: "0"
	},
	inputContainer: {
		display: "flex",
		position: "relative"
	},
	inputWrapper: {
		position: "relative",
		"& > label": {
			position: "absolute",
			whiteSpace: "nowrap",
			top: "-10px",
			left: "6px",
			padding: "0 4px",
			backgroundColor: theme.palette.white,
			color: "#79747e",
			fontSize: "13px",
			zIndex: "10"
		}
	}
}));
