import React from "react";
import { SubmarketCompPremiumCard, LocalCompPremiumCard } from "./CompPremium";
import useStyle from "./useStyle";
import PageSection from "components/controls/PageSection";
import constants from "utils/constants";
import { HeaderRightContent } from "./HeaderRightContent";
import { ScoreCard } from "./ScoreCard";
import { AutomationCard } from "./AutomationCard";
import { ParametersList } from "./ParametersList";
import { Charts } from "./Charts";
import { useLoadExclusionCountData } from "./useLoadData";
import cssUtils from "utils/cssUtils";
import routes from "components/routes";
import { CountCard } from "components/controls/Cards/CountCard/CountCard";

export const GlanceSection = () => {
	const classes = useStyle();
	const { exclusionCounts, exclusionCountsIsLoading } = useLoadExclusionCountData();

	return (
		<PageSection title={constants.titleSection.atAGlance.title} rightContent={<HeaderRightContent />}>
			<div className={classes.mainGrid}>
				<div className={[classes.gridSection].join(" ")}>
					<ParametersList />
				</div>
				<div className={[classes.chartsGrid, classes.gridSection].join(" ")}>
					<SubmarketCompPremiumCard />
					<LocalCompPremiumCard />
					<Charts />
				</div>
				<div className={[classes.gridSection, classes.scoreGrid].join(" ")}>
					<ScoreCard />
					<div className={[classes.automationAndExpiringCountGroup].join(" ")}>
						<AutomationCard />
						<CountCard
							link={routes.pricingAutomation.configureAutomation}
							title="Expiring Configuration"
							count={exclusionCounts?.count}
							isLoading={exclusionCountsIsLoading}
							className={[
								classes.expiringExclusionsCard,
								cssUtils.conditionClass(classes.loadingCards, exclusionCountsIsLoading),
							].join(" ")}
						/>
					</div>
				</div>
			</div>
		</PageSection>
	);
};
