import { Card } from "primereact/card";
import React, { FC } from "react";
import useStyle from "../useStyle";
import SubmarketComp from "./SubMarketComp";
import { useLoadData } from "./useLoadData";

export const SubmarketCompPremiumCard: FC = () => {
	const classes = useStyle();
	const { data, isLoading } = useLoadData();

	if (isLoading)
		return (
			<span className={[classes.loadingCompPremiumCard, "skeleton", "p-card"].join(" ")} data-testid="skeleton" />
		);

	return (
		<Card title={data.tileName} className={[classes.compPremiumContainer].join(" ")}>
			<SubmarketComp data={data} />
		</Card>
	);
};
