import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	textButton: {
		color: `${theme.palette.text} !important`,
		background: `${theme.palette.transparent} !important`,
		border: "none",
		"&:hover": {
			background: `${theme.palette.primary} !important`,
			color: `${theme.palette.white} !important`
		}
	},
	submitButton: {
		background: `${theme.palette.text} !important`,
		border: "none",
		"&:hover": {
			background: `${theme.palette.primary} !important`,
			color: `${theme.palette.white} !important`
		}
	},
	loadingIcon: {
		width: "15px",
		height: "15px",
		strokeWidth: "2px",
		color: theme.palette.primary,
		marginRight: "5px"
	}
}));
