import React from "react";
import nameof from "nameof";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import ValidationWrapper from "../ValidationWrapper";
import useStyle from "./useStyle";

export interface DropdownOption {
	key?: string;
	displayValue?: string;
}

export interface DropdownProps {
	inputId?: string;
	inputLabel?: string;
	name?: string;
	placeholder?: string;
	autoFocus?: boolean;
	tabIndex?: number;
	selectedValue: DropdownOption | undefined;
	options: DropdownOption[] | undefined;
	onFocus?: (event: Event) => void;
	onChange: (value: DropdownOption | undefined) => void;
	hideClear?: boolean | undefined;
	style?: object | undefined;
	filter?: boolean;
	filterBy?: string;
	validationMessage?: string | undefined;
	disabled?: boolean | undefined;
	valueTemplate?: (option: any, props: object) => any | any;
	itemTemplate?: (option: any) => any | any;
}

const optionLabel = nameof<DropdownOption>("displayValue");
const optionKey = nameof<DropdownOption>("key");

/**
 * Component used in Pricing Automation Section.
 * @param props
 * @returns React.JSX componenet
 */
const DropdownSelect = React.forwardRef((props: DropdownProps, ref: React.LegacyRef<PrimeDropdown>) => {
	const classes = useStyle();
	const selectedValue =
		(props &&
			props.options &&
			props.options.find((o) => o.key === (props && props.selectedValue && props.selectedValue.key))) ||
		undefined;
	return (
		<ValidationWrapper showErrorState={props.validationMessage != null}>
			<div className={[classes.dropdownWrapper].join(" ")}>
				<label htmlFor={props.inputId}>{props.inputLabel}</label>
				<PrimeDropdown
					ref={ref}
					{...props}
					name={props.name}
					inputId={props.inputId}
					showClear={!props.hideClear}
					value={selectedValue}
					valueTemplate={props.valueTemplate}
					itemTemplate={props.itemTemplate}
					options={props.options}
					tooltip={props.validationMessage}
					tooltipOptions={{ event: "hover" }}
					optionLabel={optionLabel}
					dataKey={optionKey}
					className={classes.root}
					filter={props.filter}
					filterBy={props.filterBy}
					onChange={(e) => props.onChange(e.value ?? {})}
					placeholder={`-- ${props.placeholder || "All"} --`}
					style={props.style}
					disabled={props.disabled}
					autoFocus={props.autoFocus}
					tabIndex={props.tabIndex}
				/>
			</div>
		</ValidationWrapper>
	);
});
export default DropdownSelect;
