import {
	ExclusionsFilterOptions,
	ExclusionsFilterSearchQuery,
	ExclusionsUITilesResult,
	PagedResultOfCompletedExclusionsGridItem,
	PagedResultOfExcludedUnitGroupGridItem,
	PagedResultOfPropertyUnitGroupExcluded,
	PricingAutomationClient,
	PropertiesUnitGroupExclusionsSearchQuery,
	SystemAutomatedSearchQuery
} from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

type TilesQuery = {
	collectionId?: number | null;
	countyId?: string | null;
	divisionId?: string | null;
	propertyId?: string | null;
};

export const useExclusionsService = () => {
	const identity = useContext(IdentityContext);

	const getExclusionsUITiles = async (query: TilesQuery, signal?: AbortSignal): Promise<ExclusionsUITilesResult> => {
		const { collectionId, countyId, divisionId, propertyId } = query;
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getExclusionsUITiles", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [collectionId, countyId, divisionId, propertyId]);
			if (data === undefined) {
				throw new Error();
			}
			return data as ExclusionsUITilesResult;
		} catch (err) {
			throw err;
		}
	};

	const getExclusions = async (
		query: PropertiesUnitGroupExclusionsSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfPropertyUnitGroupExcluded> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getExclusions", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [{ divisionId: query.divisionId }]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfPropertyUnitGroupExcluded;
		} catch (err) {
			throw err;
		}
	};

	const getCompletedExclusions = async (
		query: SystemAutomatedSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfCompletedExclusionsGridItem> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getCompletedExclusions", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfCompletedExclusionsGridItem;
		} catch (err) {
			throw err;
		}
	};

	const getExcludedUnitGroups = async (
		query: SystemAutomatedSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfExcludedUnitGroupGridItem> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getExcludedUniteGroups", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfExcludedUnitGroupGridItem;
		} catch (err) {
			throw err;
		}
	};

	const getExclusionsFilters = async (
		query: ExclusionsFilterSearchQuery,
		signal?: AbortSignal
	): Promise<ExclusionsFilterOptions> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getExclusionsFilters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [query]);
			if (data === undefined) {
				throw new Error();
			}
			return data as ExclusionsFilterOptions;
		} catch (err) {
			throw err;
		}
	};

	return { getExclusionsUITiles, getExclusions, getCompletedExclusions, getExcludedUnitGroups, getExclusionsFilters };
};
