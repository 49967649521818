import {
	PropertiesClient,
	PropertySearchQuery,
	PagedResultOfPropertySummary,
	UnitGroupsClient,
	UnitGroupDelta,
	UnitGroupSummary,
	PropertySearchFilterOptions,
	ExceptionsSearchQuery,
	PagedResultOfProperty
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";
import { PropertyDropdownSelections } from "../PricingPlatformStateTypes";

export const PropertiesPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {
		const loadFilters = function (filters: PropertyDropdownSelections): void {
			dispatcher({
				type: PricingPlatformActionTypes.PropertiesPage_FetchingProperties,
				payload: true
			});

			let filterOptionsEndpoint = getApiEndpoint(identity, PropertiesClient, "getFilterOptions");

			var filterOptionSearchQuery: PropertySearchQuery = {
				divisionId: filters.selectedDivision ? filters.selectedDivision.key : undefined,
				propertyIds: filters.selectedProperties ? filters.selectedProperties.map(x => x.key).filter(x => x !== undefined) as string[]: undefined,
				unitGroupId: filters.selectedUnitGroup ? filters.selectedUnitGroup.key : undefined,				
				county: filters.selectedCounty ? filters.selectedCounty.key : undefined,
				//filterOptionSearchQuery.assetCollectionId: (filters.selectedCollection && typeof filters.selectedCollection.key !== undefined) ? parseInt(filters.selectedCollection.key!) : undefined
			};

			if (filters.selectedCollection && typeof filters.selectedCollection.key !== undefined) {			
				filterOptionSearchQuery.assetCollectionId= parseInt(filters.selectedCollection.key!);				
			}
			//Update Filters' Options from API
			makeRemoteRequest(filterOptionsEndpoint, [filterOptionSearchQuery])
			.then((response) => {
				if( response === undefined)
				{
					throw new Error();
				}

				var options = response.data as PropertySearchFilterOptions;

				dispatcher({
					type: PricingPlatformActionTypes.PropertiesPage_LoadedFilterOptions,
					updatedOptions: options
				});
			})
			.catch((err) => { });

			dispatcher({
				type: PricingPlatformActionTypes.PropertiesPage_FetchingProperties,
				payload: false
			});
		};

		const searchProperties = function (
			propertySearchQuery: PropertySearchQuery
		): Promise<void | PagedResultOfPropertySummary | null | undefined> {
			dispatcher({
				type: PricingPlatformActionTypes.PropertiesPage_FetchingProperties,
				payload: true
			});

			let propertyEndpoint = getApiEndpoint(identity, PropertiesClient, "getPropertySummaries");

			return makeRemoteRequest(propertyEndpoint, [propertySearchQuery])
			.then((response) => {
				if( response === undefined)
				{
					throw new Error();
				}

				var serverValues = response.data as PagedResultOfPropertySummary

				dispatcher({
					type: PricingPlatformActionTypes.PropertiesPage_LoadedProperties,
					pagedResult: serverValues
				});

				dispatcher({
					type: PricingPlatformActionTypes.PropertiesPage_FetchingProperties,
					payload: false
				});

				return response.data;
			})
			.catch((err) => {});
		};

		const searchPropertyExceptions = (query: ExceptionsSearchQuery): Promise<void | PagedResultOfProperty | null | undefined> => {
			dispatcher({
				type: PricingPlatformActionTypes.PropertiesPage_FetchingProperties,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, PropertiesClient, "getPropertiesExceptionsByFilters");

			return makeRemoteRequest(apiEndpoint, [query]).then((response) => {
				if( response === undefined)
				{
					throw new Error();
				}

				var data = response.data as PagedResultOfProperty

				dispatcher({
					type: PricingPlatformActionTypes.PropertiesPage_LoadedProperties,
					pagedResult: data
				});

				dispatcher({
					type: PricingPlatformActionTypes.PropertiesPage_FetchingProperties,
					payload: false
				});

				return response.data;
			})			
		};

		const updateBaseRents = function (unitGroups: UnitGroupDelta[]): void {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupsClient, "updateBaseRents");

			makeRemoteRequest(apiEndpoint, [unitGroups])
				.then((response) => {
					console.log(response.data);

					dispatcher({
						type: PricingPlatformActionTypes.PropertiesPage_SavedUnitGroups,
						savedUnitGroups: response.data as UnitGroupSummary[]
					});
				})
				.catch((error) => {});
		};

		return {
			loadFilters,
			searchProperties,
			searchPropertyExceptions,
			updateBaseRents
		};
	}
};
