import { ExclusionUITile } from "api";
import useStyle from "./useStyle";
import React from "react";
import { InfoCard } from "./InfoCard";

interface Props {
	tiles?: ExclusionUITile[];
	isLoading?: boolean;
}

export const Tiles = ({ tiles, isLoading }: Props) => {
	const classes = useStyle();

	if (isLoading) {
		return (
			<>
				{Array.from({ length: 4 }).map((_, index) => (
					<span
						key={index}
						role="presentation"
						className={["skeleton", "p-card", classes.loadingTiles].join(" ")}
					/>
				))}
			</>
		);
	}

	return (
		<>
			{tiles?.map((item, index) => (
				<InfoCard key={index} title={item.tileHeader} completed={item.completed} />
			))}
		</>
	);
};
