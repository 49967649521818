import { useState } from "react";

export const useShowAudit = () => {
	const [showAudit, setShowAudit] = useState(false);

	const toggleShowAudit = () => {
		setShowAudit((prevState) => !prevState);
	};

	return { showAudit, toggleShowAudit };
};
