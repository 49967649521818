import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	confirmationContainer: {
		display: "flex",
		flexDirection: "column",
		paddingBlock: "16px"
	},
	title: {
		textAlign: "center"
	},
	message: {
		maxWidth: "330px"
	},
	buttonsContainer: {
		display: "flex",
		width: "100%",
		gap: "10px",
		justifyContent: "start"
	},
	titleContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "10px"
	}
}));
