import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	drawerContainer: {
		position: "sticky",
		top: "64px",
		left: 0,
		zIndex: "20",
		background: theme.palette.darkBlue,
		height: "calc(100vh - 64px)",
		width: "118px",
		"@media (max-width: 768px)": {
			display: "none"
		},
		minWidth: "118px",
		marginTop: "64px",
		color: theme.palette.white
	},
	navContainer: {
		display: "grid",
		gridTemplateColumns: "1fr"
	},
	linkItem: {
		display: "flex",
		textAlign: "center",
		flexDirection: "column",
		alignItems: "center",
		gap: "8px",
		paddingTop: "16px",
		paddingBottom: "16px",
		color: "white",
		textDecoration: "none",
		fontSize: "12px",
		textTransform: "uppercase",
		"&:hover": {
			background: theme.palette.transparentBlue
		}
	},
	linkActive: {
		background: theme.palette.blue,
		borderLeft: `3px solid ${theme.palette.background}`,
		"&:hover": {
			background: theme.palette.blue
		}
	}
}));
