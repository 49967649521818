import React, { useEffect, useState } from "react";
import Label, { withLabel } from "../Label";
import { Calendar as PrimeReactCalendar } from "primereact/calendar";
import useStyle from "./useStyle";
import ValidationWrapper from "../ValidationWrapper";
import Spacer from "../Spacer";

export enum CalendarType {
  SingleSelection = 1,
  RangeSelection = 2
}

interface YearNavigator {
  yearRange?: number[];
  useMinMaxDates?: boolean;  
  pastYearsToShow?: number;
  futureYearsToShow?: number;
}

interface CalendarProps {
  type: CalendarType;
  label?: string | undefined;
  validationMessage?: string | undefined;
  appendTo?: any;
  onChange: (firstDate: Date | undefined, secondDate: Date | undefined) => void;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  showButtonBar?: boolean;
  monthNavigator?: boolean;
  yearNavigator?: YearNavigator;
  stateful?: boolean;
  value?: Date | Date[] | undefined;
  readOnlyInput?: boolean;
  disabled?: boolean;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  const classes = useStyle();

  const [dates, setDates] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    setDates(undefined);
  }, [props.type]);

  function getDate(date: Date | Date[] | null | undefined, index: number) {
    if (!date) {
      return null;
    }

    if (Array.isArray(date)) {
      return date[index];
    }
    return date;
  }

  const label =
    props.label !== null
      ? props.label
      : props.type === CalendarType.RangeSelection
      ? "Date Range"
      : "Date";

  const yearRange = () => {
    if (!props.yearNavigator) {
      return;
    }
    
    const yearRange = props.yearNavigator.yearRange;
    if (yearRange && yearRange.length == 2) {
      return `${yearRange[0]}:${yearRange[1]}`;
    }

    if (props.yearNavigator.pastYearsToShow || props.yearNavigator.futureYearsToShow) {

      const currentYear = new Date().getFullYear();
      let start = currentYear;
      let end = currentYear;

        start = currentYear - (props.yearNavigator.pastYearsToShow ?? 0);
        end = currentYear + (props.yearNavigator.futureYearsToShow ?? 0);

      return `${start}:${end}`;    
    }
  }

  return (
    <span className="pricingPlatformPrimeOverride-input-with-validation">
      <Label label={props.label || label} />
      <Spacer orientation="h" />
      <ValidationWrapper showErrorState={props.validationMessage != null}>
        <PrimeReactCalendar
          yearNavigator={props.yearNavigator ? true : false}
          yearRange={yearRange()}
          monthNavigator={props.monthNavigator}
          appendTo={props.appendTo}
          className={classes.calendar}
          showOnFocus
          disabled={props.disabled}
          tooltip={props.validationMessage}
          dateFormat="mm/dd/yy"
          minDate={props.minDate}
          maxDate={props.maxDate}
          selectionMode={
            props.type === CalendarType.RangeSelection ? "range" : undefined
          }
          value={props.stateful ? dates : props.value}
          showButtonBar={props.showButtonBar}
          readOnlyInput={props.readOnlyInput ?? true}
          onChange={(e: any) => {
            setDates(e.value);
            const dates: Array<Date> = e.value || [];
            props.onChange(
              getDate(dates, 0) || undefined,
              getDate(dates, 1) || undefined
            );
          }}
        />
      </ValidationWrapper>
    </span>
  );
};

export default withLabel(Calendar);
