import React, { useState } from "react";
import Menu from "../Menu";
import Page from "components/layouts/Page";
import useStyle from "./useStyle";
import useFirstLoadEffect from "utils/useMountEffect";
import Spinner from "components/controls/Spinner";
import { UnitForm } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import Checkbox from "components/controls/Checkbox";
import Label from "components/controls/Label";
import { Button } from "primereact/components/button/Button";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import { RouteComponentProps } from "react-router";
import routes from "components/routes";
import useMessageArea from "components/useMessageArea";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import constants from "utils/constants";

export interface UnitPageParams {
	divisionId: string;
	propertyId: string;
	unitGroupId: string;
}

interface PageProps extends RouteComponentProps<UnitPageParams> {}

const UnitPage: React.FC<PageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.unitPageState.filterOptions,
		currentSelectedOptions: appState.unitPageState.currentFilterSelected,
		appliedSelectedOptions: appState.unitPageState.appliedFilterSelected,
		units: appState.unitPageState.units,
		tableLoading: appState.unitPageState.tableLoading,
		...appState
	};

	const actions = {
		...appActions.unitPage,
		...appActions
	};

	const [messageAreaState, , , setMessageBasedOnCustomMessage] = useMessageArea();

	const rowsPerPage: number[] = [10, 20, 50];
	interface SearchState {
		first: number;
		rows: number;
		page: number;
		totalRecords: number;
		sortField: string | undefined;
		sortOrder: number | undefined;
		expandedRows: any;
		unleasedOnly: boolean;
		initialSortField: string | undefined;
	}

	const [searchState, setSearchState] = useState<SearchState>({
		first: 0,
		rows: rowsPerPage[0],
		page: 0,
		totalRecords: 0,
		sortField: undefined,
		sortOrder: 1,
		expandedRows: undefined,
		unleasedOnly: true,
		initialSortField: "propertyName"
	});

	useFirstLoadEffect(() => {
		try {
			actions.getForm(
				appState.unitPageState.currentFilterSelected,
				appState.unitPageState.appliedFilterSelected,
				searchState.first,
				searchState.rows,
				searchState.unleasedOnly,
				props.match.params
			);
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	function selectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...pageState.currentSelectedOptions };
		curSelections.selectedDivision = division;
		curSelections.selectedProperty = undefined;
		curSelections.selectedUnitGroup = undefined;
		actions.updateCurrentSelections(curSelections);
	}

	function selectProperty(property: DropdownOption | undefined) {
		var curSelections = { ...pageState.currentSelectedOptions };
		curSelections.selectedProperty = property;
		curSelections.selectedUnitGroup = undefined;
		actions.updateCurrentSelections(curSelections);
	}

	function selectUnitGroup(unitGroup: DropdownOption | undefined) {
		var curSelections = { ...pageState.currentSelectedOptions };
		curSelections.selectedUnitGroup = unitGroup;
		actions.updateCurrentSelections(curSelections);
	}
	function applySearchHighlight() {
		var a = pageState.appliedSelectedOptions;
		var b = pageState.currentSelectedOptions;

		//if (a.selectedDivision!.key === b.selectedDivision!.key && a.selectedProperty!.key === b.selectedProperty!.key && a.selectedUnitGroup!.key === b.selectedUnitGroup!.key) {
		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	function clickCheckBox() {
		setSearchState({
			...searchState,
			unleasedOnly: !searchState.unleasedOnly
		});
	}

	function applySearch() {
		actions.searchUnits(pageState.currentSelectedOptions, 0, searchState.rows, searchState.unleasedOnly);
		setSearchState({
			...searchState,
			first: 0,
			page: 0
		});
	}

	function unitGroupDropdownDisabled(): boolean {
		var unitGroupsPopulated =
			pageState.filterOptions !== null &&
			pageState.filterOptions !== undefined &&
			pageState.filterOptions.unitGroups !== null &&
			pageState.filterOptions.unitGroups !== undefined &&
			pageState.filterOptions.unitGroups.length > 0;

		return !unitGroupsPopulated;
	}

	function onPage(e: any) {
		actions.pageUnits(
			pageState.appliedSelectedOptions,
			e.first,
			e.rows,
			searchState.unleasedOnly,
			searchState.sortField,
			searchState.sortOrder
		);

		setSearchState({
			...searchState,
			first: e.first,
			rows: e.rows,
			page: e.page
		});
	}

	function onSort(e: any) {
		actions.sortUnits(
			pageState.appliedSelectedOptions,
			0,
			searchState.rows,
			e.sortField,
			e.sortOrder,
			searchState.unleasedOnly
		);

		setSearchState({
			...searchState,
			first: 0,
			page: 0,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
			initialSortField: undefined
		});
	}

	function onRowToggle(e: any) {
		setSearchState({
			...searchState,
			expandedRows: e.data
		});
	}

	const classes = useStyle();
	var divisionOptions = undefined;
	var propertyOptions = undefined;
	var unitGroupOptions = undefined;
	var totalUnitCount = 0;
	if (pageState.filterOptions !== null && pageState.filterOptions !== undefined) {
		divisionOptions = pageState.filterOptions.divisions;
		propertyOptions = pageState.filterOptions.properties;
		unitGroupOptions = pageState.filterOptions.unitGroups;
	}
	if (
		pageState.units !== null &&
		pageState.units !== undefined &&
		pageState.units.totalCount !== null &&
		pageState.units.totalCount !== undefined
	) {
		totalUnitCount = pageState.units.totalCount;
	}

	function numberTemplate(rowData: any, column: string) {
		return rowData[column].toLocaleString("en-US");
	}

	function currencyTemplate(rowData: any, column: string) {
		return rowData[column].toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0
		});
	}

	function getMatrixLink(rowData: any) {
		var href = routes.pricingMatrix(undefined, rowData["propertyId"], rowData["unitGroupId"], rowData["id"]);
		return (
			<a className={classes.matrixLink} target="_blank" rel="noopener noreferrer" href={href}>
				{"view"}
			</a>
		);
	}

	const rowExpansionTemplate = (unit: UnitForm) => {
		var baseRent = unit.baseRent === null || unit.baseRent === undefined ? 0 : unit.baseRent;
		var amenitySum = unit.amenitySum === null || unit.amenitySum === undefined ? 0 : unit.amenitySum;
		const emptyAmenities = [{ key: "N/A", value: "N/A" }];

		return (
			<div className={classes.expansionWrapper}>
				<div className={classes.expansionChild}>
					<div className={classes.expansionLeftColumn} id="unitExpansionTable">
						<Label
							className={classes.amenityLabel}
							label={
								"Amenities: " +
								amenitySum.toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
									minimumFractionDigits: 0
								})
							}
						></Label>
						{unit.amenities === null || unit.amenities === undefined || unit.amenities.length === 0 ? (
							<DataTable
								value={emptyAmenities}
								scrollable={true}
								scrollHeight={"200px"}
								className={classes.expansionTable}
							>
								<Column field="key" header="Name" sortable></Column>
								<Column field="value" header="Amount ($)" className={classes.amenityColumn} sortable></Column>
							</DataTable>
						) : (
							<DataTable
								value={unit.amenities}
								scrollable={true}
								scrollHeight={"200px"}
								className={classes.expansionTable}
								sortField="name"
								sortOrder={1}
							>
								<Column field="name" header="Name" sortable></Column>
								<Column
									field="amount"
									header="Amount ($)"
									headerStyle={{ textAlign: "right" }}
									bodyStyle={{ textAlign: "right" }}
									body={(e: any) => currencyTemplate(e, "amount")}
									className={classes.amenityColumn}
									sortable
								></Column>
							</DataTable>
						)}
					</div>
					<div className={classes.expansionRightColumn}>
						<div className={classes.baseRent}>
							<Label label={"Base Rent"}> </Label>
							<div>
								{baseRent.toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
									minimumFractionDigits: 0
								})}
							</div>
						</div>
					</div>

					<div className={classes.expansionRightColumn}>
						<div className={classes.baseRent}>
							<Label label={"Total Amenitized Rent"}></Label>
							<div>
								{(baseRent + amenitySum).toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
									minimumFractionDigits: 0
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const dataTable = () => {
		if (pageState.units === undefined || pageState.units === null) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
			return <Spinner isVisible={true} />;
		} else if (pageState.loadingState.isLoading) {
			return <Spinner isVisible={true} />;
		} else {
			return (
				<DataTable
					value={pageState.units.units}
					paginator={true}
					alwaysShowPaginator={true}
					pageLinkSize={5}
					first={searchState.first}
					rows={searchState.rows}
					rowsPerPageOptions={rowsPerPage}
					expandedRows={searchState.expandedRows}
					onRowToggle={(e) => {
						onRowToggle(e);
					}}
					rowExpansionTemplate={rowExpansionTemplate}
					lazy={true}
					totalRecords={pageState.units.totalCount}
					loading={pageState.tableLoading}
					onPage={(e) => {
						onPage(e);
					}}
					onSort={(e) => {
						onSort(e);
					}}
					sortField={
						searchState.initialSortField !== undefined ? searchState.initialSortField : searchState.sortField
					}
					sortOrder={searchState.sortOrder}
					className={classes.dataTablestyling}
				>
					<Column expander style={{ width: "2em" }} />
					<Column field="name" header="Unit" sortable></Column>
					<Column field="propertyName" header="Property" sortable></Column>
					<Column field="unitGroupName" header="Unit Group" sortable></Column>
					<Column field="unitTypeId" header="Unit Type" sortable></Column>
					<Column
						field="squareFeet"
						body={(e: any) => numberTemplate(e, "squareFeet")}
						header="Sq ft"
						headerClassName={classes.sqft}
						className={classes.numberColumn}
						sortable
					></Column>
					<Column
						field="amenitizedRent"
						body={(e: any) => numberTemplate(e, "amenitizedRent")}
						header="Amenitized Rent ($)"
						className={classes.numberColumn}
						sortable
					></Column>
					<Column
						field="lastAchievedRent"
						body={(e: any) => numberTemplate(e, "lastAchievedRent")}
						header="Last Achieved Rent ($)"
						className={classes.numberColumn}
						sortField="lastAchievedRent"
						sortable
					></Column>
					<Column
						field="lastAchievedRentDate"
						body={(e: any) => {
							//this is a hack to get the date to display in the correct timezone
							return new Intl.DateTimeFormat("en-US", { timeZone: "UTC" }).format(
								new Date(e.lastAchievedRentDate)
							);
						}}
						header="Last Achieved Rent Date"
						className={classes.numberColumn}
						sortField="lastAchievedRentDate"
						sortable={true}
					></Column>
					<Column
						field="amenitySum"
						body={(e: any) => numberTemplate(e, "amenitySum")}
						header="Amenities ($)"
						className={classes.numberColumn}
						sortable={true}
					></Column>
					<Column field="status" header="Status" sortable></Column>
					<Column header="Pricing Matrix" body={(e: any) => getMatrixLink(e)}></Column>
				</DataTable>
			);
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.units} />}>
			{messageAreaState.messageArea}
			<div className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={pageState.currentSelectedOptions.selectedDivision}
						options={divisionOptions}
						onChange={(d) => selectDivision(d)}
						disabled={divisionOptions === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<Dropdown
						selectedValue={pageState.currentSelectedOptions.selectedProperty}
						options={propertyOptions}
						onChange={(d) => selectProperty(d)}
						disabled={propertyOptions === undefined}
						filter
						style={{ width: 350 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Unit Group:"}
					<Dropdown
						selectedValue={pageState.currentSelectedOptions.selectedUnitGroup}
						options={unitGroupOptions}
						onChange={(d) => selectUnitGroup(d)}
						disabled={unitGroupDropdownDisabled()}
						style={{ width: 250 }}
					/>
				</div>
				<div className={classes.checkbox}>
					<Checkbox
						label={"Show unleased units only"}
						labelPosition={"right"}
						checked={searchState.unleasedOnly}
						onChange={clickCheckBox}
					/>
				</div>
				<Button label="Apply" className={applySearchHighlight()} onClick={() => applySearch()}></Button>
			</div>
			<div className={classes.summaryInfo}>
				<Label label={"Total Units:  " + totalUnitCount.toLocaleString()} />
			</div>
			<div className={classes.pageWrapper}>
				<div>
					<div>{dataTable()}</div>
				</div>
			</div>
		</Page>
	);
};

export default UnitPage;
