import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	loadingTiles: {
		height: "150px",
		width: "auto"
	},
	loadingHeaderContent: {
		height: "15px",
		width: "280px",
		"@media (max-width: 768px)": {
			width: "180px"
		}
	}
}));
