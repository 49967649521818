import { isEqualString } from "utils/miscUtils";
import { z } from "zod";

export const dropdownValidation = z
	.object({
		Portfolio: z.object({
			key: z.string({
				required_error: `Portfolio is required`
			}),
			displayValue: z.string({
				required_error: `Portfolio is required`
			})
		}),
		Division: z.object({
			key: z.string({
				required_error: `Division is required`
			}),
			displayValue: z.string({
				required_error: `Division is required`
			})
		}),
		County: z.object({
			key: z.string({
				required_error: `County is required`
			}),
			displayValue: z.string({
				required_error: `County is required`
			})
		}),
		Property: z.object({
			key: z.string({
				required_error: `Property is required`
			}),
			displayValue: z.string({
				required_error: `Property is required`
			})
		}),
		"Unit Group": z.object({
			key: z.string({
				required_error: `Unit Group is required`
			}),
			displayValue: z.string({
				required_error: `Unit Group is required`
			})
		}),
		exist: z.string({
			required_error: `Exist is required`
		})
	})
	.partial()
	.superRefine((value, ctx) => {
		// Validate if the override selected already exist on the list of levels displayed
		const { exist, ...values } = value;
		const selectedOverride = Object.values(values)
			.map((item) => item?.displayValue)
			.join("");
		if (exist?.split(",")?.some((name: string) => isEqualString(selectedOverride, name))) {
			ctx.addIssue({
				path: ["exist"],
				code: z.ZodIssueCode.custom,
				message: "Override already exist, select another combination",
				fatal: true
			});
		}
	});

export type configureAutomationFormType = z.infer<typeof dropdownValidation>;
