import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";
import constants from "utils/constants";

export interface MenuProps {
  title?: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

  const createItems = (): MenuItemProps[] => {
    return(
      [

        {
          title: constants.menuTitle.unitGroupMaster.toUpperCase(),
          to: routes.unitGroupAdmin.unitGroupMasters,
          isActive:
            locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.unitGroupMasters)
        },
        {
            title: constants.menuTitle.manageUnitGroupsMasterCreate.toUpperCase(),
            to: routes.unitGroupAdmin.createUnitGroupMaster(),
            isActive:
              locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.createUnitGroupMaster())
        },
      ]
    )
}

const editItems = (): MenuItemProps[] => {
    return(
      [

        {
            title: constants.menuTitle.unitGroupMaster.toUpperCase(),
            to: routes.unitGroupAdmin.unitGroupMasters,
            isActive:
              locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.unitGroupMasters)
        },
        {
            title: constants.menuTitle.manageUnitGroupsMasterUpdate.toUpperCase(),
            to: routes.unitGroupAdmin.updateUnitGroupMaster(),
            isActive:
              locationUtils.isRouteCurrentlyActive(routes.unitGroupAdmin.updateUnitGroupMaster())
        }

      ]
    )
}
  


const UnitGroupMasterCreateMenu: React.FC<MenuProps> = function(props: MenuProps) {
    return <Menu {...props} title="ADMIN" items={createItems()} />;
  };

const UnitGroupMasterUpdateMenu: React.FC<MenuProps> = function(props: MenuProps) {
    return <Menu {...props} title="ADMIN" items={editItems()} />;
  };
  

export {
  UnitGroupMasterCreateMenu,
  UnitGroupMasterUpdateMenu
}