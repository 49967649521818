import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	pMain: {
		fontFamily: theme.fontFamily
	},
	logoContent: {
		position: "fixed",
		width: "100%",
		height: "64px",
		top: "0",
		zIndex: "1000"
	},
	pMainContent: {
		width: "100%",
		overflowX: "hidden"
	},
	"@media print": {
		logoContent: {
			position: "relative"
		}
	},
	pContentLayout: {
		display: "flex",
		flexDirection: "row",
		"@media print": {
			"& #drawer": {
				display: "none"
			}
		}
	},
	main: {
		fontFamily: theme.fontFamily
	},
	mainContent: {}
}));
