import { SecondaryReviewGroupItem, SecondaryReviewListForm, PricingAutomationBaseResult } from "api";
import React from "react";
import { ReactNode } from "react";

export const getAvailableUnitColumnTemplate = function () {
	return (rowData: ReactNode) => {
		const offer = rowData as SecondaryReviewListForm | SecondaryReviewGroupItem | PricingAutomationBaseResult;
		return (
			<div>
				{offer.availableUnitCount} <span style={{fontSize: '0.7rem', color: 'grey'}}>({offer.vacantUnitCount}V, {offer.noticeToVacateUnitCount}N)</span>
			</div>
		);
	};
};
