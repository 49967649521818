/* eslint-disable react-hooks/exhaustive-deps */
import { useUserOverridesService } from "components/forms/PricingAutomation/services/userOverrides.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = (unitGroupId : string, number : number | undefined) => {
	const { getPriceResetUnitGroupChangeLogs } = useUserOverridesService();
	const { data, isInitialLoading, isFetching, isPreviousData } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["unit-group-logs", unitGroupId, number],
		queryFn: ({ signal }) =>
			getPriceResetUnitGroupChangeLogs({ unitGroupId: unitGroupId ?? null, num: number ?? 3 }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { localState: data || [], isLoading };
};
