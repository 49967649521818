import React from "react";
import useStyle from "./useStyle";
import constants from "utils/constants";
import { formatCurrencyNoFractions, formatPercentage } from "utils/miscUtils";
import { useLoadData } from "./useLoadData";

export const HeaderRightContent = () => {
	const classes = useStyle();
	const { averageAskingPrice, averagePriceChange, percent, isLoading } = useLoadData();
	const glanceSection = constants.titleSection.atAGlance;
	if (isLoading)
		return (
			<>
				<span className={[classes.loadingContainer, "skeleton", "p-card"].join(" ")} data-testid="skeleton" />
			</>
		);
	return (
		<div className={classes.headerContainer}>
			<span>
				{`${glanceSection.rigth.averageAskingPrice} `}
				{formatCurrencyNoFractions(averageAskingPrice)}
			</span>
			<span>
				{`${glanceSection.rigth.averagePriceChange} `}
				{formatCurrencyNoFractions(averagePriceChange)}
				<span className={classes.percentage}>{formatPercentage(percent)}</span>
			</span>
		</div>
	);
};
