import React, { ReactNode } from "react";
import useStyle from "./useStyle";

interface Props {
	title: string;
	leftContent?: ReactNode;
	rightContent?: ReactNode;
}

/**
 * Page section component
 * Please, use this component inside a Page component to follow the same style pattern.
 * @param title Title of the section displayed in uppercase
 * @param leftContent Left content of the section header, It is contained in a stylized container, so use fragments to put content inside of it.
 * @param rightContent Right content of the section header, It is contained in a stylized container, so use fragments to put content inside of it.
 * @returns React.JSXElement
 */
const PageSection: React.FC<Props> = ({ title, leftContent, rightContent, children }) => {
	const classes = useStyle();

	return (
		<section className={classes.container}>
			<header className={classes.header}>
				<h2 className={classes.title}>{title}</h2>
				<div className={classes.actionsContainer}>
					<div className={classes.actionGroup}>{leftContent}</div>
					<div className={classes.actionGroup}>{rightContent}</div>
				</div>
			</header>
			{children}
		</section>
	);
};

export default PageSection;
