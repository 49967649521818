import React from "react";
import nameof from "nameof";
import { MultiSelect as PrimeMultiSelect } from "primereact/multiselect";
import { withLabel } from "../Label";
import useStyle from "./useStyle";
import listUtils from "utils/listUtils";
import ValidationWrapper from "../ValidationWrapper";

export interface DropdownOption {
	key?: string;
	displayValue?: string;
}

export interface MultiSelectProps {
	inputId?: string;
	inputLabel?: string;
	placeholder?: string;
	selectedValues: DropdownOption[] | undefined;
	options: DropdownOption[] | undefined;
	onChange: (value: DropdownOption[] | undefined) => void;
	hideClear?: boolean | undefined;
	style?: object | undefined;
	showTextOfMultipleSelections?: boolean | undefined;
	multipleSelectionsDefaultText?: string | undefined;
	filter?: boolean;
	filterBy?: string;
	validationMessage?: string | undefined;
	disabled?: boolean;
}

const optionLabel = nameof<DropdownOption>("displayValue");

/**
 * Component used in Pricing Automation Section.
 * @param props
 * @returns React.JSX componenet
 */
const DropdownMultiSelect: React.FC<MultiSelectProps> = function (props) {
	// note: this is hacky, but might be the only way to accomplish this with Prime React.
	const selectedItemTemplate = function (option: DropdownOption | undefined): JSX.Element {
		// used to inflate the size of the dropdown when no value is present.
		const noText = <span>&nbsp;</span>;

		if (!option) {
			return <span>{`-- ${props.placeholder || "All"} --`}</span>;
		}

		if (!props.selectedValues) {
			return <span>{option.displayValue}</span>;
		}

		const manyAreSelected = props.selectedValues && props.selectedValues.length > 1;

		const indexOfOption = listUtils.indexOfKeyedItem(props.selectedValues, option);

		if (indexOfOption === 0) {
			if (props.showTextOfMultipleSelections) {
				return <span>{props.selectedValues.map((value) => value.displayValue).join(", ")}</span>;
			}

			if (manyAreSelected) {
				return <span>{props.multipleSelectionsDefaultText || "Multiple Selections"}</span>;
			} else {
				return <span>{option.displayValue}</span>;
			}
		}

		return <span>{noText}</span>;
	};

	const classes = useStyle();
	const selectedValues =
		(props &&
			props.options &&
			props.options.filter(
				(o) =>
					props &&
					props.selectedValues &&
					props.selectedValues.some((selectedValue) => selectedValue.key === o.key)
			)) ||
		undefined;

	const handleFocus = (event: Event) => {
		const target =
			event.target &&
			(event.target as HTMLElement).parentElement &&
			(event.target as HTMLElement).parentElement?.parentElement;

		const container = target && target.querySelector(".p-multiselect-filter-container");

		container &&
			(container as HTMLElement).querySelector("input") &&
			((container as HTMLElement).querySelector("input") as HTMLElement).focus();
	};

	return (
		<ValidationWrapper showErrorState={props.validationMessage != null}>
			<div className={classes.dropdownWrapper}>
				<label htmlFor={props.inputId}>{props.inputLabel}</label>
				<PrimeMultiSelect
					inputId={props.inputId}
					selectedItemTemplate={selectedItemTemplate}
					className={classes.root}
					value={selectedValues}
					options={props.options}
					optionLabel={optionLabel}
					onChange={(e) => props.onChange(e.value ?? [])}
					placeholder={`-- ${props.placeholder || "Any"} --`}
					filter={props.filter}
					filterBy={props.filterBy}
					disabled={props.disabled}
					showClear={!props.hideClear}
					style={props.style}
					dataKey="key"
					onFocus={handleFocus}
				/>
			</div>
		</ValidationWrapper>
	);
};
export default withLabel(DropdownMultiSelect);
