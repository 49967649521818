import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { PricingPlatformState, getMutableCopyOfPricingPlatformState } from "../PricingPlatformStateTypes";

const UnitGroupAdminPageReducer = (
    oldState: PricingPlatformState,
    action: PricingPlatformActions
  ): PricingPlatformState => {
    const newState = getMutableCopyOfPricingPlatformState(oldState);
    
    switch (action.type) {
      case PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading:
        newState.unitGroupAdminPageState.isLoading = action.payload;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitTypes:
        newState.unitGroupAdminPageState.isLoading = action.payload;
        return newState;
    	case PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitTypes:
			  newState.unitGroupAdminPageState.unitTypes = action.result;
			  return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageCreateUnitGroups:
          newState.unitGroupAdminPageState.unitGroup = action.savedUnitGroup;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnitGroups:
          newState.unitGroupAdminPageState.unitGroup = action.updateUnitGroup;
          return newState;    
      case PricingPlatformActionTypes.UnitGroupAdminPageDeleteUnitGroups:
          newState.unitGroupAdminPageState.deleteUnitGroup = action.deleteUnitGroup;
          return newState;    
      case PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupMasters:
          newState.unitGroupAdminPageState.isLoading = action.payload;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitGroupMasters:
          newState.unitGroupAdminPageState.unitGroupMasters = action.result;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminLoadedUnitGroupMastersWithBedrooms:
            newState.unitGroupAdminPageState.unitGroupMastersWithBedrooms = action.result;
            return newState;
      case PricingPlatformActionTypes.UnitGroupAdminListUnitGroupMasterPage:
          newState.unitGroupAdminPageState.unitGroupMasterPage = action.unitGroupMasterPage;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageFetchingBedroom:
          newState.unitGroupAdminPageState.isLoading = action.payload;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadedBedroom:
          newState.unitGroupAdminPageState.unitGroupBedroom = action.unitGroupBedroom;
          return newState;          
      case PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications:
          newState.unitGroupAdminPageState.isLoading = action.payload;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadedClassifications:
          newState.unitGroupAdminPageState.classifications = action.result;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadClassificationUpdate:
          newState.unitGroupAdminPageState.updateClassification = action.payload;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupDetails:
          newState.unitGroupAdminPageState.isLoading = action.payload;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitGroupDetails:
          newState.unitGroupAdminPageState.unitGroupDetails = action.result;
          return newState;          
      case PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnitGroupFilterOptionsAction:
        newState.unitGroupAdminPageState.unitGroupFilterOptions = action.updatedOptions;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnassignedUnitTypesFilterOptionsAction:
          newState.unitGroupAdminPageState.unassignedUnitTypeFilterOptions = action.updatedOptions;
          return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction:
        newState.unitGroupAdminPageState.tableLoading = action.payload;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadUnitGroupTableAction:
        newState.unitGroupAdminPageState.pagedUnitGroups = action.pagedResult;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminListBedroomsTableAction:
        newState.unitGroupAdminPageState.unitGroupBedrooms = action.unitGroupBedrooms;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminListBedroomsDropdownAction:
        newState.unitGroupAdminPageState.bedroomDropdowns = action.bedroomDropdowns;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageLoadUnassignedUnitTypes:
        newState.unitGroupAdminPageState.unassignedUnitTypes = action.result;
        return newState;
      case PricingPlatformActionTypes.UnitGroupAdminPageSetUnassignedMappings:
        newState.unitGroupAdminPageState.unassignedUnitTypeMapping = action.payload;
        return newState;
    }
        
    return newState;
};

export default UnitGroupAdminPageReducer;