import { Dialog } from "primereact/dialog";
import React from "react";
import useStyle from "./useStyle";
import { useConfigureAutomationFilterContext } from "../../Context";
import { PricingButton } from "components/controls/PricingButton";

interface Props {
	open: boolean;
	onClose: () => void;
	level: string;
}

export const CancelConfirmation = ({ open, onClose, level }: Props) => {
	const classes = useStyle();
	const { setNewRow, handleEditedRowLevel } = useConfigureAutomationFilterContext();

	const handleCancel = () => {
		handleEditedRowLevel("");
		setNewRow({});
		onClose();
	};

	return (
		<Dialog visible={open} header={"Cancel confirmation"} focusOnShow={false} onHide={onClose}>
			<div className={classes.confirmationContainer}>
				<p className={classes.message}>
					Are you sure you want to <strong>Cancel</strong> all changes for <strong>{level}</strong>?
				</p>
			</div>
			<div className={classes.buttonsContainer}>
				<PricingButton variant="primary" type="button" onClick={handleCancel}>
					Cancel changes
				</PricingButton>
				<PricingButton variant="secondary" type="button" onClick={onClose}>
					Continue editing
				</PricingButton>
			</div>
		</Dialog>
	);
};
