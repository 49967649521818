import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	cardContainer: {
		borderRadius: "12px",
		cursor: "pointer",
		"&:hover": {
			scale: "1.025",
			background: theme.palette.grayBackground,
			transition: "0.2s"
		},
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			margin: "auto",
			textAlign: "center",
			maxWidth: "250px"
		},
		"& [class$=p-card-body]": {
			height: "100%",
			display: "flex",
			flexDirection: "column"
		},
		"& [class$=p-card-content]": {
			padding: theme.paddingScale.small,
			marginTop: "12px"
		}
	},
	link: {
		display: "inherit",
		textDecoration: "none",
		color: theme.palette.primary,
		flexGrow: 1
	},
	cardRowContent: {
		display: "flex",
		justifyContent: "space-between",
		marginTop: "4px"
	},
	loadingTiles: {
		height: "150px",
		width: "auto"
	},
	loadingHeaderContent: {
		height: "15px",
		width: "280px",
		"@media (max-width: 768px)": {
			width: "180px"
		}
	},
	disabledLink: {
		cursor: "not-allowed",
		pointerEvents: "none"
	}
}));
