import React, { useState } from "react";
import useStyle from "./useStyle";
import format from "date-fns/format";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption, PricingAdjustmentSummaryReportRequest } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import MultiSelect from "components/controls/MultiSelect";
import constants from "utils/constants";
import { formatCurrency, formatCurrencyNoFractions, formatDecimalNumber, formatPercentage } from "utils/miscUtils";
import { Calendar } from "primereact/calendar";
import { PricingAdjustmentSummaryReportResponse } from "api";
import createDataTable, { ColumnDefinition, IPagedTableState } from "components/controls/createTable";
import { addDays } from "date-fns";
import Spacer from "components/controls/Spacer";

const PricingAdjustmentSummaryReportPage: React.FC = function () {
	const classes = useStyle();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.pricingAdjustmentSummaryReportPageState.filterOptions,
		pricingAdjustmentSummaryReports: appState.pricingAdjustmentSummaryReportPageState.pricingAdjustmentSummaryReports,
		isLoading: appState.pricingAdjustmentSummaryReportPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.pricingAdjustmentSummaryReportPage,
		...appActions
	};

	const [tableState, setTableState] = useState<IPagedTableState>({  });

	//does this not need to use the store because we defined directly here?
	interface LocalState {
		currentFilters: PricingAdjustmentSummaryReportRequest;
		appliedFilters: PricingAdjustmentSummaryReportRequest;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getForm(localState.currentFilters);
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.division = division;
		curSelections.property = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.property = properties;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function onSelectStartDate(startDate: Date | Date[]) {
		var curSelections = { ...localState.currentFilters };
		curSelections.startDate = new Date(startDate.toString());
		if (curSelections.endDate && curSelections.endDate < curSelections.startDate) {
			curSelections.endDate = undefined;
		}
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function onSelectEndDate(endDate: Date | Date[]) {
		var curSelections = { ...localState.currentFilters };
		curSelections.endDate = new Date(endDate.toString());
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function applySearchDisabled() {
		const divisionNotSelected = !localState.currentFilters.division;

		var propertyNotSelected =
			localState.currentFilters.property === null ||
			localState.currentFilters.property === undefined ||
			localState.currentFilters.property.length === 0;

		return (
			pageState.isLoading ||
			(divisionNotSelected && propertyNotSelected) ||
			localState.currentFilters.startDate == undefined ||
			localState.currentFilters.endDate == undefined
		);
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		setLocalState({
			...localState,
			appliedFilters: curSelections
		});

		actions.searchReportData(curSelections);
	}

	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		}
		if (applySearchDisabled() === true) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	function numberTemplate(rowData: any, column: string) {
		return rowData[column].toLocaleString("en-US");
	}


	window.addEventListener("afterprint", (e) => {document.title = "Essex Pricing Platform"});

	function applyExport() {
		const startDate = localState.appliedFilters.startDate && format(localState.appliedFilters.startDate, "yyyy-MM-dd");
		const endDate = localState.appliedFilters.endDate && format(localState.appliedFilters.endDate, "yyyy-MM-dd");

		document.title = `PriceAdjustmentSummary_${startDate}-${endDate}`;

		window.print();
	}

	const columns: ColumnDefinition<
		PricingAdjustmentSummaryReportResponse,
		keyof PricingAdjustmentSummaryReportResponse
	>[] = [
		{ field: "division", header: "Division", className: classes.divisionColumn },
		{ field: "county", header: "County", className: classes.countyColumn },
		{ field: "collection", header: "Collection", className: classes.collectionColumn },
		{ field: "propertyName", header: "Property Name", className: classes.propertyColumn },
		{ field: "unitGroup", header: "Unit Group", className: classes.floorplanColumn },
		{
			field: "propertyAtr",
			header: "Prop ATR",
			body: (e: any) => {
				if (e.propertyAtr !== undefined) {
					return formatPercentage(e.propertyAtr);
				} else {
					return "N/A";
				}
			},
			className: classes.propertyAtrColumn
		},
		{
			field: "unitGroupAtr",
			header: "UG ATR",
			body: (e: any) => {
				if (e.unitGroupAtr !== undefined) {
					return formatPercentage(e.unitGroupAtr);
				} else {
					return "N/A";
				}
			},
			className: classes.unitGroupAtrColumn
		},
		{ field: "unitsAvailable", header: "Units Avail", className: classes.unitsAvailableColumn },
		{
			field: "startNormalizedRent",
			header: "Normalized Start Rent",
			body: (e: any) => {
				return formatCurrencyNoFractions(e.startNormalizedRent);
			},
			className: classes.startNormalizedRentColumn
		},
		{
			field: "endNormalizedRent",
			header: "Normalized End Rent",
			body: (e: any) => {
				return formatCurrencyNoFractions(e.endNormalizedRent);
			},
			className: classes.normalizedRentColumn
		},
		{
			field: "cumulativeChangeDollar",
			header: "Cumulative $",
			body: (e: any) => {
				return formatCurrencyNoFractions(e.cumulativeChangeDollar);
			},
			className: classes.normalizedRentColumn
		},
		{
			field: "cumulativeChangePercent",
			header: "Cumulative %",
			body: (e: any) => {
				return formatPercentage(e.cumulativeChangePercent);
			},
			className: classes.normalizedRentColumn
		},
		{
			field: "adjustmentType",
			header: "Daily Adjust",
			bodyStyle: { textAlign: "center" },
			headerStyle: { textAlign: "center" }
		}
	];

	const generateCsv = () => {
		let csvContent: string = "";
		csvContent += columns.map((x) => x.header).join(",") + "\r\n";

		const fields = columns.map((x) => x.field);

		if (!pageState.pricingAdjustmentSummaryReports) {
			return;
		}
		for (const item of pageState.pricingAdjustmentSummaryReports) {
			const row = [];
			for (const field of fields) {
				if (!field) {
					continue;
				}
				row.push(`"${item[field]}"`);
			}
			csvContent += row.join(",") + "\r\n";
		}

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
		const objUrl = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.setAttribute("href", objUrl);

		const startDate = localState.appliedFilters.startDate && format(localState.appliedFilters.startDate, "yyyy-MM-dd");
		const endDate = localState.appliedFilters.endDate && format(localState.appliedFilters.endDate, "yyyy-MM-dd");

		link.setAttribute(
			"download",
			`PriceAdjustmentSummary_${startDate}-${endDate}.csv`
		);
		link.click();
	};

	const displayReport = () => {
		if (
			pageState.pricingAdjustmentSummaryReports !== null &&
			pageState.pricingAdjustmentSummaryReports !== undefined
		) {
			if (pageState.pricingAdjustmentSummaryReports.length === 0) {
				return (
					<div className={classes.empty}>
						<h3>No Pricing Adjustments</h3>
					</div>
				);
			}

			return (
				<div>
					<h3>
						Report for{" "}
						{localState.appliedFilters.startDate && format(localState.appliedFilters.startDate, "MM/dd/yyyy")} to{" "}
						{localState.appliedFilters.endDate && format(localState.appliedFilters.endDate, "MM/dd/yyyy")}
					</h3>
					<div className={classes.reportHeading} style={{ pageBreakAfter: "always" }}>
						<div id="unitTypeHeadingMargin2">
							{createDataTable(
								columns, {
								value: pageState.pricingAdjustmentSummaryReports,
								state: tableState,
								paging: false,
								updateState: setTableState
							})}
						</div>
					</div>
				</div>
			);
		} else {
			if (localState.currentFilters.property === null || localState.currentFilters.property === undefined) {
				return <div className={classes.empty}>Please Select A Property Using The Filters Above</div>;
			}
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.pricingAdjustmentSummaryReport} />}>
			<div id="pricingAdjustmentSummaryReportFilters" className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={localState.currentFilters.division}
						options={pageState.filterOptions!.divisions}
						onChange={(d) => onSelectDivision(d)}
						disabled={pageState.filterOptions!.divisions === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"Select properties"}
						selectedValues={localState.currentFilters.property}
						options={pageState.filterOptions!.properties}
						onChange={(d) => onSelectProperties(d)}
						disabled={pageState.filterOptions!.properties === undefined}
						hideClear={false}
						filter
						style={{ width: 350 }}
					></MultiSelect>
				</div>
				<div className={classes.filterItem}>
					<label htmlFor="startDatePicker" title="Start Date">
						Start Date
					</label>
					<div className={classes.calendarOption}>
						<Calendar
							id="startDatePicker"
							style={{ maxHeight: "30px", alignSelf: "center", width: "150px" }}
							value={localState.currentFilters.startDate}
							onChange={(d) => onSelectStartDate(d.value)}
							disabled={pageState.filterOptions!.properties === undefined}
							maxDate={new Date()}
							dateFormat="mm-dd-yy"
							placeholder="mm-dd-yyyy"
							showIcon
						/>
					</div>
				</div>
				<div className={classes.filterItem}>
					<label htmlFor="endDatePicker" title="End Date">
						End Date
					</label>
					<div className={classes.calendarOption}>
						<Calendar
							id="endDatePicker"
							style={{ maxHeight: "30px", alignSelf: "center", width: "150px" }}
							value={localState.currentFilters.endDate}
							onChange={(d) => onSelectEndDate(d.value)}
							disabled={localState.currentFilters.startDate === undefined}
							minDate={localState.currentFilters.startDate && addDays(localState.currentFilters.startDate, 1)}
							maxDate={new Date()}
							dateFormat="mm-dd-yy"
							placeholder="mm-dd-yyyy"
							showIcon
						/>
					</div>
				</div>

				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled()}
				></Button>
				<div className={classes.exportButton}>
					<Button
						label="PDF Export"
						className={classes.applyButton}
						onClick={() => applyExport()}
						disabled={applySearchDisabled()}
					></Button>
					<Spacer></Spacer>
					<Button
						label="CSV Export"
						className={classes.applyButton}
						onClick={() => generateCsv()}
						disabled={applySearchDisabled()}
					></Button>
				</div>
			</div>
			<div id="pricingSummaryContentContainer" className={classes.contentContainer}>
				{pageState.isLoading ? <ProgressSpinner /> : <div className={classes.pageHeading}> {displayReport()}</div>}
			</div>
		</Page>
	);
};

export default PricingAdjustmentSummaryReportPage;
