import { Card } from "primereact/card";
import React from "react";
import useStyle from "./useStyle";
import { Link } from "react-router-dom";
import routes from "components/routes";
import css from "utils/cssUtils";
import { useAtAGlanceContext } from "../Context";

type Props = {
	id?: string;
	title?: string;
	toReview?: number;
	completed?: number;
	disabled?: boolean;
};

export const InfoCard = ({ id, title = "", toReview = 0, completed, disabled = false }: Props) => {
	const classes = useStyle();
	const { filters } = useAtAGlanceContext();

	const items = [
		{ name: "To Review", value: toReview },
		{ name: "Completed", value: completed }
	];

	return (
		<Link
			to={`${routes.pricingAutomation.secondaryReview}?division=${filters.divisionId}&id=${id}`}
			className={[classes.link, css.conditionClass(classes.disabledLink, disabled)].join(" ")}
		>
			<Card title={title} className={classes.cardContainer}>
				{items.map(
					(item, index) =>
						item.value !== undefined && (
							<div key={index} className={classes.cardRowContent}>
								<span>{item.name}</span>
								<span>{item.value}</span>
							</div>
						)
				)}
			</Card>
		</Link>
	);
};
