import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  filterControls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",

    "&__title": {
      fontWeight: "bold",
      fontSize: "14px"
    }
  },
  saveControls: {
    display: "flex",
    alignItems: "center"
  }
}));
