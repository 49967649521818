import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	cardContainer: {
		overflow: "hidden",
		minHeight: "180px",
		gridColumn: "span 2",
		height: "max-content",
		"@media (max-width: 1280px)": {
			gridColumn: "span 2"
		},
		"@media (max-width: 768px)": {
			gridColumn: "span 4"
		},
		borderRadius: "8px",
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			marginLeft: "auto",
			marginRight: "auto",
			textAlign: "center"
		},
		"& [class$=p-card-body]": {
			height: "100%",
			display: "flex",
			flexDirection: "column"
		},
		"& [class$=p-card-content]": {
			padding: "0px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			height: "100%"
		}
	},
	list: {
		listStyle: "none",
		width: "100%",
		margin: "0",
		padding: "0",
		marginTop: "14px",
		"& li": {
			display: "flex",
			padding: "8px 0",
			justifyContent: "space-between",
			borderBottom: `1px solid ${theme.palette.lightGray}`
		},
		"& li:first-child": {
			borderTop: `1px solid ${theme.palette.lightGray}`
		}
	},
	listItem: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	listItemMultipleRows: {
		flexFlow: "column"
	},
	listItemLabel: {
		display: "flex",
		gap: "8px",
		alignItems: "center"
	},
	subListItem: {
		marginTop: "8px"
	},
	bold: {
		fontWeight: "500"
	},
	success: {
		color: theme.palette.success
	},
	warning: {
		fill: theme.palette.warning
	},
	error: {
		color: theme.palette.error
	},
	percentageLabel: {
		display: "flex",
		justifyContent: "end",
		minWidth: "45px"
	},
	iconSpacing: {
		marginLeft: "24px"
	}
}));
