import { IconButton } from "components/controls/PricingIconButton";
import React from "react";
import useStyle from "../useStyle";
import { useConfigureAutomationFilterContext } from "../../Context";
import { useFormContext } from "react-hook-form";
import { isEmptyObject } from "utils/miscUtils";
import { useActionsContext } from "./Context/context";

export const FormatActions = ({ data }: { data: any }) => {
	const classes = useStyle();
	const { formState, trigger } = useFormContext();
	const { editedRowLevel, handleEditedRowLevel, newRow } = useConfigureAutomationFilterContext();

	const isDisabled = editedRowLevel !== "" && editedRowLevel !== data["name"];
	const isEditing = editedRowLevel === data["name"];

	const { handleOpen, handleData } = useActionsContext();

	const onOpen = (type: string) => {
		handleOpen(type);
		handleData(data);
	};

	return (
		<>
			<div className={[!isEditing && classes.hideActions, classes.actionsContainer].join(" ")}>
				<IconButton
					tooltip="Confirm"
					type="button"
					onClick={() => trigger().then((valid) => valid && onOpen("save"))}
					form="configureAutomationForm"
					disabled={isEmptyObject(newRow) && !formState.isDirty}
				>
					<i className="pi pi-check" />
				</IconButton>
				<IconButton tooltip="Cancel" type="button" onClick={() => onOpen("cancel")}>
					<i className="pi pi-times" />
				</IconButton>
			</div>
			<div className={[isEditing && classes.hideActions, classes.actionsContainer].join(" ")}>
				<IconButton
					tooltip="Edit"
					type="button"
					disabled={isDisabled}
					onClick={() => handleEditedRowLevel(data["name"])}
				>
					<i className="pi pi-pencil" />
				</IconButton>
				{data["name"] !== "Portfolio" ? (
					<IconButton tooltip="Delete" type="button" disabled={isDisabled} onClick={() => onOpen("delete")}>
						<i className="pi pi-trash" />
					</IconButton>
				) : null}
			</div>
		</>
	);
};
