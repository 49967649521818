//import { FaCentercode } from "react-icons/fa";
import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	pageWrapper: {
		display: "flex",
		width: "100%",
		padding: "1 rem"
	},
	expansionWrapper: {
		display: "flex",
		padding: "15px 15px 15px 25px"
	},
	expansionTable: {
		paddingBottom: "10px"
	},
	expansionChild: {
		backgroundColor: "white",
		border: "2px solid black",
		display: "flex",
		padding: "15px 15px 15px 15px"
	},
	expansionLeftColumn: {
		//  display: "flex",
		//  flexDirection: "column",
		//  width: "50%"
		width: "33%"
	},
	expansionRightColumn: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "80px",
		marginTop: "30px",
		width: "33%"
	},
	amenityLabel: {
		paddingBottom: "10px"
	},
	baseRent: {
		paddingBottom: "30px"
	},
	dataTablestyling: {
		marginBottom: "15px"
	},
	filterHeaders: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		padding: "8px 0 30px 15px",
		alignItems: "center"
	},
	filterItem: {
		display: "flex",
		flexDirection: "column",
		marginRight: "40px"
	},
	summaryInfo: {
		marginLeft: "16px"
	},
	checkbox: {
		marginTop: "15px",
		marginRight: "40px"
	},
	applyButton: {
		alignItems: "center",
		height: "30px",
		marginTop: "15px"
	},
	applyButton2: {
		alignItems: "center",
		height: "30px",
		marginTop: "15px",
		boxShadow: "0 0 0 0.2rem #8dcdff"
	},
	numberColumn: {
		textAlign: "right !important"
	},
	amenityColumn: {
		textAlign: "right"
	},
	sqft: {
		width: "120px",
		textAlign: "right !important"
	},
	matrixLink: {
		color: "inherit"
	}
}));
