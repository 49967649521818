import { PagedResultOfPropertySummary } from "api/pricing-platform-api";
import { ExceptionsSearchState } from "components/store/ExceptionsPage/exceptionsPageInterfaces";
import { PropertyDropdownSelections } from "components/store/PricingPlatformStateTypes";
import { 
    AdvancedFilter, 
    AdvancedFilters    
} from "components/store/PropertiesPage/propertyPageInterfaces";
import constants from "utils/constants";

/**
 * Exceptions search initial state.
 * @returns ExceptionsSearchState
 */
export const initSearchState = (): ExceptionsSearchState => {
    return {		
		skip: 0,
		take: constants.rowsPerPageOptions[0],
		sortBy: "name",
		sortDirection: constants.sort.asc,		
		sortOrder: constants.sort.ascOrder 
	}
};

/**
 * Initial filters param values from uri.
 * @param props 
 * @returns PropertyDropdownSelections
 */
export const getInitParams = (props: any): PropertyDropdownSelections => {
    const filters: PropertyDropdownSelections = {
        selectedDivision: undefined,
        selectedCollection: undefined,
        selectedProperties: undefined,			
        selectedUnitGroup: undefined
    };
    const params = props.match !== undefined ? props.match.params : undefined;
    if (params && params.divisionId !== ":divisionId") {
        filters.selectedDivision = { key: params.divisionId };
    }		

    if (params && params.collectionId !== ":collectionId") {
        filters.selectedCollection = { key: params.collectionId };
    }

    if (params && params.unitGroupId !== ":unitGroupId") {
        filters.selectedUnitGroup = { key: params.unitGroupId };
    }

    return filters;
};

/**
 * Search query for exceptions.
 * @param filters 
 * @param advancedFilters 
 * @param skip 
 * @param take 
 * @param sortField 
 * @param sortOrder 
 * @returns ExceptionsSearchState
 */
export const getSearchQuery = (
    filters: PropertyDropdownSelections | undefined,
    advancedFilters: AdvancedFilters | undefined,
    skip: number | undefined = 0,
    take: number | undefined = 10,
    sortField: string | undefined = undefined,
    sortOrder: number | undefined = undefined,
): ExceptionsSearchState => {

    if (skip <= 0) skip = 0;
    if (take <= 0) take = 10;

    let query: ExceptionsSearchState = {        
        skip: typeof skip == "undefined" ? 0 : skip,
        take: typeof take == "undefined" ? 10 : take,
        sortBy: sortField || "",
        sortOrder: sortOrder || 1,
        sortDirection: sortOrder === -1 ? constants.sort.desc : constants.sort.asc
    };

    if (filters) {
        query.divisionId = filters.selectedDivision ? filters.selectedDivision.key : undefined;
        query.propertyIds = filters.selectedProperties ? filters.selectedProperties.map(x => x.key).filter(x => x !== undefined) as string[] : undefined;
        query.unitGroupId = filters.selectedUnitGroup ? filters.selectedUnitGroup.key : undefined;
        query.county = filters.selectedCounty ? filters.selectedCounty.key : undefined;
        query.assetCollectionId = filters.selectedCollection ? parseInt(filters.selectedCollection.key || '0') : undefined;
    }

    if (advancedFilters) {
        if (advancedFilters.priorityFilters && advancedFilters.priorityFilters.length) {
            const priorityFiltersSelected = advancedFilters.priorityFilters.filter(
                (filter) => filter.operator && filter.operator.key !== "Excluded"
            );
            const priofilters: AdvancedFilter[] = priorityFiltersSelected.map((filter) => {
                const value = (filter.isPercentage ? filter.filterValue / 100 : filter.filterValue).toString();
                return {
                    filterName: filter.filterName,
                    filterLevel: filter.filterLevel,
                    filterValue: value,
                    operator: filter.operator ? Number(filter.operator.key) : undefined
                };
            });
            query.priorityFilters = priofilters.length ? priofilters : undefined;
        }

        if (
            advancedFilters.lastAssesedDateFilter &&
            advancedFilters.lastAssesedDateFilter.operator &&
            advancedFilters.lastAssesedDateFilter.operator.key !== "Excluded" &&
            advancedFilters.lastAssesedDateFilter.filterValue
        ) {
            const lastAssesedDateFilterSelected = advancedFilters.lastAssesedDateFilter;
            query.lastAssesedDateUG = {
                ...lastAssesedDateFilterSelected,
                operator: lastAssesedDateFilterSelected.operator
                    ? Number(lastAssesedDateFilterSelected.operator.key)
                    : undefined,
                filterValue: lastAssesedDateFilterSelected.filterValue
                    ? lastAssesedDateFilterSelected.filterValue.toLocaleDateString("en-US", {year:"numeric",month:"2-digit", day:"2-digit"})
                    : ""
            };
        } else {
            query.lastAssesedDateUG = undefined;
        }
    }
    
    return query;
};

/**
 * Update the drop down selected values.
 * @param type 
 * @param value 
 * @param curSelections 
 * @returns PropertyDropdownSelections
 */
export const updateFilters = (
    type: string, 
    value: any, 
    curSelections: PropertyDropdownSelections): PropertyDropdownSelections => {				
    switch (type) {
        case constants.filters.division:
            curSelections.selectedDivision = value;
            curSelections.selectedCounty = undefined;
            curSelections.selectedProperties = undefined;
            curSelections.selectedUnitGroup = undefined;
            curSelections.selectedCollection = undefined;
          break;
        case constants.filters.county:
            curSelections.selectedCounty = value;
            curSelections.selectedCollection = undefined;
            curSelections.selectedProperties = undefined;								
            curSelections.selectedUnitGroup = undefined;
          break;
        case constants.filters.collection:	
            curSelections.selectedCollection = value;			
            curSelections.selectedProperties = undefined;				
            curSelections.selectedUnitGroup = undefined;				
            break;
        case constants.filters.property:
            curSelections.selectedProperties = value;
            curSelections.selectedUnitGroup = undefined;
            curSelections.selectedUnit = undefined;
            break;			
        case constants.filters.unitGroup:				
            curSelections.selectedUnitGroup = value;
            break;
    }									
    return curSelections;		
};

/**
 * Property page data type.
 */
export type PropertyPageData= {
	pagedProperties?: PagedResultOfPropertySummary;
	onChange: (
		filters: PropertyDropdownSelections | undefined,
		advancedFilters: AdvancedFilters | undefined,
		skip: number | undefined,
		take: number | undefined,
		sortField: string | undefined,
		sortOrder: number | undefined
		) => void;
};