import React from "react";
import { Column } from "primereact/column";
import { ColumnType } from "./types";

interface Props {
	columns: ColumnType[];
}

/**
 * This component render the columns for the content and assing
 * properties to each column.
 *
 * @example
 * <ContentColumns columns={columns} />
 *
 * @returns JSX.Element
 */
export const ContentColumns = ({ columns }: Props) => {
	return (
		columns?.map(
			({ subColumns, visible = true, align = "center", color, minWidth, frozen = false }) =>
				visible &&
				subColumns &&
				subColumns.map((subColumn, index) => (
					<Column
						style={{ width: subColumn.minWidth ?? minWidth }}
						frozen={frozen}
						key={`${subColumn.name}-${index}`}
						field={subColumn.field}
						bodyStyle={{ textAlign: align, color, minWidth: subColumn.minWidth }}
						body={subColumn.format ?? subColumn.body}
					/>
				))
		) ?? []
	);
};
