import {
	AskingPriceLeasingActivityDataResult,
	AskingPriceLeasingActivityItemData,
	DashboardChartData,
	DashboardChartRecord
} from "api";
import { formatDate } from "utils/miscUtils";

export interface AskingPriceLeasingMapped extends Omit<AskingPriceLeasingActivityItemData, "date"> {
	date: string;
}

export const leasingActivityMapperUnitGroup = (
	data: AskingPriceLeasingActivityDataResult
): AskingPriceLeasingMapped[] => {
	if (!data.rows) return [];

	const mappedData = data.rows.map((row: AskingPriceLeasingActivityItemData) => ({
		...row,
		date: formatDate(row.date),
		valueEssGross: row.valueEssGross,
		valueAtrPercentage: row.valueAtrPercentage
	}));

	return mappedData;
};

export const leasingActivityMapper = (data: DashboardChartData): AskingPriceLeasingMapped[] => {
	if (!data.series) return [];

	const mappedData = data.series!.map((row: DashboardChartRecord) => ({
		...row,
		date: formatDate(row.date),
		valueEssGross: row.essGrossAskingRent,
		valueAtrPercentage: row.atr
	}));

	return mappedData;
};
