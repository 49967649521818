import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	compPremiumContainer: {
		gridColumn: "span 2",
		gridRow: "span 1",
		"& [class$=p-card-title]": {
			fontWeight: 700,
			fontSize: "0.8em",
			margin: "unset"
		},
		"& [class$=p-card-body]": {
			padding: "6px 8px"
		},
		"& [class$=p-card-content]": {
			fontSize: "0.8em",
			fontWeight: 600,
			padding: "unset",
			display: "inline-flex",
			flexDirection: "column"
		},
		"@media (max-width: 1280px)": {
			gridColumn: "span 2"
		},
		"& [data-value=false]": {
			color: theme.palette.success,
			fontWeight: 700
		},
		"& [data-value=true]": {
			color: theme.palette.softRed,
			fontWeight: 700
		}
	},
	loadingCompPremiumCard: {
		gridColumn: "span 2",
		gridRow: "span 1",
		height: "inherit"
	}
}));
