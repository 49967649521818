import React, { useState } from "react";
import Page from "components/layouts/Page";
import RequirePermission from "../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import PrimaryButton from "components/controls/PrimaryButton";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import Dialog from "components/controls/Dialog";
import { Button } from "primereact/button";
import BedroomMenu from "./BedroomMenu";
import { Bedroom, BedroomForm } from "api";
import { isNullOrUndefinedOrEmpty } from "utils/miscUtils";
import useFirstLoadEffect from "utils/useMountEffect";
import { BedroomParams, bedroomInitState, getPageActionType } from "../MiscUnitGroupFunctions";
import { InputText } from "primereact/components/inputtext/InputText";
import Spinner from "components/controls/Spinner";

import "./_manage-bedroom.scss";
import { InputNumber } from "primereact/inputnumber";

const ManageBedroomAdminPage: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();	

	const [pageIsDirty, setPageIsDirty] = useState(false);
    const [saveError, setSaveError] = useState(false);

	const history = useHistory();
    const location = useLocation();  
    const params = useParams<BedroomParams>();   

	const [localState, setLocalState] = useState({
		...bedroomInitState,
		bedroomId: params.bedroomId === "" ? "": params.bedroomId,
		isLoading: true
	});

	const renderFooter = () => {
		return (
			<div>
				<Button 
					label="OK" 
					icon="pi pi-check" 
					onClick={() => setSaveError(false)} 
				/>
			</div>
		);
	};

	const getPathParams = (): void => {
        if (location && !isNullOrUndefinedOrEmpty(location.pathname)) {                                                   					
			const action = getPageActionType(location.pathname);			
			if (action && action === constants.pageAction.create) {                                                                                                              
				localState.pageAction = constants.pageAction.create;                             
			}
			
			if (action && action === constants.pageAction.update) {
				localState.bedroomId = params.bedroomId;
				localState.pageAction = constants.pageAction.update;                  
			}    

			setLocalState({
				...localState            
			});	                    
        }  else {
			console.log("Invalid url.");
			redirectBedroomHome();
		}      
    };

	const redirectBedroomHome = () => {
		history.push(routes.unitGroupAdmin.bedrooms);
	};

	useFirstLoadEffect(() => {
		try {		

			getPathParams();

			if (localState.pageAction === constants.pageAction.update) {				
				actions.loadBedroom(localState.bedroomId).then((result: Bedroom) => {
					if (result !== undefined || result !== null) {
						localState.numberOfBedrooms = result.bedroomCount;
						localState.description = result.description!;
						localState.initialUpdateBedroom = result.bedroomCount;
						localState.initialUpdateDescription = result.description!;
						localState.isLoading = false;
						setLocalState({...localState});
					}
				})
			} else {
				localState.bedroomId = "";
				localState.numberOfBedrooms = undefined;
				localState.description = "";
				localState.isLoading = false;
				setLocalState({...localState});
			}
		} catch (error) {			
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
			localState.isLoading = false;
			setLocalState({...localState});
		}
	});
	
    const onCancel = (): void => {
		redirectBedroomHome();
	}

	const onSave = (): void => {

		localState.isLoading = true;
		setLocalState({...localState});
		
		if (localState.pageAction === constants.pageAction.create) {
			const createForm = {				
				bedroomCount: localState.numberOfBedrooms, 
				description: localState.description,
			} as BedroomForm;
		
			actions.createBedroom(createForm).then((result: string|null|undefined) => {
				redirectBedroomHome();
			});		
		} else {		
			const updateForm = {
				bedroomId: localState.bedroomId,
				bedroomCount: localState.numberOfBedrooms, 
				description: localState.description,
			} as BedroomForm;
		
			actions.updateBedroom(updateForm).then((result: string|null|undefined) => {
				redirectBedroomHome();
			});
		}
	}

    const onBedroomChange = (value: number | undefined) => {
		if(localState.pageAction === constants.pageAction.update){
			setPageIsDirty(value!==undefined && value !== localState.initialUpdateBedroom);
		}else{
			if (localState.numberOfBedrooms === undefined || value === null ) {
				setPageIsDirty(false);
			} else {
				setPageIsDirty(true);
			} 
		}
		
		let updateState = {
            ...localState,           
            numberOfBedrooms: value, 
        };
        setLocalState({...updateState}); 		
	}

    const onDescriptionChange = (value: string) => {
		if (localState.pageAction === constants.pageAction.update) {   
			setPageIsDirty((value !== localState.initialUpdateDescription) || localState.numberOfBedrooms !== localState.initialUpdateBedroom);
		}else{
			if (localState.description === "" && (localState.numberOfBedrooms !== undefined && localState.numberOfBedrooms !== null)) {
				setPageIsDirty(true);
			}
			else if(localState.description !== "" && (localState.numberOfBedrooms === undefined || localState.numberOfBedrooms === null)){
				setPageIsDirty(false);
			}
		}

		let updateState = {
            ...localState,           
            description: value, 
        };
        setLocalState({...updateState}); 				
    }

	const onBlurClick = () => {        
        if (localState.pageAction === constants.pageAction.create && (localState.numberOfBedrooms !== undefined && localState.numberOfBedrooms !==null)) {            
				setPageIsDirty(true);
		}   
		
		if (localState.pageAction === constants.pageAction.update && ((localState.numberOfBedrooms !== undefined && localState.numberOfBedrooms !== null && localState.numberOfBedrooms !== localState.initialUpdateBedroom) || (localState.description !== localState.initialUpdateDescription))){  	
			setPageIsDirty(true);
		}
    }

	const getTitle = (): string => {
		return localState.pageAction === constants.pageAction.create ? 
				"Create Bedroom" : "Edit Bedroom";
	}

    const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}>
				<PrimaryButton
						className="cancel-button"
						onClick={onCancel}
						title="CANCEL"
					/>
				<PrimaryButton
						className="save-button"
						disabled={!pageIsDirty || localState.numberOfBedrooms === undefined || localState.numberOfBedrooms === null}
						onClick={onSave}
						title="SAVE"
					/>
					<Dialog
						header="SAVE ERROR"
						footer={renderFooter()}
						isVisible={saveError}
						onHide={() => setSaveError(false)}
					>
						Save was unsuccessful. Please try again.{" "}
					</Dialog>
				</VisibilityToggle>
			</div>
		</RequirePermission>
	);

    return (
		<Page menu={
				<BedroomMenu  actionArea={navbarActionArea} action={localState.pageAction}/>
			}			
		>
			<Card className="manage-bedroom">			
				<Spinner isVisible={localState.isLoading}/>										
				<div>													
					<div className="createBedroomForm">
						<h1>{getTitle()}</h1>
						<div className="gap">
							<label className="label" htmlFor="bedroomCount">
								Number of Bedrooms
								<span className="star">*</span>
							</label>											
							<InputNumber id="bedroomCount" className="bedroom-input" 
								//autoFocus={true}
								tabIndex={1}
								minFractionDigits={0}
								step={1}
								value={localState.numberOfBedrooms}
								onChange={(e) => onBedroomChange(e.value)}							
								onBlur = {() => onBlurClick()}
								>
							</InputNumber>
						</div>
						<div className="gap">
							<label className="label" htmlFor="description">Description</label>
							<InputText id="description" className="bedroom-input" 
								tabIndex={2}
								value={localState.description} 
								onChange={(e) => onDescriptionChange(e.currentTarget.value)}
							/>
						</div>											
					</div>												
				</div>			
			</Card>
		</Page>
	);
};

export default ManageBedroomAdminPage;