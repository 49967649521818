import { DataTable } from "components/controls/DataTable";
import React from "react";
import { columns } from "./columns";
import { useLoadData } from "./useLoadData";
import useStyle from "./useStyle";

interface Props {
	hideColumnRef?: React.MutableRefObject<any>;
	unitGroupId: string;
	numberOfRows: number | undefined;
}

export const AuditLogTable = ({ hideColumnRef, unitGroupId, numberOfRows }: Props) => {
	const classes = useStyle();
	const { localState, isLoading } = useLoadData(unitGroupId, numberOfRows);

	return (
		<DataTable
			columns={columns}
			data={localState}
			loading={isLoading}
			tableName="Price reset table"
			hideColumnsRef={hideColumnRef}
			alwaysShowPaginator={false}
			tableClassName={classes.table}
		/>
	);
};
