import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import {
  getMutableCopyOfPricingPlatformState,
  PricingPlatformState,
} from "../PricingPlatformStateTypes";
import { PricingPlatformActions } from "../PricingPlatformActions";

const PricingMatrixPageReducer = (
  oldState: PricingPlatformState,
  action: PricingPlatformActions
): PricingPlatformState => {
  const newState = getMutableCopyOfPricingPlatformState(oldState);

  switch (action.type) {
    case PricingPlatformActionTypes.UpdateMatrixPageFilterOptions:
      newState.pricingMatrixState.filterOptions = action.updatedOptions;
      return newState;
    case PricingPlatformActionTypes.UpdateCurrentMatrices:
      newState.pricingMatrixState.currentMatrices = action.payload;
      return newState;
      case PricingPlatformActionTypes.UpdatePricingMatrixPageLoading:
        newState.pricingMatrixState.pageLoading = action.payload;
        return newState;
  }
  return newState;
};

export default PricingMatrixPageReducer;