/* eslint-disable react-hooks/exhaustive-deps */
import { useSecondaryReviewFilterContext } from "../../Context";
import { leadAndNetMapper } from "./leadsAndNetMapper";
import { useHomeService } from "components/forms/PricingAutomation/services/home.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getLeadAndNetApps } = useHomeService();
	const { selectedRow } = useSecondaryReviewFilterContext();
	const { data, isFetching, isPreviousData, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["leads-and-net", selectedRow],
		queryFn: ({ signal }) => getLeadAndNetApps({ divisionId: "", unitGroupId: String(selectedRow?.group) }, signal)
	});
	const isLoading = isInitialLoading || (isFetching && isPreviousData);

	return { data: leadAndNetMapper(data || {}), isLoading };
};
