import React, { useState, useEffect } from "react";
import { withLabel } from "../Label";
import useStyle from "./useStyle";
import ValidationWrapper from "../ValidationWrapper";
import { InputNumber as ReactInputNumber } from "primereact/inputnumber";

export interface InputProps {
  value: number | undefined;
  onChange?: (value: number) => void;
  onUnfocus?: (value: number) => void;
  validationMessage?: string | undefined;
  className?: string;
  disabled?: boolean;
}

const InputNumberStateless: React.FC<InputProps> = function(props) {
  const classes = useStyle(props);

  return (
    <span className="pricingPlatformPrimeOverride-input-with-validation">
      <ValidationWrapper showErrorState={props.validationMessage != null}>
        <ReactInputNumber
          value={props.value}
          disabled={props.disabled}
          className={[classes.input, props.className].join(" ")}
          mode="decimal"
          minFractionDigits={1}
          maxFractionDigits={1}
          tooltip={props.validationMessage}
          tooltipOptions={{ event: "hover" }}
          onValueChange={e => {
            const value = e.value;

            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </ValidationWrapper>
    </span>
  );
};
export default withLabel(InputNumberStateless);
