import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	dropdownsContainer: {
		display: "grid",
		gridAutoFlow: "column",
		justifyContent: "center",
		gap: "20px",
		flexWrap: "wrap",
		padding: "1.5rem 20px 0",
		"@media (max-width: 768px)": {
			gridAutoFlow: "unset",
			gridTemplateColumns: "auto"
		}
	},
	dropdowns: { width: "200px" },
	errorMessage: {
		color: theme.palette.error,
		paddingBottom: "8px",
		fontSize: "small"
	},
	loadingDropdowns: {
		width: "400px",
		height: "40px",
		borderRadius: "4px"
	}
}));
