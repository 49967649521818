import { matchPath } from 'react-router';

const isRouteCurrentlyActive = function(route: string | undefined): boolean {
  return (
    route != null &&
    window.location.href.toLowerCase().includes(route.toLowerCase())
  );
};

const matchPathWithParams = function(route: string | undefined): boolean {
  return (
    route != null &&
    !!matchPath(window.location.pathname.toLowerCase(), route.toLowerCase())
  )
}

export default {
  isRouteCurrentlyActive,
  matchPathWithParams
};
