import { useReducer, useContext } from "react";
import { Identity, IdentityContext } from "components/IdentityProvider";
import { IPagedTableState } from "components/controls/createTable";
import { PricingPlatformActionTypes } from "./PricingPlatformActionTypes";
import {
	PricingPlatformState,
	getMutableCopyOfPricingPlatformState,
	PropertyDropdownSelections
} from "./PricingPlatformStateTypes";
import { PricingPlatformActions } from "./PricingPlatformActions";
import { MessagesActionMethods } from "./Messages/MessagesActionMethods";
import { UnitPageActionMethods } from "./UnitPage/UnitPageActionMethods";
import { PropertiesPageActionMethods } from "./PropertiesPage/PropertiesPageActionMethods";
import { PricingMatrixPageActionMethods } from "./PricingMatrixPage/PricingMatrixPageActionMethods";
import { UnitAvailabilityPageActionMethods } from "./UnitAvailabilityPage/UnitAvailabilityPageActionMethods";
import { RentSummaryPageActionMethods } from "./RentSummaryPage/RentSummaryPageActionMethods";
import MessagesReducer from "./Messages/MessagesReducer";
import UnitPageReducer from "./UnitPage/UnitPageReducer";
import PropertiesPageReducer from "./PropertiesPage/PropertiesPageReducer";
import PricingMatrixPageReducer from "./PricingMatrixPage/PricingMatrixPageReducer";
import {
	UnitDropdownSelections,
	PagedResultOfPropertySummary,
	PagedResultOfProperty,
	UnitGroupAdminResultForm,
	UnitGroupAdminDetailForm,
	UnitGroupAdminDeleteResultForm,
	Bedroom
} from "api";
import UnitAvailabilityPageReducer from "./UnitAvailabilityPage/UnitAvailabilityPageReducer";
import RentSummaryPageReducer from "./RentSummaryPage/RentSummaryPageReducer";
import {
	PropertySearchState,
	PriorityFilterSelections,
	LastAssesedDateFilter,
	AdvancedFilters
} from "./PropertiesPage/propertyPageInterfaces";
import { ExceptionsSearchState } from "./ExceptionsPage/exceptionsPageInterfaces";
import { UnitGroupAdminPageActionMethods } from "./UnitGroupAdminPage/UnitGroupAdminPageActionMethods";
import UnitGroupAdminPageReducer from "./UnitGroupAdminPage/UnitGroupAdminPageReducer";
import { StaleUnitPageActionMethods } from "./StaleUnitPage/StaleUnitPageActionMethods";
import StaleUnitPageReducer from "./StaleUnitPage/StaleUnitPageReducer";
import ConcessionsReportPageReducer from "./ConcessionsReportPage/ConcessionsReportPageReducer";
import { ConcessionsReportPageActionMethods } from "./ConcessionsReportPage/ConcessionsReportPageActionMethods";
import PricingAdjustmentSummaryReportPageReducer from "./PricingAdjustmentSummaryReportPage/PricingAdjustmentSummaryReportPageReducer";
import { PricingAdjustmentSummaryReportPageActionMethods } from "./PricingAdjustmentSummaryReportPage/PricingAdjustmentSummaryReportPageActionMethods";
import AutomationValidationReportPageReducer from "./AutomationValidationReportPage/AutomationValidationReportPageReducer";
import { AutomationValidationReportPageActionMethods } from "./AutomationValidationReportPage/AutomationValidationReportPageActionMethods";

export const initialPagingState = {
	offset: 0,
	limit: 10,
	scrollable: true
};

export const initialSelectedFilters: UnitDropdownSelections = {
	selectedDivision: undefined,
	selectedProperty: undefined,
	selectedCollection: undefined,
	selectedUnitGroup: undefined
};

export const initialUnitGroupAdminFilters: UnitDropdownSelections = {
	selectedProperty: undefined
};

export const initialPropertiesPageSelectedFilters: PropertyDropdownSelections = {
	selectedDivision: undefined,
	selectedProperties: undefined,
	selectedCollection: undefined,
	selectedUnitGroup: undefined
};

export const initialPriorityFilters: PriorityFilterSelections[] = [
	{
		filterId: "PROP-ATR",
		filterLabel: "ATR",
		filterName: "ATR",
		filterValue: 0,
		filterLevel: 0,
		isPercentage: true,
		minimumValue: 0,
		operator: { key: "Excluded", displayValue: "Excl." }
	},
	{
		filterId: "UG-ATR",
		filterLabel: "ATR",
		filterName: "ATR",
		filterValue: 0,
		filterLevel: 1,
		isPercentage: true,
		minimumValue: 0,
		operator: { key: "Excluded", displayValue: "Excl." }
	},
	{
		filterId: "UG-ATRWoW",
		filterLabel: "WoW ATR",
		filterName: "ATRWoW",
		filterValue: 0,
		filterLevel: 1,
		isPercentage: true,
		minimumValue: 0,
		operator: { key: "Excluded", displayValue: "Excl." }
	},
	{
		filterId: "UG-LTL-GTL",
		filterLabel: "LTA/GTA",
		filterName: "LTAGTAPercent",
		filterValue: 0,
		filterLevel: 1,
		isPercentage: true,
		minimumValue: -100,
		operator: { key: "Excluded", displayValue: "Excl." }
	},
	{
		filterId: "UG-NET-APPS",
		filterLabel: "Net Apps 7 day",
		filterName: "NetApplications7Day",
		filterValue: 0,
		filterLevel: 1,
		isPercentage: false,
		minimumValue: 0,
		operator: { key: "Excluded", displayValue: "Excl." }
	}
	//commented out for now, see Task 7454 - @dirk 10/12/22
	// {
	// 	filterId: "UG-PRICING-BAROMETER",
	// 	filterLabel: "Barometer",
	// 	filterName: "PricingBarometer",
	// 	filterValue: 0,
	// 	filterLevel: 1,
	// 	isPercentage: false,
	// 	minimumValue: -100,
	// 	operator: { key: "Excluded", displayValue: "Excl." }
	// }
];

export const initialLastAssesedDate: LastAssesedDateFilter = {
	filterName: "LastUpdatedDate",
	filterValue: undefined,
	operator: { key: "Excluded", displayValue: "Excl." }
};

export const initialAdvancedFilters: AdvancedFilters = {
	priorityFilters: initialPriorityFilters,
	lastAssesedDateFilter: initialLastAssesedDate
};

export const initialPricingPlatformState: PricingPlatformState = {
	unitPageState: {
		filterOptions: {},
		units: {},
		currentFilterSelected: initialSelectedFilters,
		appliedFilterSelected: initialSelectedFilters,
		tableLoading: false
	},
	propertiesPageState: {
		//pretty much only used for data fetching
		filterOptions: {},
		pagedProperties: {} as PagedResultOfPropertySummary,
		pageLoading: false,

		//TODO remove not used object properties
		//not used
		currentFilters: initialPropertiesPageSelectedFilters,
		propertySearchState: {} as PropertySearchState,
		savedUnitGroups: [],
		pageIsDirty: false,
		pageIsSaving: false,
		dirtyUnitGroups: [],
		expandedRows: undefined
	},
	exceptionsPageState: {
		filterOptions: {},
		pagedProperties: {} as PagedResultOfProperty,
		exceptionsSearchState: {} as ExceptionsSearchState,
		pageLoading: false,
		currentFilters: initialPropertiesPageSelectedFilters,
		pageIsDirty: false,
		pageIsSaving: false
	},
	pricingMatrixState: {
		filterOptions: {},
		currentMatrices: [],
		pageLoading: false
	},
	unitAvailabilityPageState: {
		filterOptions: {},
		unitAvailabilityReports: undefined,
		isLoading: false
	},
	rentSummaryPageState: {
		filterOptions: {},
		propertyReport: undefined,
		isLoading: false
	},
	unitGroupAdminPageState: {
		unitGroupFilterOptions: {},
		unassignedUnitTypeFilterOptions: {},
		currentFilterSelected: initialUnitGroupAdminFilters,
		currentUnassignedFilterSelected: initialUnitGroupAdminFilters,
		currentUnitGroupFilterSelected: initialUnitGroupAdminFilters,
		unitTypes: [],
		unassignedUnitTypes: {},
		unassignedUnitTypeMapping: [],
		unitGroup: {} as UnitGroupAdminResultForm,
		unitGroupMasters: [],
		unitGroupMastersWithBedrooms: [],
		unitGroupDetails: {} as UnitGroupAdminDetailForm,
		deleteUnitGroup: {} as UnitGroupAdminDeleteResultForm,
		pagedUnitGroups: {},
		unitGroupBedroom: {} as Bedroom,
		unitGroupBedrooms: [],
		bedroomDropdowns: [],
		unitGroupMasterPage: {},
		tableLoading: false,
		isLoading: false,
		classifications: [],
		updateClassification: {}
	},
	messageState: { messages: [] },
	loadingState: { isLoading: false },
	tableState: {
		pagingState: { ...initialPagingState }
	},
	staleUnitPageState: {
		filterOptions: {},
		staleUnitReports: undefined,
		isLoading: false
	},
	concessionsReportPageState: {
		filterOptions: {},
		concessionsReports: undefined,
		isLoading: false
	},
	pricingAdjustmentSummaryReportPageState: {
		filterOptions: {},
		pricingAdjustmentSummaryReports: undefined,
		isLoading: false
	},
	automationValidationReportPageState: {
		filterOptions: {},
		automationValidationReports: undefined,
		isLoading: false
	},
};

const pricingPlatformReducer = (
	oldState: PricingPlatformState,
	action: PricingPlatformActions
): PricingPlatformState => {
	const newState = getMutableCopyOfPricingPlatformState(oldState);

	switch (action.type) {
		case PricingPlatformActionTypes.UpdateUnitPageFilterOptions:
		case PricingPlatformActionTypes.UpdateUnitPageUnits:
		case PricingPlatformActionTypes.UpdateUnitCurrentSelections:
		case PricingPlatformActionTypes.UpdateUnitAppliedSelections:
		case PricingPlatformActionTypes.UpdateUnitTableLoading:
			return UnitPageReducer(newState, action);

		case PricingPlatformActionTypes.PropertiesPage_LoadedFilterOptions:
		case PricingPlatformActionTypes.PropertiesPage_LoadedProperties:
		case PricingPlatformActionTypes.PropertiesPage_FetchingProperties:
			return PropertiesPageReducer(newState, action);

		case PricingPlatformActionTypes.UpdateMatrixPageFilterOptions:
		case PricingPlatformActionTypes.UpdateCurrentMatrices:
		case PricingPlatformActionTypes.UpdatePricingMatrixPageLoading:
			return PricingMatrixPageReducer(newState, action);

		case PricingPlatformActionTypes.UpdateLoadingState:
			newState.loadingState = action.loadingState;
			return newState;
		case PricingPlatformActionTypes.UpdateUnitAvailabilityPageFilterOptions:
		case PricingPlatformActionTypes.UpdateUnitAvailabilityPageReports:
		case PricingPlatformActionTypes.UpdateUnitAvailabilityPageIsLoading:
			return UnitAvailabilityPageReducer(newState, action);
		case PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadUnitGroupTableAction:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadUnassignedUnitTypes:
		case PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitTypes:
		case PricingPlatformActionTypes.UnitGroupAdminPageCreateUnitGroups:
		case PricingPlatformActionTypes.PropertiesPage_SavedUnitGroups:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction:
		case PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnitGroupFilterOptionsAction:
		case PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnassignedUnitTypesFilterOptionsAction:
		case PricingPlatformActionTypes.UnitGroupAdminPageSetUnassignedMappings:
		case PricingPlatformActionTypes.UnitGroupAdminListBedroomsTableAction:
		case PricingPlatformActionTypes.UnitGroupAdminListBedroomsDropdownAction:
		case PricingPlatformActionTypes.UnitGroupAdminListUnitGroupMasterPage:
		case PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupMasters:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitGroupMasters:
		case PricingPlatformActionTypes.UnitGroupAdminLoadedUnitGroupMastersWithBedrooms:
		case PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadedClassifications:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadClassificationUpdate:
		case PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitTypes:
			return UnitGroupAdminPageReducer(newState, action);
		case PricingPlatformActionTypes.UpdateRentSummaryPageFilterOptions:
		case PricingPlatformActionTypes.UpdateRentSummaryPageReport:
		case PricingPlatformActionTypes.UpdateRentSummaryPageIsLoading:
			return RentSummaryPageReducer(newState, action);
		case PricingPlatformActionTypes.ShowErrorState:
		case PricingPlatformActionTypes.ShowValidationErrorState:
			return MessagesReducer(newState, action);

		case PricingPlatformActionTypes.UpdateStaleUnitPageFilterOptions:
		case PricingPlatformActionTypes.UpdateStaleUnitPageReports:
		case PricingPlatformActionTypes.UpdateStaleUnitPageIsLoading:
			return StaleUnitPageReducer(newState, action);

		case PricingPlatformActionTypes.UpdateTablePagingState:
			newState.tableState.pagingState = action.pagingState;
			return newState;
		case PricingPlatformActionTypes.UpdateConcessionsReportPageFilterOptions:
		case PricingPlatformActionTypes.UpdateConcessionsReportPageReports:
		case PricingPlatformActionTypes.UpdateConcessionsReportPageIsLoading:
			return ConcessionsReportPageReducer(newState, action);

		case PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageFilterOptions:
		case PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageReports:
		case PricingPlatformActionTypes.UpdatePricingAdjustmentSummaryReportPageIsLoading:
			return PricingAdjustmentSummaryReportPageReducer(newState, action);

		case PricingPlatformActionTypes.UpdateAutomationValidationReportPageFilterOptions:
		case PricingPlatformActionTypes.UpdateAutomationValidationReportPageReports:
		case PricingPlatformActionTypes.UpdateAutomationValidationReportPageIsLoading:
			return AutomationValidationReportPageReducer(newState, action);
	}

	return newState;
};

const usePricingPlatformStore = function (): [
	PricingPlatformState,
	ReturnType<typeof getPricingPlatformActionMethods>
] {
	const identity = useContext(IdentityContext);
	const [PricingPlatformState, dispatcher] = useReducer(pricingPlatformReducer, {
		...initialPricingPlatformState
	});

	const getPricingPlatformActionMethods = function (identity: Identity) {
		const updateTablePagingState = function (pagingState: IPagedTableState) {
			dispatcher({
				type: PricingPlatformActionTypes.UpdateTablePagingState,
				pagingState: pagingState
			});
		};

		return {
			unitPage: UnitPageActionMethods.get(dispatcher, identity),
			propertiesPage: PropertiesPageActionMethods.get(dispatcher, identity),
			pricingMatrixPage: PricingMatrixPageActionMethods.get(dispatcher, identity),
			unitAvailabilityPage: UnitAvailabilityPageActionMethods.get(dispatcher, identity),
			unitGroupAdminPage: UnitGroupAdminPageActionMethods.get(dispatcher, identity),
			rentSummaryPage: RentSummaryPageActionMethods.get(dispatcher, identity),
			messages: MessagesActionMethods.get(dispatcher),
			staleUnitPage: StaleUnitPageActionMethods.get(dispatcher, identity),
			concessionsReportPage: ConcessionsReportPageActionMethods.get(dispatcher, identity),
			pricingAdjustmentSummaryReportPage: PricingAdjustmentSummaryReportPageActionMethods.get(dispatcher, identity),
			automationValidationReportPage: AutomationValidationReportPageActionMethods.get(dispatcher, identity),
			updateTablePagingState
		};
	};

	return [PricingPlatformState, getPricingPlatformActionMethods(identity)];
};

export default usePricingPlatformStore;
