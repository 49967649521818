import React from "react";
import useStyle from "./useStyle";
import { useExclusionsContext } from "../../../Context";
import { useFormContext } from "react-hook-form";

export const ChangeConfirmation = () => {
	const classes = useStyle();
	const { selectedRow } = useExclusionsContext();
	const { getValues } = useFormContext();
	return (
		<>
			<div className={classes.titleContainer}>
				<i className="pi pi-check-circle w-20" />
				<h3 className={classes.title}> Save Changes?</h3>
			</div>
			<div style={{ display: "flex", gap: "12px", paddingBlock: "12px" }}>
				<span>
					Amount: <strong>${getValues()?.amount}</strong>
				</span>
				<span>
					% Change: <strong>{getValues()?.percentageChange}%</strong>
				</span>
			</div>
			<p className={classes.message}>
				Clicking Continue will apply the Price <strong>${getValues()?.amount}</strong> to Property{" "}
				<strong>{`${selectedRow?.unitGroupMasterName} - ${selectedRow?.property}`}</strong>
			</p>
		</>
	);
};
