import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		maxHeight: "25px",
		minWidth: "90px",
		alignItems: "center",
		gap: "5px"
	}
}));
