import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	cardContainer: {
		width: "50%",
		height: "100%",
		overflow: "hidden",
		gridColumn: "span 2",
		gridRow: "span 2",
		minHeight: "400px",
		"@media (max-width: 1280px)": {
			gridColumn: "span 2"
		},
		"@media (max-width: 768px)": {
			gridColumn: "span 4",
			gridRow: "span 1"
		},
		borderRadius: "8px",
		"& [class$=p-card-title]": {
			fontSize: "1em",
			fontWeight: "500",
			marginLeft: "auto",
			marginRight: "auto",
			textAlign: "center"
		},
		"& [class$=p-card-body]": {
			height: "100%",
			display: "flex",
			flexDirection: "column"
		},
		"& [class$=p-card-content]": {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		}
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		padding: theme.paddingScale.small,
		height: "100%"
	},
	low: {
		fill: theme.palette.success,
		background: theme.palette.success
	},
	middle: {
		fill: theme.palette.warning,
		background: theme.palette.warning
	},
	high: {
		fill: theme.palette.error,
		background: theme.palette.error
	},
	empty: {
		fill: theme.palette.lightGray,
		background: theme.palette.lightGray
	},
	chart: {
		margin: "auto"
	},
	tooltip: {
		padding: "0px",
		paddingInline: "4px",
		"& [class$=p-card-subtitle]": {
			textAlign: "center"
		},
		"& [class$=p-card-body]": {
			padding: theme.paddingScale.small
		}
	},
	rangeList: {
		listStyle: "none",
		width: "100%",
		margin: "0",
		padding: "0",
		"& li": {
			display: "flex",
			padding: "8px 0",
			justifyContent: "space-between",
			borderBottom: `1px solid ${theme.palette.lightGray}`
		},
		"& li:first-child": {
			borderTop: `1px solid ${theme.palette.lightGray}`
		}
	},
	count: {
		fontWeight: "500"
	},
	dot: {
		display: "inline-block",
		height: "16px",
		width: "16px",
		borderRadius: "50%"
	},
	range: {
		display: "flex",
		gap: "8px",
		alignItems: "center"
	}
}));
