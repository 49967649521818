import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	dialog: {
		width: "500px",
		height: "150px",
		"& [class=p-dialog-content]": {
			height: "100%",
			paddingTop: "0",
			overflowX: "hidden"
		},
		"& [class$=p-dialog-header]": {
			borderBottom: "none",
			color: theme.palette.darkGray
		},
		"& [class$=p-dialog-header-icons]": {
			marginBottom: "auto"
		}
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "28px",
		justifyContent: "flex-end",
		height: "100%"
	},
	row: {
		display: "flex",
		flexDirection: "row",
		width: "100%"
	},
	justifyFlexEnd: {
		justifyContent: "flex-end"
	},
	inputField: {
		width: "100%"
	}
}));
