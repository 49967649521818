import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import useStyle from "./useStyle";
import { siteDefaultTheme } from "theme";
import { formatCurrencyNoFractions, formatPercentage, formatDateString, formatDateAsMonth } from "utils/miscUtils";
import { AskingPriceLeasingMapped } from "components/forms/PricingAutomation/Pages/Home/Glance/LeasingActivity/leasingActivityMapper";
interface Props {
	data: AskingPriceLeasingMapped[];
	height?: number;
}

export const LeasingActivityChart = ({ data, height }: Props) => {
	const classes = useStyle();

	return (
		<ResponsiveContainer width="100%" height={height ?? 118} className={classes.chart}>
			<LineChart data={data} margin={{ top: 10, bottom: 0 }}>
				<CartesianGrid strokeDasharray="3 3" vertical={false} />
				<Legend wrapperStyle={{ top: "-16px", right: "0px" }} align="right" iconType="circle" iconSize={10} />
				<XAxis
					dataKey="date"
					allowDuplicatedCategory={false}
					tickMargin={5}
					tickLine={false}
					height={25}
					tickSize={1}
					tickFormatter={formatDateAsMonth}
				/>

				<YAxis
					yAxisId={1}
					tickFormatter={(value) => formatCurrencyNoFractions(value)}
					tickLine={false}
					axisLine={false}
					padding={{ bottom: 3 }}
					width={45}
					domain={["dataMin", "dataMax"]}
				/>
				<YAxis
					yAxisId={2}
					dataKey="valueAtrPercentage"
					orientation="right"
					tickFormatter={(value) => formatPercentage(value)}
					tickLine={false}
					axisLine={false}
					padding={{ bottom: 3 }}
					width={40}
					domain={["dataMin", "dataMax"]}
				/>
				<Tooltip
					contentStyle={{ fontWeight: "bold" }}
					formatter={(value, name, props) => [
						props.name === "ATR" ? formatPercentage(Number(value)) : formatCurrencyNoFractions(value),
						name
					]}
					labelFormatter={(value) => formatDateString(value)}
				/>
				<Line
					yAxisId={1}
					isAnimationActive={false}
					dataKey="valueEssGross"
					name="ESS Gross Asking Rent"
					stroke={siteDefaultTheme.palette.success}
					dot={false}
					strokeWidth={1.5}
				/>
				{data && data.some((x) => x.recommendedPrice) ? (
					<Line
						yAxisId={1}
						isAnimationActive={false}
						dataKey="recommendedPrice"
						name="Recommended Price"
						stroke={siteDefaultTheme.palette.black}
						dot={false}
						strokeWidth={1.5}
					/>
				) : (
					<></>
				)}
				<Line
					yAxisId={2}
					isAnimationActive={false}
					dataKey="valueAtrPercentage"
					name="ATR"
					stroke={siteDefaultTheme.palette.blue}
					dot={false}
					strokeWidth={1.5}
					strokeDasharray="2 2"
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};
