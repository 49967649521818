import { ColumnType } from "components/controls/DataTable/types";
import { formatDateWithTime, formatPrice } from "utils/formatTemplates";

export const columns: ColumnType[] = [
	{
		name: "Date/Time",
		subColumns: [{ field: "dateTime", format: formatDateWithTime("createdDate"), sortable: false }]
	},
	{
		name: "Action Taken",
		subColumns: [
			{
				field: "actionTaken",
				format: (data) => data["automatedPricingActions"]?.[0]?.pricingActionType?.name ?? "",
				sortable: false
			}
		]
	},
	{
		name: "Adjusted Price",
		subColumns: [{ field: "adjustedPrice", format: formatPrice("normalizedRent"), sortable: false }]
	},
	{ name: "User", subColumns: [{ field: "userDisplayName", sortable: false }] },
	{ name: "Comments", subColumns: [{ field: "comment", sortable: false }] },
	{ name: "Override Reason", subColumns: [{ field: "overrideReason.name", sortable: false }] }
];
