import React, { useState, useEffect, useRef, useReducer } from "react";
import { Prompt } from "react-router-dom";
import Menu from "../Menu";
import Page from "components/layouts/Page";
import usePricingPlatformStore, {
	initialPropertiesPageSelectedFilters
} from "components/store/usePricingPlatformStore";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Spinner from "components/controls/Spinner";
import { PropertySearchQuery, PropertySummary, UnitGroupsClient, UnitGroupSummary } from "api";
import UnitGroupList from "./UnitGroupList";
import PrimaryButton from "components/controls/PrimaryButton";
import VisibilityToggle from "components/controls/VisibilityToggle";
import Spacer from "components/controls/Spacer";
import NavPrompt from "components/controls/NavPrompt";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { Button } from "primereact/components/button/Button";
import { useRemoteData } from "components/useRemoteData";
import { useApiEndpoint } from "components/useApiEndpoint";
import {
	occupancyTemplate,
	propertyLocationTemplate,
	propertyStructureTemplate,
	metricsTemplate
} from "./tableTemplates";
import {
	PropertiesPageProps,
	PropertySearchState,
	UpdatedUnitGroupDelta,
	AdvancedFilter,
	AdvancedFilters
} from "components/store/PropertiesPage/propertyPageInterfaces";
import { Toast } from "primereact/toast";
import Dialog from "components/controls/Dialog";
import RequirePermission from "../RequirePermission";
import useMessageArea from "components/useMessageArea";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import constants from "utils/constants";
import AdvancedFiltersComponent from "./AdvancedFilters";
import { initialAdvancedFilters } from "../../store/usePricingPlatformStore";
import MultiSelect from "components/controls/MultiSelect";
import { PropertyDropdownSelections } from "components/store/PricingPlatformStateTypes";

import "./_properties-page.scss";

const PropertiesPage: React.FC<PropertiesPageProps> = function (props) {
	const isMounted = useRef(false);
	const rowTokeepExpanded = useRef("");

	var [isSaving, setIsSaving] = useState(false);

	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		...appState.propertiesPageState
	};
	const actions = {
		...appActions.propertiesPage
	};

	const [messageAreaState, , , setMessageBasedOnCustomMessage] = useMessageArea();

	//initial local state
	const [localState, setLocalState] = useState({
		expandedRows: undefined,
		currentFilters: initialPropertiesPageSelectedFilters,
		advancedFilters: initialAdvancedFilters,
		appliedFilters: {},
		appliedAdvancedFilters: initialAdvancedFilters,
		showUnsavedConfirmationDialog: false,
		allowedClearingUnsavedChanges: false,
		searchTerm: ""
	});
	const [pageIsDirty, setPageIsDirty] = useState(false);

	enum ChangeActionTypes {
		RemoveAll = "RemoveAll",
		Update = "Update",
		RemoveAllForProperty = "RemoveAllForProperty"
	}
	interface ChangeAction {
		type?: ChangeActionTypes;
		delta?: UpdatedUnitGroupDelta;
		propertyId?: string;
	}
	function modifiedUnitGroupsReducer(
		updatedUGs: UpdatedUnitGroupDelta[],
		action: ChangeAction
	): UpdatedUnitGroupDelta[] {
		const { delta, type } = action;

		if (type === ChangeActionTypes.RemoveAll) {
			updatedUGs = [];
		}

		if (type === ChangeActionTypes.RemoveAllForProperty) {
			if (action.propertyId === undefined) {
				throw new Error("propertyId is required when you want to remove all for a property");
			}
			updatedUGs = updatedUGs.filter((ug) => ug.propertyId !== action.propertyId);
		}

		if (typeof delta === "undefined" || !delta.unitGroupId) {
			setPageIsDirty(updatedUGs.length > 0);
			return updatedUGs;
		}

		//make a copy of the state
		let foundUG = updatedUGs.find((ug) => ug.unitGroupId === delta.unitGroupId);
		if (foundUG) {
			if (delta.isChanged) {
				//if changed, update unit group
				updatedUGs = updatedUGs.map((ug) => {
					if (ug.unitGroupId === delta.unitGroupId) {
						ug = Object.assign(ug, delta);
					}
					return ug;
				});
			} else {
				//if not changed, remove unit group
				updatedUGs = updatedUGs.filter((ug) => ug.unitGroupId !== delta.unitGroupId);
			}
		} else if (delta.isChanged) {
			//insert a new unit group with concat
			updatedUGs = [...updatedUGs, delta];
		} else {
			console.warn(
				"property page new/unfound and unchanged UG.  You must have not marked isChanged or passed in something wrong.",
				delta
			);
		}

		//update the dirty flag if there are any changes
		setPageIsDirty(updatedUGs.length > 0);

		return updatedUGs;
	}

	//holds dirty unit groups from child components
	const [modifiedUnitGroups, setModifiedUnitGroups] = useReducer(
		modifiedUnitGroupsReducer,
		[] as UpdatedUnitGroupDelta[]
	);

	function updateUnitGroup(delta: UpdatedUnitGroupDelta) {
		setModifiedUnitGroups({
			type: ChangeActionTypes.Update,
			delta
		});
	}

	function propertyIsDirty(propertyId: string | undefined): boolean {
		if (typeof propertyId === "undefined" || !pageIsDirty) {
			return false;
		}
		return modifiedUnitGroups.some((ug) => ug.propertyId === propertyId);
	}

	//holds saved unit groups so child components can update their state and refresh themselves
	const [savedUnitGroups, setSavedUnitGroups] = useState<UnitGroupSummary[]>([]);

	//available page sizes shown in the table dropdown
	const rowsPerPageOptions: number[] = [10, 20, 50];

	const [propertySearchState, setPropertySearchState] = useState<PropertySearchState>({
		//initial state
		skip: 0,
		take: rowsPerPageOptions[0],
		sortBy: "name",
		sortDirection: "asc",
		//the grid sends back a number for the sort direction, but the api expects a string
		sortOrder: 1 //ascending (-1 = descending)
	});

	const toast = useRef<Toast>(null);

	//#region Filter events/methods

	function getInitialFiltersFromUrlParams(): PropertyDropdownSelections {
		const filters: PropertyDropdownSelections = {
			selectedDivision: undefined,
			selectedProperties: undefined,
			selectedCollection: undefined,
			selectedUnitGroup: undefined
		};
		const params = props.match.params;

		if (params.divisionId !== ":divisionId") {
			filters.selectedDivision = { key: params.divisionId };
		}

		// if (params.propertyId !== ":propertyId") {
		// 	filters.selectedProperty = { key: params.propertyId };
		// }

		if (params.collectionId !== ":collectionId") {
			filters.selectedCollection = { key: params.collectionId };
		}

		if (params.unitGroupId !== ":unitGroupId") {
			filters.selectedUnitGroup = { key: params.unitGroupId };
		}

		return filters;
	}

	//why did we have 'curSelections.selectedUnit = undefined' on every onSelectXXX if we never use it
	//so i deleted that from all of them lol
	//are all these undefineds really necessary
	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedDivision = division;
		curSelections.selectedCounty = undefined;
		curSelections.selectedProperties = undefined;
		curSelections.selectedUnitGroup = undefined;
		curSelections.selectedCollection = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.loadFilters(curSelections);
	}

	function onSelectCounty(county: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedCounty = county;
		curSelections.selectedProperties = undefined;
		curSelections.selectedUnitGroup = undefined;
		curSelections.selectedCollection = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.loadFilters(curSelections);
	}

	function onSelectCollection(collection: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedCollection = collection;
		curSelections.selectedProperties = undefined;
		curSelections.selectedUnitGroup = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.loadFilters(curSelections);
		//console.log('onSelectCollection', collection);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedProperties = properties;
		curSelections.selectedUnitGroup = undefined;
		curSelections.selectedUnit = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.loadFilters(curSelections);
	}

	function onSelectUnitGroup(unitGroup: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedUnitGroup = unitGroup;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.loadFilters(curSelections);
	}
	function unitGroupDropdownDisabled() {
		var isPopulated =
			pageState.filterOptions && pageState.filterOptions.unitGroups && pageState.filterOptions.unitGroups.length > 0;

		var selectedLength =
			localState.currentFilters.selectedProperties !== undefined &&
			localState.currentFilters.selectedProperties != null
				? localState.currentFilters.selectedProperties.length
				: 0;
		var multipleProperties = selectedLength > 1 ? true : false;

		return !isPopulated && multipleProperties;
	}

	const handleAdvancedFilterDropdownChange = (
		type: string,
		operator: DropdownOption | undefined,
		filterId: string = ""
	) => {
		const curAdvancedFilters = localState.advancedFilters;
		if (type === "priorityFilter") {
			const curPriorityFilters = curAdvancedFilters.priorityFilters;
			setLocalState({
				...localState,
				advancedFilters: {
					...curAdvancedFilters,
					priorityFilters: curPriorityFilters.map((filter) =>
						filter.filterId === filterId ? { ...filter, operator: operator } : filter
					)
				}
			});
		} else {
			const curLastAssesedDateFilter = curAdvancedFilters.lastAssesedDateFilter;
			setLocalState({
				...localState,
				advancedFilters: {
					...curAdvancedFilters,
					lastAssesedDateFilter: { ...curLastAssesedDateFilter, operator: operator }
				}
			});
		}
	};

	const handleAdvancedFilterValueChange = (type: string, e: any, filterId: string = "") => {
		const curAdvancedFilters = localState.advancedFilters;
		const { value } = e.target || e.currentTarget;
		if (type === "priorityFilter") {
			const curPriorityFilters = curAdvancedFilters.priorityFilters;
			setLocalState({
				...localState,
				advancedFilters: {
					...curAdvancedFilters,
					priorityFilters: curPriorityFilters.map((filter) =>
						filter.filterId === filterId ? { ...filter, filterValue: value } : filter
					)
				}
			});
		} else {
			const curLastAssesedDateFilter = curAdvancedFilters.lastAssesedDateFilter;
			setLocalState({
				...localState,
				advancedFilters: {
					...curAdvancedFilters,
					lastAssesedDateFilter: { ...curLastAssesedDateFilter, filterValue: value }
				}
			});
		}
	};

	function searchFromFilters() {
		//TODO update URL params ??
		//props.history.push(`/properties/${searchQuery.divisionId}/${searchQuery.propertyId}/${searchQuery.unitGroupId}/${searchQuery.collectionId}`);

		//	localState.showUnsavedConfirmationDialog = true;

		if (pageIsDirty) {
			//TODO pretty up the confirmation dialog boxes by using PrimeReact dialog or something
			let confirmed = window.confirm(
				`You have unsaved Unit Group changes.\nIf you apply this filter, your changes will be lost.\n\nClick OK to apply the filter and lose your changes, or Cancel to keep it the page as is.`
			);

			if (confirmed) {
				//remove the UGs from the dirty list
				console.warn("User applied the filters and cleared the changes");
				setModifiedUnitGroups({ type: ChangeActionTypes.RemoveAll });
				setSavedUnitGroups([]);
				//close all open rows
				//set open and closed rows
				setLocalState({
					...localState,
					expandedRows: undefined
				});
			} else {
				//user wants to keep the UGs and the row open
				return false;
			}
		}

		searchProperties(
			localState.currentFilters,
			localState.advancedFilters,
			0,
			propertySearchState.skip,
			propertySearchState.sortBy,
			propertySearchState.sortOrder
		);
	}

	function searchProperties(
		filters: PropertyDropdownSelections | undefined,
		advancedFilters: AdvancedFilters | undefined,
		skip: number | undefined = 0,
		take: number | undefined = 10,
		sortField: string | undefined = undefined,
		sortOrder: number | undefined = undefined
	) {
		if (skip <= 0) skip = 0;
		if (take <= 0) take = 10;

		let query: PropertySearchState = {
			...propertySearchState,
			skip: typeof skip != "undefined" ? skip : propertySearchState.skip || 0,
			take: typeof take != "undefined" ? take : propertySearchState.take || 10,
			sortBy: sortField || propertySearchState.sortBy || "",
			sortOrder: sortOrder || propertySearchState.sortOrder || 1,
			sortDirection: sortOrder === -1 ? "desc" : "asc"
		};

		if (filters) {
			query.divisionId = filters.selectedDivision ? filters.selectedDivision.key : undefined;
			query.propertyIds = filters.selectedProperties
				? (filters.selectedProperties.map((x) => x.key).filter((x) => x !== undefined) as string[])
				: undefined;
			query.unitGroupId = filters.selectedUnitGroup ? filters.selectedUnitGroup.key : undefined;

			query.county = filters.selectedCounty ? filters.selectedCounty.key : undefined;

			query.assetCollectionId = filters.selectedCollection
				? parseInt(filters.selectedCollection.key || "0")
				: undefined;
		}

		if (advancedFilters) {
			if (advancedFilters.priorityFilters && advancedFilters.priorityFilters.length) {
				const priorityFiltersSelected = advancedFilters.priorityFilters.filter(
					(filter) => filter.operator && filter.operator.key !== "Excluded"
				);
				const priofilters: AdvancedFilter[] = priorityFiltersSelected.map((filter) => {
					const value = (filter.isPercentage ? filter.filterValue / 100 : filter.filterValue).toString();
					return {
						filterName: filter.filterName,
						filterLevel: filter.filterLevel,
						filterValue: value,
						operator: filter.operator ? Number(filter.operator.key) : undefined
					};
				});
				query.priorityFilters = priofilters.length ? priofilters : undefined;
			}

			if (
				advancedFilters.lastAssesedDateFilter &&
				advancedFilters.lastAssesedDateFilter.operator &&
				advancedFilters.lastAssesedDateFilter.operator.key !== "Excluded" &&
				advancedFilters.lastAssesedDateFilter.filterValue
			) {
				const lastAssesedDateFilterSelected = advancedFilters.lastAssesedDateFilter;
				query.lastAssesedDateUG = {
					...lastAssesedDateFilterSelected,
					operator: lastAssesedDateFilterSelected.operator
						? Number(lastAssesedDateFilterSelected.operator.key)
						: undefined,
					filterValue: lastAssesedDateFilterSelected.filterValue
						? lastAssesedDateFilterSelected.filterValue.toLocaleDateString("en-US", {
								year: "numeric",
								month: "2-digit",
								day: "2-digit"
						  })
						: ""
				};
			} else {
				query.lastAssesedDateUG = undefined;
			}
		}

		actions.searchProperties(query as PropertySearchQuery).then((pagedResult: any) => {
			if (pagedResult === undefined) {
				setMessageBasedOnCustomMessage(
					constants.MessageAreaMessages.ApiDownTitle,
					constants.MessageAreaMessages.ApiDownMessage,
					MessageSeverityLevel.Warning as MessageSeverity,
					false
				);
				return;
			}
			if (pagedResult.items.length === 1) {
				//only 1 property returned, so lets open up the grid to be nice
				const propertyId = pagedResult.items[0].id;
				var expandedRows = localState.expandedRows || ({} as any);
				if (!expandedRows[propertyId]) {
					expandedRows[propertyId] = true;
				}
				setLocalState({ ...localState, expandedRows, appliedFilters: localState.currentFilters });
			}
		});
		setPropertySearchState({ ...query });
		setLocalState({ ...localState, appliedAdvancedFilters: localState.advancedFilters });
	}

	//#endregion Filter events/methods

	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;
		var c = localState.appliedAdvancedFilters;
		var d = localState.advancedFilters;

		//logic is funky but somewhat works ish
		if (JSON.stringify(a) === JSON.stringify(b) && JSON.stringify(c) === JSON.stringify(d)) {
			return "applyButton";
		} else {
			return "applyButton2";
		}
	}

	//start up the page
	useEffect(() => {
		//run only the first time the component is mounted
		isMounted.current = true;

		//initialize search filter state from URL params
		var urlFilters = getInitialFiltersFromUrlParams();
		setLocalState({
			...localState,
			currentFilters: urlFilters
		});

		//initial load of filter dropdown options
		actions.loadFilters(urlFilters);

		//initial load of the data
		searchProperties(urlFilters, localState.advancedFilters);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//TODO show spinner while it's saving
	//TODO disable save button while saving

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [saveError, setSaveError] = useState(false);
	const renderFooter = () => {
		return (
			<div>
				<Button label="OK" icon="pi pi-check" onClick={() => setSaveError(false)} />
			</div>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [submitState, saveEditedUnitGroups] = useRemoteData(
		useApiEndpoint(UnitGroupsClient, "updateBaseRents"),
		[modifiedUnitGroups],
		{
			onChange: (changedUnitGroups) => {
				//to test property save error, uncomment lines 346-348 and comment out lines 349-358
				//if (changedUnitGroups) {
				//throw Error
				//}

				setIsSaving(false);

				//reset our dirty state
				setModifiedUnitGroups({ type: ChangeActionTypes.RemoveAll });

				if (changedUnitGroups && changedUnitGroups.length > 0) {
					setSavedUnitGroups(changedUnitGroups);
				} else {
					setSavedUnitGroups([]);
				}
			},
			onError: (e) => {
				setSaveError(true);
				console.warn("Error updateBaseRentByUnitGroupId", e);
				setIsSaving(false);
				//TODO - show error message in a toast
				//   responseHandlers.use({
				// 	 "400": (ex: ApiException) => {
				// 		const form = ex.result;
				// 		//setMessageBasedOnForm(form);
				// 		setState({ ...ex.result });
				// 	 },
				// 	 _: (ex) => {
				// 		//setMessageBasedOnApiException(ex);
				// 	 }
				//   })(error);
			}
		}
	);

	//#region Grid stuff

	function onPage(e: any) {
		searchProperties(
			localState.currentFilters,
			localState.advancedFilters,
			e.first,
			e.rows,
			propertySearchState.sortBy,
			propertySearchState.sortOrder
		);
	}

	function onSort(e: any) {
		searchProperties(
			localState.currentFilters,
			localState.advancedFilters,
			0,
			propertySearchState.take,
			e.sortField,
			e.sortOrder
		);
	}

	function onRowToggle(e: any) {
		//validate if user wants to keep the UGs and the row open to keep it expanded
		const collapsedRow = rowTokeepExpanded.current;
		const expandedRows = e.data;

		if (propertyIsDirty(collapsedRow)) expandedRows[collapsedRow] = true;

		//set open and closed rows
		setLocalState({ ...localState, expandedRows: expandedRows });
		rowTokeepExpanded.current = "";
	}

	const onRowCollapse = (event: any) => {
		const property = event.data as PropertySummary;

		if (propertyIsDirty(property.id)) {
			console.log("onRowCollapse - IS DIRTY! " + property.id);

			//TODO pretty up the confirmation dialog boxes by using PrimeReact dialog or something
			let confirmed = window.confirm(
				`${property.name} has unsaved Unit Group changes.\nIf you collapse this row, your changes will be lost.\n\nClick OK to collapse this row and lose your changes, or Cancel to keep it open.`
			);

			if (confirmed) {
				//remove the UGs from the dirty list
				console.warn("User closed the row and cleared the changes for " + property.name);
				setModifiedUnitGroups({ type: ChangeActionTypes.RemoveAllForProperty, propertyId: property.id });
			} else {
				//user wants to keep the UGs and the row open
				rowTokeepExpanded.current = property.id || "";
				return false;
			}
		}
	};

	const onRowClick = (event: any) => {
		//toggle the expanded row based on a click in the row
		const property = event.data as PropertySummary;
		const propertyId: string = property.id || "";

		var expandedRows = localState.expandedRows || ({} as any);
		if (expandedRows[propertyId]) {
			//later on, if you want, you can close it like this as long as you check isDirty
			//delete expandedRows[propertyId];
		} else {
			expandedRows[propertyId] = true;
		}

		setLocalState({ ...localState, expandedRows });
	};

	const dataTable = () => {
		if (pageState.pagedProperties === undefined) {
			return <Spinner isVisible={true} />;
		} else if (
			!pageState.pageLoading &&
			pageState.pagedProperties.items &&
			pageState.pagedProperties.items.length === 0
		) {
			return (
				<div className="error-message-styling">
					No properties found. You either need to adjust the page filters, or you do not have permission to view
					any properties.
				</div>
			);
		} else {
			return (
				<DataTable
					id="property-list"
					value={pageState.pagedProperties.items}
					paginator={true}
					alwaysShowPaginator={true}
					pageLinkSize={5}
					rowsPerPageOptions={rowsPerPageOptions}
					expandedRows={localState.expandedRows}
					onRowCollapse={onRowCollapse}
					onRowToggle={onRowToggle}
					onRowClick={onRowClick}
					className={`property-list p-datatable-striped ${pageIsDirty ? "dirty" : ""}`}
					tableClassName="property-list-table"
					rowClassName={(rowData: PropertySummary) => {
						return { "property-list_row": true, dirty: propertyIsDirty(rowData.id) };
					}}
					//this child component does the API call to get the unit groups
					rowExpansionTemplate={(property: PropertySummary) => {
						return (
							<UnitGroupList
								property={property}
								savedUnitGroups={savedUnitGroups}
								setSavedUnitGroups={setSavedUnitGroups}
								updateUnitGroupState={updateUnitGroup}
							/>
						);
					}}
					lazy={true}
					totalRecords={pageState.pagedProperties.totalCount}
					loading={pageState.pageLoading}
					onPage={onPage}
					onSort={onSort}
					first={propertySearchState.skip}
					rows={propertySearchState.take}
					sortField={propertySearchState.sortBy}
					sortOrder={propertySearchState.sortOrder}
					dataKey="id"
					emptyMessage="No properties found"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} properties"
				>
					<Column expander style={{ width: "35px" }} className="property-list_column property-list-expander" />
					<Column
						field="name"
						header="Property"
						sortable={!pageIsDirty}
						body={propertyLocationTemplate}
						className="property-list_column"
					/>
					<Column
						field="totalUnits"
						header="Units"
						sortable={!pageIsDirty}
						body={propertyStructureTemplate}
						className="property-list_column"
					/>
					<Column
						field="atr"
						header="ATR %"
						sortable={!pageIsDirty}
						body={occupancyTemplate}
						className="property-list_column"
					/>
					<Column header="Metrics" body={metricsTemplate} className="property-list_column" />
				</DataTable>
			);
		}
	};

	//#endregion grid stuff

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canManagePricing]}>
			<div style={{ display: "flex", alignItems: "center", paddingRight: "6px" }}>
				<NavPrompt
					isVisible={pageIsDirty}
					message={`You have ${modifiedUnitGroups.length} unsaved ${
						modifiedUnitGroups.length === 1 ? "unit group" : "unit groups"
					}.`}
					iconArea={<i className="pi pi-save" />}
				/>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.pageLoading}>
					<PrimaryButton
						disabled={!pageIsDirty}
						onClick={() => {
							setIsSaving(true);
							saveEditedUnitGroups([]);
						}}
						title="SAVE"
					/>
					<Dialog
						header="SAVE ERROR"
						footer={renderFooter()}
						isVisible={saveError}
						onHide={() => setSaveError(false)}
					>
						Save was unsuccessful. Please try again.{" "}
					</Dialog>
				</VisibilityToggle>
			</div>
		</RequirePermission>
	);

	return (
		<Page
			menu={<Menu title={constants.menuTitle.properties} actionArea={navbarActionArea} />}
			className="properties-page"
		>
			{messageAreaState.messageArea}
			<Prompt
				when={pageIsDirty}
				message={`You have ${modifiedUnitGroups.length} unsaved ${
					modifiedUnitGroups.length === 1 ? "unit group" : "unit groups"
				}.\n\nIf you proceed, your changes will be lost. Are you sure you want to proceed?`}
			/>
			<div>
				<div className="filter-headers-1">
					<div className="filter-item">
						<span className="filter-item__title">Division:</span>
						<Dropdown
							selectedValue={localState.currentFilters.selectedDivision}
							options={pageState.filterOptions!.divisions}
							onChange={(d) => onSelectDivision(d)}
							disabled={!(pageState.filterOptions && pageState.filterOptions.divisions)}
							style={{ width: 200 }}
						/>
					</div>
					<div className="filter-item">
						<span className="filter-item__title">County:</span>
						<Dropdown
							selectedValue={localState.currentFilters.selectedCounty}
							options={pageState.filterOptions!.counties}
							onChange={(d) => onSelectCounty(d)}
							disabled={!(pageState.filterOptions && pageState.filterOptions.counties)}
							style={{ width: 180 }}
						/>
					</div>
					<div className="filter-item">
						<span className="filter-item__title">Collection:</span>
						<Dropdown
							selectedValue={localState.currentFilters.selectedCollection}
							options={pageState.filterOptions!.collections}
							onChange={(d) => onSelectCollection(d)}
							disabled={!(pageState.filterOptions && pageState.filterOptions.collections)}
							style={{ width: 180 }}
						/>
					</div>
					<div className="filter-item">
						<span className="filter-item__title">Property:</span>
						<MultiSelect
							showTextOfMultipleSelections={true}
							placeholder={"All"}
							selectedValues={localState.currentFilters.selectedProperties}
							options={pageState.filterOptions!.properties}
							onChange={(d) => onSelectProperties(d)}
							disabled={!(pageState.filterOptions && pageState.filterOptions.properties)}
							hideClear={false}
							filter
							style={{ width: "400px" }}
						></MultiSelect>
					</div>
					<div className="filter-item">
						<span className="filter-item__title">Unit Group:</span>
						<Dropdown
							selectedValue={localState.currentFilters.selectedUnitGroup}
							options={pageState.filterOptions!.unitGroups}
							onChange={(d) => onSelectUnitGroup(d)}
							disabled={unitGroupDropdownDisabled()}
							style={{ width: 250 }}
						/>
					</div>
				</div>
				<div className="filter-headers-2">
					<AdvancedFiltersComponent
						priorityFilters={localState.advancedFilters.priorityFilters}
						lastAssesedDateFilter={localState.advancedFilters.lastAssesedDateFilter}
						onDropdownChange={handleAdvancedFilterDropdownChange}
						onValueChange={handleAdvancedFilterValueChange}
					/>
					<div className="priority-apply-button">
						<Button label="Apply" className={applySearchHighlight()} onClick={searchFromFilters}></Button>
					</div>
				</div>
			</div>

			<div id="property-list-wrapper" className="properties-page">
				{isSaving ? <Spinner isVisible={true} /> : ""}
				<div className="summaryInfo">
					{!pageState.pageLoading && pageState.pagedProperties.totalCount
						? "Total Properties: " + pageState.pagedProperties.totalCount.toLocaleString()
						: " "}
				</div>
				{dataTable()}
			</div>

			<Toast ref={toast} position="bottom-right" />
			<Dialog
				header="Confirmation"
				isVisible={localState.showUnsavedConfirmationDialog}
				onHide={() => setLocalState({ ...localState, showUnsavedConfirmationDialog: false })}
				footer={<div>footer</div>}
			>
				<div className="confirmation-content">
					<i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
					<span>
						{`You have ${modifiedUnitGroups.length} unsaved ${
							modifiedUnitGroups.length === 1 ? "unit group" : "unit groups"
						}.`}{" "}
					</span>
					<span>If you proceed, your changes will be lost. Are you sure you want to proceed?</span>
				</div>
			</Dialog>
		</Page>
	);
};

export default PropertiesPage;
