import { ColumnType } from "components/controls/DataTable/types";
import { siteDefaultTheme as theme } from "theme";
import {
	formatPrice,
	formatPercentage,
	formatPercentageRoundedWhole,
	formatDecimalPoints,
	formatVariance,
	formatBoolAsYorN,
	formatDateNullable,
	formantNormalizedRentCalculatedBaseRent,
	formatDate
} from "utils/formatTemplates";
import { getAvailableUnitColumnTemplate } from "../../../utils/columnBodyHelper";

export const columns: ColumnType[] = [
	{ name: "Property", subColumns: [{ field: "property" }], align: "left", color: theme.palette.primary },
	{ name: "Unit Group", subColumns: [{ field: "unitGroupMasterName" }], align: "left" },
	{ name: "Total Units", minWidth: "100px", subColumns: [{ field: "unitCount", name: "" }] },
	{
		name: "Unit Avl",
		minWidth: "100px",
		subColumns: [{ field: "availableUnitCount", body: getAvailableUnitColumnTemplate(), name: "" }]
	},
	{
		name: "ATR",
		subColumns: [
			{ field: "atr", name: "%", sortable: true, format: formatPercentage("atr") },
			{ field: "atrVariance", name: "VARIANCE %", sortable: true, format: formatPercentage("atrVariance") }
		]
	},
	{
		name: "ATR - Property",
		visible: false,
		subColumns: [
			{ field: "atrProperty", name: "%", format: formatPercentage("atrProperty") },
			{ field: "atrPropertyVariance", name: "VARIANCE %", format: formatPercentage("atrPropertyVariance") }
		]
	},
	{ name: "Recommended Action", subColumns: [{ field: "recommendedActionName", minWidth: "200px" }], align: "left" },
	{
		name: "Recommended Price",
		minWidth: "240px",
		subColumns: [
			{
				field: "recommendedPrice",
				name: "$",
				format: formantNormalizedRentCalculatedBaseRent("recommendedPrice", "currentAverageAmenities")
			},
			{ field: "recommendedPriceChange", name: "$ CHANGE", format: formatPrice("recommendedPriceChange") },
			{ field: "recommendedPricePercent", name: "%", format: formatPercentage("recommendedPricePercent") }
		]
	},
	{ name: "Current Price", subColumns: [{ field: "currentPrice", format: formatPrice("currentPrice") }] },
	{
		name: "Daily Amenity Variance",
		visible: false,
		subColumns: [{ name: "$", field: "averageAmenitiesVariance", format: formatPrice("averageAmenitiesVariance") }]
	},
	{ name: "Last Price", visible: false, subColumns: [{ field: "lastPrice", format: formatPrice("lastPrice") }] },
	{
		name: "Last Price Adj",
		minWidth: "300px",
		subColumns: [
			//{ name: "$", field: "currentPrice", format: formatPrice("currentPrice") },
			{
				name: "$ CHANGE",
				field: "lastPriceChangeDifference",
				format: formatPrice("lastPriceChangeDifference")
			},
			{
				name: "% CHANGE",
				field: "lastPriceChangePercent",
				format: formatPercentage("lastPriceChangePercent")
			},
			{
				name: "DATE",
				field: "lastPriceDate",
				format: formatDate("lastPriceDate")
			}
		]
	},
	{
		name: "Leads",
		subColumns: [
			{ field: "leads", name: "#" },
			{
				field: "leadsWoWPctVariance",
				name: "VARIANCE",
				format: formatPercentageRoundedWhole("leadsWoWPctVariance")
			}
		]
	},
	{
		name: "Net Apps",
		minWidth: "13ch",
		subColumns: [{ field: "netApps", name: "#" }]
	},
	{
		name: "Net Apps - Property",
		minWidth: "13ch",
		visible: false,
		subColumns: [{ field: "netAppsProperty", name: "#" }]
	},
	{
		name: "LTA/GTA",
		minWidth: "13ch",
		subColumns: [
			{ field: "ltaGtaRecommended", name: "NEW", format: formatPercentage("ltaGtaRecommended") },
			{ field: "ltaGta", name: "CURRENT", format: formatPercentage("ltaGta") }
		]
	},
	{
		name: "LTA/GTA - Property",
		minWidth: "13ch",
		visible: false,
		subColumns: [{ field: "ltaGtaProperty", name: "%", format: formatPercentage("ltaGtaProperty") }]
	},
	{
		name: "Net Conversion - Property",
		minWidth: "16ch",
		subColumns: [
			{ field: "netConversion", name: "%", format: formatPercentage("netConversion") },
			{
				field: "netConversionVariance",
				name: "VARIANCE",
				format: formatVariance("netConversionVariance")
			}
		]
	},
	{
		name: "Leasing Velocity",
		minWidth: "16ch",
		subColumns: [
			{ field: "leasingVelocityTarget", name: "TARGET", format: formatDecimalPoints("leasingVelocityTarget") },
			{
				field: "leasingVelocity",
				name: "DELTA",
				format: formatDecimalPoints("leasingVelocity")
			}
		]
	},
	{
		name: "Concessions",
		visible: false,
		subColumns: [{ field: "concesions" }]
	},
	{
		name: "Manual Exclusion",
		subColumns: [
			{ field: "isManualExclusion", name: "Excluded", format: formatBoolAsYorN("isManualExclusion") },
			{
				name: "Expiring Date",
				minWidth: "100px",
				field: "manualExclusionExpiration",
				format: formatDateNullable("manualExclusionExpiration")
			}
		]
	},
	{
		name: "Review Reason",
		align: "left",
		subColumns: [{ field: "reviewReasonDescription" }]
	}
];
