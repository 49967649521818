import React, { useRef } from "react";
import { DataTable } from "components/controls/DataTable";
import constants from "utils/constants";
import PageSection from "components/controls/PageSection";
import { useLoadData } from "./useLoadData";
import { columns } from "./columns";
import { useSearchState } from "./useSearchState";
import { usePopup } from "../PriceReset/usePopup";
import { PriceResetPopUp } from "../PriceReset/PopUp";

export const UserModifiedUnitGroups = () => {
	const { onPage, onSort, searchState } = useSearchState();
	const { localState, isLoading } = useLoadData(searchState);
	const hideColumnsRef = useRef<HTMLDivElement>(null);
	const showingRef = useRef<HTMLDivElement>(null);
	const { handleClose, handleOpen, open } = usePopup();

	return (
		<PageSection
			title={constants.titleSection.userModifiedUnitGroups}
			leftContent={<div ref={showingRef} />}
			rightContent={<div ref={hideColumnsRef} />}
		>
			<DataTable
				onPage={onPage}
				onSort={onSort}
				columns={columns}
				loading={isLoading}
				data={localState?.items}
				showingRef={showingRef}
				searchState={searchState}
				onRowDoubleClick={(e) => handleOpen({ e, completed: true })}
				hideColumnsRef={hideColumnsRef}
				totalRecords={localState?.totalCount}
				tableName={constants.tableContent.exclusionsTitle}
				emptyMessage={constants.tableContent.exclusionsEmpty}
			/>
			<PriceResetPopUp
				open={open}
				onClose={handleClose}
				columns={columns}
				sectionTitle={constants.titleSection.userModifiedUnitGroups}
			/>
		</PageSection>
	);
};
