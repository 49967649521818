import React from "react";
import PageSection from "components/controls/PageSection";

import { ChangePriceHold } from "./ChangePriceHold";
import useStyle from "./useStyle";
import constants from "utils/constants";

export const RecommendationSection = () => {
	const classes = useStyle();
	return (
		<div className={classes.container}>
			<PageSection title={constants.titleSection.priceAdjustment}>
				<ChangePriceHold />
			</PageSection>
		</div>
	);
};
