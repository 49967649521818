/* eslint-disable react-hooks/exhaustive-deps */
import { useConfigureAutomationFilterContext } from "../Context";
import { mapFilterKeys } from "./mapFilterKeys";
import { hierarchyMapper } from "./hierarchyMapper";
import { useConfigureAutomationService } from "components/forms/PricingAutomation/services/configureAutomation.service";
import { useQuery } from "@tanstack/react-query";

export const useLoadData = () => {
	const { getHierarchyLevels, getConfigPageFilters } = useConfigureAutomationService();
	const { filters } = useConfigureAutomationFilterContext();
	const { data, isInitialLoading } = useQuery({
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		queryKey: ["configure-automation-filters", filters],
		queryFn: async ({ signal }) => getConfigPageFilters(mapFilterKeys(filters), signal)
	});

	const hierarchyQuery = useQuery({
		refetchInterval: false,
		keepPreviousData: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		queryKey: ["hierarchy-filter"],
		queryFn: async ({ signal }) => getHierarchyLevels(signal)
	});

	const filterOptions = {
		hierarchyLevels: hierarchyMapper(hierarchyQuery.data?.hierarchyLevels),
		divisions: data?.divisions,
		counties: data?.counties,
		properties: data?.properties,
		unitGroups: data?.unitGroups
	};

	const isLoading = hierarchyQuery.isInitialLoading || isInitialLoading;

	return { filterOptions, isLoading };
};
