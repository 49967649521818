import React from "react";
import { PieChart, Pie, Cell, Tooltip, TooltipProps } from "recharts";
import useStyle from "./useStyle";
import { AtrScore } from "components/forms/PricingAutomation/interfaces";
import { Card } from "primereact/card";

const cx = 105;
const cy = 120;
const iR = 50;
const oR = 100;

interface Props {
	data: AtrScore[];
}

const fallBackData = [{ name: "empty", count: 1 }];

export const Chart = ({ data }: Props) => {
	const classes = useStyle();
	const isEmpty = data.every(({ count }) => !count);

	const CustomTooltip = ({ active, payload }: TooltipProps) => {
		if (active && payload && payload.length) {
			return (
				<Card
					title={payload[0].payload.range}
					subTitle={`Total: ${payload[0].value}`}
					className={classes.tooltip}
				/>
			);
		}
		return null;
	};

	return (
		<PieChart width={220} height={180} className={classes.chart}>
			<Pie
				isAnimationActive={false}
				dataKey="count"
				startAngle={180}
				endAngle={0}
				data={isEmpty ? fallBackData : data}
				cx={cx}
				cy={cy}
				innerRadius={iR}
				outerRadius={oR}
				stroke="none"
			>
				{isEmpty ? (
					<Cell className={classes.empty} />
				) : (
					data.map((entry, index) => <Cell key={`cell-${index}`} className={classes[entry.name]} />)
				)}
			</Pie>
			{!isEmpty && <Tooltip content={<CustomTooltip />} />}
		</PieChart>
	);
};
