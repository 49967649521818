import {
  PricingMatrixClient,
  PricingMatrixPropertyForm,
  PricingMatrixDropdownSelections,
  PricingMatrixFilterOptions
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import {
  getApiEndpoint,
} from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";
import { PricingMatrixPageParams } from "components/forms/PricingMatrixPage";

export const PricingMatrixPageActionMethods = {
  get: function (
    dispatcher: React.Dispatch<PricingPlatformActions>,
    identity: Identity,
  ) {
    const getDropdownSelections = function (
      currentFilterSelected: PricingMatrixDropdownSelections,
      params: PricingMatrixPageParams,
    ): Promise<PricingMatrixDropdownSelections | null | undefined> {
      let filterOptionsEndpoint = getApiEndpoint(
        identity,
        PricingMatrixClient,
        "getMatrixFilterOptions"
      );

       var paramSelections = {...currentFilterSelected};

       if (params !== undefined && params !== null) {
        paramSelections = getRawSelectionsFromParams(params, currentFilterSelected);
        if (paramSelections.selectedProperties !== null && paramSelections.selectedProperties !== undefined) {
          getMatrices(paramSelections);
        }
      }

      return new Promise((resolve, reject) => {
        makeRemoteRequest(filterOptionsEndpoint, [paramSelections])
        .then((response) => {
          if( response === undefined)
          {
            throw new Error();
          }
          var options = response.data as PricingMatrixFilterOptions;
          dispatcher({
            type: PricingPlatformActionTypes.UpdateMatrixPageFilterOptions,
            updatedOptions: options,
          });
  
          var matchedSelections = matchOptionsWithParams(options, params);
          resolve(matchedSelections);

        }).catch((err) => {
          reject(err);
        });
      });
    };

    const updateDropdownSelections = function (
      currentFilterSelected: PricingMatrixDropdownSelections,
    ): void {
      let filterOptionsEndpoint = getApiEndpoint(
        identity,
        PricingMatrixClient,
        "getMatrixFilterOptions"
      );

      makeRemoteRequest(filterOptionsEndpoint, [currentFilterSelected])
      .then((response) => {
        if( response === undefined)
          {
            throw new Error();
          }
        var options = response.data as PricingMatrixFilterOptions;
        dispatcher({
          type: PricingPlatformActionTypes.UpdateMatrixPageFilterOptions,
          updatedOptions: options,
        });
      })
      .catch((err)=>
      {
        
      })
      ;
    };

    const getMatrices = function (
      currentFilterSelected: PricingMatrixDropdownSelections,
    ): void {
      let matrixEndpoint = getApiEndpoint(
        identity,
        PricingMatrixClient,
        "getUnitMatrices"
      );

      dispatcher({
        type: PricingPlatformActionTypes.UpdatePricingMatrixPageLoading,
        payload: true,
      });
      dispatcher({
        type: PricingPlatformActionTypes.UpdateCurrentMatrices,
        payload: undefined,
      });

      makeRemoteRequest(matrixEndpoint, [currentFilterSelected])
        .then((response) => {
          if( response === undefined)
          {
            throw new Error();
          }
          var matrices = response.data as PricingMatrixPropertyForm[];

          dispatcher({
            type: PricingPlatformActionTypes.UpdateCurrentMatrices,
            payload: matrices,
          });

          dispatcher({
            type: PricingPlatformActionTypes.UpdatePricingMatrixPageLoading,
            payload: false,
          });
        })
        .catch((err)=>
      {
        
      })
        ;
    };

    const getRawSelectionsFromParams = function(
      params: PricingMatrixPageParams,
      appliedOptions: PricingMatrixDropdownSelections
    ): PricingMatrixDropdownSelections {
      var selections: PricingMatrixDropdownSelections = {...appliedOptions};


      if (params.divisionId !== ":divisionId") {
        selections.selectedDivision = { key: params.divisionId};
      }

      if (params.propertyId !== ":propertyId") {
        selections.selectedProperties = [];
        selections.selectedProperties[0] = { key: params.propertyId};
      }


      if (params.unitGroupId !== ":unitGroupId" && params.propertyId !== ":propertyId") {
        selections.selectedUnitGroup = {key: params.unitGroupId};
      }
      if (params.unitId !== ":unitId" && params.propertyId !== ":propertyId") {
        selections.selectedUnit = {key: params.unitId};
      }

      return selections;
    }

    const matchOptionsWithParams = function(
      options: PricingMatrixFilterOptions,
      params: PricingMatrixPageParams,
    ): PricingMatrixDropdownSelections {
      var selectedDivision = undefined;
      var selectedProperties = undefined;
      var selectedUnitGroup = undefined;
      var selectedUnit = undefined;


      if (options.divisions !== null && options.divisions !== undefined) {
        selectedDivision = options.divisions.find(x => x.key === params.divisionId);
      }
      if (options.properties !== null && options.properties !== undefined) {
        selectedProperties = options.properties.filter(x=> x.key === params.propertyId);
      }

      if (options.unitGroups !== null && options.unitGroups !== undefined) {
        selectedUnitGroup = options.unitGroups.find(x => x.key === params.unitGroupId);
      }

      if (options.units !== null && options.units !== undefined) {
        selectedUnit = options.units.find(x => x.key === params.unitId);
      }

      var dropdownSelections: PricingMatrixDropdownSelections = {
        selectedDivision: selectedDivision, 
        selectedProperties: selectedProperties, 
        selectedUnitGroup: selectedUnitGroup,
        selectedUnit: selectedUnit
      };
      return dropdownSelections;
    }


    return {
      getDropdownSelections,
      updateDropdownSelections,
      getMatrices,
    };
  }
};
