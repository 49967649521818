import React, { useState, useEffect } from "react";
import { withLabel } from "../Label";
import useStyle from "./useStyle";
import { InputText } from "primereact/inputtext";
import ValidationWrapper from "../ValidationWrapper";

export interface InputProps {
  value: string | undefined | null;
  onChange?: (value: string) => void;
  onUnfocus?: (value: string) => void;
  validationMessage?: string | null | undefined;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = function(props) {
  const classes = useStyle(props);
  const [input, setInput] = useState(props.value || "");

  useEffect(() => {
    setInput(props.value || "");
  }, [props.value]);

  return (
    <span className="pricingPlatformPrimeOverride-input-with-validation">
      <ValidationWrapper showErrorState={props.validationMessage != null}>
        <InputText
          value={input}
          className={classes.input}
          tooltip={props.validationMessage}
          tooltipOptions={{ event: "hover" }}
          disabled={props.disabled}
          onChange={e => {
            const value = e.currentTarget.value;
            setInput(value);

            if (props.onChange) {
              props.onChange(value);
            }
          }}
          onBlur={e => {
            if (props.onUnfocus) {
              props.onUnfocus(input || "");
            }
          }}
          onKeyDownCapture={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (props.onUnfocus) {
                props.onUnfocus(input || "");
              }
            }
          }}
        />
      </ValidationWrapper>
    </span>
  );
};
export default withLabel(Input);
