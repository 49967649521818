import {
	AutomationValidationClient,
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";
import windowUtils from "utils/windowUtils";

export const AutomationValidationReportPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {

		const getCsvForRun = function (runId: string): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, AutomationValidationClient, "getCsvForRun");
			makeRemoteRequest(filterOptionsEndpoint, [runId]).then((response) => {
				const data = response.data;
				if (data && data.message) {
					windowUtils.openLink(data.message, false);
				}
			});
		};

		const getAutomationRuns = function (): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, AutomationValidationClient, "getAutomationRuns");
			dispatcher({
				type: PricingPlatformActionTypes.UpdateAutomationValidationReportPageIsLoading,
				payload: true
			});
			makeRemoteRequest(filterOptionsEndpoint, []).then((response) => {
				const data = response.data;
				if (data) {
					dispatcher({
						type: PricingPlatformActionTypes.UpdateAutomationValidationReportPageReports,
						payload: data
					});
				}
			}).finally(() => {
				dispatcher({
					type: PricingPlatformActionTypes.UpdateAutomationValidationReportPageIsLoading,
					payload: false
				});
			});
		};

		return {
			getCsvForRun,
			getAutomationRuns
		};
	}
};
