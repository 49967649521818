import React from "react";
import useStyle from "./useStyle";
import { Card } from "primereact/card";
import constants from "utils/constants";
import { Chart } from "./Chart";
import { RangeList } from "./RangeList";
import { useLoadData } from "./useLoadData";

export const ScoreCard = () => {
	const classes = useStyle();
	const { atrCounts, isLoading } = useLoadData();

	if (isLoading) {
		return <span className={[classes.cardContainer, "skeleton", "p-card"].join(" ")} />;
	}

	return (
		<Card title={constants.cardTitle.scoreCard} className={classes.cardContainer}>
			<div className={classes.contentContainer}>
				<Chart data={atrCounts} />
				<RangeList data={atrCounts} />
			</div>
		</Card>
	);
};
