import React from "react";
import pageStyle from "./ChangeLog.module.css";
import { UnitGroupChangeLog } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import formats from "./formats";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatPSTDate } from "utils/miscUtils";

export interface ChangeLogInputProps {
	log: UnitGroupChangeLog[] | undefined;
}

export default function ChangeLog(props: ChangeLogInputProps) {
	const { log } = props;

	if (!log || log.length === 0) {
		return <></>;
	}

	let headerGroup = (
		<ColumnGroup>
			<Row>
				<Column field="createdDate" header="Date/Time" sortable />
				<Column field="userDisplayName" header="User" sortable />
				<Column field="baseRent" header="Base Rent" className={`${pageStyle.changeLogNumberColumn}`} />
				<Column
					field="normalizedRent"
					header="Normalized Rent"
					className={pageStyle.changeLogNumberColumn}
				/>
				<Column field="holdActivatedWithoutPriceChange" header="Held" className={pageStyle.changeLogCheckColumn} />
				<Column field="comment" header="Comment" className={pageStyle.changeLogCommentColumn} />
				<Column field="overrideReason.name" header="Override Reason" className={pageStyle.changeLogOverrideReasonColumn} />
			</Row>
		</ColumnGroup>
	);

	return (
		<div className={pageStyle.changeLog}>
			<Accordion className={pageStyle.changeLogAccordion}>
				<AccordionTab
					headerClassName={pageStyle.changeLogAccordionHeader}
					header={`Change Log (${log.length.toLocaleString()})`}
				>
					<DataTable
						value={log}
						paginator={true}
						rows={10}
						rowsPerPageOptions={[10, 20, 50]}
						dataKey="id"
						className={`p-datatable-striped  p-datatable-gridlines  p-datatable-sm ${pageStyle.changeLogTable}`}
						sortField="createdDate"
						sortOrder={-1} //descending
						headerColumnGroup={headerGroup}
						autoLayout={true}
					>
						<Column
							field="createdDate"
							header="Time"
							sortable
							className={pageStyle.changeLogTimeColumn}
							body={(e: UnitGroupChangeLog) => {
								if (typeof e.createdDate === "undefined") {
									return "N/A";
								}

								return formatPSTDate(e.createdDate);
							}}
						/>
						<Column field="userDisplayName" header="User" sortable />

						<Column
							field="baseRent"
							header="Base Rent"
							className={pageStyle.changeLogNumberColumn}
							body={(e: UnitGroupChangeLog, props: any) => {
								var change = (props.rowIndex === 0 && e.baseRentChange) ? <span>({formats.displayAsCurrencyChange(e.baseRentChange)})</span> : "";
								return <span>{formats.displayAsCurrency(e.baseRent)} {change}</span>
							}}
						/>

						<Column
							field="normalizedRent"
							header="Normalized Rent"
							className={pageStyle.changeLogNumberColumn}
							body={(e: UnitGroupChangeLog) => {
								var title = `Current normalized rent is base rent (${formats.displayAsCurrency(e.baseRent)}) + the unit group's current amenities (${formats.displayAsCurrency(e.currentAverageTotalUnitAmenities)}). Note: the average amenities at the time of this change were ${formats.displayAsCurrency(e.averageTotalUnitAmenities)}.`;

								return <span title={title}>{formats.displayAsCurrency(e.normalizedRent)}</span>;
							}}
						/>

						<Column
							field="holdActivatedWithoutPriceChange"
							header="Held"
							className={pageStyle.changeLogCheckColumn}
							body={(e: UnitGroupChangeLog) => {
								if (e.holdActivatedWithoutPriceChange) {
									return <i className="pi pi-check" title="Price reviewed."></i>;
								}
							}}
						/>

						<Column field="comment" header="Comment" className={pageStyle.changeLogCommentColumn} />
						<Column field="overrideReason.name" header="Override Reason" className={pageStyle.changeLogOverrideReasonColumn} />
					</DataTable>
				</AccordionTab>
			</Accordion>
		</div>
	);
}
