import {
	GetConcessionListResult,
	PagedResultOfSecondaryReviewGroupItem,
	PagedResultOfSecondaryReviewListForm,
	PricingAutomationClient,
	PricingAutomationOverrideClient,
	ResponsePayloadOfPriceAdjustmentResponse,
	SecondaryReviewBulkApprovalRequest,
	SecondaryReviewFilterOptions,
	SecondaryReviewSearchQuery,
	SecondaryReviewTilesResponse,
	SecondaryReviewTilesSearch,
	UnitGroupChangeLog
} from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

export const useSecondaryReviewService = () => {
	const identity = useContext(IdentityContext);

	const getSecondaryReviewsTiles = async (
		payload: SecondaryReviewTilesSearch,
		signal?: AbortSignal
	): Promise<SecondaryReviewTilesResponse> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSecondaryReviewsTiles", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as SecondaryReviewTilesResponse;
		} catch (err) {
			throw err;
		}
	};

	const getSecondaryReviewFilters = async (
		payload: SecondaryReviewSearchQuery,
		signal?: AbortSignal
	): Promise<SecondaryReviewFilterOptions> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSecondaryReviewFilters", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as SecondaryReviewFilterOptions;
		} catch (err) {
			throw err;
		}
	};

	const getSecondaryReviews = async (
		payload: SecondaryReviewSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfSecondaryReviewListForm> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getSecondaryReviews", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfSecondaryReviewListForm;
		} catch (err) {
			throw err;
		}
	};

	const getSecondaryReviewsCompletedByFilters = async (
		payload: SecondaryReviewSearchQuery,
		signal?: AbortSignal
	): Promise<PagedResultOfSecondaryReviewGroupItem> => {
		const endpoint = getApiEndpoint(
			identity,
			PricingAutomationClient,
			"getSecondaryReviewsCompletedByFilters",
			signal
		);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as PagedResultOfSecondaryReviewGroupItem;
		} catch (err) {
			throw err;
		}
	};

	const getPriceResetUnitGroupChangeLogs = async (
		{
			unitGroupId,
			num
		}: {
			unitGroupId: string | null;
			num: number;
		},
		signal?: AbortSignal
	): Promise<UnitGroupChangeLog[]> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getPriceResetUnitGroupChangeLogs", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [unitGroupId, num]);
			if (data === undefined) {
				throw new Error();
			}
			return data as UnitGroupChangeLog[];
		} catch (err) {
			throw err;
		}
	};

	const getConcessionList = async (
		payload: { unitGroupId?: string | null },
		signal?: AbortSignal
	): Promise<GetConcessionListResult> => {
		const { unitGroupId } = payload;
		const endpoint = getApiEndpoint(identity, PricingAutomationClient, "getConcessionList", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [unitGroupId]);
			if (data === undefined) {
				throw new Error();
			}
			return data as GetConcessionListResult;
		} catch (err) {
			throw err;
		}
	};

	const bulkApproveSecondaryReviews = async (
		payload: SecondaryReviewBulkApprovalRequest,
		signal?: AbortSignal
	): Promise<ResponsePayloadOfPriceAdjustmentResponse> => {
		const endpoint = getApiEndpoint(identity, PricingAutomationOverrideClient, "bulkSavePriceAdjustment", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as ResponsePayloadOfPriceAdjustmentResponse;
		} catch (err) {
			throw err;
		}
	};

	return {
		getSecondaryReviews,
		getSecondaryReviewsTiles,
		getSecondaryReviewFilters,
		getSecondaryReviewsCompletedByFilters,
		getPriceResetUnitGroupChangeLogs,
		getConcessionList,
		bulkApproveSecondaryReviews
	};
};
