import { Card } from "primereact/card";
import React from "react";
import useStyle from "../useStyle";
import { Link } from "react-router-dom";
import routes from "components/routes";
import { useAtAGlanceContext } from "../Context";

type Props = {
	title?: string;
	completed?: number;
};

export const InfoCard = ({ title = "", completed = 0 }: Props) => {
	const classes = useStyle();
	const { filters } = useAtAGlanceContext();

	const items = [{ name: "Excluded", value: completed }];
	return (
		<Link
			to={`${routes.pricingAutomation.exclusions}?division=${filters.divisionId}&reason=${title}`}
			className={classes.link}
		>
			<Card title={title} className={classes.cardContainer}>
				{items.map((item, index) => (
					<div key={index} className={classes.cardRowContent}>
						<span>{item.name}</span>
						<span>{item.value}</span>
					</div>
				))}
			</Card>
		</Link>
	);
};
