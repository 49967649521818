import React from "react";
import DropdownSelect from "components/controls/DropdownSelect";
import { useLoadData } from "./useLoadData";
import useStyle from "./useStyle";
import { useFormContext, Controller } from "react-hook-form";
import { getCapitalizeAndSplitBySpace } from "utils/miscUtils";
import { DropdownOption } from "api";

export const Fields = () => {
	const { data, isLoading } = useLoadData();
	const { control, errors } = useFormContext();
	const classes = useStyle();

	const getFormErrorMessage = (name: string) => {
		return errors[name] && <small className={classes.errorMessage}>{errors[name].displayValue?.message}</small>;
	};

	return (
		<>
			<div className={classes.dropdownsContainer}>
				{isLoading ? (
					<span role="presentation" className={[classes.loadingDropdowns, "skeleton"].join(" ")} />
				) : null}
				{data?.map((dropdown) => {
					const [label, options] = dropdown;
					const dropdownLabel = getCapitalizeAndSplitBySpace(label);
					return (
						<Controller
							defaultValue={{}}
							key={dropdownLabel}
							name={dropdownLabel}
							control={control}
							render={(field) => {
								return (
									<div className={classes.dropdowns}>
										<DropdownSelect
											filter
											options={options as DropdownOption[]}
											selectedValue={field.value}
											inputId={`newOverride_${dropdownLabel}`}
											inputLabel={dropdownLabel}
											{...field}
										/>
										{getFormErrorMessage(dropdownLabel)}
									</div>
								);
							}}
						/>
					);
				})}
			</div>
		</>
	);
};
