import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";

import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import {
	ClassificationForm,
	DropdownOption,
	UnitGroupAdminDeleteResultForm,
	UnitGroupAdminDetailForm,
	UnitGroupAdminForm,
	UnitGroupAdminResultForm,
	UnitGroupMasterForm
} from "api/pricing-platform-api";
import { UnitGroupMappingCreateMenu, UnitGroupMappingUpdateMenu } from "./ManageUnitGroupMappingMenu";
import Page from "components/layouts/Page";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import useMessageArea from "components/useMessageArea";
import IconButton, { IconType } from "components/controls/IconButton";
import PrimaryButton from "components/controls/PrimaryButton";
import Dialog from "components/controls/Dialog";
import Spinner from "components/controls/Spinner";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import constants, { pageAction } from "utils/constants";
import RequirePermission from "components/forms/RequirePermission";
import {
	getAssigned,
	getPageActionType,
	getUnassigned,
	IParams,
	listItemTemplate,
	mapToClassification,
	mapToUnitGroupMaster,
	removeItems,
	unitGroupAdminInitState,
	IUnitTypeList,
	updateAssignedUnitTypes,
	updateUnassignedUnitTypes,
	getChangedUnitTypes
} from "../MiscUnitGroupFunctions";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import Dropdown from "components/controls/Dropdown";
import { getDropDownItemIndex, isEmptyObject, isNullOrUndefinedOrEmpty } from "utils/miscUtils";
import useFirstLoadEffect from "utils/useMountEffect";

import "./_manage-unit-group.scss";
import { InputNumber } from "primereact/inputnumber";

const ManageUnitGroupAdmin: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };

	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();

	const [localState, setLocalState] = useState(unitGroupAdminInitState);
	const [selectedAssignedUnitTypes, setSelectedAssignedUnitTypes] = useState<IUnitTypeList[]>([]);
	const [selectedUnassignedUnitTypes, setSelectedUnassignedUnitTypes] = useState<IUnitTypeList[]>([]);
	const [isSaving, setIsSaving] = useState(false);
	const [pageIsDirty, setPageIsDirty] = useState(false);
	const [saveError, setSaveError] = useState(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [deleteResponse, setDeleteResponse] = useState("");

	const history = useHistory();
	const location = useLocation();
	const params = useParams<IParams>();

	const getPathParams = () => {
		if (location && !isNullOrUndefinedOrEmpty(location.pathname)) {
			if (!isEmptyObject(params)) {
				const action = getPageActionType(location.pathname);
				if (action && action === constants.pageAction.create) {
					localState.divisionId = params.divisionId;
					localState.divisionName = decodeURIComponent(params.divisionName);
					localState.propertyId = params.propertyId;
					localState.propertyName = params.propertyName;
					localState.pageAction = constants.pageAction.create;
				}

				if (action && action === constants.pageAction.update) {
					localState.divisionId = params.divisionId;
					localState.divisionName = decodeURIComponent(params.divisionName);
					localState.propertyId = params.propertyId;
					localState.propertyName = params.propertyName;
					localState.unitGroupId = params.unitGroupId;
					localState.pageAction = constants.pageAction.update;
				}

				setLocalState({
					...localState
				});
			} else {
				console.log("Invalid url.");
				redirectUnitGroupHome();
			}
		}
	};

	const resetForm = () => {
		localState.selectedUnitGroupMaster = undefined;
		localState.selectedClassification = undefined;
		localState.normalizedRent = undefined;
		localState.unitGroupMaster = [];
		localState.classification = [];
		localState.unitTypes = [];
		localState.assignedUnitTypes = [] as IUnitTypeList[];
		localState.unassignedUnitTypes = [] as IUnitTypeList[];
		localState.unassignedTotal = 0;
		localState.assignedTotal = 0;
	};

	const redirectUnitGroupHome = () => {
		resetForm();
		history.push(
			routes.unitGroupAdmin.unitgroup(
				localState.divisionId,
				encodeURIComponent(localState.divisionName),
				localState.propertyId,
				localState.propertyName
			)
		);
	};

	const loadData = () => {
		resetForm();
		getPathParams();

		if (isCreate()) {
			getUnitGroupDetailByPropertyId(localState.propertyId);
		}

		if (isEdit()) {
			getUnitGroupDetailByIds(localState.propertyId, localState.unitGroupId);
		}
	};

	const isCreate = (): boolean => {
		return localState.pageAction === constants.pageAction.create;
	};

	const isEdit = (): boolean => {
		return localState.pageAction === constants.pageAction.update;
	};

	const fillDropDown = (unitGroupAdminDetailForm: UnitGroupAdminDetailForm) => {
		if (
			unitGroupAdminDetailForm.unitGroupMasterList !== undefined &&
			unitGroupAdminDetailForm.unitGroupMasterList.length > 0
		) {
			localState.unitGroupMaster = mapToUnitGroupMaster(
				unitGroupAdminDetailForm.unitGroupMasterList as UnitGroupMasterForm[]
			);
		}

		if (
			unitGroupAdminDetailForm.classificationList !== undefined &&
			unitGroupAdminDetailForm.classificationList.length > 0
		) {
			localState.classification = mapToClassification(
				unitGroupAdminDetailForm.classificationList as ClassificationForm[]
			);
		}

		if (unitGroupAdminDetailForm.unitTypeList !== undefined && unitGroupAdminDetailForm.unitTypeList.length > 0) {
			const unitTypes = unitGroupAdminDetailForm.unitTypeList!;
			localState.unassignedUnitTypes = getUnassigned(unitTypes);
			localState.unassignedTotal = localState.unassignedUnitTypes.length;
			if (isEdit()) {
				localState.assignedUnitTypes = getAssigned(unitTypes);
				localState.assignedTotal = localState.assignedUnitTypes.length;
			}
		}

		setLocalState({
			...localState
		});
	};

	const getUnitGroupDetailByPropertyId = (propertyId: string) => {
		actions
			.getUnitGroupDetailByPropertyId(propertyId)
			.then((result: UnitGroupAdminDetailForm | boolean | undefined) => {
				const unitGroupDetail = result as UnitGroupAdminDetailForm;
				fillDropDown(unitGroupDetail);
			});
	};

	const getUnitGroupDetailByIds = (propertyId: string, unitGroupId: string) => {
		actions
			.getUnitGroupDetailByIds(propertyId, unitGroupId)
			.then((result: UnitGroupAdminDetailForm | void | boolean | undefined) => {
				const unitGroupAdminDetailForm = result as UnitGroupAdminDetailForm;
				fillDropDown(unitGroupAdminDetailForm);
				const unitGroupDetail = unitGroupAdminDetailForm.unitGroupAdminResultForm!;

				if (unitGroupDetail !== null) {
					if (unitGroupDetail.unitGroupMasterId !== undefined && unitGroupDetail.classificationId !== undefined) {
						setPageIsDirty(true);
					}

					localState.unitGroupMasterId = unitGroupDetail.unitGroupMasterId!;
					localState.classificationId = unitGroupDetail.classificationId!;

					if (unitGroupDetail.unitGroupMasterId !== null) {
						const index = getDropDownItemIndex(
							localState.unitGroupMaster!,
							unitGroupDetail.unitGroupMasterId!.toString()
						);
						localState.selectedUnitGroupMaster = localState.unitGroupMaster![index];
					}

					if (unitGroupDetail.classificationId !== null) {
						const index = getDropDownItemIndex(
							localState.classification!,
							unitGroupDetail.classificationId!.toString()
						);
						localState.selectedClassification = localState.classification![index];
					}

					setLocalState({
						...localState
					});
				}
			});
	};

	const createUnitGroup = (unitGroupForm: UnitGroupAdminForm) => {
		actions.createUnitGroup(unitGroupForm).then((unitGroup: UnitGroupAdminResultForm) => {
			if (unitGroup === undefined) {
				setSaveError(true);
				setMessageBasedOnCustomMessage(
					constants.MessageAreaMessages.ApiDownTitle,
					constants.MessageAreaMessages.ApiDownMessage,
					MessageSeverityLevel.Warning as MessageSeverity,
					false
				);
				return;
			}
			setIsSaving(false);
			redirectUnitGroupHome();
		});
	};

	const updateUnitGroup = (unitGroupForm: UnitGroupAdminForm) => {
		actions.updateUnitGroup(unitGroupForm).then((unitGroup: UnitGroupAdminResultForm) => {
			if (unitGroup === undefined) {
				setSaveError(true);
				setMessageBasedOnCustomMessage(
					constants.MessageAreaMessages.ApiDownTitle,
					constants.MessageAreaMessages.ApiDownMessage,
					MessageSeverityLevel.Warning as MessageSeverity,
					false
				);
				return;
			}
			setIsSaving(false);
			redirectUnitGroupHome();
		});
	};

	const deleteUnitGroup = (propertyId: string, unitGroupId: string) => {
		setIsSaving(true);
		actions.deleteUnitGroup(propertyId, unitGroupId).then((result: UnitGroupAdminDeleteResultForm) => {
			setIsSaving(false);
			if (result !== null || result !== undefined) {
				setDeleteConfirmation(false);
				redirectUnitGroupHome();
			} else {
				setDeleteConfirmation(false);
				setDeleteResponse(constants.message.notFound);
			}
		});
	};

	useFirstLoadEffect(() => {
		try {
			loadData();
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	useEffect(() => {}, [localState.selectedUnitGroupMaster, localState.selectedClassification]); // eslint-disable-line react-hooks/exhaustive-deps

	const dialogOnHide = (name: string) => {
		if (name === constants.dialogType.save) {
			setSaveError(false);
		}

		if (name === constants.dialogType.delete) {
			setDeleteConfirmation(false);
		}
	};

	const renderFooter = (name: string) => {
		if (name === constants.dialogType.save) {
			return (
				<div>
					<Button label={constants.buttonLabel.ok} icon="pi pi-check" onClick={() => setIsSaving(false)} />
				</div>
			);
		}

		if (name === constants.dialogType.delete) {
			return (
				<div>
					<Button
						label={constants.buttonLabel.no}
						icon="pi pi-times"
						onClick={() => setDeleteConfirmation(false)}
					/>
					<Button
						label={constants.buttonLabel.ok}
						icon="pi pi-check"
						onClick={() => {
							deleteUnitGroup(localState.propertyId, localState.unitGroupId);
						}}
					/>
				</div>
			);
		}

		return <div>'No Dialog Footer'</div>;
	};

	const handleOnChange = (input: { [key: string]: string | DropdownOption | undefined }) => {
		setLocalState((vals) => ({ ...vals, ...input }));
	};

	const onSelectUnitGroupMaster = (unitGroup: DropdownOption | undefined) => {
		const unitGroupMasterId = unitGroup && unitGroup.key ? unitGroup.key : undefined;
		let updateState = {
			...localState,
			selectedUnitGroupMaster: unitGroup,
			unitGroupMasterId: unitGroupMasterId
		};
		setLocalState({ ...updateState });

		if (isCreate()) {
			if (
				unitGroupMasterId === undefined ||
				localState.normalizedRent === undefined ||
				localState.classificationId === undefined
			) {
				setPageIsDirty(false);
			} else if (
				unitGroupMasterId !== undefined &&
				localState.normalizedRent !== undefined &&
				localState.classificationId !== undefined
			) {
				setPageIsDirty(true);
			}
		}

		if (isEdit()) {
			if (unitGroupMasterId !== undefined) {
				setPageIsDirty(true);
			} else {
				setPageIsDirty(false);
			}
		}
	};

	const onSelectClassification = (classification: DropdownOption | undefined) => {
		const classificationId = classification && classification.key ? classification.key : undefined;
		let updateState = {
			...localState,
			selectedClassification: classification,
			classificationId: classificationId
		};
		setLocalState({ ...updateState });
		if (isCreate()) {
			if (
				localState.unitGroupMasterId === undefined ||
				localState.normalizedRent === undefined ||
				classificationId === undefined
			) {
				setPageIsDirty(false);
			} else if (
				localState.unitGroupMasterId !== undefined &&
				localState.normalizedRent !== undefined &&
				classificationId !== undefined
			) {
				setPageIsDirty(true);
			}
		}

		if (isEdit()) {
			if (classificationId !== undefined) {
				setPageIsDirty(true);
			} else {
				setPageIsDirty(false);
			}
		}
	};

	const onBlurClick = () => {
		if (isCreate()) {
			if (
				localState.unitGroupMasterId === undefined ||
				localState.normalizedRent === undefined ||
				localState.classificationId === undefined
			) {
				setPageIsDirty(false);
			} else if (
				localState.unitGroupMasterId !== undefined &&
				localState.normalizedRent !== undefined &&
				localState.classificationId !== undefined
			) {
				setPageIsDirty(true);
			}
		}
	};

	const onMoveRightClick = () => {
		const updatedAssigned = updateAssignedUnitTypes(localState.assignedUnitTypes, selectedUnassignedUnitTypes);
		const updatedUnassigned = removeItems(localState.unassignedUnitTypes, selectedUnassignedUnitTypes);
		let updateState = {
			...localState,
			assignedTotal: updatedAssigned.length,
			unassignedTotal: updatedUnassigned.length,
			assignedUnitTypes: updatedAssigned,
			unassignedUnitTypes: updatedUnassigned
		};
		setLocalState({ ...updateState });
		setSelectedUnassignedUnitTypes([]);
	};

	const onMoveRightAllClick = () => {
		const updatedAssigned = updateAssignedUnitTypes(localState.assignedUnitTypes, localState.unassignedUnitTypes);
		localState.unassignedUnitTypes.length = 0;
		let updateState = {
			...localState,
			assignedTotal: updatedAssigned.length,
			unassignedTotal: 0,
			assignedUnitTypes: updatedAssigned
		};
		setLocalState({ ...updateState });
	};

	const onMoveLeftClick = () => {
		const updatedUnassigned = updateUnassignedUnitTypes(localState.unassignedUnitTypes, selectedAssignedUnitTypes);
		const updatedAssigned = removeItems(localState.assignedUnitTypes, selectedAssignedUnitTypes);
		let updateState = {
			...localState,
			assignedTotal: updatedAssigned.length,
			unassignedTotal: updatedUnassigned.length,
			assignedUnitTypes: updatedAssigned,
			unassignedUnitTypes: updatedUnassigned
		};
		setLocalState({ ...updateState });
		selectedAssignedUnitTypes.length = 0;
	};

	const onMoveLeftAllClick = () => {
		const updatedUnassigned = updateUnassignedUnitTypes(localState.unassignedUnitTypes, localState.assignedUnitTypes);
		localState.assignedUnitTypes.length = 0;
		let updateState = {
			...localState,
			assignedTotal: 0,
			unassignedTotal: updatedUnassigned.length,
			unassignedUnitTypes: updatedUnassigned
		};
		setLocalState({ ...updateState });
	};

	const renderMenu = () => {
		if (isCreate() === true) {
			return <UnitGroupMappingCreateMenu actionArea={navbarActionArea} />;
		} else {
			return <UnitGroupMappingUpdateMenu actionArea={navbarActionArea} />;
		}
	};

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllManageAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}>
					<PrimaryButton
						className="cancel-button"
						title={constants.buttonLabel.cancel}
						onClick={() => redirectUnitGroupHome()}
					/>
					<PrimaryButton
						disabled={!pageIsDirty}
						onClick={() => {
							setIsSaving(true);
							var unitTypeIdList = getChangedUnitTypes(
								localState.assignedUnitTypes,
								localState.unassignedUnitTypes
							);

							var unitGroupForm: UnitGroupAdminForm = {
								propertyId: localState.propertyId,
								unitGroupId: localState.unitGroupId!,
								unitGroupMasterId: localState.unitGroupMasterId + "",
								normalizedRent: localState.normalizedRent!,
								classificationId: localState.classificationId + "",
								unitTypes: unitTypeIdList
							};

							if (localState.pageAction === pageAction.create) {
								createUnitGroup(unitGroupForm);
							}

							if (localState.pageAction === pageAction.update) {
								updateUnitGroup(unitGroupForm);
							}
						}}
						title={constants.buttonLabel.save}
					/>
					<Dialog
						header={constants.message.dialog.save.title}
						footer={renderFooter(constants.dialogType.save)}
						isVisible={saveError}
						onHide={() => dialogOnHide(constants.dialogType.save)}
					>
						{constants.message.dialog.save.body}
					</Dialog>
				</VisibilityToggle>
			</div>
		</RequirePermission>
	);

	return (
		<Page menu={renderMenu()}>
			<Spinner isVisible={pageState.isLoading || isSaving} />
			<Card className="manage-unit-group">
				<h2>{localState.propertyName}</h2>
				<div>
					<div className="gap">
						<label className="label">
							Unit Group
							<span className="star">*</span>
						</label>
						<Dropdown
							selectedValue={localState.selectedUnitGroupMaster}
							options={localState.unitGroupMaster}
							onChange={(e) => onSelectUnitGroupMaster(e!)}
							placeholder="Select Unit Group"
							style={{ width: 400 }}
							disabled={isEdit()}
							autoFocus={true}
							tabIndex={1}
							filter
						/>
					</div>
					{isCreate() && (
						<div className="gap">
							<label htmlFor="normalizedRent" className="label gap">
								Starting Normalized Rent
								<span className="star">*</span>
							</label>
							<InputNumber
								style={{ width: 400, height: 32.11 }}
								id="normalizedRent"
								name="normalizedRent"
								placeholder={"Enter normalized rent"}
								value={localState.normalizedRent}
								mode="currency"
								currency="USD"
								locale="en-US"
								maxFractionDigits={0}
								required
								onChange={(e) => handleOnChange({ normalizedRent: e.value })}
								onBlur={() => onBlurClick()}
								tabIndex={2}
							/>
						</div>
					)}
					<div className="gap">
						<label className="label">
							Classification
							<span className="star">*</span>
						</label>
						<Dropdown
							selectedValue={localState.selectedClassification}
							options={localState.classification}
							onChange={(e) => {
								onSelectClassification(e!);
								//console.log({classification: e as DropdownOption | undefined})
								//handleOnChange({classification: e as DropdownOption | undefined})
							}}
							placeholder="Select Classification"
							style={{ width: 400 }}
							tabIndex={3}
							filter
						/>
					</div>
					<div className="gap">
						<label className="label">Assign Unit Types</label>
						<div className="row">
							<div className="col-25">
								<label className="list-title">Unassigned: ({localState.unassignedTotal})</label>
								<ListBox
									style={{ width: "20rem", height: "20rem" }}
									listStyle={{ maxHeight: "20rem" }}
									id="unassignedUnitTypes"
									optionLabel="name"
									value={selectedUnassignedUnitTypes}
									options={localState.unassignedUnitTypes}
									onChange={(e) => setSelectedUnassignedUnitTypes(e.value)}
									itemTemplate={listItemTemplate}
									multiple
									tabIndex="4"
								/>
							</div>
							<div className="col-5">
								<IconButton
									className="move-right-icon"
									icon={IconType.MoveRight}
									onClick={() => onMoveRightClick()}
								/>
								<IconButton
									className="move-right-all-icon"
									icon={IconType.MoveRightAll}
									onClick={() => onMoveRightAllClick()}
								/>
								<IconButton
									className="move-left-icon"
									icon={IconType.MoveLeft}
									onClick={() => onMoveLeftClick()}
								/>
								<IconButton
									className="move-left-all-icon"
									icon={IconType.MoveLeftAll}
									onClick={() => onMoveLeftAllClick()}
								/>
							</div>
							<div className="col-25">
								<label className="list-title">Assigned: ({localState.assignedTotal})</label>
								<ListBox
									style={{ width: "20rem", height: "20rem" }}
									listStyle={{ maxHeight: "20rem" }}
									id="assignedUnitTypes"
									optionLabel="name"
									value={selectedAssignedUnitTypes}
									options={localState.assignedUnitTypes}
									onChange={(e) => setSelectedAssignedUnitTypes(e.value)}
									itemTemplate={listItemTemplate}
									multiple
									tabIndex="5"
								/>
							</div>
							<div className="row">
								{isEdit() && (
									<PrimaryButton
										title={constants.buttonLabel.delete}
										className=" delete-button"
										disabled={deleteConfirmation}
										onClick={() => setDeleteConfirmation(true)}
									/>
								)}
							</div>
							{deleteResponse !== "" && <div className="row alert alert-danger">{deleteResponse}</div>}
							<Dialog
								header={constants.message.dialog.delete.title}
								footer={renderFooter(constants.dialogType.delete)}
								isVisible={deleteConfirmation}
								onHide={() => dialogOnHide(constants.dialogType.delete)}
							>
								{constants.message.dialog.delete.body}
							</Dialog>
						</div>
					</div>
				</div>
			</Card>
		</Page>
	);
};

export default ManageUnitGroupAdmin;
